import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { ParameterModel } from '../../../interfaces/models';
import '../../../styles/sass/form/_inputSubmit.scss';
interface InputSubmitComponentProps {
  Parameters: ParameterModel[];
  formHasErrors: boolean;
}
/* eslint-disable complexity */
const InputSubmitComponent: React.FC<InputSubmitComponentProps> = ({
  Parameters,
  formHasErrors,
}: InputSubmitComponentProps) => {
  const [Title, setTitle] = useState<string>();
  const [FontSizeTitle, setFontSizeTitle] = useState('');
  const [FontFamilyTitle, setFontFamilyTitle] = useState('');
  const [BackgroundColor, setBackgroundColor] = useState('');
  const [Padding, setPadding] = useState('');
  const [Margin, setMargin] = useState('');
  const [ColorTitle, setColorTitle] = useState('');
  const [BorderRadius, setBorderRadius] = useState('');

  useEffect(() => {
    Parameters.forEach((parameter) => {
      if (parameter.type === 'Title') {
        setTitle(parameter.value);
      } else if (parameter.type === 'FontFamilyTitle') {
        setFontFamilyTitle(parameter.value);
      } else if (parameter.type === 'FontTitle') {
        setFontSizeTitle(parameter.value);
      } else if (parameter.type === 'BackgroundColor') {
        setBackgroundColor(parameter.value);
      } else if (parameter.type === 'Padding') {
        setPadding(parameter.value);
      } else if (parameter.type === 'ColorTitle') {
        setColorTitle(parameter.value);
      } else if (parameter.type === 'Margin') {
        setMargin(parameter.value);
      }
    });

    console.log("Parameter BackgroundColor",Title,BackgroundColor);
  }, [Parameters]);

  const fontSizeTitle =
    FontSizeTitle === 'Small'
      ? '17'
      : FontSizeTitle === 'Medium'
      ? '21'
      : FontSizeTitle === 'Big'
      ? '30'
      : FontSizeTitle === 'ExtraLarge'
      ? '42'
      : '';

  return (
    <Button
      name="inputButton"
      className={formHasErrors ? 'submit-disabled' : ''}
      style={{ margin: '0px 0px 48px 20px', maxWidth: 'calc(100vw - 40px)' }}
    >
      <input
        type="submit"
        value={Title}
        style={{
          width: 'auto',
          cursor: 'pointer',
          borderRadius: BorderRadius ? BorderRadius : '50px',
          fontSize: fontSizeTitle + 'px',
          backgroundColor: formHasErrors ? 'gray' : BackgroundColor,
          fontFamily: FontFamilyTitle,
          padding: Padding,
          margin: Margin,
          color: formHasErrors ? '' : ColorTitle,
          whiteSpace: 'pre-line'
        }}
      />
    </Button>
  );
};

export default InputSubmitComponent;
