import { Button, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { HashLink } from 'react-router-hash-link';
import { ParameterModel } from '../interfaces/models';
import '../styles/sass/_buttonComponent.scss';

interface ButtonComponentProps {
  parameters: ParameterModel[];
}
const ButtonComponent: React.FC<ButtonComponentProps> = ({ parameters }: ButtonComponentProps) => {
  const [FontFamilyText, setFontFamilyText] = useState('');
  const [ColorTextButton, setColortText] = useState('');
  const [fontText, setFontText] = useState('');
  const [Text, setText] = useState('');
  const [BorderRadius, setBorderRadius] = useState('');
  const [BackgroundColor, setBackgroundColor] = useState('');
  const [link, setLink] = useState('');
  const [Padding, setPadding] = useState('');
  const [FontWeightText, setFontWeightText] = useState('');
  const [FlexibleFontSizeText, setFlexibleFontText] = useState<string>('');
  const [colorText, setColorText] = useState('');
  const [realButtonFontSize, setRealButtonFontSize] = useState('');
  const [PDFUrl, setPDFUrl] = useState('');
  useEffect(() => {
    parameters.forEach((parameter) => {
      if (parameter.type === 'Text') {
        setText(parameter.value);
      } else if (parameter.type === 'ColorTextButton') {
        setColortText(parameter.value);
      } else if (parameter.type === 'FontFamilyText') {
        setFontFamilyText(parameter.value);
      } else if (parameter.type === 'FontTextButton') {
        setFontText(parameter.value);
      } else if (parameter.type === 'BorderRadius') {
        setBorderRadius(parameter.value);
      } else if (parameter.type === 'BackgroundColor') {
        setBackgroundColor(parameter.value);
      } else if (parameter.type === 'LinkUrl') {
        let value = parameter.value;
        if (value.includes('wa.link') && (!value.includes('https') || !value.includes('http'))) {
          value = 'https://' + value;
        }
        setLink(value);
      } else if (parameter.type === 'Padding') {
        setPadding(parameter.value);
      } else if (parameter.type === 'FontWeightText') {
        setFontWeightText(parameter.value);
      } else if (parameter.type === 'FlexibleFontSizeText') {
        setFlexibleFontText(parameter.value);
      } else if (parameter.type === 'ColorText') {
        setColorText(parameter.value);
      } else if (parameter.type === 'PDFUrl') {
        setPDFUrl(parameter.value);
      }
    });
  }, [parameters]);

  const scrollWithOffset = (el: any) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    let elementAbouUs = document.getElementById(el.id);
    let paddingComponent = Number(elementAbouUs?.style.paddingTop.replace('px', ''));
    const yOffset = -(106 - paddingComponent);
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  const fontSizeText =
    fontText === 'Small'
      ? '17px'
      : fontText === 'Medium'
      ? '21px'
      : fontText === 'Big'
      ? '31px'
      : fontText === 'ExtraLarge'
      ? '42px'
      : '';

  const isExternalLink = link.includes('http:') || link.includes('https:');

  const FontText = FlexibleFontSizeText !== '' ? FlexibleFontSizeText : fontSizeText;

  const ColorText = ColorTextButton !== '' ? ColorTextButton : colorText;

  const matches = useMediaQuery('(max-width:400px)');

  useEffect(() => {
    getFontSize();
  }, [FlexibleFontSizeText, FontText, matches]);
  const getFontSize = () => {
    let fontSize = matches ? descompFontSize(FontText).pxValue / 15 + 3 + 'vw' : FontText;
    setRealButtonFontSize(fontSize);
  };

  const descompFontSize = (value: string) => {
    if (value.includes('px') || value.includes('em') || value.includes('rem')) {
      let fontNumber = { pxValue: 17, value: 17 };
      let lengthUnit = value.includes('rem') ? 3 : 2;
      try {
        fontNumber.value = parseInt(value.slice(0, value.length - lengthUnit), 10);
        fontNumber.pxValue = value.includes('px') ? fontNumber.value : fontNumber.value * 16;
      } catch (error) {
        console.error(error);
      }
      return fontNumber;
    } else {
      try {
        let fontNumber = { value: parseInt(value, 10) || 17, pxValue: parseInt(value, 10) };
        return fontNumber;
      } catch (error) {
        console.error(error);
      }
    }
    return { pxValue: 17, value: 17 };
  };

  const renderButton = () => {
    return (
      <Button
        name="textButton"
        variant="contained"
        style={{
          fontFamily: FontFamilyText,
          fontSize: realButtonFontSize,
          color: ColorText,
          borderRadius: BorderRadius,
          backgroundColor: BackgroundColor,
          padding: Padding,
          fontWeight: FontWeightText,
        }}
      >
        {Text}
      </Button>
    );
  };
  return (
    <div className="buttonComponent">
      {
        PDFUrl !== '' ? (
          <a target='_blank' href={PDFUrl}>
            {renderButton()}
          </a>
        ):
      isExternalLink ? (
        <a target="_blank" href={link}>
          {renderButton()}
        </a>
      ) : (
        <HashLink to={link} scroll={scrollWithOffset}>
          {renderButton()}
        </HashLink>
      )}
    </div>
  );
};

export default ButtonComponent;
