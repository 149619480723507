import { fetchAppSettings } from '../cms/services/AppSettingsService';
import { ComponentClassType } from '../interfaces/enums';
import { AppSettings, ComponentViewModel, FullViewModel, InitDataModel, ParameterModel } from '../interfaces/models';
import { getByParentId } from './ComponentService';
import { fetchInitComponentsData, fetchInitData, fetchInitDataNonRecursive } from './ViewServices';

interface InitDataServiceInterface {
  getInitData: Function;
}

interface ParamsConstHeaderInterface {
  metaTagTitle?: string;
  metaTagDomain?: string;
  webIcon?: string;
  titleFormat?: string;
  MainActivityIndicator?: string;
}

class InitDataService implements InitDataServiceInterface {
  
  private static instance: InitDataService;

  private static initDataModelMaster: Map<string, InitDataModel> = new Map();

  private static appSettings: AppSettings;

  
  // @ts-ignore
  public static getInstance  (): InitDataService { 
    if (!InitDataService.instance) {
      InitDataService.instance = new InitDataService();
    }
    return InitDataService.instance;
  }

  private constructor() {}

  public async getInitData(language: string, setInitData:(initData: InitDataModel | undefined) => void) {
    if(InitDataService.initDataModelMaster.get(language)) {
      return InitDataService.initDataModelMaster.get(language);
    }else{
      let appSettings = await this.getAppSettings();
      console.log("This is app settins to change recursive function => ",appSettings);
      appSettings.recursiveInitialFunction ?  this.getInitComponentsDataRecursive(language, setInitData) : this.getInitComponentsDataNonRecursive(language, setInitData)
    }
  }

  public async getAppSettings(): Promise<AppSettings> {
    if(!InitDataService.appSettings){
      InitDataService.appSettings = await this.initAppSettings();
      return InitDataService.appSettings; 
    }else{
      return InitDataService.appSettings;
    }
  }


  public async getInitAppData(): Promise<Map<string,any>>{
    let initAppData = new Map<string,any>();
    let appSettings = await this.getAppSettings();
    initAppData.set("AppSettings", appSettings);
    return initAppData;
  }

  

  
  public getServiceInitData(language: string){
    return InitDataService.initDataModelMaster.get(language);
  }

  public setServiceInitData(language: string, initData: InitDataModel){
    InitDataService.initDataModelMaster.set(language, initData);
  }
  
  private getInitComponentsDataRecursive = async (language: string, setInitData: (InitData: InitDataModel | undefined) => void) => {
    console.log("Get initial data recursively")
    let initComponentsData = await fetchInitComponentsData(language);
    if(initComponentsData && initComponentsData.menuView.components.length > 0){
      let componentsMenuView = structuredClone(initComponentsData.menuView.components);
      await this.getComponentsByParent(componentsMenuView, componentsMenuView,initComponentsData, language, setInitData);
    }
  };

  private getInitComponentsDataNonRecursive = async (language: string, setInitData: (InitData: InitDataModel | undefined) => void) => {
    console.log("Get initial data non recursively");
    let initComponentsData = await fetchInitDataNonRecursive(language);
    console.log("This is the data obtained => ",initComponentsData);
    if(initComponentsData && initComponentsData.menuView.components.length > 0){
      let componentsMenuView = structuredClone(initComponentsData.menuView.components);
      let newInitData = structuredClone(initComponentsData);
      newInitData.menuView.components =  componentsMenuView;
      setInitData(newInitData);
      InitDataService.getInstance().setServiceInitData(language, newInitData);
    
    }
  };

  

  // eslint-disable-next-line max-params
  private getComponentsByParent = async (components: ComponentViewModel[], parentComponents: ComponentViewModel[], initData: InitDataModel, language: string, setInitData:((initData: InitDataModel | undefined) => void)) => {
    if(!components) return ;
    let children: ComponentViewModel[] = []; 
    for(const component of components) {
      children = await getByParentId(component.id, language);
      component.children = children;
      this.getComponentsByParent(component.children, parentComponents, initData, language, setInitData)
    };
    let newInitData = structuredClone(initData);
    newInitData.menuView.components =  parentComponents;
    setInitData(newInitData);
    InitDataService.getInstance().setServiceInitData(language, newInitData);
  }




  private async initAppSettings(){
    let appSettings = await fetchAppSettings();
    let appSet: AppSettings;
    try {
      appSet = appSettings as AppSettings;
    } catch (error) {
      appSet = {
        hideBrightsoftLoader: false,
        hideCandidates: false,
        hideContacts: false,
        facebookPixelToken: "",
        googleManagerToken: "",
        languages: ["ES","EN"],
        hideBackground: true,
        mainActivityIndicator: "",
        websiteTitle: "Brightsoft",
        domain: "",
        titleFormat: "",
        hideLanguage: false,
        recursiveInitialFunction: true,
      }
    } 
    return appSet;
  }


}
export default InitDataService;
