import React, { useState, RefObject, SetStateAction, createRef, Dispatch, useEffect } from 'react';
import '../../styles/sass/nav/_submenulist.scss';
import { LinkParam } from '../../interfaces/interfaces';
import SubMenuItem from './SubMenuItem';
import { Grid } from '@mui/material';
import { Height } from '@mui/icons-material';

interface SubMenuListProps {
  name?: string;
  // lastLinkHovered?: string;
  itemList: LinkParam[];
  hasLeftLine?: boolean;
  subItem: boolean;
  setSubMenuHeight?: Dispatch<SetStateAction<number>>;
  colorText: string;
  FontFamilyText: string;
  parentIndex: number;
  setIndexTitle: (index: number) => void;
}

const SubMenuList: React.FC<SubMenuListProps> = ({
  name,
  itemList,
  hasLeftLine,
  subItem,
  setSubMenuHeight,
  colorText,
  FontFamilyText,
  parentIndex,
  setIndexTitle
}: SubMenuListProps) => {
  const [lastSubItemHoverted, setLastSubItemHoverted] = useState('');
  const subMenuGrid = createRef<HTMLDivElement>();

  useEffect(() => {
    if (setSubMenuHeight) {
      setSubMenuHeight(subMenuGrid.current?.scrollHeight ? subMenuGrid.current?.scrollHeight : 0);
    }
  }, [lastSubItemHoverted]);


  return (
    <>
      <Grid
        item
        className={hasLeftLine ? 'has-left-line' : ''}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        style={{ minWidth: '265px' }}
      >
        <div className="sub-menu-list" ref={subMenuGrid}>
          <div className="list">
            {itemList.map((item, index) => {
              return (
                <SubMenuItem
                  subItem={subItem}
                  key={index}
                  isHovered={item.Text === lastSubItemHoverted}
                  text={item.Text}
                  link={item.Link}
                  setLastSubItemHoverted={(e: string) => setLastSubItemHoverted(e)}
                  colorText={colorText}
                  FontFamilyText={FontFamilyText}
                  parentIndex={parentIndex}
                  setIndexTitle={setIndexTitle}
                />
              );
            })}
          </div>
        </div>
      </Grid>
      {itemList.map((item, index) => {
        return item?.SubLinkList && item?.SubLinkList?.length > 0 && item.Text === lastSubItemHoverted ? (
          <SubMenuList
            subItem={true}
            key={index}
            name={item.Text}
            itemList={item.SubLinkList}
            hasLeftLine={true}
            colorText={colorText}
            FontFamilyText={FontFamilyText}
            parentIndex={parentIndex}
            setIndexTitle={setIndexTitle}
          />
        ) : null;
      })}
    </>
  );
};

export default SubMenuList;
