import React, { useRef, useEffect, useState, CSSProperties } from 'react';
import useWindowDimensions from '../Background/WindowSize';
import '../../styles/sass/flipcard/_contentcard.scss';
import { Box, useMediaQuery } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
interface ContentCardWithoutImageProps {
  text: string;
  cardSize: string;
  linkUrl?: string;
  isLink?: boolean;
  onClickArea?: Function;
  isLinkNewTab?: boolean;
  description?: string;
  windowSize?: string;
  id: string;
  bgSizeCard?: string | undefined;
  isOpenState?: boolean | undefined;
  FontFamilyText: string;
  maxHeight: string;
}

const ContentCardWithoutImage: React.FC<ContentCardWithoutImageProps> = ({
  text,
  cardSize,
  onClickArea,
  description,
  isOpenState,
  id,
  FontFamilyText,
  maxHeight
}: ContentCardWithoutImageProps) => {
  const { height, width } = useWindowDimensions();
  let cardRef = useRef<HTMLDivElement>(null);
  let [currentHeight, setCurrentHeight] = useState(0);

  const matches = useMediaQuery('(min-width:900px)');
  useEffect(() => {
    let elm = cardRef.current as Element;
    if (elm.scrollWidth < 400 && matches) {
      setCurrentHeight(elm.scrollWidth - 180);
    } else {
      setCurrentHeight(elm.scrollWidth);
      
    }
  }, [currentHeight, window.innerWidth, window.innerHeight, height, width]);

  let contentCardClass = cardSize === 'STD' ? 'content-cardSTD' : 'content-cardAllSizes';

  let contentCardClass2 = cardSize === 'STD' ? 'content-cardSTD2' : 'content-cardAllSizes2';

  let styleButton = {
    backgroundColor: 'transparent',
    border: 'none',
    margin: 'auto',
  };

  let styleCard = { height: `${maxHeight !== ' ' ?  maxHeight : currentHeight}px` };

  const handleClickBottom = (e: any) => {
    if (onClickArea) {
      onClickArea(e);
    }
  };

  const buttonElement = () => {
    return (
      <button name="standardTextButton" style={styleButton} onClick={handleClickBottom}>
        <div className="div-click-area" style={{ display: 'block', cursor: 'pointer' }}>
          <div id={'text-center-' + id} className="text-center" style={{ padding: '15px', fontFamily: FontFamilyText }}>
            {text}
          </div>
        </div>
      </button>
    );
  };

  return (
    <div
      style={{ position: 'relative', backgroundColor: '#115173', cursor: 'pointer' }}
      id={'content-card-' + id}
      title={description}
      onClick={handleClickBottom}
    >
      <div id={'content-card-bg-' + id} ref={cardRef} className={contentCardClass2} />
      <Box id={'content-card-content-' + id} className={contentCardClass} style={styleCard}>
        {buttonElement()}
        <div className="bottom">
            {isOpenState && isOpenState !== undefined ? (
              <ExpandLessIcon
                fontSize="large"
                style={{ fill: '#00a1df' }}
                onClick={(e) => {
                  e.preventDefault();
                  handleClickBottom(e);
                }}
              />
            ) : (
              <ExpandMoreIcon
                fontSize="large"
                style={{ fill: '#00a1df' }}
                onClick={(e) => {
                  e.preventDefault();
                  handleClickBottom(e);
                }}
              />
            )}
          </div>
      </Box>
    </div>
  );
};
export default ContentCardWithoutImage;
