import { createNewCandidate } from '../cms/services/CareersService';
import { createNewContact } from '../cms/services/ContactService';
import { ComponentClassType } from '../interfaces/enums';
import { LinkParam, LinkParamInit } from '../interfaces/interfaces';
import {
  CandidateDto,
  CandidateModel,
  CandidateToken,
  CaptchaParams,
  CMSViewChangesModel,
  ComponentViewModel,
  ContactDto,
  ContactModel,
  ContactToken,
  FullViewModel,
  InitDataModel,
  InitDataModelWithComponent,
  instanceOfValidationErrorModel,
  ParameterModel,
  ValidationErrorModel,
  ViewDto,
  ViewModel,
  ViewsChangesDto,
} from '../interfaces/models';
import { ConsumeRestAPIEndPoint, RequestOptions } from './ApiRestServiceCommon';
import * as _ from 'lodash';


const ViewService = () => {};

export default ViewService;

const baseUrl = process.env.REACT_APP_API_URL;

export const fetchViewList = async (): Promise<Array<ViewModel>> => {
  let optionRequest = await RequestOptions('GET', null);
  try {
    let viewList: ViewModel[] = await ConsumeRestAPIEndPoint('/api/v1/Views', optionRequest);
    return viewList || [];
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const postView = async (view: FullViewModel): Promise<FullViewModel | ValidationErrorModel | null> => {
  let optionRequest = RequestOptions('POST', view);
  try {
    let result: FullViewModel | ValidationErrorModel | null = await ConsumeRestAPIEndPoint(
      '/api/v1/Views',
      optionRequest,
    );
    return result;
  } catch (e) {
    console.error(e);
  }
  return null;
};

export const postViewChanges = async (changes: ViewsChangesDto): Promise<CMSViewChangesModel | ValidationErrorModel | null> => {
  let optionRequest = RequestOptions('POST', changes);
  try {
    let result: CMSViewChangesModel | ValidationErrorModel | null = await ConsumeRestAPIEndPoint(
      '/api/v1/ViewChanges',
      optionRequest,
    );
    return result;
  } catch (e) {
    console.error(e);
  }
  return null;
};

export const postSaveNewView = async (view: ViewDto): Promise<CMSViewChangesModel | ValidationErrorModel | null> => {
  let optionRequest = RequestOptions('POST', view);
  try {
    let result: CMSViewChangesModel | ValidationErrorModel | null = await ConsumeRestAPIEndPoint(
      '/api/v1/Views',
      optionRequest,
    );
    return result;
  } catch (e) {
    console.error(e);
  }
  return null;
};

let cache: any = {};

export const clearCache = () => {
  // console.log('Clearing cache!');
  cache = {};
};

export const fetchInitData = async (lang: string | undefined): Promise<InitDataModel> => {
  const language = lang ? lang : 'EN';
  const key = 'fetchInitData-' + language;
  let current: Promise<InitDataModel> = cache[key];
  if (current) {
    current = new Promise((resolve, reject) => {
      current.then((result) => resolve(result)).catch((error) => reject(error));
    });
    return current;
  }

  const optionRequest = RequestOptions('GET', null);
  current = ConsumeRestAPIEndPoint(`/api/v1/Views/initData/${language}`, optionRequest);
  cache[key] = current;
  return current;
};


export const fetchInitComponentsData = async  (lang: string | undefined) => {
  const language = lang ? lang : 'EN';
  const key = 'fetchInitData-' + language;
  let current: Promise<InitDataModel> = cache[key];
  if (current) {
    current = new Promise((resolve, reject) => {
      current.then((result) => resolve(result)).catch((error) => reject(error));
    });
    return current;
  }
  const optionRequest = RequestOptions('GET', null);
  current = ConsumeRestAPIEndPoint(`/api/v1/Views/initComponentsData/${lang}`, optionRequest);
  cache[key] = current;
  return current;
};

export const fetchInitDataNonRecursive= async  (lang: string | undefined) => {
  const language = lang ? lang : 'EN';
  const key = 'fetchInitData-' + language;
  let current: Promise<InitDataModel> = cache[key];
  if (current) {
    current = new Promise((resolve, reject) => {
      current.then((result) => resolve(result)).catch((error) => reject(error));
    });
    return current;
  }
  const optionRequest = RequestOptions('GET', null);
  current = ConsumeRestAPIEndPoint(`/api/v1/Views/initDataNonRecursive/${lang}`, optionRequest);
  cache[key] = current;
  return current;
};


export const fetchInitChildrenComponentsData = async (lang: string | undefined): Promise<InitDataModel> => {
  const language = lang ? lang : 'EN';
  const key = 'fetchInitChildrenComponentsData-' + language;
  let current: Promise<InitDataModel> = cache[key];
  if (current) {
    current = new Promise((resolve, reject) => {
      current.then((result) => resolve(result)).catch((error) => reject(error));
    });
    return current;
  }

  const optionRequest = RequestOptions('GET', null);
  current = ConsumeRestAPIEndPoint(`/api/v1/Views/initChildrenComponentsData/${language}`, optionRequest);

  cache[key] = current;
  return current;
};

export const fetchViewByIdLang = async (viewId: string, lang: string | undefined): Promise<FullViewModel> => {
  const language = lang ? lang : 'EN';
  const key = `fetchViewByIdLang-${viewId}-${language}`;
  let current: Promise<FullViewModel> = cache[key];
  if (current) {
    current = new Promise((resolve, reject) => {
      current.then((result) => resolve(result)).catch((error) => reject(error));
    });
    return current;
  }

  // console.log('not getting from cache: %s', key);
  const optionRequest = await RequestOptions('GET', null);
  current = ConsumeRestAPIEndPoint(`/api/v1/Views/${viewId}/${language}`, optionRequest);

  cache[key] = current;
  return current;
};

export const filterViewByName = (name: string, list: Array<ViewModel>): ViewModel | null => {
  let res: ViewModel | null = null;
  const foundItem = list.filter((item) => item.name === name)[0];
  res = foundItem ? foundItem : null;
  return res;
};

export const filterViewByNameCapital = (name: string, list: Array<ViewModel>): ViewModel | null => {
  let res: ViewModel | null = null;
  const foundItem = list.filter((item) => item.name.toLocaleUpperCase() === name.toLocaleUpperCase())[0];
  res = foundItem ? foundItem : null;
  return res;
};

export const contactUsPost = async (
  inputValues: any,
  captchaParams: CaptchaParams,
  resetAction: () => void,
): Promise<ContactModel | ValidationErrorModel | null> => {
  let contactDto: ContactDto = {
    firstName: inputValues.firstNameInput,
    lastName: inputValues.lastNameInput,
    email: inputValues.emailInput,
    phone: inputValues.phoneInput,
    country: inputValues.countryInput,
    company: inputValues.companyInput,
    description: inputValues.descriptionInput,
    createdAt: new Date(),
  };

  let request: ContactToken = {
    token: captchaParams.token,
    contact: contactDto,
    hideCaptcha: captchaParams.hideCaptcha,
  };

  try {
    let result: ContactModel | ValidationErrorModel | null = await createNewContact(request, resetAction);
    return result;
  } catch (e) {
    console.error(e);
  }
  return null;
};

export const candidatePost = async (
  inputValues: any,
  captchaParams: CaptchaParams,
  resetAction: () => void,
): Promise<CandidateModel | ValidationErrorModel | null> => {
  const candidate: CandidateDto = {
    firstName: inputValues.firstNameInput,
    lastName: inputValues.lastNameInput,
    email: inputValues.emailInput,
    phone: inputValues.phoneInput,
    job: inputValues.jobInput,
    documentUrl: inputValues.cvInput,
    description: inputValues.descriptionInput,
    createdAt: new Date(),
  };

  let request: CandidateToken = {
    token: captchaParams.token,
    candidate: candidate,
    hideCaptcha: captchaParams.hideCaptcha,
  };
  try {
    let result: CandidateModel | ValidationErrorModel | null = await createNewCandidate(request, resetAction);
    return result;
  } catch (e) {
    console.error(e);
  }
  return null;
};

export const getHeaderParams = (view: FullViewModel): LinkParam[] => {
  console.info('ViewServices.getHeaderParams view', view);
  const componentViewModel = extractHeader(view);
  console.info('ViewServices.getHeaderParams componentViewModel', componentViewModel);
  const linkParams = getLinks(componentViewModel, 0);
  console.info('ViewServices.getHeaderParams linkParams', linkParams);
  let filteredLinkParams = filterElements(linkParams);
  console.info('ViewServices.getHeaderParams filteredLinkParams', filteredLinkParams);
  return filteredLinkParams;
};

export const getHeaderImageUrlParams = (view: FullViewModel): string[] => {
  const componentViewModel = extractHeader(view);
  let params = [''];
  if (componentViewModel) {
    let parameterImage = componentViewModel.parameters.filter((e) => {
      return e.type === 'ImageUrl' ;
    });

    params = parameterImage.map((pImageUrl) => {
      return pImageUrl.value;
    });
  }
  return params;
};

export const getAllHeaderParams = (view: FullViewModel): { [key: string]: string } => {
  const componentViewModel = extractHeader(view);
  let result: { [key: string]: string } = {};
  console.log("All params recovery => ",componentViewModel, view);
  if (componentViewModel) {
    componentViewModel.parameters.forEach((pm) => {
      result[pm.type + '_' + pm.groupId] = pm.value;
    });
  }
  return result;
};

export const getObjectFit = (view: FullViewModel): string => {
  const componentViewModel = extractHeader(view);
  let parameter = 'cover';
  if (componentViewModel) {
    let parameterObject = componentViewModel.parameters.find((e) => {
      return e.type === 'ObjectFit';
    });
    parameter = parameterObject?.value || 'cover';
  }
  
  return parameter;
}

export const getMargin= (view: FullViewModel): string => {
  const componentViewModel = extractHeader(view);
  let parameter = '';
  if (componentViewModel) {
    let parameterObject = componentViewModel.parameters.find((e) => {
      return e.type === 'Margin';
    });
    parameter = parameterObject?.value || '';
  }
  
  return parameter;
}

export const getFixed = (view: FullViewModel): boolean => {
  const componentViewModel = extractHeader(view);
  let parameter = true;
  if (componentViewModel) {
    let parameterObject = componentViewModel.parameters.find((e) => {
      return e.type === 'Fixed';
    });
    parameter = parameterObject?.value ? parameterObject.value.toLowerCase() === 'true' : false;
  }

  return parameter;
};
export const getFullWidth = (view: FullViewModel): boolean => {
  const componentViewModel = extractHeader(view);
  let parameter = true;
  if (componentViewModel) {
    let parameterObject = componentViewModel.parameters.find((e) => {
      return e.type === 'FullWidth';
    });
    parameter = parameterObject?.value ? parameterObject.value.toLowerCase() === 'true' : false;
  }

  return parameter;
};
export const getHeaderColorParameter = (view: FullViewModel): string => {
  const componentViewModel = extractHeader(view);
  let param = '';
  if (componentViewModel) {
    let element = componentViewModel.parameters.filter((e) => {
      return e.type === 'ColorText';
    });
    param = element.length > 0 ? element[0].value : '';
  }
  return param;
};

export const getFontFamilyParameter = (view: FullViewModel): string => {
  const componentViewModel = extractHeader(view);
  let param = '';
  if (componentViewModel) {
    let element = componentViewModel.parameters.filter((e) => {
      return e.type === 'FontFamilyText';
    });
    param = element.length > 0 ? element[0].value : '';
  }
  return param;
};

export const getFooterStyle = (view: FullViewModel): string => {
  const componentViewModel = extractFooter(view);
  let param = '';
  if (componentViewModel) {
    let element = componentViewModel.parameters.filter((e) => {
      return e.type === 'FooterStyle';
    });
    param = element.length > 0 ? element[0].value : '';
  }
  return param;
};

export const getMenuIconColorParameters = (view: FullViewModel): string[] => {
  const componentViewModel = extractHeader(view);
  let param = [''];
  if (componentViewModel) {
    let element = componentViewModel.parameters.filter((e) => {
      return e.type === 'ColorMenuIcon';
    });
    param = element.map((parameter) => {
      return parameter.value;
    });
  }
  return param;
};

export const getColorTitleParameters = (view: FullViewModel): string[] => {
  const componentViewModel = extractHeader(view);
  let param = [''];
  if (componentViewModel) {
    let element = componentViewModel.parameters.filter((e) => {
      return e.type === 'ColorTitle';
    });
    param = element.map((parameter) => {
      return parameter.value;
    });
  }
  return param;
};

export const getBackgroundColorParameters = (view: FullViewModel): string[] => {
  const componentViewModel = extractHeader(view);
  let param = [''];
  if (componentViewModel) {
    let element = componentViewModel.parameters.filter((e) => {
      return e.type === 'BackgroundColor';
    });
    param = element.map((parameter) => {
      return parameter.value;
    });
  }
  return param;
};

export const getLinkParamsFlatten = (view: FullViewModel): LinkParam[] => {
  let res: LinkParam[] = [];
  res = getLinksFlatten(extractHeader(view));
  return res;
};

const filterElements = (params: LinkParam[]): LinkParam[] => {
  const res: LinkParam[] = params.filter(
    (element: LinkParam) =>
      element.ViewId !== 'master' &&
      element.ViewId !== 'home' &&
      element.ViewId !== 'language' &&
      element.ViewId !== '',
  );
  return res;
};

const extractHeader = (view: FullViewModel): ComponentViewModel | null => {
  let result: ComponentViewModel | null = null;
  if (view?.components?.length > 0) {
    for (let component of view.components) {
      const componentTypeEnum = ComponentClassType;
      if (component.type === componentTypeEnum.Header) {
        result = component;
        break;
      }
    }
  }
  return result;
};

const extractFooter = (view: FullViewModel): ComponentViewModel | null => {
  let result: ComponentViewModel | null = null;
  if (view?.components?.length > 0) {
    for (let component of view.components) {
      const componentTypeEnum = ComponentClassType;
      if (component.type === componentTypeEnum.Footer) {
        result = component;
        break;
      }
    }
  }
  return result;
};

const getLinks = (component: ComponentViewModel | null, count: number): LinkParam[] => {
  let res: LinkParam[] = [];
  if (component?.children && component.children.length > 0) {
    component.children.forEach((c: ComponentViewModel) => {
      res.push(getLinkParams(c, getLinks, count));
    });
    let sorted = _.sortBy(res, 'Order', 'Text');
    res = sorted;
  }

  return res;
};

const getLinksFlatten = (component: ComponentViewModel | null): LinkParam[] => {
  let res: LinkParam[];
  let partialRes: LinkParam[] = getLinks(component, 0);
  res = flattenLinks(partialRes);
  return res;
};

const flattenLinks = (links: LinkParam[]): LinkParam[] => {
  const res: LinkParam[] = [];
  links.forEach((link) => {
    res.push(link);
    if (Array.isArray(link.SubLinkList) && link.SubLinkList.length) {
      res.push(...flattenLinks(link.SubLinkList));
    }
  });
  return res;
};

const getLinkParams = (component: ComponentViewModel, getDeepLinks: Function, count: number): LinkParam => {
  let res: LinkParam = { ...LinkParamInit };
  component.parameters.forEach((param: ParameterModel) => {
    if (param.type === 'Link') {
      res.Link = param.value;
    }
    if (param.type === 'Text') {
      res.Text = param.value;
    }
    if (param.type === 'ViewId') {
      res.ViewId = param.value;
    }
    if (param.type === 'Theme') {
      res.Theme = param.value;
    }
    if (param.type === 'Type') {
      res.Type = param.value;
    }
    if (param.type === 'ShowSubMenu' ) {
      res.ShowSubMenu = param.value.toLowerCase() === 'true' ? true : false;
    }
    if (param.type === 'HideTitle') {
      res.HideTitle = param.value.toLowerCase() === 'true' ? true : false;
    }
    res.Key = component.key;
    res.Level = count;
    res.Order = component.index;
  });

  res.SubLinkList = component?.children && component?.children?.length > 0 ? getDeepLinks(component, count + 1) : [];
  return res;
};


export const orderHeaderComponents = (LinkParamList: LinkParam[]): LinkParam[] => {
  let sortedArray: LinkParam[] = LinkParamList.sort((obj1, obj2) => {
    if (obj1.Order && obj2.Order) {
      if (obj1.Order > obj2.Order) {
        return 1;
      }

      if (obj1.Order < obj2.Order) {
        return -1;
      }
    } else {
      console.error(`bad data cannot sort: ${obj1.Key} ${obj2.Key}`);
    }

    return 0;
  });

  let counter = 0;

  for (counter; counter < sortedArray.length; counter++) {
    sortedArray[counter].SubLinkList = sortedArray[counter].SubLinkList.sort((obj1, obj2) => {
      if (obj1.Order > obj2.Order) {
        return 1;
      }

      if (obj1.Order < obj2.Order) {
        return -1;
      }

      return 0;
    });
  }

  console.log('orderHeaderComponents', sortedArray);
  return sortedArray;
};

const convertIntoNumber = (key: string): Number => {
  let counter = 0;
  let newNumber = '';

  for (counter; counter < key.length; counter++) {
    let aChar = key[counter];
    if (aChar !== 'M' && aChar !== '_') {
      newNumber = newNumber + aChar;
    }
  }

  if (newNumber.length < 1) {
    newNumber = '0';
  }

  return parseInt(newNumber, 36);
};
