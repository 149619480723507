import React from 'react';
import Button from '@mui/material/Button';
import '../../styles/sass/nav/_navlinkitem.scss';

interface NavLinkItemProps {
  text: string;
  isActive: boolean;
  isShowSubmenu: boolean;
  type?: string;
  getColorText:Function;
  FontFamilyText: string;
}

const NavLinkItem: React.FC<NavLinkItemProps> = ({
  text,
  isActive,
  isShowSubmenu,
  type,
  getColorText,
  FontFamilyText,
}: NavLinkItemProps) => {
  
  const fontFamilyText = FontFamilyText ? FontFamilyText : '';
  let showSubMenu = isShowSubmenu ? 'nav-link-show-sub-menu' : '';
  let active = isActive ? 'nav-link-active' : '';
  let isButton = type === 'Button' ? 'is-button' : '';

  return (
    <div className="nav-link-item">
      <Button name="textButton" aria-controls="simple-menu" className={`${showSubMenu} ${active} ${isButton}`}>
        <div className="font-item" style={{ color: getColorText(), fontFamily: fontFamilyText }}>
          {text}
        </div>
      </Button>
    </div>
  );
};

export default NavLinkItem;
