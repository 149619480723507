import { Grid, GridSize, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ComponentClassType } from '../../interfaces/enums';
import { ComponentViewModel, ViewModel } from '../../interfaces/models';
import { getGenericParams } from '../../utils/utils';
import useWindowDimensions from '../Background/WindowSize';
import RowFlipCard from '../Card/RowFlipCard';
import ImageComponent from '../ImageComponent/ImageComponent';
import HorizontalRule from './HorizontalRule';
import ItemFooter from './ItemFooter';

interface DynamicFooterProps {
  Component: ComponentViewModel;
  isOpen: boolean;
  type: string;
  currentLanguage?: string;
  setIndexWithText: (text: string) => void;
}

const DynamicFooter: React.FC<DynamicFooterProps> = ({
  Component,
  isOpen,
  type,
  currentLanguage,
  setIndexWithText
}: DynamicFooterProps) => {
  const [columns, setColumns] = useState(12);
  const [backgroundColor, setBackgroundColor] = useState('');
  const [padding, setPadding] = useState('');
  const [mobile, setMobile] = useState(false);
  const [tablet, setTablet] = useState(false);

  const windowSize = useWindowDimensions();

  useEffect(() => {
    setMobile(type === 'xs' || type === 'sm' ? true : false);
    setTablet(type === 'md' ? true : false);
    console.info('DynamicFooter: ' + Component.key + ' type: ' + Component.type);
    Component.parameters.forEach((parameter) => {
      console.info('DynamicFooter: ' + Component.key + ' parameter.type=' + parameter.type);
      if (parameter.type === 'BackgroundColor') {
        setBackgroundColor(parameter.value);
      } else if (parameter.type === 'Padding') {
        setPadding(parameter.value);
      } else if (parameter.type === 'Columns') {
        let cols: number = parseInt(parameter.value, 10);
        cols = isNaN(cols) ? 1 : cols;
        setColumns(cols);
      }
    });
  });

  const getImageInlined = () => {
    return (
      <Grid container direction="row">
        {Component.children.map((component, index) => {
          return component.type === 'ImageComponent' &&
            getGenericParams(component).Inlined?.toLowerCase() === 'true' ? (
            <Grid key={index} item>
              <ImageComponent params={component.parameters} />
            </Grid>
          ) : null;
        })}
      </Grid>
    );
  };

  const isWithoutDescription = (component: ComponentViewModel) => {
    if (component.parameters.length === 0) {
      return true;
    }
    return false;
  };
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const getItemsFooter = () => {
    return (
      <Grid
        container
        style={{ display: !mobile ? 'flex' : '', flexWrap: 'wrap', padding: '20px' }}
        title="footer items"
      >
        {Component.children.map((childComponent, childIndex) => {
          const genericParams = getGenericParams(childComponent);
          return childComponent.type === ComponentClassType.ItemFooter ? (
            <Grid item xs={12} md={3} lg={3} key={childComponent.key + childIndex}>
              <ItemFooter
                counter={0}
                Component={childComponent}
                isOpen={matches ? false : isOpen}
                withoutDescription={isWithoutDescription(childComponent)}
                Border={genericParams.Border}
                setIndexWithText={setIndexWithText}
                mainText={genericParams.Text ? genericParams.Text : ''}
              />
            </Grid>
          ) : null;
        })}
      </Grid>
    );
  };

  return (
    <footer
      id="footer"
      className="footer"
      style={{
        backgroundColor: backgroundColor,
        padding: padding,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 'calc(100vw - 1px)',
      }}
    >
      {getItemsFooter()}
      {getImageInlined()}

      {Component.children.map((childComponent, index) => {
        let childParams = getGenericParams(childComponent);
        return childComponent.type === ComponentClassType.Row ? (
          
          <RowFlipCard
            parameters={childComponent.parameters}
            Columns={childParams.Columns as GridSize}
            Components={childComponent.children}
            isOpen={isOpen}
            Row={true}
            Justify={windowSize.width > 780 ? 'space-between' : 'center'}
            componentKey={childComponent.key}
            key={childComponent.key + index}
            IsFooter={true}
          />
        ) : childComponent.type === ComponentClassType.HorizontalRule ? (
          <HorizontalRule key={index} type={type} parameters={childComponent.parameters} />
        ) : childComponent.type === ComponentClassType.ItemFooter ? null : (
          <div key={childComponent.key + index} style={{ backgroundColor: 'red', padding: padding }}>
            {childComponent.type} is not allowed in footer.
          </div>
        );
      })}
    </footer>
  );
};

export default DynamicFooter;
