import React, { useState } from 'react';
import { LinkParam } from '../../interfaces/interfaces';
import { Link } from 'react-router-dom';
import '../../styles/sass/sidenav/_sidenavitem.scss';
import { Button, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SideNavList from '../SideNav/SideNavList';
import { HashLink } from 'react-router-hash-link';
interface SideNavItemProps {
  parentIndex: number;
  order: number;
  item: LinkParam;
  setIsOpen: (isOpen: boolean) => void;
  setLastLinkHover: (text: string) => void;
  lastLinkHover: string;
  setIndexTitle: (index: number) => void
  mainText: string;
}

const SideNavItem: React.FC<SideNavItemProps> = ({
  parentIndex,
  order,
  item,
  setIsOpen,
  setLastLinkHover,
  lastLinkHover,
  setIndexTitle,
  mainText
}: SideNavItemProps) => {
  const [isListOpen, setIsListOpen] = useState(false);
  const scrollWithOffset = (el: any) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    let elementAbouUs = document.getElementById(el.id);
    let paddingComponent = Number(elementAbouUs?.style.paddingTop.replace('px', ''));
    const yOffset = -(106 - paddingComponent);
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  const checkTextHashLink = () => {
    if ( mainText !== item.Text){
      setIndexTitle(parentIndex)
    }
  };

  return (
    <div
      className="side-nav-item"
      onMouseEnter={(e) => {
        setLastLinkHover(item.Text);
      }}
      title={'order-' + item.Order}
    >
      <div className="title">
        <div className="left" style={{ paddingLeft: item.Level ? item.Level * 15 : 0 }}>
          {!item.Link.includes('#') ? (
            <Link to={item.Link} className={lastLinkHover === item.Text ? 'under-line' : ''} onClick={() => setIndexTitle(parentIndex)}>
              <Button name="textButton" aria-controls="simple-menu" onClick={() => setIsOpen(false)}>
                {item.Text}
              </Button>
            </Link>
          ) : (
            <HashLink to={item.Link} scroll={scrollWithOffset} onClick={() => checkTextHashLink()}>
              <Button name="text2Button" aria-controls="simple-menu" onClick={() => setIsOpen(false)}>
                {item.Text}
              </Button>
            </HashLink>
          )}
        </div>
        <div className="right">
          {item.SubLinkList.length ? (
            <div
              className="icon"
              // onClick={() => setIsListOpen(!isListOpen)}
            >
              <IconButton aria-label="Delete" onClick={() => setIsListOpen(!isListOpen)}>
                <ExpandMoreIcon />
              </IconButton>
            </div>
          ) : null}
        </div>
      </div>
      {isListOpen ? (
        <SideNavList
          itemList={item.SubLinkList}
          setIsOpen={() => {
            setIsOpen(false);
          }}
          setIndexTitle={setIndexTitle}
          parentIndex={parentIndex}
          mainText={mainText}
        />
      ) : null}
    </div>
  );
};

export default SideNavItem;
