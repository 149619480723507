/**
 * method for get request options for POST, GET, PUT
 * @param method
 * @param body
 * @constructor
 */
import { ExecException } from 'child_process';
import {
  CandidateDto,
  CandidateModel,
  ErrorModel,
  instanceOfValidationErrorModel,
  RefreshTokenModel,
  ValidationErrorModel,
} from '../interfaces/models';
import { SnackBarNotify } from './cms/ServiceRxjsCommon';
import { getTokenMemory, removeTokenMemory, updateTokenMemory } from './SessionTokenService';

/**
 * method for get request options for POST, PUT, DELETE Files
 * @param method
 * @param body
 * @constructor
 */
export const RequestOptionsFiles = (method: string, body: any) => {
  let headObj: any = {};
  let tokenObj = getAuthenticationToken();
  if (null !== tokenObj) {
    headObj.Authorization = 'Bearer ' + tokenObj.accessToken;
  }

  let result = null;
  switch (method) {
    case 'POST':
      result = getRequestConfiguration(method, headObj, null, body);
      break;

    case 'PUT':
      result = getRequestConfiguration(method, headObj, null, body);
      break;

    case 'DELETE':
      result = getRequestConfiguration(method, headObj, null, body);
      break;

    default:
      result = getRequestConfiguration(method, headObj, null, null);
      break;
  }
  return result;
};

/**
 * method for get request options
 * @param method
 * @param body
 * @constructor
 */
export const RequestOptions = (method: string, body: any) => {
  let headObj: any = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  let tokenObj = getAuthenticationToken();
  if (null !== tokenObj) {
    headObj.Authorization = 'Bearer ' + tokenObj.accessToken;
  }

  let result = null;
  switch (method) {
    case 'POST':
      result = getRequestConfiguration(method, headObj, body, null);
      break;
    case 'GET':
      result = getRequestConfiguration(method, headObj, null, null);
      break;
    case 'PUT':
      result = getRequestConfiguration(method, headObj, body, null);
      break;
    case 'DELETE':
      result = getRequestConfiguration(method, headObj, body, null);
      break;
    default:
      result = getRequestConfiguration(method, headObj, null, null);
      break;
  }
  return result;
};

export const ConsumeRestAPIEndPoint = async (
  endPointUrl: string,
  requestOptions: any,
): Promise<any | ValidationErrorModel | ErrorModel> => {
  let response: any = {
    status: 500,
    statusText: 'Unexpected error please call your system administrator.',
  };

  try {
    response = await fetch(endPointUrl, requestOptions);

    if (response.status === 401) {
      return redirectToLoginTokenExpired(endPointUrl, requestOptions);
    } else if (response.status === 204) {
      return {};
    }

    if (response.ok) {
      const result: any = await response.json();
      return result;
    } else {
      let result: any = await response.json();
      result = requestAnalyzerError(result, endPointUrl, requestOptions);
      return result;
    }
  } catch (error) {
    const newData: ValidationErrorModel = {
      errorCode: response.status.toString(),
      userMessage: response.statusText,
      interfaceName: '',
      stacktrace: '',
      developerMessage: '',
      validations: [],
    };
    const result = requestAnalyzerError(newData, endPointUrl, requestOptions);
    return result;
  }
};

/**
 * method for call Api Rest of server
 * @param endPointUrl
 * @param requestOptions
 * @constructor
 */
export const ConsumeRestAPIEndPointOld = async (
  endPointUrl: string,
  requestOptions: any,
): Promise<any | ValidationErrorModel | ErrorModel> => {
  let result: any = {};
  try {
    await fetch(endPointUrl, requestOptions)
      .then((response) => {
        if (!response.ok && response.statusText) {
          console.error('Response not OK: %o', response);
          const newData: ValidationErrorModel = {
            errorCode: response.status.toString(),
            userMessage: response.statusText,
            interfaceName: '',
            stacktrace: '',
            developerMessage: '',
            validations: [],
          };
          result = requestAnalyzerError(newData, endPointUrl, requestOptions);
          return result;
        }

        if (response.status === 204) {
          return response;
        } else if (response.status === 401) {
          return redirectToLoginTokenExpired(endPointUrl, requestOptions);
        } else {
          let jsonPromise = response.json();
          return jsonPromise;
        }
      })
      .then((data) => {
        if (data?.errorCode) {
          result = requestAnalyzerError(data, endPointUrl, requestOptions);
        } else {
          result = data;
        }
      });
    return result;
  } catch (error) {
    console.error('Catch error %o', error);
    return result;
  }
};

/**
 * method for request error analyzer
 * @param data
 */
const requestAnalyzerError = (data: ValidationErrorModel, theUrl: string, requestOptions: any) => {
  console.error(data.developerMessage || data.userMessage);
  switch (parseInt(data.errorCode, 36)) {
    case 400: {
      let message400 = data.userMessage;
      message400 = concatErrorsOfValues(data, message400);
      SnackBarNotify.sendSnackBarNotify({
        isShow: true,
        message: message400,
        type: 'error',
        durationHide: 5000,
      });
      return data;
    }
    case 401: {
      SnackBarNotify.sendSnackBarNotify({
        isShow: true,
        message: data.userMessage,
        type: 'error',
        durationHide: 5000,
      });
      return redirectToLoginTokenExpired(theUrl, requestOptions);
    }
    case 403: {
      SnackBarNotify.sendSnackBarNotify({
        isShow: true,
        message: data.userMessage,
        type: 'error',
        durationHide: 5000,
      });
      return data;
    }
    case 404: {
      SnackBarNotify.sendSnackBarNotify({
        isShow: true,
        message: data.userMessage,
        type: 'error',
        durationHide: 5000,
      });
      return data;
    }
    case 415: {
      let message415 = data.userMessage;
      message415 = concatErrorsOfValues(data, message415);
      SnackBarNotify.sendSnackBarNotify({
        isShow: true,
        message: message415,
        type: 'error',
        durationHide: 5000,
      });
      return data;
    }
    case 422: {
      let message422 = data.userMessage;
      message422 = concatErrorsOfValues(data, message422);
      SnackBarNotify.sendSnackBarNotify({
        isShow: true,
        message: message422,
        type: 'error',
        durationHide: 5000,
      });
      return data;
    }
    case 500: {
      SnackBarNotify.sendSnackBarNotify({
        isShow: true,
        message: data.userMessage,
        type: 'error',
        durationHide: 5000,
      });
      return data;
    }
    default:
      return data;
  }
};

/**
 * method for get validation
 */
const getAuthenticationToken = () => {
  let accessTokenRememberMe = localStorage.getItem('accessToken');
  let refreshTokenRememberMe = localStorage.getItem('refreshToken');
  let accessTokenSession = sessionStorage.getItem('accessToken');
  let refreshTokenSession = sessionStorage.getItem('refreshToken');

  if (accessTokenRememberMe && refreshTokenRememberMe) {
    return {
      accessToken: accessTokenRememberMe,
      refreshToken: refreshTokenRememberMe,
    };
  } else if (accessTokenSession && refreshTokenSession) {
    return {
      accessToken: accessTokenSession,
      refreshToken: refreshTokenSession,
    };
  } else {
    return null;
  }
};

/**
 * method for get result options
 * @param method
 * @param headObj
 * @param body
 * @param bodyFile
 */
/* eslint-disable max-params */
const getRequestConfiguration = (method: string, headObj: any, body: any, bodyFile: any) => {
  let result: any = {
    method: method,
    headers: headObj,
  };
  if (null !== body) {
    result.body = JSON.stringify(body);
  }
  if (null !== bodyFile) {
    result.body = bodyFile;
  }
  return result;
};

/**
 * method for refresh token update
 * @param originalRequestedUrl
 * @param requestOptions
 */
const redirectToLoginTokenExpired = async (
  originalRequestedUrl: string,
  requestOptions: any,
): Promise<any | ValidationErrorModel | null> => {
  let toSend = { refreshToken: getTokenMemory().refreshToken };
  if (!toSend.refreshToken || toSend.refreshToken === 'undefined') {
    removeTokenMemory();
    window.location.pathname = '/cms/login';
    return null;
  }

  let refreshTokenOptions = RequestOptions('POST', toSend);
  try {
    const response = await fetch('/api/v1/Users/RefreshToken', refreshTokenOptions);

    if (response.ok) {
      const refreshTokenModel: RefreshTokenModel = await response.json();
      updateTokenMemory(refreshTokenModel.accessToken, refreshTokenModel.refreshToken);
      const newRequestOptions = JSON.parse(JSON.stringify(requestOptions));
      newRequestOptions.Authorization = `Bearer ${refreshTokenModel.accessToken}`;
      return ConsumeRestAPIEndPoint(originalRequestedUrl, newRequestOptions);
    } else {
      const errorModel: ValidationErrorModel = await response.json();
      let errorMessage = errorModel.userMessage;
      if (!errorMessage) {
        errorMessage = response.statusText;
        errorModel.userMessage = errorMessage;
      }
      cannotRefreshToken(errorMessage);
      return errorModel;
    }
  } catch (error) {
    cannotRefreshToken(error);
    return { userMessage: error };
  }
};

const cannotRefreshToken = (errorMessage: any) => {
  removeTokenMemory();
  console.error(errorMessage);
  SnackBarNotify.sendSnackBarNotify({
    isShow: true,
    message: errorMessage,
    type: 'error',
    durationHide: 5000,
  });
  window.location.pathname = '/cms/login';
};
/**
 * method for concat error values
 * @param data
 * @param message
 */
const concatErrorsOfValues = (data: ValidationErrorModel, message: string) => {
  let retVal = message;
  if (data?.validations) {
    for (let validation of data.validations) {
      let valueMsg = validation.field + ': ' + validation.userMessage;
      retVal = retVal + ', ' + valueMsg;
    }
  }
  return retVal;
};

export const getReCAPTCHASiteKey = async (): Promise<string> => {
  let optionRequest = await RequestOptions('GET', null);
  const result: string | undefined | null | ValidationErrorModel = await ConsumeRestAPIEndPoint(
    '/api/v1/AppSettings/RecaptchaSiteKey',
    optionRequest,
  );

  if (!result || instanceOfValidationErrorModel(result)) return '';

  return result;
};

export const getReCAPTCHAApiScript = async (): Promise<string> =>  {
  let optionRequest = await RequestOptions('GET', null);
  const result: string | undefined | null | ValidationErrorModel = await ConsumeRestAPIEndPoint(
    '/api/v1/AppSettings/RecaptchaApiScript',
    optionRequest,
  );

  if (!result || instanceOfValidationErrorModel(result)) return '';

  return result;
} 


