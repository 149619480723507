import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/sass/nav/_submenuitem.scss';
import Button from '@mui/material/Button';
import { NavHashLink } from 'react-router-hash-link';

interface SubMenuItemProps {
  text: string;
  link: string;
  isHovered: boolean;
  setLastSubItemHoverted: (e: string) => void;
  subItem: boolean;
  colorText: string;
  FontFamilyText: string;
  parentIndex: number;
  setIndexTitle: (index: number) => void;
}

const SubMenuItem: React.FC<SubMenuItemProps> = ({
  text,
  link,
  isHovered,
  setLastSubItemHoverted,
  subItem,
  colorText,
  FontFamilyText,
  parentIndex,
  setIndexTitle,
}: SubMenuItemProps) => {
  const isHashLink = () => {
    return link.includes('#');
  };

  const colorTextParameter = colorText ? colorText : 'white';
  const fontFamilyText = FontFamilyText ? FontFamilyText : '';
  const scrollWithOffset = (el: any) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -160;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    console.log('scrollWithOffset', el, el.getBoundingClientRect(), window.pageYOffset, yCoordinate, yOffset);
  };

  return (
    <div className={`sub-menu-item ${isHovered ? 'is-hover' : ''} `} onMouseEnter={() => setLastSubItemHoverted(text)}>
      {isHashLink() ? (
        <NavHashLink to={link} scroll={(el) => scrollWithOffset(el)}>
          <Button name="textButton" style={subItem ? { textTransform: 'capitalize' } : {}} aria-controls="simple-menu" onClick={() => setIndexTitle(parentIndex)}>
            <div className="font-item" style={{ color: colorTextParameter, fontFamily: fontFamilyText }}>
              {text}
            </div>
          </Button>
        </NavHashLink>
      ) : (
        <Link to={link}>
          <Button name="linkButton" style={subItem ? { textTransform: 'capitalize' } : {}} aria-controls="simple-menu" onClick={() => setIndexTitle(parentIndex)}>
            <div className="font-item" style={{ color: colorTextParameter, fontFamily: fontFamilyText }}>
              {text}
            </div>
          </Button>
        </Link>
      )}
    </div>
  );
};

export default SubMenuItem;
