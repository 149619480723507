import React, { useEffect, useState } from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import '../../styles/sass/_dynamicFooter.scss';
import '../../styles/sass/_imageComponent.scss';
import { ParameterModel } from '../../interfaces/models';
import useWindowDimensions from '../Background/WindowSize';
import PictureComponent from '../PictureComponent';
import EmailComponent from '../EmailComponent';
export interface ImageComponentProps {
  params?: ParameterModel[];
  isFooter?: undefined |  boolean;
  padding?: string;
}

const ImageComponent: React.FC<ImageComponentProps> = ({ params, isFooter }: ImageComponentProps) => {
  const [imageUrl, setImageUrl] = useState('');
  const [imageWidth, setImageWidth] = useState('');
  const [imageHeight, setImageHeight] = useState('');
  const [margin, setMargin] = useState('');
  const [maxWidth, setMaxWidth] = useState('');
  const [maxHeight, setMaxHeight] = useState('');
  const [linkUrl, setLinkUrl] = useState('');
  const [description, setDescription] = useState('');
  const [ImageMobileUrl,setImageMobileUrl] = useState('');
  const [ImageWidthMobile, setImageWidthMobile] = useState('');
  const [ImageHeightMobile, setImageHeightMobile] = useState('');
  const [Email, setEmail] = useState('');
  const [Inlined, setInlined] = useState(false);
  const [padding, setPadding] = useState('');

  const windowSize = useWindowDimensions();

  useEffect(() => {
      params?.forEach((param) => {
      if (param.type === 'ImageUrl') {
        setImageUrl(param.value);
      } else if (param.type === 'ImageWidth') {
        setImageWidth(param.value);
      } else if (param.type === 'ImageHeight') {
        setImageHeight(param.value);
      } else if (param.type === 'Margin' && windowSize.width > 768) {
        setMargin(param.value);
      } else if (param.type === 'MaxWidth') {
        setMaxWidth(param.value);
      } else if (param.type === 'MaxHeight') {
        setMaxHeight(param.value);
      } else if (param.type === 'LinkUrl') {
        setLinkUrl(param.value);
      } else if (param.type === 'Description') {
        setDescription(param.value);
      } else if (param.type === 'ImageMobileUrl'){
        setImageMobileUrl(param.value);
      } else if (param.type === 'ImageWidthMobile'){
        setImageWidthMobile(param.value ? param.value : imageWidth);
      }else if (param.type === 'ImageHeightMobile') {
        setImageHeightMobile(param.value ? param.value : imageHeight);
      }else if (param.type === 'Email') {
        setEmail(param.value);
      }else if(param.type === 'Inlined') {
        setInlined(param.value.toLowerCase() === 'true' ? true : false);
      }else if (param.type === 'Padding') {
        setPadding(param.value);
      }
    });
  }, [params]);

  const matchesSize = useMediaQuery('(min-width:600px)');

    useEffect(()=> {
      if(window.innerWidth <= 414 && ImageWidthMobile && ImageHeightMobile){
        setImageWidth(ImageWidthMobile ? structuredClone(ImageWidthMobile) : structuredClone(imageWidth));
        setImageHeight(ImageHeightMobile ? structuredClone(ImageHeightMobile) : structuredClone(imageHeight));  
      }
    },[matchesSize])

    useEffect(()=> {
      if(window.innerWidth <= 414 && ImageWidthMobile ){
        setImageWidth(ImageWidthMobile ? structuredClone(ImageWidthMobile) : structuredClone(imageWidth));
      }
    },[imageWidth]);

    useEffect(()=> {
      if(window.innerWidth <= 414 &&  ImageHeightMobile){
        setImageHeight(ImageHeightMobile ? structuredClone(ImageHeightMobile) : structuredClone(imageHeight));  
      }
    },[imageHeight]);

  const isEmailComponent = Email && imageUrl;
  const islinkImage = linkUrl && imageUrl && description && !Email;
  const isBiography = () => imageUrl && Inlined;
  const styleLink = {
    margin: margin,
    width: maxWidth === '' ? '100%' : maxWidth + 'px',
    height: maxHeight === '' ? '100%' : maxHeight + 'px',
    padding: padding,
  }
  if (isBiography()) {
    return (
      <div
        className="biography"
        style={styleLink}
      >
        <PictureComponent imageUrl={imageUrl} imageWidth={imageWidth} imageMobileUrl={ImageMobileUrl}>
          <img src={imageUrl} alt={description} width={imageWidth} height={imageHeight} title="external link" loading='lazy'/>
        </PictureComponent>
        <div>
          {description.split('|').map((item, index) => (
            item === ' ' ? 
            <br key={index} />
            :
            <span key={index} dangerouslySetInnerHTML={{ __html: item.replaceAll('**/','</strong>').replaceAll('**','<strong>') }} />
            
          ))}
        </div>
      </div>
    );
  } 
 else if (islinkImage) {
    return (
      <a
        href={linkUrl}
        className="image-external-link"
        target="_blank"
        style={styleLink}
      >
        <PictureComponent imageUrl={imageUrl} imageWidth={imageWidth} imageMobileUrl={ImageMobileUrl}>
          <img src={imageUrl} alt={description} width={imageWidth} height={imageHeight} title="external link" loading='lazy'/>
        </PictureComponent>
        <div>
          {description.split('|').map((item, index) => (
            <span key={index} dangerouslySetInnerHTML={{ __html: item }} />
          ))}
        </div>
      </a>
    );
  } else if(isEmailComponent){
    return (
      <EmailComponent email={Email} >
        <PictureComponent imageUrl={imageUrl} imageWidth={imageWidth} imageMobileUrl={ImageMobileUrl}>
            <img src={imageUrl} alt={description} width={imageWidth} height={imageHeight} title="external link" loading='lazy'/>
          </PictureComponent>
      </EmailComponent>
    );
  } 
   else if (!linkUrl && imageUrl && description) {
    return (
      <div className="image-component-container">
        <p>{description}</p>
        <PictureComponent imageUrl={imageUrl} imageWidth={imageWidth} imageMobileUrl={ImageMobileUrl}>
          <img src={imageUrl} alt={description} width={imageWidth} height={imageHeight} title={description} loading='lazy' />
        </PictureComponent>
      </div>
    );
  } else {
    return (
      <div
        className="image-component"
        style={styleLink}
      >
        <Grid container direction="column" alignItems= { isFooter ? "flex-start" : "center"}>
          <Grid item>
            <Grid container direction="column">
              {imageUrl ? (
                <Grid item style={{ maxWidth: '100%', maxHeight: '100%' }}>
                  {linkUrl === '' ? (
                    <PictureComponent imageUrl={imageUrl} imageWidth={imageWidth} imageMobileUrl={ImageMobileUrl}> 
                      <img
                        style={{
                          width: maxWidth === '' ? '100%' : maxWidth + 'px',
                          height: maxHeight === '' ? '100%' : maxHeight + 'px',
                        }}
                        src={imageUrl}
                        width={imageWidth}
                        height={imageHeight}
                        alt={'Image Component of ' + imageUrl}
                        loading='lazy'
                      />
                    </PictureComponent>
                    
                  ) : (
                    <a href={linkUrl} target="_blank" tabIndex={-1}>
                      <PictureComponent imageUrl={imageUrl} imageWidth={imageWidth} imageMobileUrl={ImageMobileUrl}>
                      <img
                        className="network-icon"
                        style={{
                          width: maxWidth === '' ? '100%' : maxWidth + 'px',
                          height: maxHeight === '' ? '100%' : maxHeight + 'px',
                        }}
                        src={imageUrl}
                        width={imageWidth}
                        height={imageHeight}
                        alt={'Image Component of ' + imageUrl}
                        loading='lazy'
                      />
                      </PictureComponent>
                    </a>
                  )}
                </Grid>
              ) : (
                <Grid item lg={1} md={2} sm={3} xs={10} />
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
};

export default ImageComponent;
