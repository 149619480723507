import { CountryDef, JobDef } from '../interfaces/interfaces';

export const countryList: CountryDef[] = [
    {
        name: 'United States',
        code: '1',
    },
    {
        name: 'Argentina',
        code: '54',
    },
    {
        name: 'Bolivia',
        code: '591',
    },
    {
        name: 'Canada',
        code: '1',
    },
    {
        name: 'Chile',
        code: '56',
    },
    {
        name: 'Colombia',
        code: '57',
    },
    {
        name: 'Germany',
        code: '49',
    },
    {
        name: 'India',
        code: '91',
    },
    {
        name: 'Mexico',
        code: '49',
    },
    {
        name: 'Norway',
        code: '47',
    },
];

export const jobList: JobDef[] = [

    {
        id: "Developer",
        name: 'Desarrollador de Software',
        groupId: 'ES',

    },

    {
        id: "Developer",
        name: 'Software Developer',
        groupId: 'EN'
    },

    {
        name: 'Control de Calidad',
        groupId: 'ES',
        id: "QA"
    },

    {
        id: "QA",
        name: 'Quality Assurance',
        groupId: 'EN'
    },

    {
        id: 'Admin',
        name: "Administraci&oacute;n",
        groupId: 'ES'
    },

    {
        id: 'Admin',
        groupId: 'EN',
        name: "Admin"
    },

    {
        id: 'Marketing',
        name: "Marketing",
        groupId: 'ES'
    },

    {
        id: 'Marketing',
        groupId: 'EN',
        name: "Marketing"
    },

   
];
