import React, { useEffect, useState } from 'react';
import '../styles/sass/_rowcomponent.scss';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { ComponentViewModel } from '../interfaces/models';
import { getGenericParams } from '../utils/utils';
import slugify from 'react-slugify';
import { useMediaQuery, useTheme } from '@mui/material';

interface RowComponentProps {
  children?: React.ReactElement | React.ReactElement[] | undefined;
  theme?: string;
  component?: ComponentViewModel;
  isFooter?: undefined | boolean;
}

const RowComponent: React.FC<RowComponentProps> = ({ children, component, isFooter }: RowComponentProps) => {
  const [VerticalAlignment, setVerticalAlignment] = useState('');
  const [flex, setFlex] = useState('');

  useEffect(() => {
    getVerticalAlignment();
  });

  const getVerticalAlignment = () => {
    if (!component) return;
    const parameterVertical = getGenericParams(component).VerticalAlignment;
    if (parameterVertical) {
      setFlex('flex');
      setVerticalAlignment(parameterVertical);
    }
  };

  const verticalAlignment =
    VerticalAlignment === 'Center' ? 'Center' : VerticalAlignment === 'Bottom' ? 'End' : 'Start';

  const theme = useTheme();
  const matchMobile = useMediaQuery(theme.breakpoints.down('md'));
      
  const withoutPadding = () => {
    const withoutPaddingParameter = component?.parameters.some(
      (param) => param.type === 'WithoutPadding' && param.value === 'true',
    );
    return withoutPaddingParameter;
  };

  return (
    <Container
      className={'row-container-' + slugify(component?.id)}
      fixed
      style={{
        height: '100%',
        width: '100%',
        alignItems: verticalAlignment,
        display: flex,
        padding: withoutPadding() ? 0 : '',
      }}
    >
      <Grid
        className={'row-grid-container-' + component?.key}
        container
        spacing={3}
        style={{ height: '100%', padding: withoutPadding() ? 0 : '' }}
      >
        <Grid
          className={'row-grid-item-' + component?.key}
          item
          xs={12}
          md={12}
          lg={12}
          style={{ height: '100%', padding:  withoutPadding() ? 0 : '', paddingLeft: isFooter && matchMobile ? '16px' : ''}}
        >
          {children}
        </Grid>
      </Grid>
    </Container>
  );
};

export default RowComponent;
