import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Grid, TextField, Theme } from '@mui/material';
import { ParameterModel } from '../../../interfaces/models';
import { isValidEmail, isValidPhoneNumber, isValidText } from '../../../utils/utils';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import '../../../styles/sass/form/_inputText.scss';

const PREFIX = 'InputTextComponent';

const classes = {
  field: `${PREFIX}-field`,
  fullfield: `${PREFIX}-fullfield`,
  inputLabel: `${PREFIX}-inputLabel`,
  selectedItem: `${PREFIX}-selectedItem`
};

const StyledGrid = styled(Grid)((
  { theme } : { theme : Theme }
) => ({
  [`& .${classes.field}`]: {
    margin: 'auto',
    padding: theme.spacing(1),
    [theme.breakpoints.between(1, 281)]: {
      width: '162px',
    },
    [theme.breakpoints.between(281, 321)]: {
      width: '202px',
    },
    [theme.breakpoints.between(321, 361)]: {
      width: '242px',
    },
    [theme.breakpoints.between(361, 376)]: {
      width: '252px',
    },
    [theme.breakpoints.between(376, 415)]: {
      width: '282px',
    },
    [theme.breakpoints.between(415, 541)]: {
      width: '277px',
    },
    [theme.breakpoints.between(541, 769)]: {
      width: '337px',
    },
    [theme.breakpoints.between(769, 1025)]: {
      width: '487px',
    },
    [theme.breakpoints.up(1025)]: {
      width: '517px',
    },
  },

  [`& .${classes.fullfield}`]: {
    margin: 'auto',
    paddingTop: '9px',
    paddingBottom: '9px',
    [theme.breakpoints.between(1, 281)]: {
      width: '184px',
    },
    [theme.breakpoints.between(281, 321)]: {
      width: '224px',
    },
    [theme.breakpoints.between(321, 361)]: {
      width: '264px',
    },
    [theme.breakpoints.between(361, 376)]: {
      width: '274px',
    },
    [theme.breakpoints.between(376, 415)]: {
      width: '304px',
    },
    [theme.breakpoints.between(415, 541)]: {
      width: '299px',
    },
    [theme.breakpoints.between(541, 769)]: {
      width: '359px',
    },
    [theme.breakpoints.between(769, 1025)]: {
      width: '509px',
    },
    [theme.breakpoints.up(1025)]: {
      width: '539px',
    },
  },

  [`& .${classes.inputLabel}`]: {
    top: '-1px',
    '&.shrink': {
      marginTop: '10px',
    },
  },
  [`& .${classes.selectedItem}`]: {
    backgroundColor: '#FFFFFF !important',
  }
}));

interface InputTextComponentProps {
  Parameters: ParameterModel[];
  getValue: Function;
  idParent: string;
  clear: boolean;
  codePhone: string;
}
/* eslint-disable complexity */
const InputTextComponent: React.FC<InputTextComponentProps> = ({
  Parameters,
  getValue,
  idParent,
  clear,
  codePhone,
}: InputTextComponentProps) => {
  const [valueInput, setValue] = useState<string>('');
  const [valueInputErrors, setValueInputErrors] = useState<Array<string>>([' ']);
  const [FontFamilyTitle, setFontFamilyTitle] = useState('');
  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [FontSizeTitle, setFontSizeTitle] = useState('');
  const [stylized, setStylized] = useState(true);
  const [error, setError] = useState('');
  const [changed, setChanged] = useState(false);
  const [Inlined, setInlined] = useState('false');
  const [EmailIcon, setEmailIcon] = useState('');
  const [isEmpty, setIsEmpty] = useState(false);
  const [emptyError,setEmptyError] = useState("This field can not be blank");
  const [type,setType] = useState("Text");
  const [errorValue, setErrorValue] = useState(false);

  
  useEffect(() => {
    Parameters.forEach((parameter) => {
      if (parameter.type === 'Title') {
        setTitle(parameter.value);
      } else if (parameter.type === 'Name') {
        setName(parameter.value);
      } else if (parameter.type === 'FontFamilyTitle') {
        setFontFamilyTitle(parameter.value);
      } else if (parameter.type === 'FontTitle') {
        setFontSizeTitle(parameter.value);
      } else if (parameter.type === 'Stylized') {
        setStylized(parameter.value.toLowerCase() === 'false' ? false : true);
      } else if (parameter.type === 'Inlined') {
        setInlined(parameter.value);
      } else if (parameter.type === 'Error') {
        setError(parameter.value);
      } else if(parameter.type === 'EmailIcon'){
        setEmailIcon(parameter.value);
      } else if(parameter.type === 'ErrorEmpty'){
        setEmptyError(parameter.value);
      } else if(parameter.type === 'TypeInput'){
        setType(parameter.value);
      } 
    });
  }, [Parameters]);

  useEffect(() => {
    setValue('');
    setChanged(false);
    getValue(name, '', true);
    setIsEmpty(false);
  }, [clear]);

  useEffect(()=>{
    if(name === 'phoneInput' && codePhone !== ''){
      setValue(`+(${codePhone})`);
    }
  },[codePhone])

  const evaluateInput = (value: string) =>  type === 'Email' ? isValidEmail(value) :  type === 'Phone' ? isValidPhoneNumber(value) : isValidText(value);

  const handleInputChange = (e: any) => {
    const { value } = e.target;
    let haveErrorInput = evaluateInput(value);
    setErrorValue(!haveErrorInput);
    setValue(value);
    if (name === 'emailInput') {
      getValue(name, value, isValidEmail(value));
    } else {
      getValue(name, value, evaluateInput(value));
    }
    setChanged(true);
    restoreEmpty(value);
 
  };

  const restoreEmpty = (value: string) => {
    if(value !== ' '){
      setIsEmpty(false);
    }
  }

  

  const TextLength = 
  name === 'emailInput' 
  ? 256 : 
  name === 'companyInput' 
  ? 64 : 
  name === 'nameInput' || name === 'lastNameInput' ||  name === 'phoneInput' || name === 'countryInput' 
  ? 32 : 0;

  const fontSizeTitle =
    FontSizeTitle === 'Small'
      ? '17'
      : FontSizeTitle === 'Medium'
      ? '21'
      : FontSizeTitle === 'Big'
      ? '30'
      : FontSizeTitle === 'ExtraLarge'
      ? '42'
      : '';
  
  const heightSize = 
      FontSizeTitle === 'Small'
      ? '38px'
      : FontSizeTitle === 'Medium'
      ? '42px'
      : FontSizeTitle === 'Big'
      ? '52px'
      : FontSizeTitle === 'ExtraLarge'
      ? '66px'
      : '';

  
  const getStyleEmailIcon = EmailIcon.toLowerCase()=== 'true' ? 'styleEmailIcon' : '';
  const renderImage = () => {
    if(name === 'emailInput' && EmailIcon.toLowerCase() === 'true'){
      return (
        <div className= {isEmpty ? 'emailIconRequired' : 'emailIcon'}>    
          <MailOutlineIcon className='icon' fontSize='large' style={{height:heightSize}} />
        </div>
      )
    }
    return '';
  }
  const invalid = () => {
    setIsEmpty(true);
  }
  return (
    <StyledGrid className="input-Text" item xs={6} md={Inlined === 'false' ? 6 : 3}  style={{ paddingBottom: '70px' }}>
      
      {stylized ? (
        <div>
        <TextField
          id={name}
          error={isEmpty}
          className={`${classes.field} disable-highlight`}
          label={title}
          value={valueInput}
          variant="standard"
          required
          name="Name"
          onChange={(e) => handleInputChange(e)}
          margin="normal"
          InputLabelProps={{
            classes: {
              root: classes.inputLabel,
              shrink: 'shrink',
            },
          }}
          style={{ fontSize: fontSizeTitle + 'px',width:'100%', maxHeight:'47px' }}
            onInvalid={invalid}
        />
        <div>
        {isEmpty ?
          <p  className="error-text-color">
            {emptyError}
          </p>
          :
          null
        }
        {
          errorValue? 
          <p  className="error-text-color">
            {error}
          </p>
          :
          null
        }
      </div>
      </div>
      ) : (
        <div style={{width:'calc(100% - 10px)'}}>
          <div className={ getStyleEmailIcon  }>
           {renderImage()}
            <input 
              className = {isEmpty ? 'redInput' : ''}
              maxLength={TextLength}
              type="text"
              placeholder={title}
              value={valueInput}
              onChange={(e) => handleInputChange(e)}
              style={{
                padding: '13px',
                borderRadius: '10px',
                fontFamily: FontFamilyTitle,
                fontSize: fontSizeTitle + 'px',
                border: isEmpty ? '3px solid red' : '3px solid #cccccc' ,
              }}
              name={name}
              required
              onInvalid={invalid}
            />
            
          </div>
          {name === 'emailInput' && !isValidEmail(valueInput) && changed ? (
            <p className="error-text-color">{error ? error : ''}</p>
          ) : null}
        </div>
      )}

     
    </StyledGrid>
  );
};

export default InputTextComponent;
