import React, { useEffect, useState } from 'react';
import '../../styles/sass/detailcard/_detailcard.scss';
import FlipCard from '../Card/FlipCard';
import DetailItem from './DetailItem';
import { getGenericParams } from '../../utils/utils';
import { ComponentViewModel, ParameterModel } from '../../interfaces/models';
import ContentCard from '../Card/ContentCard';
import ContentCardWithoutImage from '../Card/ContentCardWithoutImage';
import { useMediaQuery, useTheme } from '@mui/material';

interface DetailCardProps {
  isOpen: boolean;
  ComponentList?: ComponentViewModel[];
  ParamArray: ParameterModel[];
  windowSize?: string;
  id: string;
}

const DetailCard: React.FC<DetailCardProps> = ({
  isOpen,
  ComponentList,
  ParamArray,
  windowSize,
  id,
}: DetailCardProps) => {
  const [isOpenState, setIsOpenState] = useState(true);
  const [title, setTitle] = useState('');
  const [size, setSize] = useState('');
  const [withoutImage, setWithoutImage] = useState('false');
  const [FontFamilyText, setFontFamilyText] = useState(' ');
  const [FontFamilyTitle, setFontFamilyTitle] = useState(' ');
  const [MaxHeight, setMaxHeight] = useState(' ');
  const [keepOpenOnMobile, setKeepOpenOnMobile] = useState('false');
  const [Padding, setPadding] = useState('');
  useEffect(() => {
    const setValues = () => {
      if (ParamArray?.length) {
        ParamArray.forEach((param) => {
          switch (param.type) {
            case 'Title':
              setTitle(param.value);
              break;
            case 'Size':
              setSize(param.value);
              break;
            case 'WithoutImage':
              setWithoutImage(param.value);
              break;
            case 'FontFamilyTitle': {
              setFontFamilyTitle(param.value);
              break;
            }
            case 'FontFamilyText': {
              setFontFamilyText(param.value);
              break;
            }
            case 'MaxHeight': {
              setMaxHeight(param.value);
              break;
            }
            case 'KeepOpenOnMobile': {
              setKeepOpenOnMobile(param.value);
              break;
              }
            case 'Padding': {
              setPadding(param.value);
              break;
            }
            default:
              break;
          }
        });
      }
    };
    setValues();
  }, [ParamArray]);
  useEffect(() => {
    setIsOpenState(isOpen);
  }, [isOpen]);
  

  const handleClickArea = (a: Event) => {
        setIsOpenState(!isOpenState);
  };
  const theme = useTheme();
  const matchMedium = useMediaQuery(theme.breakpoints.down('md'));
  const matchLarge = useMediaQuery(theme.breakpoints.up('md'));
  useEffect(()=> {
    if(matchMedium && isOpenState && keepOpenOnMobile.toLowerCase() !== 'true'){
      setIsOpenState(false);
    }
    if(matchLarge && !isOpenState){
      setIsOpenState(true);
    }

  },[matchMedium, matchLarge, keepOpenOnMobile])
  return (
    <div>
      {withoutImage.toLowerCase() === 'true' ? (
        <div className="detailCard-withoutImage" style={{padding:Padding}}>
          <ContentCardWithoutImage
            text={title}
            cardSize={size}
            id={id}
            windowSize={windowSize}
            FontFamilyText={FontFamilyTitle ? FontFamilyTitle : ' '}
            onClickArea={(e: Event) => handleClickArea(e)}
            isOpenState={isOpenState}
            maxHeight={MaxHeight} 
          />
        </div>
      ) : (
        <FlipCard
          id={id}
          windowSize={windowSize}
          paramArray={ParamArray}
          onClickArea={(e: Event) => handleClickArea(e)}
          isOpenState={isOpenState}
        />
      )}

      <div>
        {ComponentList && isOpenState
          ? ComponentList.map((component: ComponentViewModel, index) => {
              let params = getGenericParams(component);
              return (
                <DetailItem
                  link={params.Link ? params.Link : '/'}
                  FontTitle={params.FontTitle ? params.FontTitle : ''}
                  ColorTitle={params.ColorTitle ? params.ColorTitle : ''}
                  FontFamilyText={params.FontFamilyText ? params.FontFamilyText : ' '}
                  FontFamilyTitle={params.FontFamilyTitle ? params.FontFamilyTitle : ' '}
                  {...params}
                  key={component.id}
                />
              );
            })
          : null}
      </div>
    </div>
  );
};

export default DetailCard;
