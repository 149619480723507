import {
  AppSettings,
  instanceOfValidationErrorModel,
  ValidationErrorModel,
  } from '../../interfaces/models';
import { ConsumeRestAPIEndPoint, RequestOptions } from '../../services/ApiRestServiceCommon';
  
  export const fetchAppSettings = async (): Promise<AppSettings | ValidationErrorModel | null> => {
    let optionRequest = await RequestOptions('GET', null);
    let result: AppSettings| ValidationErrorModel | null = await ConsumeRestAPIEndPoint(
      '/api/v1/AppSettings/GetAppSettings',
      optionRequest,
    );
  
    if (instanceOfValidationErrorModel(result) || !result) {
      return result;
    } else {
      const AppSettings: AppSettings = result;
      return AppSettings;
    }
  };

  
