import React, { FormEvent, useState, useEffect, createRef, useRef, MutableRefObject } from 'react';
import { styled } from '@mui/material/styles';
import '../../styles/sass/form/_contactusform.scss';
import RowComponent from '../RowComponent';
import { Grid, Button, Theme, Autocomplete } from '@mui/material';
import { getGenericParams, isValidEmail, searchCountry, isValidPhone } from '../../utils/utils';
import { GenericparamDef } from '../../interfaces/interfaces';
import { contactUsPost } from '../../services/ViewServices';
import { countryList } from '../../data/formsData';
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';
import '../../styles/sass/form/_careersform.scss';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { ComponentViewModel } from '../../interfaces/models';
import ReCAPTCHA from 'react-google-recaptcha';
import AppSettingsService from '../../services/AppSettingsService';
import { validateAndSendForm } from '../../services/cms/recaptchaService';

const PREFIX = 'ContactUsForm';

const classes = {
  field: `${PREFIX}-field`,
  inputLabel: `${PREFIX}-inputLabel`,
  topStyle: `${PREFIX}-topStyle`
};

const Root = styled('div')((
  { theme } : { theme : Theme}
) => ({
  [`& .${classes.field}`]: {
    padding: theme.spacing(1),
    [theme.breakpoints.between(1, 281)]: {
      width: '162px',
    },
    [theme.breakpoints.between(281, 321)]: {
      width: '202px',
    },
    [theme.breakpoints.between(321, 361)]: {
      width: '242px',
    },
    [theme.breakpoints.between(361, 376)]: {
      width: '252px',
    },
    [theme.breakpoints.between(376, 415)]: {
      width: '282px',
    },
    [theme.breakpoints.between(415, 541)]: {
      width: '277px',
    },
    [theme.breakpoints.between(541, 769)]: {
      width: '337px',
    },
    [theme.breakpoints.between(769, 1025)]: {
      width: '487px',
    },
    [theme.breakpoints.up(1025)]: {
      width: '517px',
    },
  },

  [`& .${classes.inputLabel}`]: {
    top: '-1px',
    '&.shrink': {
      marginTop: '10px',
    },
  },

  [`& .${classes.topStyle}`]: {
    top: '-7px',
  }
}));

interface ContactUsFormProps {
  form: ComponentViewModel;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ContactUsForm: React.FC<ContactUsFormProps> = ({ form }: ContactUsFormProps) => {
  const [firstNameInput, setNameInput] = useState('');
  const [nameInputErrors, setNameInputErrors] = useState<Array<string>>([]);

  const [lastNameInput, setLastNameInput] = useState(' ');

  const [emailInput, setEmailInput] = useState('');
  const [emailInputErrors, setEmailInputErrors] = useState<Array<string>>([]);

  const [phoneInput, setPhoneInput] = useState('');
  const [phoneInputErrors, setPhoneInputErrors] = useState<Array<string>>([]);

  const [countryInput, setCountryInput] = useState('');
  const [countryInputErrors, setCountryInputErrors] = useState<Array<string>>([]);

  const [companyInput, setCompanyInput] = useState('');
  const [companyInputErrors, setCompanyInputErrors] = useState<Array<string>>([]);

  const [descriptionInput, setDescriptionInput] = useState('');
  const [descriptionInputErrors, setDescriptionInputErrors] = useState<Array<string>>([]);

  const [paramList, setParamList] = useState<GenericparamDef[]>([]);
  const [formHasErrors, setFormHasErrors] = useState<boolean>(true);
  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const recaptchaRef = useRef() as MutableRefObject<ReCAPTCHA>;

  useEffect(() => {
    loadReCaptcha();
  }, []);

  useEffect(() => {
    const componentMap: GenericparamDef[] = form.children.map((comp) => {
      return getGenericParams(comp);
    });
    setParamList(componentMap);
  }, [form]);

  const params = getGenericParams(form);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    await validateAndSendForm(saveForm);
  };

  const saveForm = (token: string) => {
    const resetForm = () => {
      setNameInput('');
      setEmailInput('');
      setPhoneInput('');
      setCountryInput('');
      setCompanyInput('');
      setDescriptionInput('');
      recaptchaRef.current.reset();
    };

    if (
      firstNameInput !== '' &&
      emailInput !== '' &&
      phoneInput !== '' &&
      countryInput !== '' &&
      companyInput !== '' &&
      descriptionInput !== ''
    ) {
      contactUsPost(
        {
          firstNameInput,
          lastNameInput,
          emailInput,
          phoneInput,
          countryInput,
          companyInput,
          descriptionInput,
        },
        {
          token: token,
          hideCaptcha: false,
        },
        resetForm,
      );
      handleClickOpen();
    } else {
      evaluateFormBeforeSubmit();
      handleErrorLog();
    }
  };
  const handleErrorLog = () => {
    enqueueSnackbar('Invalid Data', { variant: 'error' });
  };
  const filterParamsByTitle = (param: GenericparamDef, f: string) => {
    return param.Title === f;
  };

  const getStringOfParamsByTitle = (paramList: GenericparamDef[], title: string): string => {
    let res: string;
    let obj: GenericparamDef = paramList.filter((list) => filterParamsByTitle(list, title))[0]
      ? paramList.filter((list) => filterParamsByTitle(list, title))[0]
      : {};

    res = obj?.Text ? obj.Text : '';
    return res;
  };

  const getStringOfParamsByTitle1 = (paramList: GenericparamDef[], title: string): string => {
    // console.log(paramList);
    let res: string;
    let obj: GenericparamDef = paramList.filter((list) => filterParamsByTitle(list, title))[0]
      ? paramList.filter((list) => filterParamsByTitle(list, title))[0]
      : {};

    res = obj?.Error ? obj.Error : '';
    return res;
  };

  const getStringOfParamsByTitle2 = (paramList: GenericparamDef[], title: string): string => {
    let res: string;
    let obj: GenericparamDef = paramList.filter((list) => filterParamsByTitle(list, title))[0]
      ? paramList.filter((list) => filterParamsByTitle(list, title))[0]
      : {};

    res = obj?.Error1 ? obj.Error1 : '';
    return res;
  };

  const evaluateList = (value: boolean, msg: string, list: Array<string>) => {
    value ? list.push(msg) : list.filter((i) => i !== msg);
  };

  const evalNameInput = (value: string) => {
    setNameInput(value);
    const msg_name_required = getStringOfParamsByTitle1(paramList, 'Name');
    const nameErrors: Array<string> = [];
    evaluateList(value === '', msg_name_required, nameErrors);
    setNameInputErrors(nameErrors);
  };

  const evalEmailInput = (value: string) => {
    setEmailInput(value);
    const msg_email_required = getStringOfParamsByTitle1(paramList, 'Email');
    const msg_email_invalid = getStringOfParamsByTitle2(paramList, 'Email');
    const emailErrors: Array<string> = [];
    evaluateList(value === '', msg_email_required, emailErrors);
    evaluateList(!isValidEmail(value) && value !== '', msg_email_invalid, emailErrors);
    setEmailInputErrors(emailErrors);
  };

  const evalPhoneInput = (value: string) => {
    setPhoneInput(value);
    const msg_phone_required = getStringOfParamsByTitle1(paramList, 'Phone');
    const msg_phone_invalid = getStringOfParamsByTitle2(paramList, 'Phone');

    const phoneErrors: Array<string> = [];
    evaluateList(value === '' || value.length < 6, msg_phone_required, phoneErrors);
    evaluateList(!isValidPhone(value), msg_phone_invalid, phoneErrors);
    setPhoneInputErrors(phoneErrors);
  };

  const evalCountryInput = (value: string) => {
    setCountryInput(value);
    const currentCountry = searchCountry(countryList, value);

    if (value && currentCountry !== undefined) {
      if (phoneInput.indexOf('+(') === -1) {
        setPhoneInput(`+(${currentCountry.code})`);
      } else {
        const [partA, partB] = phoneInput.split(')');
        setPhoneInput(`+(${currentCountry.code})${partA}`);
        setPhoneInput(`+(${currentCountry.code})${partB}`);
      }
    } else {
      if (phoneInput.indexOf('+(') === -1) {
        setPhoneInput('');
      } else {
        const [partA, partB] = phoneInput.split(')');
        setPhoneInput(partA);
        setPhoneInput(partB);
      }
    }
    const msg_country_required = getStringOfParamsByTitle1(paramList, 'Country');
    const countryErrors: Array<string> = [];
    evaluateList(value === '', msg_country_required, countryErrors);
    setCountryInputErrors(countryErrors);
  };

  const evalCompnayInput = (value: string) => {
    setCompanyInput(value);
    const msg_company_required = getStringOfParamsByTitle1(paramList, 'Company');
    const countryErrors: Array<string> = [];
    evaluateList(value === '', msg_company_required, countryErrors);
    setCompanyInputErrors(countryErrors);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const evalDescriptionInput = (value: string) => {
    setDescriptionInput(value);
    const msg_description_required = getStringOfParamsByTitle1(paramList, 'Description');
    const countryErrors: Array<string> = [];
    evaluateList(value === '', msg_description_required, countryErrors);
    setDescriptionInputErrors(countryErrors);
  };

  const evaluateFormBeforeSubmit = () => {
    evalNameInput(firstNameInput);
    evalEmailInput(emailInput);
    evalPhoneInput(phoneInput);
    evalCountryInput(countryInput);
    evalCompnayInput(companyInput);
    evalDescriptionInput(descriptionInput);
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    switch (name) {
      case 'Name':
        evalNameInput(value);
        break;
      case 'Email':
        evalEmailInput(value);
        break;
      case 'Phone':
        evalPhoneInput(value);
        break;
      case 'Country':
        evalCountryInput(value);
        break;
      case 'Company':
        evalCompnayInput(value);
        break;
      case 'Description':
        evalDescriptionInput(value);
        break;
    }
  };

  const handleAutocompleteChange = (parameterName: string, value: any) => {
    switch (parameterName) {
      case 'Country':
        evalCountryInput(value);
        break;
    }
  };

  /*
    useEffect(() => {
      console.log('formHasErrors', formHasErrors)
    }, [formHasErrors]);
    */

  useEffect(() => {
    const evaluateFormHasErrors = (): boolean => {
      const res =
        firstNameInput === '' ||
        emailInput === '' ||
        phoneInput === '' ||
        countryInput === '' ||
        companyInput === '' ||
        descriptionInput === '' ||
        emailInputErrors.length !== 0 ||
        nameInputErrors.length !== 0 ||
        phoneInputErrors.length !== 0 ||
        countryInputErrors.length !== 0 ||
        companyInputErrors.length !== 0 ||
        descriptionInputErrors.length !== 0;
      return res;
    };

    setFormHasErrors(evaluateFormHasErrors());
  }, [
    emailInputErrors,
    nameInputErrors,
    phoneInputErrors,
    countryInputErrors,
    companyInputErrors,
    descriptionInputErrors,
  ]);

  const loadReCaptcha = async () => {
    let siteKey = await AppSettingsService.getInstance().getReCaptchaSiteKey();
    let apiScript = await AppSettingsService.getInstance().getRecaptchaApiScriptUrl();
    let existsRecaptcha = document.getElementById('recaptcha-script');
    if (!existsRecaptcha) {
      const script = document.createElement('script');
      script.id = 'recaptcha-script';
      script.src = apiScript + siteKey;
      document.body.appendChild(script);
    }
  };

  return (
    <Root className="contact-us-form">
      <RowComponent>
        <Grid className="form-grid" container spacing={3}>
          <Grid item xs={12}>
            <h1>{params.Title}</h1>
          </Grid>
        </Grid>
        <Grid
          className="form-grid"
          container
          spacing={1}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} lg={6} className="form-grid-item">
            <TextField
              label={getStringOfParamsByTitle(paramList, 'Name')}
              id={getStringOfParamsByTitle(paramList, 'Name')}
              className={`${classes.field} disable-highlight`}
              value={firstNameInput}
              required
              variant="standard"
              name="Name"
              onChange={(e) => handleInputChange(e)}
              margin="normal"
              InputLabelProps={{
                classes: {
                  root: classes.inputLabel,
                },
              }}
            />
            <div style={{ color: 'RED' }}>
              {nameInputErrors.map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </div>
          </Grid>
          <Grid item xs={12} lg={6} className="form-grid-item">
            <TextField
              id={getStringOfParamsByTitle(paramList, 'Email')}
              label={getStringOfParamsByTitle(paramList, 'Email')}
              className={`${classes.field} disable-highlight`}
              value={emailInput}
              variant="standard"
              required
              name="Email"
              onChange={(e) => handleInputChange(e)}
              margin="normal"
              InputLabelProps={{
                classes: {
                  root: classes.inputLabel,
                  shrink: 'shrink',
                },
              }}
            />
            <div style={{ color: 'RED' }}>
              {emailInputErrors.map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </div>
          </Grid>
          <Grid item xs={12} lg={6} className="form-grid-item">
            <Autocomplete
              id={getStringOfParamsByTitle(paramList, 'Country')}
              options={countryList.map((c) => c.name)}
              onChange={(e, v) => {
                if (v) handleAutocompleteChange('Country', v);
              }}
              classes={{
                popupIndicator: classes.topStyle,
                clearIndicator: classes.topStyle,
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={`${classes.field} disable-highlight`}
                  required
                  value={countryInput}
                  variant="standard"
                  onChange={(e) => handleInputChange(e)}
                  label={getStringOfParamsByTitle(paramList, 'Country')}
                  name="Country"
                  InputLabelProps={{
                    classes: {
                      root: classes.inputLabel,
                      shrink: 'shrink',
                    },
                  }}
                />
              )}
            />

            {/*
            <TextField
              // style={{ backgroundColor: "WHITE", borderRadius: "5px" ,marginTop:"0px" }}
              select
              required
              // InputLabelProps={{ style: { marginLeft: "5px" } }}
              label={getStringOfParamsByTitle(paramList, 'Country')}
              id={getStringOfParamsByTitle(paramList, 'Country')}
              style={{ paddingRight: '15px' }}
              className={classes.field}
              value={countryInput}
              onChange={(e) => handleInputChange(e)}
              name="Country"
            >
              {countryList.map((item) => (
                <MenuItem style={{ marginLeft: '5px' }} key={item.name} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
            */}

            <div style={{ color: 'RED' }}>
              {countryInputErrors.map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </div>
          </Grid>
          <Grid item xs={12} lg={6} className="form-grid-item">
            <TextField
              label={getStringOfParamsByTitle(paramList, 'Phone')}
              id={getStringOfParamsByTitle(paramList, 'Phone')}
              className={`${classes.field} disable-highlight`}
              value={phoneInput}
              variant="standard"
              required
              name="Phone"
              onChange={(e) => handleInputChange(e)}
              margin="normal"
              InputLabelProps={{
                classes: {
                  root: classes.inputLabel,
                  shrink: 'shrink',
                },
              }}
            />

            <div style={{ color: 'RED' }}>
              {phoneInputErrors.map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </div>
          </Grid>
          <Grid item xs={12} lg={6} className="form-grid-item">
            <TextField
              label={getStringOfParamsByTitle(paramList, 'Company')}
              id={getStringOfParamsByTitle(paramList, 'Company')}
              className={`${classes.field} disable-highlight`}
              value={companyInput}
              variant="standard"
              required
              name="Company"
              onChange={(e) => handleInputChange(e)}
              margin="normal"
              InputLabelProps={{
                classes: {
                  root: classes.inputLabel,
                  shrink: 'shrink',
                },
              }}
            />

            <div style={{ color: 'RED' }}>
              {companyInputErrors.map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </div>
          </Grid>
          <Grid item xs={12} lg={6} className="form-grid-item">
            <TextField
              label={getStringOfParamsByTitle(paramList, 'Description')}
              id={getStringOfParamsByTitle(paramList, 'Description')}
              className={`${classes.field} disable-highlight`}
              value={descriptionInput}
              variant="standard"
              required
              rows="8"
              multiline
              name="Description"
              onChange={(e) => handleInputChange(e)}
              margin="normal"
              InputLabelProps={{
                classes: {
                  root: classes.inputLabel,
                  shrink: 'shrink',
                },
              }}
            />

            <div style={{ color: 'RED' }}>
              {' '}
              {descriptionInputErrors.map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </div>
          </Grid>
        </Grid>

        <Grid className="form-grid" container spacing={1}>
          <Grid item xs={12} lg={12} className="form-grid-item">
            <form className="form-body" onSubmit={(e: FormEvent) => handleSubmit(e)}>
              <Button name="inputButton" disabled={formHasErrors} className={formHasErrors ? 'submit-disabled' : ''}>
                <input
                  style={{ cursor: 'pointer' }}
                  type="submit"
                  value={getStringOfParamsByTitle(paramList, 'Submit').toUpperCase()}
                />
              </Button>
            </form>
          </Grid>
        </Grid>
      </RowComponent>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {params.CVMessage ? params.CVMessage : 'Your form has been sended'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="okButton" onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Root>
  );
};

export default ContactUsForm;
