import { createNewCandidate } from '../cms/services/CareersService';
import { createNewContact } from '../cms/services/ContactService';
import { ComponentClassType } from '../interfaces/enums';
import { LinkParam, LinkParamInit } from '../interfaces/interfaces';
import {
  CandidateDto,
  CandidateModel,
  CandidateToken,
  CaptchaParams,
  CMSViewChangesModel,
  ComponentViewModel,
  ContactDto,
  ContactModel,
  ContactToken,
  FullViewModel,
  InitDataModel,
  instanceOfValidationErrorModel,
  ParameterModel,
  ValidationErrorModel,
  ViewModel,
  ViewsChangesDto,
} from '../interfaces/models';
import { ConsumeRestAPIEndPoint, RequestOptions, RequestOptionsFiles } from './ApiRestServiceCommon';
import * as _ from 'lodash';



export const getByParentId = async ( parentId: string, lang: string) => {
    let res:ComponentViewModel[] = [];
    const language = lang ? lang : 'EN';
  
    if (parentId) {

      let requestOptionsFiles = RequestOptionsFiles('GET', null);
      const getByParentIdURL = `/api/v1/Components/children/${parentId}/${language}`;
      const result: ComponentViewModel[] | ValidationErrorModel | null = await ConsumeRestAPIEndPoint(
        getByParentIdURL,
        requestOptionsFiles,
      );
      if (!result || instanceOfValidationErrorModel(result)) {
        return res;
      }
      res = result;
    }
    return res;
  };

  export const getById = async ( componentId: string) => {
    let res:ComponentViewModel | null = null;
    console.log("Get by parent id => ",componentId);
    if (componentId) {

      let requestOptionsFiles = RequestOptionsFiles('GET', null);
      const getByParentIdURL = `/api/v1/Components/${componentId}`;
      const result: ComponentViewModel | ValidationErrorModel | null = await ConsumeRestAPIEndPoint(
        getByParentIdURL,
        requestOptionsFiles,
      );
        console.log("This is result from get BY component ID => ", componentId);
      if (!result || instanceOfValidationErrorModel(result)) {
        return res;
      }
      res = result;
    }
    return res;
  };
  
  