import React, { useEffect, useState } from 'react';
import { LinkParam } from '../../interfaces/interfaces';
import '../../styles/sass/sidenav/_sidenav.scss';
import SideNavList from './SideNavList';
import { Link, useLocation } from 'react-router-dom';
import { Button, Divider, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ObjectFitType } from '../../interfaces/enums';
import PictureComponent from '../PictureComponent';

interface SideNavProps {
  setIsOpen: (isOpen: boolean) => void;
  itemList: LinkParam[];
  activeView: string;
  setCurrentLanguage: (lang: string) => void;
  currentLanguage: string | undefined;
  ImageUrlParameterList: string[];
  allParamsList: { [key: string]: string };
  HideLanguage: boolean;
  ObjectFit: string;
  showLanguages: boolean;
  setShowLanguages: () => void;
  setIndexTitle: (index: number) => void;
  setPageTitleWithCurrentIndex: () => void;
}

const SideNav: React.FC<SideNavProps> = ({
  itemList,
  setIsOpen,
  activeView,
  setCurrentLanguage,
  currentLanguage,
  ImageUrlParameterList,
  allParamsList,
  HideLanguage,
  ObjectFit,
  showLanguages,
  setShowLanguages,
  setIndexTitle,
  setPageTitleWithCurrentIndex
}: SideNavProps) => {
  const [imageWidth, setImageWidth] = useState('auto');
  const [imageHeight, setImageHeight] = useState('auto');
  const [disabledButton, setDisabledButton] = useState(false);

  useEffect(() => {
    if (allParamsList && currentLanguage) {
      let w = allParamsList['ImageWidth_' + currentLanguage];
      let h = allParamsList['ImageHeight_' + currentLanguage];
      if (w && h) {
        setImageWidth(w);
        setImageHeight(h);
      }
    }
  }, [currentLanguage, allParamsList]);

  
  useEffect(() => {
    if(!HideLanguage && showLanguages){
        setDisabledButton(true);
    }
  },[showLanguages, HideLanguage]);
 


  let activeStyle = { borderBottom: '2px solid #00a2dc', color: '#022a70' };
  const getSrcImage = () => {
    return ImageUrlParameterList.length > 1
      ? ImageUrlParameterList[1]
      : ImageUrlParameterList.length > 0
      ? ImageUrlParameterList[0]
      : ' ';
  };
  const getObjectFit = () => {
    let dictionaryTypes = new Map<string, ObjectFitType>([
      ['cover', ObjectFitType.cover],
      ['contain', ObjectFitType.contain],
      ['fill', ObjectFitType.fill],
      ['none', ObjectFitType.none],
    ]);
    return ObjectFit ? dictionaryTypes.get(ObjectFit) : ObjectFitType.cover;
  };


  const setLanguage = (language: string) => {
    setDisabledButton(false);
    setCurrentLanguage(language);
    setShowLanguages();
  }

  
  let disabledStyleButton =  {
    color: "rgba(0, 0, 0, 0.87)",
    cursor: "not-allowed",
    opacity: 0.2
  };

  const getStyleButton = (title: string) => {
    return !disabledButton ? disabledStyleButton : currentLanguage === title ? activeStyle : {};
  };

  return (
    <div className="side-nav">
      <div className="logo">
        <Link to="/">
          <PictureComponent imageUrl={getSrcImage()} imageWidth={imageWidth}>
            <img
              src={getSrcImage()}
              alt="Logo"
              style={{ objectFit: getObjectFit() }}
              width={imageWidth}
              height={imageHeight}
              loading='lazy'
            />
          </PictureComponent>
        </Link>
        <div className="close-sidenav">
          <IconButton
            aria-label="Delete"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div className="children">
        <SideNavList setIsOpen={(value: boolean) => setIsOpen(value)} itemList={itemList} setIndexTitle={setIndexTitle}  />
      </div>
      <Divider />
      {!HideLanguage ? (
        <div className="language">
          <Button
            name="englishButton"
            style={getStyleButton('EN')}
            onClick={() => {setLanguage('EN'); setPageTitleWithCurrentIndex();}}
            disabled={!disabledButton}
          >
            <div className="font-item">English</div>
          </Button>
          <Button
            name="spanishButton"
            style={getStyleButton('ES')}
            onClick={() => {setLanguage('ES'); setPageTitleWithCurrentIndex();}}
            disabled={!disabledButton}
          >
            <div className="font-item">Spanish</div>
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default SideNav;
