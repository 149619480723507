import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { LinkParam } from '../../interfaces/interfaces';
import NavLinkItem from './NavLinkItem';
import '../../styles/sass/nav/_navlinks.scss';
import { HashLink } from 'react-router-hash-link';

interface navLinksprops {
  linkParamList: LinkParam[];
  activeLink: string;
  onLastLinkHovered: (e: string) => void;
  isShowSubmenu: boolean;
  setSpaceOnNav: (num: number) => void;
  setLeftOffsets: Dispatch<SetStateAction<(number | undefined)[]>>;
  getColorText: Function;
  FontFamilyText: string;
  match: boolean;
  language: string | undefined;
  setIndexCurrentTitle: ( (index: number) => void );
 }

const NavLinks: React.FC<navLinksprops> = ({
  linkParamList,
  activeLink,
  onLastLinkHovered,
  isShowSubmenu,
  getColorText,
  setLeftOffsets,
  FontFamilyText,
  match,
  language,
  setIndexCurrentTitle,
}: navLinksprops) => {
  const buttonLinks = useRef<(HTMLAnchorElement | null)[]>([]);
  const style = {
    color: 'white',
  };

  useEffect(() => {
    const handleResize = () => {
      setTimeout(() => {
        setLeftOffsets(
          buttonLinks.current.map((buttonLink) => {
            if (!match && buttonLink) {
              return buttonLink?.offsetLeft - 300;
            }
            return buttonLink?.offsetLeft;
          }),
        );
      
      }, 1000);
    };
    
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [match, language]);

  

  const scrollWithOffset = (el: any) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    let elementAbouUs = document.getElementById(el.id);
    let paddingComponent = Number(elementAbouUs?.style.paddingTop.replace('px', ''));
    const yOffset = -(106 - paddingComponent);
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  return (
    <div className="nav-links">
      {linkParamList
        ? linkParamList.map((item, index) => {
            return !item.Link.includes('#') ? (
              <Link
                ref={(el) => {
                  buttonLinks.current[index] = el;
                }}
                key={index + (language ? language : '')}
                style={style}
                to={item.Link}
                onMouseEnter={(e) => onLastLinkHovered(item.Text)}
                tabIndex={-1}
                onClick={() => setIndexCurrentTitle(index)}
              >
                {item.Text !== '' && !item.HideTitle ? (
                  <NavLinkItem
                    text={item.Text}
                    isActive={activeLink === item.ViewId}
                    isShowSubmenu={isShowSubmenu}
                    type={item.Type}
                    getColorText={getColorText}
                    FontFamilyText={FontFamilyText}
                  />
                ) : null}
              </Link>
            ) : (
              <HashLink key={index} to={item.Link} scroll={scrollWithOffset}>
                {item.Text !== '' && !item.HideTitle ? (
                  <NavLinkItem
                    text={item.Text}
                    isActive={activeLink === item.ViewId}
                    isShowSubmenu={isShowSubmenu}
                    type={item.Type}
                    getColorText={getColorText}
                    FontFamilyText={FontFamilyText}
                  />
                ) : null}
              </HashLink>
            );
          })
        : null}
    </div>
  );
};
export default NavLinks;
