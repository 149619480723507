import React, { useEffect, useState } from 'react';
import { Grid , TextField , TextareaAutosize, useTheme, useMediaQuery, styled, Theme, createTheme } from '@mui/material';
import { ParameterModel } from '../../../interfaces/models';
import { BorderColor, Title } from '@mui/icons-material';
import { isValidEmail } from '../../../utils/utils';
import '../../../styles/sass/form/_inputText.scss';
import { Box } from '@mui/system';

const PREFIX = 'InputTextAreaComponent';

const classes = {
  field: `${PREFIX}-field`,
  fullfield: `${PREFIX}-fullfield`,
  inputLabel: `${PREFIX}-inputLabel`,
  selectedItem: `${PREFIX}-selectedItem`
};

const StyledGrid = styled(Grid)((
  { theme } : { theme : Theme }
) => ({
  [`& .${classes.field}`]: {
    margin: 'auto',
    padding: theme.spacing(1),
    [theme.breakpoints.between(1, 281)]: {
      width: '162px',
    },
    [theme.breakpoints.between(281, 321)]: {
      width: '202px',
    },
    [theme.breakpoints.between(321, 361)]: {
      width: '242px',
    },
    [theme.breakpoints.between(361, 376)]: {
      width: '252px',
    },
    [theme.breakpoints.between(376, 415)]: {
      width: '282px',
    },
    [theme.breakpoints.between(415, 541)]: {
      width: '277px',
    },
    [theme.breakpoints.between(541, 769)]: {
      width: '337px',
    },
    [theme.breakpoints.between(769, 1025)]: {
      width: '487px',
    },
    [theme.breakpoints.up(1025)]: {
      width: '517px',
    },
  },

  [`& .${classes.fullfield}`]: {
    margin: 'auto',
    paddingTop: '9px',
    paddingBottom: '9px',
    [theme.breakpoints.between(1, 281)]: {
      width: '184px',
    },
    [theme.breakpoints.between(281, 321)]: {
      width: '224px',
    },
    [theme.breakpoints.between(321, 361)]: {
      width: '264px',
    },
    [theme.breakpoints.between(361, 376)]: {
      width: '274px',
    },
    [theme.breakpoints.between(376, 415)]: {
      width: '304px',
    },
    [theme.breakpoints.between(415, 541)]: {
      width: '299px',
    },
    [theme.breakpoints.between(541, 769)]: {
      width: '359px',
    },
    [theme.breakpoints.between(769, 1025)]: {
      width: '509px',
    },
    [theme.breakpoints.up(1025)]: {
      width: '539px',
    },
  },

  [`& .${classes.inputLabel}`]: {
    top: '-1px',
    '&.shrink': {
      marginTop: '10px',
    },
  },

  [`& .${classes.selectedItem}`]: {
    backgroundColor: '#FFFFFF !important',
  }
}));


interface InputTextAreaComponentProps {
  Parameters: ParameterModel[];
  getValue: Function;
  idParent: string;
  clear: boolean;
}
/* eslint-disable complexity */
const InputTextAreaComponent: React.FC<InputTextAreaComponentProps> = ({
  Parameters,
  getValue,
  idParent,
  clear,
}: InputTextAreaComponentProps) => {
  const [valueInput, setValue] = useState<string>('');
  const [FontFamilyTitle, setFontFamilyTitle] = useState('');
  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [FontSizeTitle, setFontSizeTitle] = useState('');
  const [stylized, setStylized] = useState(true);
  const [IsEmpty, setIsEmpty] = useState(false);
  const [EmptyError,setEmptyError] = useState("This field can not be blank");

  useEffect(() => {
    Parameters.forEach((parameter) => {
      if (parameter.type === 'Title') {
        setTitle(parameter.value);
      } else if (parameter.type === 'Name') {
        setName(parameter.value);
      } else if (parameter.type === 'FontFamilyTitle') {
        setFontFamilyTitle(parameter.value);
      } else if (parameter.type === 'FontTitle') {
        setFontSizeTitle(parameter.value);
      } else if (parameter.type === 'Stylized') {
        setStylized(parameter.value.toLowerCase() === 'false' ? false : true);
      } else if (parameter.type === 'ErrorEmpty') {
        setEmptyError(parameter.value);
      }
    });
  }, [Parameters]);

  useEffect(() => {
    setValue('');
    getValue(name, '', true);
    setIsEmpty(false);
  }, [clear]);

  const handleInputChange = (e: any) => {
    // debugger;
    const { value } = e.target;
    setValue(value);
    getValue(name, value, true);
  };

  const fontSizeTitle =
    FontSizeTitle === 'Small'
      ? '17'
      : FontSizeTitle === 'Medium'
      ? '21'
      : FontSizeTitle === 'Big'
      ? '30'
      : FontSizeTitle === 'ExtraLarge'
      ? '42'
      : '';
  const invalid = () => {
    setIsEmpty(true);
  }
  const getWidthDifference = () => {
    if(matchFull){
      return '10px';
    }else if (matchTablet){
      return '30px';
    }else if (match){
      return '35px';
    }
  }
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1200,
        xl: 1536,
      },
    },
  });
  const match = useMediaQuery(theme.breakpoints.up('sm'));
  const matchTablet = useMediaQuery(theme.breakpoints.up('sm'));
  const matchFull = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Grid className="input-TextArea" item xs={6} md={ 6 } style={{ paddingBottom: '70px', width:'100%', minWidth:'345px' }}>
      {stylized ? 
      <div>
          <TextField
          label={title}
          error={IsEmpty}
          className={`${classes.field} disable-highlight`}
          value={valueInput}
          required
          rows="8"
          variant="standard"
          multiline
          name={title}
          onChange={(e) => handleInputChange(e)}
          onInvalid={invalid}
          margin="normal"
          style={{ fontSize: fontSizeTitle + 'px',width:match ? 'calc(100% - 20px)' : '100%' }}
          InputLabelProps={{
            classes: {
              root: classes.inputLabel,
              shrink: 'shrink',
            },
          }}
        />
         {IsEmpty ?
          <p  className="error-text-color">
            {EmptyError}
          </p>
          :
          null
        }
        </div>
        :
        <Box sx={{width:{xs:'calc(100% - 20px)',md:'calc(100%)'}}}>
          <TextareaAutosize
          className={IsEmpty ? 'redInput' : ''}
          maxLength={2000}
          placeholder={title}
          value={valueInput}
          required
          onChange={(e) => handleInputChange(e)}
          style={{
            padding: '13px',
            borderRadius: '10px',
            fontFamily: FontFamilyTitle,
            fontSize: fontSizeTitle + 'px',
            border: IsEmpty ? '3px solid red' : '3px solid #cccccc',
            width: "calc(100% - 10px)",
            resize: 'none',
            height: '300px',
            overflow: 'hidden auto',
            
          }}
                   
          onInvalid={invalid}
          />
        </Box>
      }
      
    </Grid>
  );
};

export default InputTextAreaComponent;
