import React, { useState, useEffect } from 'react';
import '../styles/sass/_textblockimage.scss';
import { Grid } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { Email } from '@mui/icons-material';
import EmailComponent from './EmailComponent';
import '../styles/sass/_address.scss';

interface AddressProps {
  contact?: string;
  job?: string;
  phone?: string;
  email?: string;
  address?: string;
  city?: string;
  country?: string;
  link?: string;
  iframe?: string;
  color?: string;
  fontFamily?: string;
  fontSize?: string;
  margin?: string;
  address1?: string;
  address2?: string;
}

const Address: React.FC<AddressProps> = ({
  contact,
  job,
  phone,
  email,
  address,
  city,
  country,
  link,
  iframe,
  color,
  fontFamily,
  fontSize,
  margin,
  address1,
  address2,
}: AddressProps) => {
  const iframeId: string = uuidv4();
  const [srcIfrane, setSrcIframe] = useState('');

  useEffect(() => {
    let src: string[] = iframe !== undefined ? iframe.split('"') : [];
    setSrcIframe(src[1]);
  }, [iframe]);

  const fontFamilyText = fontFamily ? fontFamily : '';
  const colorText = color ? color : '';
  const fontSizeText =
    fontSize === 'Small'
      ? '17'
      : fontSize === 'Medium'
      ? '21'
      : fontSize === 'Big'
      ? '30'
      : fontSize === 'ExtraLarge'
      ? '42'
      : '';

  return (
    <div  style={{ color: colorText, fontFamily: fontFamilyText, fontSize: fontSizeText + 'px' }}>
      <Grid container justifyContent="center" alignItems="center" alignContent="center">
        {iframe !== undefined ? (
          <Grid item xs={12} sm={12}>
            <iframe
              title={iframeId}
              id={iframeId}
              src={srcIfrane}
              width="100%"
              height="225"
              tabIndex={0}
              frameBorder="0"
              style={{ margin: 'auto', display: 'block' }}
              allowFullScreen={false}
              aria-hidden="false"
            />
          </Grid>
        ) : (
          <Grid
            className="address"
            container
            spacing={1}
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            style={{ marginTop: '30px', marginBottom: '30px', margin:margin}}
          >
            {contact !== undefined ? (
              <Grid item>
                <p style={{ color: colorText}}>{contact}</p>
              </Grid>
            ) : null}
            {phone !== undefined ? (
              <Grid item>
                <p style={{ color: colorText}}>{phone}</p>
              </Grid>
            ) : null}
            {email !== undefined ? (
              <Grid item>
                <EmailComponent email={email}  />
              </Grid>
            ) : null}
            {address !== undefined ? (
              <Grid item>
                <p style={{ color: colorText}}>{address}</p>
              </Grid>
            ) : null}
            {job !== undefined ? (
              <Grid item>
                <p>{job}</p>
              </Grid>
            ) : null}
            {address1 !== undefined ? (
              <Grid item>
                <p style={{ color: colorText }}>{address1}</p>
              </Grid>
            ) : null}
            {address2 !== undefined ? (
              <Grid item>
                <p style={{ color: colorText }}>{address2}</p>
              </Grid>
            ) : null}
            <Grid item>
              <p style={{ color: colorText}}>
                {city && country ? `${city} - ${country}` : city || country}
              </p>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default Address;
