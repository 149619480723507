import React, { useState, useEffect } from 'react';
import { ParameterModel } from '../interfaces/models';
import '../styles/sass/_textblock.scss';

interface TextBlockProps {
  paramArray: ParameterModel[];
}

const DataCard: React.FC<TextBlockProps> = (props: TextBlockProps) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [img, setImg] = useState('');

  useEffect(() => {
    setValues();
  }, []);

  const setValues = () => {
    if (props?.paramArray?.length > 0) {
      props.paramArray.forEach((param) => {
        if (param.type === 'Title') {
          setTitle(param.value);
        }
        if (param.type === 'Text') {
          setDescription(param.value);
        }
        if (param.type === 'URL') {
          setImg(param.value);
        }
      });
    }
  };

  return (
    <div className="text-block">
      <h3 className="title" dangerouslySetInnerHTML={{ __html: title }} />
      <p className="description" dangerouslySetInnerHTML={{ __html: description }} />
      {img && <p className="description">{img}</p>}
    </div>
  );
};
export default DataCard;
