import React, {  useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import '../../../styles/sass/form/_inputRadioStylized.scss';
import { ParameterModel } from '../../../interfaces/models';
import { isEmpty } from 'lodash';

interface InputRadioStylizedProps {
  Parameters: ParameterModel[];
  getValue: Function;
  clear: boolean;
}
/* eslint-disable complexity */
const InputRadioStylized: React.FC<InputRadioStylizedProps> = ({
  Parameters,
  getValue,
  clear,
}: InputRadioStylizedProps) => {
  const [FontFamilyTitle, setFontFamilyTitle] = useState('');
  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [FontSizeTitle, setFontSizeTitle] = useState('');
  const [IsEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    Parameters.forEach((parameter) => {
      if (parameter.type === 'Title') {
        setTitle(parameter.value);
      } else if (parameter.type === 'Name') {
        setName(parameter.value);
      } else if (parameter.type === 'FontFamilyTitle') {
        setFontFamilyTitle(parameter.value);
      } else if (parameter.type === 'FontTitle') {
        setFontSizeTitle(parameter.value);
      }
    });
  }, [Parameters]);
  useEffect(() => {
    let input = document.getElementById(title + '-' + name) as unknown as HTMLInputElement;
    input.checked = false;
    getValue(name, '', true);
    setIsEmpty(false);
  }, [clear]);

  const IsChecked = (event: any) => {
    if (getValue) {
      getValue(name, title, true);
    }
  };

  const fontSizeTitle =
    FontSizeTitle === 'Small'
      ? '17'
      : FontSizeTitle === 'Medium'
      ? '21'
      : FontSizeTitle === 'Big'
      ? '30'
      : FontSizeTitle === 'ExtraLarge'
      ? '42'
      : '';
  const fontIcon =
    FontSizeTitle === 'Small'
      ? '22'
      : FontSizeTitle === 'Medium'
      ? '26'
      : FontSizeTitle === 'Big'
      ? '35'
      : FontSizeTitle === 'ExtraLarge'
      ? '47'
      : '';
  const invalid = () => {
    setIsEmpty(true);
  };
  return (
    <div className="component-input-radio">
      <Grid container direction="column" justifyContent="center">
        <Grid container direction="row">
          <Grid item xs={2} />
          <Grid item xs={8}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <label style={{ fontFamily: FontFamilyTitle, fontSize: fontSizeTitle + 'px' }}>{title}</label>
              </Grid>
              <Grid item>
                <input
                  required
                  type="radio"
                  id={title + '-' + name}
                  style={{
                    width: fontIcon + 'px',
                    height: fontIcon + 'px',
                    border: IsEmpty ? '3px solid red' : '3px solid #053F5E',
                    borderRadius: '50%',
                  }}
                  name={name}
                  value={title}
                  onClick={IsChecked}
                  onInvalid={invalid}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2} />
        </Grid>
        <Grid container direction="row">
          <Grid item xs={2} />
          <Grid item xs={8}>
            <hr className={IsEmpty ? 'horizontalRuleRed' : ''} />
          </Grid>
          <Grid item xs={2} />
        </Grid>
      </Grid>
    </div>
  );
};

export default InputRadioStylized;
