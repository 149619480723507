import React, { useEffect, useState } from 'react';
import '../../styles/sass/detailcard/_detailitem.scss';
import { Link, useNavigate } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import { Grid, useMediaQuery } from '@mui/material';
import PictureComponent from '../PictureComponent';

interface DetailItemProps {
  Title?: string;
  ImageUrl?: string;
  ImageMobileUrl?: string;
  ImageWidth?: string;
  ImageHeight?: string;
  ImageWidthMobile?:string;
  ImageHeightMobile?:string;
  link?: string;
  FontTitle?: string;
  FontFamilyTitle?: string;
  ColorTitle?: string;
}

const DetailItem: React.FC<DetailItemProps> = ({
  Title,
  ImageUrl,
  ImageMobileUrl,
  ImageWidth,
  ImageHeight,
  ImageWidthMobile,
  ImageHeightMobile,
  link,
  FontTitle,
  FontFamilyTitle,
  ColorTitle,
}: DetailItemProps) => {
  const [imageHeight, setImageHeight] = useState('0');
  const [imageWidth, setImageWidth] = useState('0');
  const url = link ? link : '/';
  const isHashLink = url.includes('#');
  const isUrlLink = url.includes('https://') || url.includes('http://');
  const fontSizeTitle =
    FontTitle === 'Small'
      ? '17'
      : FontTitle === 'Medium'
      ? '21'
      : FontTitle === 'Big'
      ? '30'
      : FontTitle === 'ExtraLarge'
      ? '55'
      : '';

  const scrollWithOffset = (el: any) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -160;
    setTimeout(() => {
      window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }, 1500);
  };

  let didRedirect = false;
  const setRedirect = () => {
    didRedirect = true;
  };

  const redirectUrl = () => {
    if (!didRedirect) {
      document.getElementById(url)?.click();
    }
    didRedirect = false;
  };

  const mobileMatch = useMediaQuery('(max-width:899px)');

  useEffect(() => {
    if(mobileMatch){
      setImageHeight(ImageHeightMobile ? ImageHeightMobile : '0');
      setImageWidth(ImageWidthMobile ? ImageWidthMobile : '0');

    } else {
      setImageHeight(ImageHeight  ? ImageHeight : '0');
      setImageWidth(ImageWidth ? ImageWidth : '0');
    }
  },[mobileMatch]);



  const RenderPictureComponent = () => {
    return (
      <PictureComponent imageUrl={ImageUrl ? ImageUrl : ''} imageWidth={imageWidth} imageMobileUrl={ImageMobileUrl}>
        <img src={ImageUrl} alt={'Image of ' + Title + ' in detail item'} width={imageWidth} height={imageHeight} loading='lazy'/>
      </PictureComponent>
    );
  }

  const renderWithImage = () => {
    if (isHashLink) {
      return (
        <NavHashLink
          title="isHashLink"
          style={{ textDecoration: 'none', color: 'white' }}
          to={url}
          scroll={(el) => scrollWithOffset(el)}
          id={url}
          onClick={setRedirect}
        >
          <RenderPictureComponent />
        </NavHashLink>
      );
    } else if (isUrlLink) {
      return (
        <a
          title="isUrlLink"
          href={url}
          target="_blank"
          style={{ textDecoration: 'none' }}
          id={url}
          onClick={setRedirect}
        >
          <RenderPictureComponent />
        </a>
      );
    } else {
      return (
        <Link
          title="aUrlLink"
          style={{ textDecoration: 'none', color: 'white' }}
          to={url}
          id={url}
          onClick={setRedirect}
        >
          <RenderPictureComponent />
        </Link>
      );
    }
  };

  const renderWithoutLinkUrl = () => {
    if (Title) {
      return (
        <Grid title="renderWithoutLinkUrl" container justifyContent="center" alignItems="center" spacing={1}>
          <Grid item xs={1} />
          <Grid item xs={6}>
            <PictureComponent imageUrl={ImageUrl ? ImageUrl : ''} imageWidth={imageWidth} imageMobileUrl={ImageMobileUrl}>
              <img
                src={ImageUrl}
                alt={'Image of ' + Title + ' in detail item'}
                style={{ textAlign: 'right', width: '100%' }}
                width={imageWidth}
                height={imageHeight}
                loading='lazy'
              />
            </PictureComponent>
          </Grid>
          <Grid item xs={4}>
            <p
              style={{
                textAlign: 'left',
                fontSize: fontSizeTitle + 'px',
                fontFamily: FontFamilyTitle,
                color: ColorTitle,
              }}
            >
              {Title}
            </p>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      );
    } else {
      return (
        <Grid container justifyContent="center" alignItems="center" spacing={1}>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <PictureComponent imageUrl={ImageUrl ? ImageUrl : ''} imageWidth={imageWidth} imageMobileUrl={ImageMobileUrl}>
              <img
                src={ImageUrl}
                alt={'Image of ' + Title + ' in detail item'}
                style={{ textAlign: 'right', width: '100%' }}
                width={imageWidth}
                height={imageHeight}
                loading='lazy'
              />
            </PictureComponent>
          </Grid>

          <Grid item xs={1} />
        </Grid>
      );
    }
  };

  const renderWithoutImage = () => {
    if (isHashLink) {
      return (
        <NavHashLink
          title="renderWithoutImage"
          className="link-box"
          to={url}
          scroll={(el) => scrollWithOffset(el)}
          id={url}
          onClick={setRedirect}
        >
          <h1>{Title}</h1>
        </NavHashLink>
      );
    } else if (isUrlLink) {
      return (
        <a title="renderWithoutImage" href={url} target="_blank" className="link-box" id={url} onClick={setRedirect}>
          <h1>{Title}</h1>
        </a>
      );
    } else {
      return (
        <Link title="renderWithoutImage" className="link-box" to={url} id={url} onClick={setRedirect}>
          <h1>{Title}</h1>
        </Link>
      );
    }
  };

  return (
    <div className="detail-item">
      <div className="tail-area">
        <div className="tail" style={{ visibility: url === '/' ? 'hidden' : 'visible' }} />
      </div>
      <div
        className="box"
        style={{
          cursor: url === '/' ? 'default' : 'pointer',
          background: url === '/' ? 'transparent' : '',
          border: url === '/' ? '0' : '',
        }}
        onClick={redirectUrl}
      >
        {link && link !== '/' ? (ImageUrl ? renderWithImage() : renderWithoutImage()) : renderWithoutLinkUrl()}
      </div>
    </div>
  );
};

export default DetailItem;
