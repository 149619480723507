import React, { useContext, useEffect, useState } from 'react';
import PageLayout from './components/PageLayout';
import './styles/sass/_app.scss';
import './cms/App.css';
import Cookies from 'js-cookie';
import { MyContext, emptyUser } from './cms/contextApi/MyProvider';
import FullScreenLoading from './components/Loading/FullscreenLoading';
import { InitDataModel, UserModel } from './interfaces/models';
import InitDataService from './services/InitDataService';

interface WebAppProps {
  mainActivityIndicator: string;
  hideBrightsoftLoader: boolean;
  languages: string[];
  hideBackground: boolean;
  setPageTitle: ( page: string) => void;
}

const WebApp: React.FC<WebAppProps> = (props: WebAppProps) => {
  const [user, setUser] = useContext(MyContext);
  const [loading, setLoading] = useState(true);
  const [language, setLanguage] = useState(props.languages?.[0] || 'defaultLanguage');
  const [initData, setInitData] = useState<InitDataModel | undefined>();
  
  useEffect(() => {
    let setUserFunction = setUser as React.Dispatch<React.SetStateAction<UserModel>>;
    let currentUserInfo: UserModel;
    const userInfoCookie = Cookies.get('userInfo');
    

    if (userInfoCookie) {
      currentUserInfo = JSON.parse(userInfoCookie as string) as UserModel;
      setUserFunction(currentUserInfo);
    } else {
      setUserFunction(emptyUser);
    }

  }, [setUser]);

  useEffect(() => {
    InitDataService.getInstance()
    .getInitData(language, setInitData)
    .then((initData1) => {
      setInitData(initData1);
    });
  }, [language]);

  return (
    <>
      <PageLayout
        initData={initData}
        language={language}
        setLanguage={setLanguage}
        setLoading={(e) => setLoading(e)}
        isPreview={false}
        mainActivityIndicator={props.mainActivityIndicator}
        hideBrightsoftLoader={props.hideBrightsoftLoader}
        hideBackground={props.hideBackground}
        setPageTitle={props.setPageTitle}
      />
      <FullScreenLoading state={loading} mainActivityIndicator={props.mainActivityIndicator} hideBrightsoftLoader={props.hideBrightsoftLoader}/>
    </>
  );
};

export default WebApp;
