import {
  CandidateDto,
  CandidateModel,
  CandidateToken,
  instanceOfValidationErrorModel,
  ValidationErrorModel,
} from '../../interfaces/models';
import { ConsumeRestAPIEndPoint, RequestOptions } from '../../services/ApiRestServiceCommon';

export const fetchCareersList = async (): Promise<Array<CandidateModel> | ValidationErrorModel | null> => {
  let optionRequest = await RequestOptions('GET', null);
  let result: Array<CandidateModel> | ValidationErrorModel | null = await ConsumeRestAPIEndPoint(
    '/api/v1/Candidates',
    optionRequest,
  );

  if (instanceOfValidationErrorModel(result) || !result) {
    return result;
  } else {
    const list: CandidateModel[] = result;
    list.sort((n1, n2) => {
      let date1 = new Date(n1.createdAt);
      let date2 = new Date(n2.createdAt);
      return date2.getTime() - date1.getTime();
    });
    return list;
  }
};

export const getCandidate = async (queryParams: any): Promise<CandidateModel | ValidationErrorModel | null> => {
  let optionRequest = await RequestOptions('GET', null);
  let result: CandidateModel | ValidationErrorModel | null = await ConsumeRestAPIEndPoint(
    '/api/v1/Candidates/' + queryParams,
    optionRequest,
  );

  return result;
};

export const createNewCandidate = async (
  requestData: CandidateToken,
  resetAction: () => void,
): Promise<CandidateModel | ValidationErrorModel | null> => {
  let optionRequest = RequestOptions('POST', requestData);

  try {
    let result: CandidateModel | ValidationErrorModel | null = await ConsumeRestAPIEndPoint(
      '/api/v1/Candidates',
      optionRequest,
    );
    if (result) {
      resetAction();
    }
    return result;
  } catch (e) {
    console.error(e);
  }
  return null;
};
