import React, { useEffect, useState } from 'react';
import { CarouselItemDef } from '../../interfaces/interfaces';
import '../../styles/sass/carousel/_carouselitem.scss';
import { Container, Grid, useMediaQuery } from '@mui/material';
import { CarouselItemText } from './CarouselItemText';
import PictureComponent from '../PictureComponent';

const CarouselItem: React.FC<CarouselItemDef> = ({
  Id,
  Title,
  Text,
  ImageUrl,
  ImageMobileUrl,
  ImageWidth,
  ImageHeight,
  ImageWidthMobile,
  ImageHeightMobile,
  LinkUrl,
  LinkText,
  show,
  Theme,
  FontTitle,
  FontText,
  ColorText,
  ColorTitle,
  BackgroundColor,
  LineHeightTitle,
  LineHeightText,
  FontWeightTitle,
  FontWeightText,
  FontFamilyTitle,
  FontFamilyText,
  ColorTextButton,
  FontTextButton,
  BorderRadius,
  Padding,
  children,
  PDFUrl,
  WithoutUIButton,
  AutoHeightMobile,
}: CarouselItemDef) => {
  const isDarker = Theme === 'Dark' ? 'img-darker' : '';
  const matches = useMediaQuery('(min-width:600px)');
  const autoHeightMobile = AutoHeightMobile?.toLowerCase() === "true" ? true : false;
  const [imageWidth, setImageWidth] = useState<string>(ImageWidth ? ImageWidth : '');
  const [imageHeight, setImageHeight] = useState<string>(ImageHeight ? ImageHeight : '');
  useEffect(()=> {
    if(window.innerWidth <= 414){
      setImageWidth(ImageWidthMobile ? ImageWidthMobile : '');
      setImageHeight(ImageHeightMobile ? ImageHeightMobile : '');  
    }
  },[matches])



  const getClassName = () => {
    const classAutoHeightMobile = autoHeightMobile ? " auto-height-mobile auto-resize" : "";
    return "carousel-item" + classAutoHeightMobile;
  }
  return show ? (
    <div id={`carousel-item-${Id}`} className={getClassName()} style={{height: autoHeightMobile?  ImageHeightMobile+'px' : ''}}>
      <PictureComponent imageUrl={ImageUrl ? ImageUrl : ''} imageWidth={ImageWidth} imageMobileUrl={ImageMobileUrl}>
        <img
          // eslint-disable-next-line react/no-unknown-property
          fetchpriority="high"
          className={isDarker}
          src={ImageUrl}
          width={imageWidth}
          height={imageHeight}
          alt={Title ? 'Image of ' + Title + ' in carousel' : 'image description'}
        />

      </PictureComponent>
      
      <div className="text-position">
        <Container fixed>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8} lg={7}>
              <CarouselItemText
                Title={Title}
                Text={Text}
                LinkUrl={LinkUrl}
                LinkText={LinkText}
                FontTitle={FontTitle}
                FontText={FontText}
                ColorText={ColorText}
                ColorTitle={ColorTitle}
                BackgroundColor={BackgroundColor}
                LineHeightTitle={LineHeightTitle}
                LineHeightText={LineHeightText}
                FontWeightTitle={FontWeightTitle}
                FontWeightText={FontWeightText}
                FontFamilyTitle={FontFamilyTitle}
                FontFamilyText={FontFamilyText}
                BorderRadius={BorderRadius}
                FontTextButton={FontTextButton}
                ColorTextButton={ColorTextButton}
                Padding={Padding}
                PDFUrl={PDFUrl}
                WithoutUIButton={WithoutUIButton}
              >
                {children}
              </CarouselItemText>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  ) : null;
};

export default CarouselItem;
