import { ComponentDef, GenericparamDef, CountryDef, ILink, IGenericParam } from '../interfaces/interfaces';
import { ComponentViewModel, ParameterModel } from '../interfaces/models';
import { ComponentClassType } from '../interfaces/enums';
import { GridSize } from '@mui/material';

export const filterComponent = (componentList: ComponentViewModel[], keyFilter: string): ComponentViewModel => {
  let res: ComponentViewModel = {
    id: '',
    key: '',
    parameters: [],
    children: [],
    index: 0,
    type: ComponentClassType.TextBlock,
  };

  if (componentList) {
    const filtered = componentList.filter((component) => component.key === keyFilter)[0];
    res = filtered ? filtered : res;
  }

  return res;
};

export const getFooter = (componentList: ComponentViewModel[]): ComponentViewModel => {
  let res: ComponentViewModel = {
    id: '',
    key: '',
    parameters: [],
    children: [],
    index: 0,
    type: ComponentClassType.TextBlock,
  };

  if (componentList) {
    const filtered = componentList.filter((component) => component.type === ComponentClassType.Footer)[0];
    res = filtered ? filtered : res;
  }

  return res;
};

export const filterComponents = (componentList: ComponentViewModel[], keyFilter: string): ComponentViewModel => {
  let res: ComponentViewModel = {
    key: '',
    parameters: [],
    id: '',
    children: [],
    index: 0,
    type: ComponentClassType.TextBlock,
  };

  if (componentList) {
    const filtered = componentList.filter((component) => component.key === keyFilter)[0];
    res = filtered ? filtered : res;
  }
  return res;
};

export const filterComponentByType = (componentList: ComponentDef[], keyFilter: string): ComponentDef => {
  let res: ComponentDef = {
    Id: 0,
    ParentId: 0,
    Key: '',
    Parameters: [],
    Type: '',
    Components: [],
  };

  if (componentList) {
    const filtered = componentList.filter((component) => component.Type === keyFilter)[0];
    res = filtered ? filtered : res;
  }
  return res;
};

export const filterComponentDetails = (res: ComponentViewModel): ILink => {
  let result: ILink = {
    children: [],
    text: '',
    url: '',
    level: '',
  };

  res.parameters.forEach((param, index) => {
    if (param.type === 'Text') {
      result.text = param.value;
    }

    if (param.type === 'Link') {
      result.url = param.value;
    }

    if (param.type === 'Level') {
      result.level = param.value;
    }
  });
  result.children = res.children;
  return result;
};

export const getGroupId = (component: ComponentViewModel): string => {
  let result = 'EN';
  if (component.parameters && component.parameters.length > 0) {
    let first = component.parameters[0];
    return first.groupId;
  }
  return result;
};

export const getGenericParams = (component: ComponentViewModel): IGenericParam => {
  const res: GenericparamDef = {};
  /* eslint-disable complexity */
  component.parameters.forEach((param: ParameterModel) => {
    if (param.type === 'Title') {
      res.Title = param.value;
    }
    if (param.type === 'HideTitle') {
      res.HideTitle = param.value;
    }
    if (param.type === 'Text') {
      res.Text = param.value;
    }
    if (param.type === 'ImageUrl') {
      res.ImageUrl = param.value;
    }
    if (param.type === 'ImageHeight') {
      res.ImageHeight = param.value;
    }
    if (param.type === 'ImageWidth') {
      res.ImageWidth = param.value;
    }
    if (param.type === 'Link') {
      res.Link = param.value;
    }
    if (param.type === 'LinkUrl') {
      res.LinkUrl = param.value;
    }
    if (param.type === 'LinkText') {
      res.LinkText = param.value;
    }
    if (param.type === 'ViewId') {
      res.ViewId = param.value;
    }
    if (param.type === 'Size') {
      res.Size = param.value;
    }
    if (param.type === 'Theme') {
      res.Theme = param.value;
    }
    if (param.type === 'Language') {
      res.Language = param.value;
    }
    if (param.type === 'Type') {
      res.Type = param.value;
    }
    if (param.type === 'Error') {
      res.Error = param.value;
    }
    if (param.type === 'Error1') {
      res.Error1 = param.value;
    }
    if (param.type === 'BreakLine') {
      res.BreakLine = param.value;
    }
    if (param.type === 'FontTitle') {
      res.FontTitle = param.value;
    }
    if (param.type === 'AlignmentTitle') {
      res.AlignmentTitle = param.value;
    }
    if (param.type === 'ColorTitle') {
      res.ColorTitle = param.value;
    }
    if (param.type === 'FontText') {
      res.FontText = param.value;
    }
    if (param.type === 'AlignmentText') {
      res.AlignmentText = param.value;
    }
    if (param.type === 'ColorText') {
      res.ColorText = param.value;
    }
    if (param.type === 'MaxLengthText') {
      res.MaxLengthText = param.value;
    }
    if (param.type === 'CVMessage') {
      res.CVMessage = param.value;
    }
    if (param.type === 'PDFUrl') {
      res.PDFUrl = param.value;
    }
    if (param.type === 'Contact') {
      res.Contact = param.value;
    }
    if (param.type === 'Job') {
      res.Job = param.value;
    }
    if (param.type === 'Phone') {
      res.Phone = param.value;
    }
    if (param.type === 'Email') {
      res.Email = param.value;
    }
    if (param.type === 'Address') {
      res.Address = param.value;
    }
    if (param.type === 'Address1') {
      res.Address1 = param.value;
    }
    if (param.type === 'Address2') {
      res.Address2 = param.value;
    }
    if (param.type === 'City') {
      res.City = param.value;
    }
    if (param.type === 'Country') {
      res.Country = param.value;
    }
    if (param.type === 'Iframe') {
      res.Iframe = param.value;
    }
    if (param.type === 'WithoutImage') {
      res.WithoutImage = param.value;
    }
    if (param.type === 'Stylized') {
      res.Stylized = param.value;
    }
    if (param.type === 'BackgroundColor') {
      res.BackgroundColor = param.value;
    }
    if (param.type === 'Margin') {
      res.Margin = param.value;
    }
    if (param.type === 'LineHeightTitle') {
      res.LineHeightTitle = param.value;
    }
    if (param.type === 'LineHeightText') {
      res.LineHeightText = param.value;
    }
    if (param.type === 'FontWeightTitle') {
      res.FontWeightTitle = param.value;
    }
    if (param.type === 'FontFamilyTitle') {
      res.FontFamilyTitle = param.value;
    }
    if (param.type === 'FontFamilyText') {
      res.FontFamilyText = param.value;
    }
    if (param.type === 'Name') {
      res.Name = param.value;
    }
    if (param.type === 'Inlined') {
      res.Inlined = param.value;
    }
    if (param.type === 'HTMLText') {
      res.HTMLText = param.value;
    }
    if (param.type === 'MaxWidth') {
      res.MaxWidth = param.value;
    }
    if (param.type === 'IsCopyright') {
      res.IsCopyright = param.value;
    }
    if (param.type === 'ColorMenuIcon') {
      res.ColorMenuIcon = param.value;
    }
    if (param.type === 'FontWeightText') {
      res.FontWeightText = param.value;
    }
    if (param.type === 'FontTextButton') {
      res.FontTextButton = param.value;
    }
    if (param.type === 'ColorTextButton') {
      res.ColorTextButton = param.value;
    }
    if (param.type === 'BorderRadius') {
      res.BorderRadius = param.value;
    }
    if (param.type === 'EmailIcon') {
      res.EmailIcon = param.value;
    }
    if (param.type === 'VerticalAlignment') {
      res.VerticalAlignment = param.value;
    }
    if (param.type === 'Required') {
      res.Required = param.value;
    }
    if (param.type === 'TargetForm') {
      res.TargetForm = param.value;
    }
    if (param.type === 'FooterStyle') {
      res.FooterStyle = param.value;
    }
    if (param.type === 'Direction') {
      res.Direction = param.value;
    }
    if (param.type === 'MetaTagTitle') {
      res.MetaTagTitle = param.value;
    }
    if (param.type === 'MetaTagDomain') {
      res.MetaTagDomain = param.value;
    }
    if (param.type === 'WebIcon') {
      res.WebIcon = param.value;
    }
    if (param.type === 'MaxHeight') {
      res.MaxHeight = param.value;
    }
    if (param.type === 'Border') {
      res.Border = param.value;
    }
    if (param.type === 'ErrorEmpty') {
      res.ErrorEmpty = param.value;
    }
    if (param.type === 'Date') {
      res.Date = param.value;
    }
    if (param.type === 'Schedule') {
      res.Schedule = param.value;
    }
    if (param.type === 'Cost') {
      res.Cost = param.value;
    }
    if (param.type === 'Place') {
      res.Place = param.value;
    }
    if (param.type === 'Padding') {
      res.Padding = param.value;
    }
    if (param.type === 'FlexibleFontSizeText') {
      res.FlexibleFontSizeText = param.value;
    }
    if (param.type === 'FlexibleFontSizeTitle') {
      res.FlexibleFontSizeTitle = param.value;
    }
    if (param.type === 'ObjectFit') {
      res.ObjectFit = param.value;
    }
    if (param.type === 'TitleFormat') {
      res.TitleFormat = param.value;
    }
    if (param.type === 'MainActivityIndicator') {
      res.MainActivityIndicator = param.value;
    }
    if (param.type === 'Description') {
      res.Description = param.value;
    }
    if (param.type === 'Columns') {
      let intColumns = parseInt(param.value, 10);
      if (isNaN(intColumns)) {
        res.Columns = 'auto';
      } else {
        res.Columns = intColumns as GridSize;
      }
    }
    if (param.type === 'Lg') {
      res.Lg = param.value;
    }
    if (param.type === 'Xs') {
      res.Xs = param.value;
    }
    if (param.type === 'ImageMobileUrl'){
      res.ImageMobileUrl = param.value;
    }
    if (param.type === 'ImageHeightMobile'){
      res.ImageHeightMobile = param.value;
    }
    if (param.type === 'ImageWidthMobile'){
      res.ImageWidthMobile = param.value;
    }
    if (param.type === 'MinWidth'){
      res.MinWidth = param.value;
    }
    if (param.type === 'WithoutUIButton') {
      res.WithoutUIButton = param.value;
    }
    if (param.type === 'AutoHeightMobile') {
      res.AutoHeightMobile = param.value;
    }
  });
  if (!res.Theme) res.Theme = 'default';
  return res;
};

export const filterParamlistByTitle = (paramList: GenericparamDef[], value: string): GenericparamDef => {
  let result: GenericparamDef;
  result = paramList.filter((param: GenericparamDef) => {
    return param.Title === value;
  })[0];
  return result;
};

export const isValidEmail = (value: string): boolean => {
  // eslint-disable-next-line
  const emailRE =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRE.test(value);
};

export const isValidText = (value: string): boolean => {
  const onlyTextRegex = /^[a-zA-Z\s]*$/;
  return onlyTextRegex.test(value);
};

export const isValidPhoneNumber = (value: string): boolean => {
  const phoneRegex = /^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g;
  return phoneRegex.test(value);
};

export const isValidPhone = (value: string): boolean => {
  // eslint-disable-next-line
  const phoneRE = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]{5,11}$/g;
  return phoneRE.test(value);
};

export const searchCountry = (countryList: CountryDef[], value: string): CountryDef => {
  const res = countryList.filter((a) => a.name === value)[0];
  return res;
};
