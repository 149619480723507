import React, { useState, useEffect } from 'react';
import { ComponentViewModel, ParameterModel } from '../../interfaces/models';
import '../../styles/sass/Blog/_blogLayout.scss';
import BlogComponent from './BlogComponent';
import { Alignment, ComponentClassType } from '../../interfaces/enums';
import { useMediaQuery } from '@mui/material';

interface BlogSectionComponentProps {
  paramArray: ParameterModel[];
  children?: ComponentViewModel[]  | undefined;
  textFilter: string;
}



/* eslint-disable complexity */
const BlogSectionComponent: React.FC<BlogSectionComponentProps> = (props: BlogSectionComponentProps) => {
  const [fontTitle, setFontTitle] = useState('17px');
  const [colorTitle, setColorTitle] = useState('');
  const [fontFamilyTitle, setFontFamilyTitle] = useState(' ');
  const [fontWeightTitle, setFontWeightTitle] = useState('');
  const [alignmentTitle, setAlignmentTitle] = useState('Left')
  const [title, setTitle] = useState('');
  
 
  const init = (): ComponentViewModel[] =>  {
    let componentModels: ComponentViewModel[] = [];
    let arrayParameterModel: ParameterModel[] = [];
    let arrayComponentViewModel: ComponentViewModel[] = [];
    let componentModel: ComponentViewModel = {
      parameters: arrayParameterModel,
      children: arrayComponentViewModel,
      type: ComponentClassType.Blog,
      key:'',
      index:0,
      id:''
    }
    return componentModels;
  }
  // eslint-disable-next-line no-undef
  const [blogs,setBlogs] = useState<JSX.Element[] >();
  useEffect(() => {
    setValues();
    renderBlogs();
  }, [props]);

  useEffect(() => {
    renderBlogs();
  }, [props.textFilter])

  const setValues = () => {
    if (props?.paramArray?.length > 0) {
      props.paramArray.forEach((param, index) => {
        const theType = param.type;
        switch (theType) {
          case 'FlexibleFontSizeTitle': {
            setFontTitle(param.value);
            break;
          }
          case 'ColorTitle': {
            setColorTitle(param.value);
            break;
          }
          case 'FontFamilyTitle': {
            setFontFamilyTitle(param.value);
            break;
          }
          case 'FontWeightTitle': {
            setFontWeightTitle(param.value);
            break;
          }
          case 'Title': {
            setTitle(param.value);
            break;
          }
          case 'AlignmentTitle': {
            setAlignmentTitle(param.value);
            break;
          }
         
          default:
            break;
        }
      });
    }
  };

  const AlignmentTitle = () :Alignment=> {
    let alignmentDict = new Map<string,Alignment>([
      ['left', Alignment.left],
      ['center', Alignment.center],
      ['right', Alignment.right]
    ]);
    let alignment = alignmentDict.get(alignmentTitle.toLowerCase()) || Alignment.left;
    return alignment;
  }
  const matchText = (parameters: ParameterModel[]) => {
    let text = parameters.find((parameter) => {
      return parameter.type === 'Article';
    })?.value ;
    let title = parameters.find((parameter) => {
      return parameter.type === 'Title';
    })?.value ; 
    text = text?.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    title = title?.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    let textFilteNormalize = props.textFilter.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return text?.toLowerCase().includes(textFilteNormalize.toLowerCase()) || title?.toLowerCase().includes(textFilteNormalize.toLowerCase()) || false;
  } 

  const renderBlogs = () =>  {
    let blogs =  props?.children?.map((blog) => {
      return matchText(blog.parameters) ?(<BlogComponent paramArray={blog.parameters} key={blog.key}>{blog.children}</BlogComponent> ) : null
    // eslint-disable-next-line no-undef
    }) as unknown as JSX.Element[];
    if(blogs){
      setBlogs(blogs);
    }
  }

  const descompFontSize = (value: string ) => {
    if (value.includes('px') || value.includes('em') || value.includes('rem')) {
      let fontNumber = {pxValue:17, value: 17};
      let lengthUnit = value.includes('rem') ? 3 : 2;
      try {
        fontNumber.value = parseInt(value.slice(0, value.length - lengthUnit), 10);
        fontNumber.pxValue = value.includes('px') ? fontNumber.value : fontNumber.value * 16;
      } catch (error) {
        console.error(error);
      }
      return fontNumber;
    } else {
      try {
        let fontNumber = { value: parseInt(value, 10) || 17 , pxValue: parseInt(value, 10)};
        return fontNumber;
      } catch (error) {
        console.error(error);
      }
    }
    return {pxValue: 17, value:17};
  };

  const getFontSize = () => {
  
    let fontSize = matches ? (descompFontSize(fontTitle || "").pxValue / 15 +3) +'vw' : fontTitle;
    return fontSize;
  };

  
  const matches = useMediaQuery('(max-width:400px)');

  const FontWeight = fontWeightTitle.toLowerCase() === 'true' ? 'bold' : '';
  const renderSection = () => {
    return blogs && blogs.length > 0 ? 
        <div>
            <p style={{fontFamily: fontFamilyTitle, fontWeight: FontWeight, fontSize: getFontSize(), color: colorTitle, textAlign: AlignmentTitle(), marginBottom: matches ? '-30px' : '' }}>{title}</p>
            {blogs}
        </div>
        :
        null
    
  }
  
  return (
    <div className="blog-section" style={{paddingBottom: '50px'}}>
        {renderSection() }
    </div>
  );
};
export default BlogSectionComponent;
