import React, { useEffect, useState } from 'react';
import { styled, Theme } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/lab/Alert';
import { SnackBarNotify } from '../../services/cms/ServiceRxjsCommon';
const PREFIX = 'SnackBarComponent';

const classes = {
  root: `${PREFIX}-root`
};

const Root = styled('div')((
  { theme } : { theme : Theme}
) => ({
  [`&.${classes.root}`]: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  }
}));

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SnackBarComponent = () => {

  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState('info'); // the possible values: info, warning, success, error
  const [durationHide, setDurationHide] = useState(3000);

  useEffect(() => {
    getNotifyOfService();
  }, []);

  const getNotifyOfService = () => {
    SnackBarNotify.getSnackBarNotifyObservable().subscribe((result) => {
      if (result) {
        setOpen(result.isShow);
        setMessage(result.message);
        setType(result.type);
        setDurationHide(result.durationHide);
      }
    });
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    setOpen(false);
    let snackBar = SnackBarNotify.getSnackBarNotify();
    snackBar.isShow = false;
    SnackBarNotify.sendSnackBarNotify(snackBar);
  };

  const getSnackBarElement = () => {
    switch (type) {
      case 'info':
        return (
          <Alert onClose={handleClose} severity="info">
            <p style={{ width: '300px' }}> {message} </p>
          </Alert>
        );

      case 'warning':
        return (
          <Alert onClose={handleClose} severity="warning">
            <p style={{ width: '300px' }}> {message} </p>
          </Alert>
        );

      case 'success':
        return (
          <Alert onClose={handleClose} severity="success">
            <p style={{ width: '300px' }}> {message} </p>
          </Alert>
        );

      case 'error':
        return (
          <Alert onClose={handleClose} severity="error">
            <p style={{ width: '300px' }}> {message} </p>
          </Alert>
        );

      default:
        return (
          <Alert onClose={handleClose} severity="info">
            <p style={{ width: '300px' }}> {message} </p>
          </Alert>
        );
    }
  };

  return (
    <Root className={classes.root}>
      <Snackbar
        open={open}
        autoHideDuration={durationHide}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {getSnackBarElement()}
      </Snackbar>
    </Root>
  );
};
export default SnackBarComponent;
