import React, { useState, useEffect } from 'react';
import { ComponentViewModel, ParameterModel } from '../interfaces/models';
import '../styles/sass/_flexibleContainer.scss';
import '../styles/sass/_alignment.scss';
import { Grid, useMediaQuery } from '@mui/material';
import { ComponentClassType } from '../interfaces/enums';
import TextInline from './TextInline';
import { Console } from 'console';
interface FlexibleContainerProps {
  paramArray: ParameterModel[];
  components: ComponentViewModel[];
}

/* eslint-disable complexity */
const FlexibleContainer: React.FC<FlexibleContainerProps> = (props: FlexibleContainerProps) => {
  
  const [BackgroundColor, setBackgroundColor] = useState('');
  const [Columns, setColumns] = useState(6);
  const [BorderRadius, setBorderRadius] = useState('');
  const [Components, setComponents] = useState<ComponentViewModel[]>(props.components === null ? [] : props.components); 
  
  useEffect(() => {
    setValues();
  }, [props]);


  const setValues = () => {
    if (props?.paramArray?.length > 0) {
      props.paramArray.forEach((param, index) => {
        const theType = param.type;
        switch (theType) {
          case 'BackgroundColor': {
            setBackgroundColor(param.value);
            break;
          }
          case 'Columns': {
            try {
              let parseToNumber = Number.parseInt(param.value, 10);
              setColumns(parseToNumber);
            } catch (error) {console.info("FlexibleContainer,45: Error to parse ", param.value, " to number.")}
            break;
          }
          case 'BorderRadius': {
            setBorderRadius(param.value);
            break;
          }
          default:
            break;
        }
      });
    }
  };


 

  return ( 
    <Grid container className="flexible-container" style={{ borderRadius: BorderRadius, backgroundColor: BackgroundColor}} columns={Columns} >
        {
          Components.map((component,index) =>{ 
            return (
              <Grid item xs='auto' md='auto' lg={1} className="flexible-container-item"  key={'flexible-container-item-'+ index}>
                {
                  component.type === ComponentClassType.TextInline ?
                  (<TextInline paramArray={component.parameters} components={component.children} isFooterComponent={false}/> ) 
                  :
                  null
                }
              </Grid>
            )
          })
          
        }
    </Grid>
  )
  
};
export default FlexibleContainer;
