import React, { FC, ReactNode, useState } from 'react';
import { UserModel } from '../../interfaces/models';

export const emptyUser: UserModel = {
  email: '',
  firstName: '',
  lastName: '',
  roleName: '',
  id: '',
  roleId: '',
  isReactivated: false,
  lastPasswordUpdate: new Date(),
};

let a: React.Dispatch<React.SetStateAction<UserModel>>;
a = (e) => {};

export const MyContext = React.createContext([emptyUser, a]);

interface MyProviderProps {
  children: ReactNode;
}

const MyProvider: FC<MyProviderProps> = ({ children }: MyProviderProps) => {
  let [user, setUser] = useState<UserModel>(emptyUser);
  return <MyContext.Provider value={[user, setUser]}>{children}</MyContext.Provider>;
};

export default MyProvider;
