import AppSettingsService from '../AppSettingsService';

export const validateAndSendForm = async (tokenHandler: (aToken: string) => void): Promise<void> => {
  let siteKey = await AppSettingsService.getInstance().getReCaptchaSiteKey();

  let resReady = await window.grecaptcha.ready(async () => {
    let resExecute = await window.grecaptcha.execute(siteKey, { action: 'submit' }).then((token: string) => {
      tokenHandler(token);
    });
  });
};
