import React, { useEffect, useState } from 'react';
import '../../styles/sass/_background.scss';
import BlockColumn from './BlockColumn';

interface BlockRowProps {
  nroColumns: number;
  nroRows: number;
  type: string;
}

const BlockRow: React.FC<BlockRowProps> = (props: BlockRowProps) => {
  let val: number[] = [];
  const [rowsNumber, setRowsNumbers] = useState(val);
  const [className, setClassName] = useState('');

  useEffect(() => {
    let value: number[] = [];

    function SetNroColums(columns: number) {
      for (let _i = 0; _i < columns; _i++) {
        value.push(_i);
      }
    }
    SetNroColums(props.nroColumns);
    setRowsNumbers(value);

    let listStyles: string[] = ['columnp', 'columnp2', 'columnp3'];

    if (props.type === 'lg') {
      setClassName(listStyles[0]);
    } else if (props.type === 'md') {
      setClassName(listStyles[1]);
    } else {
      setClassName(listStyles[2]);
    }
  }, [props.nroColumns, props.nroRows, props.type]);

  return (
    <div>
      {rowsNumber.length !== 0 &&
        rowsNumber.map((c, index) => {
          return (
            <div key={index} className={className}>
              <BlockColumn type={props.type} rowsNumber={props.nroRows} />
            </div>
          );
        })}
    </div>
  );
};

export default BlockRow;
