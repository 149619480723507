import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { ILink } from '../../interfaces/interfaces';
import { filterComponentDetails, getGenericParams } from '../../utils/utils';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import '../../styles/sass/_itemFooter.scss';
import { ComponentViewModel } from '../../interfaces/models';
import { HashLink } from 'react-router-hash-link';
import RowFlipCard from '../Card/RowFlipCard';
import { GridSize, useMediaQuery, Theme, useTheme } from '@mui/material';
import { ComponentClassType } from '../../interfaces/enums';
import ImageCollection from '../ImageComponent/ImageCollection';

const PREFIX = 'ItemFooter';

const classes = {
  root: `${PREFIX}-root`,
  icon: `${PREFIX}-icon`,
  heading: `${PREFIX}-heading`,
  heading2: `${PREFIX}-heading2`,
  accordionSummaryExpandIcon: `${PREFIX}-accordionSummaryExpandIcon`,
  accordionSummaryExpandIcon2: `${PREFIX}-accordionSummaryExpandIcon2`
};

const Root = styled('div')((
  { theme } : { theme : Theme }
) => ({
  [`& .${classes.root}`]: {
    color: theme.palette.text.primary,
  },

  [`& .${classes.icon}`]: {
    margin: 0,
    fontSize: 32,
    fill: 'White',
    transform: 'rotate(-90deg)',
  },

  [`& .${classes.heading}`]: {
    background:'inherit',
    boxShadow: 'none',
    color: 'white',
    minHeight: '0px',
  },

  [`& .${classes.heading2}`]: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    backgroundColor: 'inherit',
    boxShadow: 'none',
    color: 'white',
    minHeight: '0px',
    '&:hover': {
      borderBottom: '2px solid white',
      backgroundColor: '#6F9EAF',
    },
  },

  [`& .${classes.accordionSummaryExpandIcon}`]: {
    // right: "10%",
    padding: 0,
    maxHeight: '30px',
    maxWidth: '30px',
    margin: '0px',
    '&:hover': {
      cursor:'pointer'
    },
    '&:target': {
      BackGround: 'white'
    },
  },

  [`& .${classes.accordionSummaryExpandIcon2}`]: {
    right: '40px',
    padding: 0,
    maxHeight: '30px',
    maxWidth: '30px',
    margin: '0px',
  }
}));

interface FooterItemProps {
  Component: ComponentViewModel;
  isOpen: boolean;
  counter: number;
  withoutDescription?: boolean | undefined;
  Border?: string;
  mainText: string;
  setIndexWithText: (text: string) => void;
}

const ItemFooter: React.FC<FooterItemProps> = ({
  Component,
  isOpen,
  counter,
  withoutDescription,
  Border,
  mainText,
  setIndexWithText,

}: FooterItemProps) => {
  const [isOpenLocal, setIsOpen] = useState(isOpen);
  const [columns, setColumns] = useState(12);
  const [BorderLeft,setBorderLeft] = useState('0px');
  const [BorderRight,setBorderRight] = useState('0px');
  const [BorderBottom,setBorderBottom] = useState('0px');
  const [BorderTop,setBorderTop] = useState('0px');


  const [description, setDescription] = useState('');
  const [url, setUrl] = useState('');

  useEffect(() => {
    let desctructBorder = Border?.split(' ');
    if (desctructBorder && desctructBorder.length > 3) {
      setBorderTop(desctructBorder[0]);
      setBorderRight(desctructBorder[1]);
      setBorderBottom(desctructBorder[2]);
      setBorderLeft(desctructBorder[3]);
    }
    const setValues = () => {
      if (Component?.parameters?.length > 0) {
        let result: ILink = filterComponentDetails(Component);
        setDescription(result.text);
        setUrl(result.url);
      }
    };
    setValues();
  }, [Component]);

  useEffect(() => {
    setIsOpen(isOpen);
  }, [isOpen]);

  const isHashLink = () => {
    return url.includes('#');
  };

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const scrollWithOffset = (el: any) => {
    const yCoordinate = el.getBoundingClientRect().top;
    window.scrollTo({ top: yCoordinate - 80 });
  };

  return description !== '' && url !== '' ?
  <Root style={{ minHeight: '0px' }} id={'item-footer-order-' + Component.index}>
    <Accordion
      className="footerHeading"
      classes={{
        root: classes.heading,
        expanded: classes.heading,
      }}
      expanded={isOpenLocal && Component.children.length !== 0}
      onChange={(event: React.ChangeEvent<{}>, x: boolean) => {
        if (!isOpen) {
          setIsOpen(!isOpenLocal);
        }
      }}
    >
      <AccordionSummary
        classes={{
          root: classes.heading2,
          expandIconWrapper: !isOpen ? classes.accordionSummaryExpandIcon : classes.accordionSummaryExpandIcon2,
        }}
        style={{ minHeight: '0px', cursor: 'default' }}
        expandIcon={!isOpen && Component?.children?.length ? <ExpandMoreIcon className={classes.icon} /> : null}
        aria-controls="panel1a-content"
        id={'panel1a-header' + Component.id}
        
      >
        {isHashLink() ? (
          <HashLink
            className="item-wrapper"
            tabIndex={-1}
            style={{
              color: 'white',
              marginLeft: counter > 1 ? counter * 10 : 0,
              fontWeight: counter === 0 ? 'bold' : 'normal',
              textTransform: counter === 0 ? 'uppercase' : 'none',
            }}
            to={url}
            scroll={(el) => scrollWithOffset(el)}
            onClick={() => setIndexWithText(mainText)}
          >
            {description}
          </HashLink>
        ) : (
          <Link
            className="item-wrapper"
            tabIndex={-1}
            style={{
              textDecoration: 'none',
              color: 'white',
              marginLeft: counter > 1 ? counter * 10 : 0,
              fontWeight: counter === 0 ? 'bold' : 'normal',
              textTransform: counter === 0 ? 'uppercase' : 'none',
            }}
            to={url}
            onClick={() => setIndexWithText(mainText)}
          >
            {description}
          </Link>
        )}
      </AccordionSummary>

      {Component?.children?.length > 0
        ? Component?.children?.map((c, index) => {
            return <ItemFooter  counter={counter + 1} key={index} Component={c} isOpen={false} withoutDescription={withoutDescription} mainText={mainText} setIndexWithText={setIndexWithText} />;
          })
        : null}
    </Accordion>
  </Root>
  :
  <div style={{marginTop:'12px', marginBottom: '12px', display:'flex', flexDirection:matches ? 'column' : 'row'}}>
    {matches ? 
      <div style={{
        marginTop:'20px', 
        marginBottom:'20px',
        borderLeft: '0 solid white',
        borderBottom: '0 solid white',
        borderRight: '0 solid white',
        borderTop: '2px solid white',
      }}/>
   
      :
      <div style={{
        marginTop:'20px', 
        marginBottom:'20px',
        borderLeft: BorderLeft+' solid white',
        borderBottom: BorderBottom+' solid white',
        borderRight: BorderRight+' solid white',
        borderTop: BorderTop+' solid white',
      }}/>
    }
    
    <div >
    {Component?.children?.length > 0 ? Component?.children?.map((component,index)=>{
      
      return component.type === ComponentClassType.Row ?
      <RowFlipCard 
        key={index}
        parameters={component.parameters}
        Columns={columns as GridSize}
        Components={component?.children}
        isOpen={isOpen}
        Row={true}
        IsFooter={true}
        Justify="start"
        componentKey={component.key}
      />
      :
      component.type === ComponentClassType.ImageCollection?
              <ImageCollection key={index} components={component.children} padding={getGenericParams(component)?.Padding} margin={getGenericParams(component).Margin} />
      :
      null
    })
    :
    null
  }
    </div>
  </div>;
};
export default ItemFooter;
