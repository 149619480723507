import React, { useEffect, useRef, useState } from 'react';
import { Grid, TextField, Theme, TextareaAutosize, Button } from '@mui/material';
import { ParameterModel } from '../../../interfaces/models';
import { BorderColor, Title } from '@mui/icons-material';
import { isValidEmail } from '../../../utils/utils';
import '../../../styles/sass/form/_inputText.scss';
import { removeFile, sendFile } from '../../../services/cms/fileService';
import { Link } from 'react-router-dom';

interface InputFileComponentProps {
  Parameters: ParameterModel[];
  getValue: Function;
  idParent: string;
  clear: boolean;
}
/* eslint-disable complexity */
const InputFileComponent: React.FC<InputFileComponentProps> = ({
  Parameters,
  getValue,
  idParent,
  clear,
}: InputFileComponentProps) => {
  const [FontFamilyTitle, setFontFamilyTitle] = useState('');
  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [FontSizeTitle, setFontSizeTitle] = useState('');
  const [IsEmpty, setIsEmpty] = useState(false);

  const [link, setLink] = useState('');
  const [fileName, setFileName] = useState('');
  const [emptyError, setEmptyError] = useState('This field can not be blank');
  let cardRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    Parameters.forEach((parameter) => {
      if (parameter.type === 'Title') {
        setTitle(parameter.value);
      } else if (parameter.type === 'Name') {
        setName(parameter.value);
      } else if (parameter.type === 'FontFamilyTitle') {
        setFontFamilyTitle(parameter.value);
      } else if (parameter.type === 'FontTitle') {
        setFontSizeTitle(parameter.value);
      } else if (parameter.type === 'ErrorEmpty') {
        setEmptyError(parameter.value);
      }
    });
  }, [Parameters]);

  useEffect(() => {
    const divisiones = link.split('/');
    setFileName(divisiones[divisiones.length - 1]);
  }, [link]);

  useEffect(() => {
    getValue(name, '', true);
    setIsEmpty(false);
    setLink('');
    setFileName('');
  }, [clear]);

  const handleInputChange = (e: any) => {
    // debugger;
    const { value } = e.target;
    sendResumeFile(e);
    getValue(name, value, true);
    restoreEmpty(value);
  };

  const invalid = () => {
    setIsEmpty(true);
  };

  const onInputClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    const element = event.target as HTMLInputElement;
    element.value = '';
  };

  const sendResumeFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    let res = await sendFile(event, 'Resume');
    console.info('We are sending the resume file', res);
    setLink(res);
    getValue(name, res, true);
  };

  const deleteResumeFile = async (fileName: string) => {
    await removeFile(fileName, 'Resume');

    let aName = '';
    if (cardRef?.current?.files) {
      if (cardRef.current.files.length > 0) {
        aName = cardRef.current.files[0].name;
        if (!aName) aName = '';
      }
    }
  };

  const restoreEmpty = (value: string) => {
    if (value !== ' ') {
      setIsEmpty(false);
    }
  };

  return (
    <Grid className="input-file" item xs={6} md={6} style={{ paddingBottom: '70px', width: '100%', minWidth: '345px' }}>
      <div className="field-file">
        <Grid
          className="form-grid"
          container
          spacing={2}
          direction="row"
          wrap="nowrap"
          alignContent="center"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <input
              onClick={(a) => {
                onInputClick(a);
              }}
              // hidden
              className="file"
              style={{ display: 'none' }}
              type="file"
              id="fileCV"
              name="CV"
              required
              onChange={(a) => {
                handleInputChange(a);
              }}
              onInvalid={invalid}
              accept=".doc,.docx,.pdf"
            />
            <Button className="button-styled" component="span">
              <label style={{ cursor: 'pointer', color: IsEmpty ? 'red' : '' }} htmlFor="fileCV">
                {title}
              </label>
            </Button>
          </Grid>
          {link !== '' && link !== 'error' ? (
            <>
              <Grid item xs={12}>
                <Link
                  style={{
                    color: 'white',
                    fontSize: '15px',
                    margin: 'auto',
                    display: 'block',
                  }}
                  target="_blank"
                  to={link}
                >
                  {fileName}
                </Link>
              </Grid>
              <Grid item>
                <Button
                  name="cancelButton"
                  style={{
                    color: 'white',
                    minWidth: '0px',
                    width: '24px!important',
                    marginRight: '4px',
                  }}
                  onClick={() => {
                    deleteResumeFile(fileName);
                    setLink('');
                  }}
                >
                  x
                </Button>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} />
              <Grid item />
            </>
          )}
        </Grid>
      </div>
      {IsEmpty ? <p className="error-text-color">{emptyError}</p> : null}
    </Grid>
  );
};

export default InputFileComponent;
