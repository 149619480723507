import React, { useEffect, useState } from 'react';
import { ParameterModel } from '../../interfaces/models';

interface HTMLRenderProps {
  paramArray: ParameterModel[];
}
const HTMLRender: React.FC<HTMLRenderProps> = ({ paramArray }: HTMLRenderProps) => {
  const [htmlText, setHTMLText] = useState('');
  const [ColorText, setColorText] = useState('');
  const [fontText, setFontText] = useState('');
  const [FontFamilyText, setFontFamily] = useState('');
  useEffect(() => {
    paramArray.forEach((parameter) => {
      if (parameter.type === 'HTMLText') {
        validText(parameter.value) ? setHTMLText(parameter.value) : setHTMLText('');
      } else if (parameter.type === 'FontFamilyText') {
        setFontFamily(parameter.value);
      } else if (parameter.type === 'ColorText') {
        setColorText(parameter.value);
      } else if (parameter.type === 'FontText') {
        setFontText(parameter.value);
      }
    });
  });
  const validText = (text: string) => {
    let include = text.toLowerCase().replace(/\s+/g, '').includes('<script');
    return !include;
  };
  const fontSizeText =
    fontText === 'Small'
      ? '17'
      : fontText === 'Medium'
      ? '21'
      : fontText === 'Big'
      ? '30'
      : fontText === 'ExtraLarge'
      ? '42'
      : '';

  return (
    <div
      dangerouslySetInnerHTML={{ __html: htmlText }}
      style={{ color: ColorText, fontSize: fontSizeText + 'px', fontFamily: FontFamilyText }}
    />
  );
};

export default HTMLRender;
