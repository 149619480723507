
import React, { ReactNode, useEffect, useState } from "react";
import '../styles/sass/_emailComponent.scss';
import { Link } from "react-router-dom";
interface EmailProps {
    email: string;
    children ?: ReactNode | null; 
    styleLinkProps?: {} ;
};

const EmailComponent: React.FC<EmailProps> = ({ email, children = null, styleLinkProps }) => {
    const [dataUser, setDataUser] = useState('');
    const [dataDomain, setDataDomain] = useState('');
    useEffect(()=> {
        let [user, domain ] = email.split('@');
        setDataUser(user);
        setDataDomain(domain);
    },[email]);

    const mailtoLink = `mailto:${email}`;

   

    return (
        children ? 
        <Link to={mailtoLink}  className="image-external-link" style={styleLinkProps} >
            {children}
            <div>
                <span className="my-email" data-user={dataUser} data-domain={dataDomain}/>
            </div>
        </Link>
        :

        <Link to={mailtoLink} className="my-email" data-user={dataUser} data-domain={dataDomain} style={styleLinkProps} />


        
    );
};

export default EmailComponent;