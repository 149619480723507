import React from 'react';
import { styled } from '@mui/material/styles';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';

const PREFIX = 'MapViewComponent';

const classes = {
  container: `${PREFIX}-container`,
  wrapper: `${PREFIX}-wrapper`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.container}`]: {
    width: '100%',
  },

  [`& .${classes.wrapper}`]: {
    width: '1200px',
    height: '300px',
  }
}));

interface MapViewProps {
  zoom: number;
  lat: number;
  long: number;
  popupMessage: string;
  theme: string | undefined;
}

const MapViewComponent: React.FC<MapViewProps> = (props: MapViewProps) => {

  return (
    <Root className={classes.container + `text-block-image ${props.theme}`}>
      <MapContainer
        center={[Number(props.lat), Number(props.long)]}
        zoom={Number(props.zoom)}
        className={classes.wrapper}
        scrollWheelZoom={true}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={[Number(props.lat), Number(props.long)]}>
          <Popup>
            {props.popupMessage}
            <br />*
          </Popup>
        </Marker>
      </MapContainer>
    </Root>
  );
};

export default MapViewComponent;
