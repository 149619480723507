import React, { useEffect, useState } from 'react';
import '../styles/sass/_rowcomponent.scss';

interface PictureComponentProps {
  children: React.ReactElement;
  pictureClassName?: string;
  imageUrl: string;
  imageMobileUrl?: string;
  imageWidth?: string;
}

const PictureComponent: React.FC<PictureComponentProps> = ({
  children,
  pictureClassName,
  imageUrl,
  imageMobileUrl,
  imageWidth,
}: PictureComponentProps) => {
  const [imageUrlMobile, setImageUrlMobile] = useState<string>('');

  useEffect(() => {
    let imageMobile = imageMobileUrl || '';
    if(imageMobileUrl !== ''){
      setImageUrlMobile(imageMobile);
    }
  }, [imageUrl,imageMobileUrl]);


  return imageUrlMobile ? (
    <picture className={pictureClassName}>
      {imageUrlMobile ? <source media="(max-width: 899px)" srcSet={imageUrlMobile} /> : null}
      <source media="(min-width: 900px)" srcSet={imageUrl} />
      {children}
    </picture>
  ) : (
    children
  );
};

export default PictureComponent;
