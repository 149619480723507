import Cookies from 'js-cookie';
/**
 * service for save token in session
 * @param accessToken
 * @param refreshToken
 */
export const saveTokenMemorySession = (accessToken: string | null, refreshToken: string | null) => {
  //  for session storage save
  if (accessToken) {
    sessionStorage.setItem('accessToken', accessToken);
  }
  if (refreshToken) {
    sessionStorage.setItem('refreshToken', refreshToken);
  }

  // TODO remove cookies
  if (accessToken) {
    //  for save cookies
    Cookies.set('userToken', accessToken, {
      expires: 7,
      sameSite: 'Strict',
      path: '/',
    });
  }
};

/**
 * service for save token in local storage
 * @param accessToken
 * @param refreshToken
 */
export const saveTokenMemoryLocalStorage = (accessToken: string | null, refreshToken: string | null) => {
  //  for local storage save
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
  }
  if (refreshToken) {
    localStorage.setItem('refreshToken', refreshToken);
  }

  // TODO remove cookies
  if (accessToken) {
    //  for save cookies
    Cookies.set('userToken', accessToken, {
      expires: 7,
      sameSite: 'Strict',
      path: '/',
    });
  }
};

/**
 * service for update session token
 * @param accessToken
 * @param refreshToken
 */
export const updateTokenMemory = (accessToken: string | null, refreshToken: string | null) => {
  //  for local storage save
  // if (localStorage.getItem("accessToken")) {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    sessionStorage.setItem('accessToken', accessToken);
  }
  // }
  // if (localStorage.getItem("refreshToken") !== null) {
  if (refreshToken) {
    localStorage.setItem('refreshToken', refreshToken);
    sessionStorage.setItem('refreshToken', refreshToken);
  }
  // }

  // //  for session storage save
  // // if (sessionStorage.getItem("accessToken") !== null) {
  // if (accessToken !== null && accessToken !== '') {

  // }
  // // }
  // if (sessionStorage.getItem("refreshToken") !== null) {
  //     if(refreshToken !== null && refreshToken !== '')
  //         sessionStorage.setItem('refreshToken', refreshToken);
  // }

  // TODO remove cookies
  if (null !== accessToken) {
    //  for save cookies
    Cookies.set('userToken', accessToken, {
      expires: 7,
      sameSite: 'strict',
      path: '/',
    });
  }
};

/**
 * service for get token of memory
 */
export const getTokenMemory = () => {
  let result: any = { accessToken: '', refreshToken: '' };
  //  for local storage save
  let at = localStorage.getItem('accessToken');
  if (at) {
    result.accessToken = at;
  }

  let rt = localStorage.getItem('refreshToken');
  if (rt) {
    result.refreshToken = rt;
  }

  //  for session storage save
  at = sessionStorage.getItem('accessToken');
  if (at) {
    result.accessToken = at;
  }

  rt = sessionStorage.getItem('refreshToken');
  if (rt) {
    result.refreshToken = rt;
  }

  return result;
};

/**
 * service for removed token memory
 */
export const removeTokenMemory = () => {
  //  for local storage save
  if (localStorage.getItem('accessToken')) {
    localStorage.removeItem('accessToken');
  }
  if (localStorage.getItem('refreshToken')) {
    localStorage.removeItem('refreshToken');
  }

  //  for session storage save
  if (sessionStorage.getItem('accessToken')) {
    sessionStorage.removeItem('accessToken');
  }
  if (sessionStorage.getItem('refreshToken')) {
    sessionStorage.removeItem('refreshToken');
  }
  // TODO remove cookies
  Cookies.remove('userToken');
  // Cookies.remove('userInfo');
};

/**
 * service for save last Page path url
 */
export const saveLastPageSurf = () => {
  localStorage.setItem('pathName', window.location.pathname);
  sessionStorage.setItem('pathName', window.location.pathname);
};

/**
 * service for get last urlPath
 */
export const getLastPageSurf = () => {
  let result = null;
  if (null !== localStorage.getItem('pathName')) {
    result = localStorage.getItem('pathName');
  }
  if (null !== sessionStorage.getItem('pathName')) {
    result = sessionStorage.getItem('pathName');
  }
  return result;
};

/**
 * service for remove last page
 */
export const removeLastPageSurf = () => {
  if (null !== localStorage.getItem('pathName')) {
    localStorage.removeItem('pathName');
  }
  if (null !== sessionStorage.getItem('pathName')) {
    sessionStorage.removeItem('pathName');
  }
};
