import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Grid, TextField, Theme, MenuItem, useTheme, useMediaQuery } from '@mui/material';
import { ComponentViewModel, ParameterModel } from '../../../interfaces/models';
import { countryList } from '../../../data/formsData';
import '../../../styles/sass/form/_inputCheckbox.scss';
import { searchCountry } from '../../../utils/utils';
const PREFIX = 'InputCheckboxComponent';

const classes = {
  field: `${PREFIX}-field`,
  fullfield: `${PREFIX}-fullfield`,
  inputLabel: `${PREFIX}-inputLabel`,
  selectedItem: `${PREFIX}-selectedItem`
};

const StyledGrid = styled(Grid)((
  { theme } : { theme : Theme }
) => ({
  [`& .${classes.field}`]: {
    margin: 'auto',
    padding: theme.spacing(1),
    [theme.breakpoints.between(1, 281)]: {
      width: '162px',
    },
    [theme.breakpoints.between(281, 321)]: {
      width: '202px',
    },
    [theme.breakpoints.between(321, 361)]: {
      width: '242px',
    },
    [theme.breakpoints.between(361, 376)]: {
      width: '252px',
    },
    [theme.breakpoints.between(376, 415)]: {
      width: '282px',
    },
    [theme.breakpoints.between(415, 541)]: {
      width: '277px',
    },
    [theme.breakpoints.between(541, 769)]: {
      width: '337px',
    },
    [theme.breakpoints.between(769, 1025)]: {
      width: '487px',
    },
    [theme.breakpoints.up(1025)]: {
      width: '517px',
    },
  },

  [`& .${classes.fullfield}`]: {
    margin: 'auto',
    paddingTop: '9px',
    paddingBottom: '9px',
    [theme.breakpoints.between(1, 281)]: {
      width: '184px',
    },
    [theme.breakpoints.between(281, 321)]: {
      width: '224px',
    },
    [theme.breakpoints.between(321, 361)]: {
      width: '264px',
    },
    [theme.breakpoints.between(361, 376)]: {
      width: '274px',
    },
    [theme.breakpoints.between(376, 415)]: {
      width: '304px',
    },
    [theme.breakpoints.between(415, 541)]: {
      width: '299px',
    },
    [theme.breakpoints.between(541, 769)]: {
      width: '359px',
    },
    [theme.breakpoints.between(769, 1025)]: {
      width: '509px',
    },
    [theme.breakpoints.up(1025)]: {
      width: '539px',
    },
  },

  [`& .${classes.inputLabel}`]: {
    top: '-1px',
    '&.shrink': {
      marginTop: '10px',
    },
  },

  [`& .${classes.selectedItem}`]: {
    backgroundColor: '#FFFFFF !important',
  }
}));

interface InputCheckboxComponentProps {
  Parameters: ParameterModel[];
  getValue: Function;
  idParent: string;
  items: ComponentViewModel[];
  clear: boolean;
  setPhone:Function;
}
/* eslint-disable complexity */
const InputCheckboxComponent: React.FC<InputCheckboxComponentProps> = ({
  Parameters,
  getValue,
  idParent,
  clear,
  items,
  setPhone,
}: InputCheckboxComponentProps) => {
  const [valueInput, setValue] = useState<string>('');
  const [FontFamilyTitle, setFontFamilyTitle] = useState('');
  const [name, setName] = useState('');
  const [itemsTitle, setItemsTitle] = useState(['']);
  const [title, setTitle] = useState('');
  const [FontSizeTitle, setFontSizeTitle] = useState('');
  const [IsEmpty, setIsEmpty] = useState(false);
  const [emptyError, setEmptyError] = useState('This field can not be blank');
  const [errorValue, setErrorValue] = useState(false);
  useEffect(() => {
     let titles : string [] = [];
    Parameters.forEach((parameter) => {
      if (parameter.type === 'Title') {
        setTitle(parameter.value);
      } else if (parameter.type === 'Name') {
        setName(parameter.value);
      } else if (parameter.type === 'FontFamilyTitle') {
        setFontFamilyTitle(parameter.value);
      } else if (parameter.type === 'FontTitle') {
        setFontSizeTitle(parameter.value);
      } else if (parameter.type === 'ErrorEmpty') {
        setEmptyError(parameter.value);
      } 
    });
    items.forEach((item)=>{
        let params = item.parameters;
        if(params.length > 0){
            titles.push(params[0].value);
        }
    });
    setItemsTitle(titles);
  }, [Parameters]);

  useEffect(() => {
    setValue('');
    getValue(name, '', true);
    setIsEmpty(false);
  }, [clear]);

  
  const handleInputChange = (e: any) => {
    const { value } = e.target;
    setValue(value);
    getValue(name, value, true);
    restoreEmpty(value);
    if(name === 'countryInput'){
      const currentCountry = searchCountry(countryList, value);
      setPhone(currentCountry.code);
    }

  };

  const fontSizeTitle =
    FontSizeTitle === 'Small'
      ? '17'
      : FontSizeTitle === 'Medium'
      ? '21'
      : FontSizeTitle === 'Big'
      ? '30'
      : FontSizeTitle === 'ExtraLarge'
      ? '42'
      : '';
  const invalid = () => {
    setIsEmpty(true);
  }

  const restoreEmpty = (value: string) => {
    if(value !== ' '){
      setIsEmpty(false);
    }
  }

 
const theme = useTheme();
const match = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <StyledGrid className="input-checkbox" item xs={6} md={6} style={{ paddingBottom: '70px', width:'100%', minWidth:'345px' }}>
      <TextField
              select
              required
              error={IsEmpty}
              label={title}
              id={'id-'+title}
              className={`${classes.field} disable-highlight ${IsEmpty ? 'error-text-color' : ''}` }
              classes={{
                root: classes.selectedItem,
              }}
              value={valueInput}
              variant="standard"
              onChange={(e) => handleInputChange(e)}
              name={title}
              InputLabelProps={{
                classes: {
                  root: classes.inputLabel,
                  shrink: 'shrink',
                },
              }}
              SelectProps={{
                MenuProps: { disableScrollLock: true },
              }}
              onInvalid={invalid}
              style={{fontFamily:FontFamilyTitle,fontSize:fontSizeTitle,width:match ? 'calc(100% - 20px)' : '100%' }}
            >
              {itemsTitle.map((titleItem) => (
                <MenuItem
                  key={titleItem}
                  value={titleItem}
                  classes={{
                    selected: classes.selectedItem,
                    root: classes.selectedItem,
                  }}
                >
                  {titleItem}
                </MenuItem>
              ))}
            </TextField>
            {IsEmpty ?
              <p  className="error-text-color">
                {emptyError}
              </p>
              :
              null
            }

    </StyledGrid>
  );
};

export default InputCheckboxComponent;
