import { getReCAPTCHAApiScript, getReCAPTCHASiteKey } from './ApiRestServiceCommon';

interface AppSettingsServiceInterface {
  getReCaptchaSiteKey: Function;
  getRecaptchaApiScriptUrl: Function;
}

class AppSettingsService implements AppSettingsServiceInterface {
  private static instance: AppSettingsService;

  private static key: string;

  private static apiScript: string;

  public static getInstance(): AppSettingsService {
    if (!AppSettingsService.instance) {
      AppSettingsService.instance = new AppSettingsService();
    }

    return AppSettingsService.instance;
  }

  private constructor() {}

  public async getReCaptchaSiteKey() {
    if (AppSettingsService.key) {
      return AppSettingsService.key;
    } else {
      let keyAsync = await getReCAPTCHASiteKey();
      AppSettingsService.key = keyAsync;
      return keyAsync;
    }
  }

    public async getRecaptchaApiScriptUrl() {
    if (AppSettingsService.apiScript) {
      return AppSettingsService.apiScript;
    } else {
      let apiScriptAsync = await getReCAPTCHAApiScript();
      AppSettingsService.apiScript = apiScriptAsync;
      return apiScriptAsync;
    }
  }
}
export default AppSettingsService;
