import React, { useEffect, useState } from 'react';
import '../../styles/sass/_background.scss';

import Block from './Block';

interface BlockColumnProps {
  rowsNumber: number;
  type: string;
}

const BlockColumn: React.FC<BlockColumnProps> = (props: BlockColumnProps) => {
  let val: number[] = [];
  const [columns, setColumns] = useState(val);

  useEffect(() => {
    let columnsArray: number[] = [];
    for (let _i = 0; _i < props.rowsNumber; _i++) {
      columnsArray.push(_i);
    }
    setColumns(columnsArray);
  }, [props.rowsNumber]);

  return (
    <div>
      <Block type={props.type} header={true} />
      {columns.map((c, index) => {
        return <Block type={props.type} key={index} header={false} />;
      })}
    </div>
  );
};
export default BlockColumn;
