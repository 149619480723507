import React, { useState, useEffect, useRef } from 'react';
import '../styles/sass/_view.scss';
import ComponentView from './ComponentView';
import ViewService, { filterViewByName, fetchViewByIdLang } from '../services/ViewServices';
import BackGround from './Background/Background';
import useWindowDimensions from './Background/WindowSize';
import ResizeDetector from 'react-resize-detector';
import { ComponentViewModel, FullViewModel, ViewModel } from '../interfaces/models';
import { ComponentClassType } from '../interfaces/enums';
import _ from 'lodash';

interface ViewProps {
  activeView: string;
  isOpen: boolean;
  type: string;
  language: string | undefined;
  viewList: Array<ViewModel>;
  setLoading: (lang: boolean) => void;
  setShowComponents: (lang: boolean) => void;
  setLanguage: (lang: string) => void;
  setActiveView: (viewId: string) => void;
  isPreview: boolean;
  SetMenuView: Function;
  hideBrightsoftLoader: boolean;
  hideBackground: boolean;
  index: number;
}

const View: React.FC<ViewProps> = ({
  activeView,
  isOpen,
  type,
  language,
  viewList,
  setLoading,
  setShowComponents,
  setLanguage,
  setActiveView,
  isPreview,
  SetMenuView,
  hideBrightsoftLoader,
  hideBackground,
}: ViewProps) => {
  const initViewModel: FullViewModel = { id: '', name: '', language: '', components: [] };
  let value = 0;


  const { height } = useWindowDimensions();
  const [viewData, setViewData] = useState(initViewModel);
  const [isCarouselFirst, setIsCarouselFirst] = useState(false);
  const inputEl = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState(value);
  const [currentDataLanguage, setCurrentDataLanguage] = useState('')

  useEffect(() => {
    setActiveView(activeView);
  })
  useEffect(() => {
    const theUrl = window.location.href;
    const sharpIndex = theUrl.indexOf('#');
    if (sharpIndex < 0) {
      window.scroll(0, 0);
    } else {
      let theId = theUrl.substr(sharpIndex, theUrl.length - sharpIndex);
      if (theId.length > 1) {
        theId = _.trimStart(theId, '#');
        let counter = 0;
        const maxCount = 10;
        const tryToScroll = () => {
          const elementToScrollTo = document.getElementById(theId);
          if (elementToScrollTo) {
            const yCoordinate = elementToScrollTo.getBoundingClientRect().top;

            if (yCoordinate > 82) {
              window.scrollTo({ top: yCoordinate - 82 });
              // elementToScrollTo.scrollIntoView();
              counter = maxCount;
            } else {
              if (counter < maxCount) {
                setTimeout(tryToScroll, 1500);
                counter++;
              }
            }
          } else {
            if (counter < maxCount) {
              setTimeout(tryToScroll, 1500);
              counter++;
            }
          }
        };

        tryToScroll();
      }
    }
  }, []);

  useEffect(() => {

    const evaluateHeaderSpace = (): boolean => {
      let res =
        viewData?.components?.length > 0 &&
        (viewData.components[0].type === ComponentClassType.Carousel ||
          (viewData.components[1] &&
            (viewData.components[0].type === ComponentClassType.Header ||
              viewData.components[0].type === ComponentClassType.Footer) &&
            viewData.components[1].type === ComponentClassType.Carousel));

      if (viewData.components && viewData.components.length !== 0) {
        setShowComponents(true);
        setTimeout(() => setLoading(false), 100);
      }
      return res;
    };
    setIsCarouselFirst(evaluateHeaderSpace());
    changeViewStructure();
  }, [viewData, activeView]);

  const removeDeletedItems = (component: ComponentViewModel) => {
    let newComponent = component;
    newComponent.parameters = newComponent.parameters.filter((param) => param.style !== 'deleted');
    newComponent.children = newComponent.children.filter((comp) => comp.style !== 'deleted');
    newComponent.children = newComponent.children.map((childComponent) => {
      return removeDeletedItems(childComponent);
    });
    return newComponent;
  };

  useEffect(() => {
    const auxFunction = async () => {
      const viewGuid = filterViewByName(activeView, viewList);
      
      if ( (viewGuid) && (viewData.components.length === 0 || viewData.name !== activeView || viewData.language !== language)) {
        console.log("View data recharge => ", viewData);
        fetchViewByIdLang(viewGuid.id, language).then(async (view) => {
          if (isPreview) {
            let stringData = localStorage.getItem('ViewData');
            let viewData: ComponentViewModel[] = stringData ? JSON.parse(stringData) : [];
            viewData = viewData.filter((parentComp) => parentComp.style !== 'deleted');
            viewData = viewData.map((parentComponent) => {
              return removeDeletedItems(parentComponent);
            });
            const newData: FullViewModel = {
              id: view.id,
              name: view.name,
              components: viewData,
              language: language
            };

            setViewData(newData);
          } else {
            const orderedList: ComponentViewModel[] = orderData(view.components);
            const neWData: FullViewModel = {
              id: view.id,
              name: view.name,
              components: orderedList,
              language: language
            };

            const finalOrderedList: ComponentViewModel[] = orderData2(neWData.components);
            const newData2: FullViewModel = {
              id: view.id,
              name: view.name,
              components: finalOrderedList,
              language
            };
            setViewData(newData2);
          }
        });
      }
    };

    auxFunction();
  }, [activeView, language, viewList]);

  useEffect(() => {
    setSize(window.innerHeight);
    if (inputEl?.current) {
      setSize(inputEl.current.clientHeight);
    }
  }, [size, viewData, isOpen, type, window.innerHeight]);

  function orderData(data: ComponentViewModel[]) {
    if (!data) return [];
    let list: ComponentViewModel[] = [];
    let i = 0;
    let k = 0;
    let condition = false;
    let position = 0;

    for (i; i < data.length; i++) {
      if (data[i].type === ComponentClassType.Carousel) {
        condition = true;
        list.push(data[i]);
        position = i;
      }
    }
    if (condition) {
      for (k; k < data.length; k++) {
        if (k !== position) {
          list.push(data[k]);
        }
      }
    } else {
      list = data;
    }
    return list;
  }

  function onResize() {
    setSize(window.innerHeight);
    if (inputEl?.current) {
      setSize(inputEl.current.clientHeight);
    }
  }



  function orderData2(dataList: ComponentViewModel[]) {
    if (!dataList) return [];
    dataList.sort((a, b) => (a.index > b.index ? 1 : -1));

    let i = 0;
    for (i; i < dataList.length; i++) {
      if (dataList[i].children) {
        orderData2(dataList[i].children);
      }
    }
    return dataList;
  }

  const changeViewStructure = () => {
    let isAddedFooter = false;
    let headerComponent = viewData.components.filter((component) => {
      return component.type === ComponentClassType.Header;
    });
    let footerComponent = viewData.components.filter((component) => {
      return component.type === ComponentClassType.Footer;
    });
    if (headerComponent.length > 0) {
      let items: ComponentViewModel[] = [];
      items.push(headerComponent[0]);
      let view: FullViewModel = {
        components: items,
        id: 'master-component',
        name: '',
        language: language
      };
      if (footerComponent.length > 0) {
        isAddedFooter = true;
        SetMenuView(view, footerComponent[0]);
      } else {
        SetMenuView(view, null);
      }
    }
    if (!isAddedFooter && footerComponent.length > 0) {
      SetMenuView(null, footerComponent[0]);
    }
  };

 
  return (
    <div className="view" ref={inputEl}>
      <div id="backdrop">
        {!hideBackground ? <BackGround height={height < 650 ? size : size + 200} type={type} nroRows={6} /> : null}
      </div>
      <div id="carousel-change" className={isCarouselFirst ? '' : 'header-space'} />
      {viewData?.components && viewData?.components.length > 0
        ? viewData.components.map((componentViewModel) => {
          return (
            <ComponentView
              componentViewModel={componentViewModel}
              key={componentViewModel.id}
              id={componentViewModel.id}
              isOpen={isOpen}
              componentKey={componentViewModel.key}
              hideBrightsoftLoader={hideBrightsoftLoader}
            />
          );
        })
        : null}
      <ResizeDetector handleWidth handleHeight onResize={onResize} />
    </div>
  );
};

export default View;
