/* eslint-disable react/no-unknown-property */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ComponentViewModel, ParameterModel } from '../interfaces/models';
import '../styles/sass/_textInline.scss';
import '../styles/sass/_alignment.scss';
import PictureComponent from './PictureComponent';
import { useMediaQuery } from '@mui/material';
import { isEqual } from 'lodash';
import EmailComponent from './EmailComponent';
import ButtonComponent from './ButtonComponent';
interface TextInlineProps {
  paramArray: ParameterModel[];
  components: ComponentViewModel[];
  isFooterComponent: boolean | undefined;
}

/* eslint-disable complexity */
const TextInline: React.FC<TextInlineProps> = (props: TextInlineProps) => {
  const [title, setTitle] = useState('');
  const [linkUrl, setLinkUrl] = useState('');
  const [linkText, setLinkText] = useState('');
  const [description, setDescription] = useState('');
  const [fontTitle, setFontTitle] = useState('');
  const [alignmentTitle, setAlignmentTitle] = useState('');
  const [fontText, setFontText] = useState('');
  const [alignmentText, setAlignmentText] = useState('');
  const [colorTitle, setColorTitle] = useState('');
  const [colorText, setColorText] = useState('');
  const [hideTitle, setHideTitle] = useState(false);
  const [LineHeightTitle, setLineHeightTitle] = useState('');
  const [LineHeightText, setLineHeightText] = useState('');
  const [FontWeightTitle, setFontWeightTitle] = useState('false');
  const [FontFamilyText, setFontFamilyText] = useState(' ');
  const [FontFamilyTitle, setFontFamilyTitle] = useState(' ');
  const [MaxWidth, setMaxWidth] = useState('');
  const [IsCopyright, setIsCopyright] = useState(false);
  const [Margin, setMargin] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [imageWidth, setImageWidth] = useState('');
  const [imageHeight, setImageHeight] = useState('');
  const [imageMaxWidth, setImageMaxWidth] = useState('');
  const [imageMaxHeight, setImageMaxHeight] = useState('');
  const [minWidth, setMinWidth] = useState('');

  const [ImageMobileUrl,setImageMobileUrl] = useState('');
  const [ImageWidthMobile, setImageWidthMobile] = useState('');
  const [ImageHeightMobile, setImageHeightMobile] = useState('');
  const [email, setEmail] = useState<string | null>(null);
  const [PDFUrl, setPDFUrl] = useState<string>('');
  const [Components, setComponents] = useState<ComponentViewModel[]>(props.components === null ? [] : props.components); 
  const [IsFooterComponent, setIsFooterComponent] = useState(false);
  useEffect(() => {
    let emptyObject = {};
    if (props.isFooterComponent && !isEqual(props.isFooterComponent, emptyObject)) {
      setIsFooterComponent(props.isFooterComponent);
    } 
  }, [props.isFooterComponent])

  useEffect(() => {
    setValues();
  }, [props]);


  const setValues = () => {
    if (props?.paramArray?.length > 0) {
      props.paramArray.forEach((param, index) => {
        const theType = param.type;
        switch (theType) {
          case 'Title': {
            setTitle(param.value);
            break;
          }
          case 'Text': {
            setDescription(param.value);
            break;
          }
          case 'LinkText': {
            setLinkText(param.value);
            break;
          }
          case 'LinkUrl': {
            let value = param.value.startsWith('wa.link') ? `https://${param.value}` : param.value;
            setLinkUrl(value);
            break;
          }
          case 'FontTitle': {
            setFontTitle(param.value);
            break;
          }
          case 'AlignmentTitle': {
            setAlignmentTitle(param.value);
            break;
          }
          case 'ColorTitle': {
            setColorTitle(param.value);
            break;
          }
          case 'FontText': {
            setFontText(param.value);
            break;
          }
          case 'AlignmentText': {
            setAlignmentText(param.value);
            break;
          }
          case 'ColorText': {
            setColorText(param.value);
            break;
          }
          case 'LineHeightTitle': {
            setLineHeightTitle(param.value);
            break;
          }
          case 'LineHeightText': {
            setLineHeightText(param.value);
            break;
          }
          case 'FontWeightTitle': {
            setFontWeightTitle(param.value);
            break;
          }
          case 'FontFamilyTitle': {
            setFontFamilyTitle(param.value);
            break;
          }
          case 'FontFamilyText': {
            setFontFamilyText(param.value);
            break;
          }
          case 'MaxWidth': {
            setMaxWidth(param.value);
            break;
          }
          case 'IsCopyright': {
            param.value.toLowerCase() === 'true' ? setIsCopyright(true) : setIsCopyright(false);
            break;
          }
          case 'HideTitle': {
            if (param.value === 'true') {
              setHideTitle(true);
            } else {
              setHideTitle(false);
            }
            break;
          }
          case 'Margin': {
            setMargin(param.value);
            break;
          }
          case 'ImageUrl': {
            setImageUrl(param.value);
            break;
          }
          case 'ImageWidth': {
            setImageWidth(param.value);
            break;
          }
          case 'ImageHeight': {
            setImageHeight(param.value);
            break;
          }
          case 'ImageMaxWidth': {
            setImageMaxWidth(param.value);
            break;
          }
          case 'ImageMaxHeight': {
            setImageMaxHeight(param.value);
            break;
          }
          case 'ImageMobileUrl': {
            setImageMobileUrl(param.value);
            break;
          }
          case 'ImageWidthMobile': {
            setImageWidthMobile(param.value ? param.value : imageWidth);
            break;
          }
          case 'ImageHeightMobile': {
            setImageHeightMobile(param.value ? param.value : imageHeight);
            break;
          }
          case 'MinWidth':{
            setMinWidth(param.value);
            break;
          }
          case 'Email': {
            setEmail(param.value);
            break;
          }
          case 'PDFUrl':{
            setPDFUrl(param.value);
            break;
          }
          default:
            break;
        }
      });
    }
  };

  const isExternalLink = linkUrl.includes('http://') || linkUrl.includes('https://');
  const fontWeightTitle = FontWeightTitle?.toLowerCase() === 'true' ? 'bolder' : 'lighter';
  const fontSizeText =
    fontText === 'Small'
      ? '17'
      : fontText === 'Medium'
      ? '21'
      : fontText === 'Big'
      ? '30'
      : fontText === 'ExtraLarge'
      ? '42'
      : '';
  const fontSizeTitle =
    fontTitle === 'Small'
      ? '17'
      : fontTitle === 'Medium'
      ? '21'
      : fontTitle === 'Big'
      ? '30'
      : fontTitle === 'ExtraLarge'
      ? '42'
      : '';

    const matchesSize = useMediaQuery('(min-width:600px)');

    useEffect(()=> {
      if(window.innerWidth <= 414 && ImageWidthMobile && ImageHeightMobile){
        setImageWidth(ImageWidthMobile ? structuredClone(ImageWidthMobile) : structuredClone(imageWidth));
        setImageHeight(ImageHeightMobile ? structuredClone(ImageHeightMobile) : structuredClone(imageHeight));  
      }
    },[matchesSize])

    useEffect(()=> {
      if(window.innerWidth <= 414 && ImageWidthMobile ){
        setImageWidth(ImageWidthMobile ? structuredClone(ImageWidthMobile) : structuredClone(imageWidth));
      }
    },[imageWidth]);

    useEffect(()=> {
      if(window.innerWidth <= 414 &&  ImageHeightMobile){
        setImageHeight(ImageHeightMobile ? structuredClone(ImageHeightMobile) : structuredClone(imageHeight));  
      }
    },[imageHeight]);
  
  const renderImageWithButton = () => {
    return (
      <div className="image-textInline text-inline-button" style={{minWidth: imageMaxWidth !== "" ? imageMaxWidth : '', maxHeight: imageMaxHeight !== "" ? imageMaxHeight : ''}}>
          <div className='picture-button'>
            <PictureComponent imageUrl={imageUrl} imageWidth={imageWidth} imageMobileUrl={ImageMobileUrl}>
              <img
                src={imageUrl}
                className={isOnlyImage() ? 'only-img' : ''}
                width={imageWidth}
                height={imageHeight}
                alt="single image with text inline"
                loading='lazy'
                fetchpriority="low"
                style={{ maxWidth: imageMaxWidth, maxHeight: imageMaxHeight}}
              />
            </PictureComponent>
            <ButtonComponent  parameters={Components[0].parameters}/>
          </div>
        </div>
    )
  }

  const renderImageWithLink = () => {
    let renderElementWithLink;
    PDFUrl !== '' 
    ? (renderElementWithLink = (
      <div className="image-textInline pdf-url">
        <a href={PDFUrl} target="_blank">
          <PictureComponent imageUrl={imageUrl} imageWidth={imageWidth} imageMobileUrl={ImageMobileUrl}>
            <img
              src={imageUrl}
              className={isOnlyImage() ? 'only-img' : ''}
              width={imageWidth}
              height={imageHeight}
              alt="external link"
              loading='lazy'
              fetchpriority="low"
            />
          </PictureComponent>
          
        </a>
      </div>
    ))
  : isExternalLink 
      ? (renderElementWithLink = (
          <div className="image-textInline">
            <a href={linkUrl} target="_blank">
              <PictureComponent imageUrl={imageUrl} imageWidth={imageWidth} imageMobileUrl={ImageMobileUrl}>
                <img
                  src={imageUrl}
                  className={isOnlyImage() ? 'only-img' : ''}
                  width={imageWidth}
                  height={imageHeight}
                  alt="external link"
                  loading='lazy'
                  fetchpriority="low"
                />
              </PictureComponent>
              
            </a>
          </div>
        ))
      : (renderElementWithLink = (
          <div className="image-textInline">
            <Link to={linkUrl}>
              <PictureComponent imageUrl={imageUrl} imageWidth={imageWidth} imageMobileUrl={ImageMobileUrl}>
                <img
                  src={imageUrl}
                  className={isOnlyImage() ? 'only-img' : ''}
                  width={imageWidth}
                  height={imageHeight}
                  alt="router link"
                  loading='lazy'
                  fetchpriority="low"
                />
              </PictureComponent>
            </Link>
          </div>
        ));
    return renderElementWithLink;
  };

  const renderImage = () => {
    let renderElement;
    if (imageUrl) {
      renderElement = linkUrl || PDFUrl !== '' ? (
        renderImageWithLink()
      ) 
      : Components.length > 0 ?
        renderImageWithButton()
      : (
        <div className="image-textInline only-image" style={{minWidth: imageMaxWidth !== "" ? imageMaxWidth : '', maxHeight: imageMaxHeight !== "" ? imageMaxHeight : ''}}>
          <PictureComponent imageUrl={imageUrl} imageWidth={imageWidth} imageMobileUrl={ImageMobileUrl}>
            <img
              src={imageUrl}
              className={isOnlyImage() ? 'only-img' : ''}
              width={imageWidth}
              height={imageHeight}
              alt="single image with text inline"
              loading='lazy'
              fetchpriority="low"
              style={{ maxWidth: imageMaxWidth, maxHeight: imageMaxHeight}}
            />
          </PictureComponent>
          
        </div>
      );
    }
    return renderElement;
  };

  const renderTitle = () => {
    if (hideTitle) {
      return null;
    } else {
      if (title !== '' && fontTitle !== '' && colorTitle !== '') {
        return (
          <h3
            className={'title ' + alignmentTitle}
            style={{
              fontSize: `${fontSizeTitle}px`,
              color: colorTitle,
              lineHeight: LineHeightTitle,
              fontFamily: FontFamilyTitle,
              display: hideTitle ? 'none' : '',
              fontWeight: fontWeightTitle,
            }}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        );
      } else if (title !== '' && fontTitle !== '' && colorTitle === '') {
        return (
          <h3
            className={'title ' + alignmentTitle}
            style={{
              fontSize: `${fontSizeTitle}px`,
              lineHeight: LineHeightTitle,
              fontWeight: fontWeightTitle,
              fontFamily: FontFamilyTitle,
            }}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        );
      } else if (title !== '' && fontTitle === '' && colorTitle !== '') {
        return (
          <h3
            className={'title ' + alignmentTitle}
            style={{
              color: colorTitle,
              lineHeight: LineHeightTitle,
              fontWeight: fontWeightTitle,
              fontFamily: FontFamilyTitle,
            }}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        );
      } else if (title !== '' && fontTitle === '' && colorTitle === '') {
        return (
          <h3
            className={'title ' + alignmentTitle}
            style={{ lineHeight: LineHeightTitle, fontWeight: fontWeightTitle, fontFamily: FontFamilyTitle }}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        );
      } else {
        return null;
      }
    }
  };

  const renderText = () => {
    if (description !== '' && fontText !== '' && colorText !== '') {
      return (
        <p
          className={'description ' + alignmentText}
          style={{
            fontSize: `${fontSizeText}px`,
            color: colorText,
            lineHeight: LineHeightText,
            fontFamily: FontFamilyText,
          }}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      );
    } else if (description !== '' && fontText !== '' && colorText === '') {
      return (
        <p
          className={'description ' + alignmentText}
          style={{ fontSize: `${fontSizeText}px`, lineHeight: LineHeightText, fontFamily: FontFamilyText }}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      );
    } else if (description !== '' && fontText === '' && colorText !== '') {
      return (
        <p
          className={'description ' + alignmentText}
          style={{ color: colorText, lineHeight: LineHeightText, fontFamily: FontFamilyText }}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      );
    } else if (description !== '' && fontText === '' && colorText === '') {
      return (
        <p
          className={'description ' + alignmentText}
          style={{ lineHeight: LineHeightText, fontFamily: FontFamilyText }}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      );
    } else {
      return null;
    }
  };

  const isOnlyImage = () => description.trim() === '' && title.trim() === '';

  return  email ?
  <div className="text-inline email-text-inline" style={{ padding: '0', maxWidth: MaxWidth + 'px', margin: Margin }}>
    <p className="description" >
      <EmailComponent email={email ? email : ""}  styleLinkProps={{
        fontSize: `${fontSizeText}px`,
        color: colorText,
        lineHeight: LineHeightText,
        fontFamily: FontFamilyText,
      }}/>
    </p>
    
  
  </div>:
  !IsCopyright ? (
    <div className="text-inline" style={{ padding: '0', maxWidth: MaxWidth + 'px', margin: Margin, minWidth: minWidth !== '' ? minWidth : imageMaxWidth !== '' && IsFooterComponent === true ? imageMaxWidth : ''}}>
      {renderImage()}
      {!isOnlyImage() ? renderTitle() : null}
      {!isOnlyImage() ? renderText() : null}
      {linkText && linkUrl && <Link to={linkUrl}>{linkText}</Link>}
    </div>
  ): 
  (
    <div className="text-inline copyright" style={{ padding: '0', maxWidth: MaxWidth + 'px', margin: Margin }}>
      {renderText()}
    </div>
  );
};
export default TextInline;
