import React, { useEffect, useState } from 'react';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import '../../styles/sass/_dynamicFooter.scss';
import { ComponentViewModel } from '../../interfaces/models';
import { ComponentClassType } from '../../interfaces/enums';
import ImageComponent from './ImageComponent';
import { left } from '../../../node_modules/@popperjs/core/lib/enums';
interface ImageCollectionProps {
  components: ComponentViewModel[];
  padding?: string;
  margin?: string;
}
/* eslint-disable complexity */
const ImageCollection: React.FC<ImageCollectionProps> = ({ components, padding, margin }: ImageCollectionProps) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return (
      <div className="image-collection" style={{ padding:padding ,paddingLeft: isMobile ? '8px' : '50px', margin:margin}}>
      <Grid container>
        <Grid item xs={12}>
          <Grid  container  >
            {components?.map((component, index) => {
              return component.type === ComponentClassType.ImageComponent ? (
                <Grid item key={index} style={{ paddingLeft: isMobile && index > 0 ? '30px' : '' }}>
                  <ImageComponent params={component.parameters} />
                </Grid>
              ) : null;
            })}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ImageCollection;
