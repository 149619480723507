import React, { useState, useEffect, SetStateAction, Dispatch } from 'react';
import { styled, Theme } from '@mui/material/styles';
import '../../styles/sass/nav/_navbar.scss';
import { Container, Grid, IconButton, Tooltip, Button, useMediaQuery, TooltipProps, tooltipClasses } from '@mui/material';
import Menu from '@mui/icons-material/Menu';
import NavLinks from './NavLinks';
import { LinkParam } from '../../interfaces/interfaces';
import world from '../../styles/images/world.png';
import { Link, useLocation } from 'react-router-dom';
import { ObjectFitType } from '../../interfaces/enums';
import PictureComponent from '../PictureComponent';
import { Language } from '@mui/icons-material';
const PREFIX = 'NavBar';

const classes = {
  tooltip: `${PREFIX}-tooltip`,
  root: `${PREFIX}-root`,
  icon: `${PREFIX}-icon`,
  button: `${PREFIX}-button`,
  input: `${PREFIX}-input`
};

const Root = styled('div')((
  { theme }: {theme: Theme}
) => ({
  [`& .${classes.root}`]: {
    color: theme.palette.text.primary,
  },

  [`& .${classes.icon}`]: {
    margin: theme.spacing(1),
    fontSize: 26,
  },

  [`& .${classes.button}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.input}`]: {
    display: 'none',
  }
}));


interface NavBarProps {
  linkParamList: LinkParam[];
  imageUrlParameterList: string[];
  allParamsList: { [key: string]: string };
  activeView: string;
  setLastLinkHovered: (e: string) => void;
  isShowSubmenu: boolean;
  openSideNav: () => void;
  setCurrentLanguage: (lang: string) => void;
  setSpaceOnNav: (width: number) => void;
  isMouseHovered: boolean;
  lastLinkHovered: string;
  currentLanguage: string | undefined;
  setLeftOffsets: Dispatch<SetStateAction<(number | undefined)[]>>;
  colorTextParameter: string;
  colorTitleParameter: string[];
  backgroundColorParameters: string[];
  FontFamilyText: string;
  ColorMenuParameters: string[];
  ObjectFit: string;
  HideLanguage: boolean;
  FullWidth: boolean;
  showLanguages: boolean;
  Margin: string;
  setShowLanguages: () => void;
  setIndexTitle: (index: number) => void;
  setPageTitleWithCurrentIndex: () => void;
}



const NavBar: React.FC<NavBarProps> = ({
  linkParamList,
  imageUrlParameterList,
  allParamsList,
  activeView,
  setLastLinkHovered,
  isShowSubmenu,
  openSideNav,
  setCurrentLanguage,
  setSpaceOnNav,
  isMouseHovered,
  lastLinkHovered,
  currentLanguage,
  setLeftOffsets,
  colorTextParameter,
  colorTitleParameter,
  backgroundColorParameters,
  FontFamilyText,
  ColorMenuParameters,
  ObjectFit,
  HideLanguage,
  FullWidth,
  showLanguages,
  Margin,
  setShowLanguages,
  setIndexTitle,
  setPageTitleWithCurrentIndex
}: NavBarProps) => {
  const [isScrollTop, setIsScrollTop] = useState(true);
  const [themeType, setThemeType] = useState('Light');
  const [match, setMatch] = useState(false);
  const [hideLanguage, setHideLanguage] = useState(false);
  const [imageWidth, setImageWidth] = useState('auto');
  const [imageHeight, setImageHeight] = useState('auto');
  const [imageLogoUrl, setImageLogoUrl] = useState('');
  const [imageLogoMobileUrl, setImageLogoMobileUrl] = useState('');
  const [disabledButton, setDisabledButton] = useState(false);

  useEffect(() => {
    scrollEffect();
    let isMounted = true; // note mutable flag
    if (isMounted) setHideLanguage(HideLanguage); // add conditional check

    return () => {
      isMounted = false;
      setIndexTitle(-1);
    };
  }, []);

  


  useEffect(() => {
    if (allParamsList && currentLanguage) {
      let w = allParamsList['ImageWidth_' + currentLanguage];
      let h = allParamsList['ImageHeight_' + currentLanguage];
      if (w && h) {
        setImageWidth(w);
        setImageHeight(h);
      }
    }
  }, [currentLanguage, allParamsList]);

  useEffect(() => {
    if (activeView === 'services' || activeView === 'technologies' || activeView === 'careers') {
      setThemeType('Color');
    } else {
      setThemeType('Light');
    }
  }, [activeView]);

  useEffect(() => {
    if (isMouseHovered && lastLinkHovered) {
      setThemeType('Light');
    } else {
      if (activeView === 'services' || activeView === 'technologies' || activeView === 'careers') {
        setThemeType('Color');
      } else {
        setThemeType('Light');
      }
    }
  }, [isMouseHovered, lastLinkHovered]);

  useEffect(() => {
    if(!hideLanguage && showLanguages){
        setDisabledButton(true);
    }
  },[showLanguages, hideLanguage]);

  const scrollEffect = () => {
    window.addEventListener('scroll', onScrollHandle);
    return () => window.removeEventListener('scroll', onScrollHandle);
  };

  const onScrollHandle = () => {
    const scrollTop = document.documentElement.scrollTop;
    if (scrollTop === 0) {
      setIsScrollTop(true);
    } else if (isScrollTop) {
      setIsScrollTop(false);
    }
  };

  useEffect(()=> {
    getSrcImageMobileUrl();
  },[allParamsList])

  const getSrcImageMobileUrl = () => {
    let imageMobile = allParamsList['ImageMobileUrl_' +currentLanguage ] || '';
    let imageHeight = allParamsList['ImageHeightMobile_'+currentLanguage] || '0';
    let imageWidth = allParamsList['ImageWidthMobile_'+currentLanguage] || '0';
    if(imageMobile !== ''){
      setImageLogoMobileUrl(imageMobile);
    }
    if(window.innerWidth < 414){
      setImageHeight(imageHeight);
      setImageWidth(imageWidth);

    }
  } 

  const getSrcImage = () => {
    let imageUrl;
    if (!imageUrlParameterList || imageUrlParameterList.length === 0) return ' ';
    if (imageUrlParameterList.length > 1 && (isShowSubmenu || themeType === 'Color')) {
      imageUrl = imageUrlParameterList[1];
    } else {
      imageUrl = imageUrlParameterList[0];
    }
    return imageUrl;
  };

  const matches = useMediaQuery('(min-width:900px)');
  useEffect(() => {
    let srcUrl = getSrcImage();
    setImageLogoUrl(srcUrl);
   
  }, [imageUrlParameterList, isShowSubmenu, themeType]);
  
  useEffect(() => {
    setMatch(matches);
  }, [matches]);

  const setLanguage = (language: string) => {
    setDisabledButton(false);
    setCurrentLanguage(language);
    setShowLanguages();
  }



  const scrollClass = isScrollTop ? 'nav-top' : 'nav-scroll';
  const themeColorClass = themeType === 'Color' ? 'nav-theme-color' : '';
  const subMenuClass = isShowSubmenu ? 'show-sub-menu' : '';
  const languageTextClass = isShowSubmenu || themeType === 'Color' ? 'color-blue' : 'color-white';

  const getBackgroundColor = () => {
    if (backgroundColorParameters && backgroundColorParameters.length > 0) {
      if (!isScrollTop) {
        return backgroundColorParameters.length > 1 ? backgroundColorParameters[1] : backgroundColorParameters[0];
      } else {
        if (isShowSubmenu) {
          return backgroundColorParameters.length > 2 ? backgroundColorParameters[2] : backgroundColorParameters[0];
        }
        return backgroundColorParameters[0];
      }
    } else {
      return '';
    }
  };

  const getColorText = () => {
    if (colorTitleParameter.length > 0) {
      if (isShowSubmenu || themeType === 'Color') {
        return colorTitleParameter.length > 1 ? colorTitleParameter[1] : colorTitleParameter[0];
      } else {
        return colorTitleParameter[0];
      }
    } else {
      return colorTextParameter ? colorTextParameter : 'white';
    }
  };

  const getColorIcon = () => {
    let result = 'rgb(24, 88, 99)';
    if (ColorMenuParameters && ColorMenuParameters.length > 1) {
      result = isScrollTop ? ColorMenuParameters[0] : ColorMenuParameters[1];
    } else {
      result = ColorMenuParameters.length !== 0 ? ColorMenuParameters[0] : 'rgb(24, 88, 99)';
    }
    return result;
  };

  let activeStyle = { borderBottom: '2px solid #00a2dc', color: '#022a70' };
  let fullWidthStyle = !matches ?{
    margin: '0',
    width: '100%',
    maxWidth: 'none',
    padding: '0',
    display: 'flex',
    alignItems: 'center'
  }: {
    margin: '0',
    width: '100%',
    maxWidth: 'none',
    padding: '0',
  };

  let fullWidthImage = {
    minWidth: '0',
  };

  const getObjectFit = () => {
    let dictionaryTypes = new Map<string, ObjectFitType>([
      ['cover', ObjectFitType.cover],
      ['contain', ObjectFitType.contain],
      ['fill', ObjectFitType.fill],
      ['none', ObjectFitType.none],
    ]);
    return ObjectFit ? dictionaryTypes.get(ObjectFit) : ObjectFitType.cover;
  };

  const imageGridSize = FullWidth ? 6 : 2;
  const navLinkGridSize = FullWidth ? 6 : 9;

  let disabledStyleButton =  {
    color: "rgba(0, 0, 0, 0.87)",
    cursor: "not-allowed",
    opacity: 0.2
  };

  const getStyleButton = (title: string) => {
    return !disabledButton ? disabledStyleButton : currentLanguage === title ? activeStyle : {};
  };
  return (
    <Root
      className={`nav-bar ${scrollClass} ${subMenuClass} ${themeColorClass}`}
      style={{ backgroundColor: getBackgroundColor() }}
    >
      <Container fixed className={!matches ? 'withoutMargin' : ''} style={FullWidth ? fullWidthStyle : {}} >
        <Grid className="grid-container" container spacing={FullWidth ? 0 : 1} >
          <Grid item xs={10} sm={10} md= {imageGridSize } lg={ imageGridSize }>
            <div className="logo-section" style={FullWidth ? fullWidthImage : {}}>
              <Link to="/" style={{maxWidth:'100%'}}>
                <PictureComponent imageUrl={imageLogoUrl} imageWidth={imageWidth} imageMobileUrl={imageLogoMobileUrl}>
                  <img
                    src={imageLogoUrl}
                    alt="Logo Company"
                    style={{ objectFit: getObjectFit(), objectPosition: 'left', minWidth: FullWidth ? '0' : '', maxWidth: '100%', width: getObjectFit() === 'cover' ? '100%' : '', height: '100%', margin: Margin}}
                    width={imageWidth}
                    height={imageHeight}
                    loading='lazy'
                  />
                </PictureComponent>
              </Link>
            </div>
          </Grid>

          <Grid item xs={1} sm={1} md={ navLinkGridSize } lg={ navLinkGridSize } sx={{display:{xs:'none',sm:'none',md:'block'}, paddingRight: '10px', flex: '1 1 auto' }}>
            <NavLinks
              linkParamList={linkParamList}
              activeLink={activeView}
              isShowSubmenu={isShowSubmenu || themeType === 'Color'}
              onLastLinkHovered={(e) => setLastLinkHovered(e)}
              setSpaceOnNav={setSpaceOnNav}
              setLeftOffsets={setLeftOffsets}
              getColorText={getColorText}
              FontFamilyText={FontFamilyText}
              match={match}
              language={currentLanguage}
              setIndexCurrentTitle={setIndexTitle}
            />
          </Grid>
          {!hideLanguage  ? (
            <Grid item xs={1} sm={1} md={1} lg={1} sx={{display:{xs:'none',sm:'none',md:'block',xl:'block'}}}>
                <div className="img-container">
                  <HtmlTooltip
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: 'common.white',
                        },
                      },
                    }}
                    title={
                      <div className="tooltip-container">
                        <Button
                          name="englishButton"
                          style={getStyleButton('EN')}
                          onClick={() => {setLanguage('EN'); setPageTitleWithCurrentIndex(); }}
                          disabled={!disabledButton}
                        >
                          <div className="font-item" style={{ color: colorTextParameter }}>
                            English
                          </div>
                        </Button>
                        <Button
                          name="spanishButton"
                          style={getStyleButton('ES')}
                          onClick={() => {setLanguage('ES'); setPageTitleWithCurrentIndex();}}
                          disabled={!disabledButton}
                        >
                          <div className="font-item" style={{ color: colorTextParameter }}>
                            Español
                          </div>
                        </Button>
                      </div>
                    }
                  >
                   <div className="iconlang-container">
                        <img className="side-margin-xs" src={world} alt="world" width={27} height={27} />
                        <div
                          className={'font-item side-margin-xs ' + languageTextClass}
                          style={{ color: getColorText() }}
                        >
                          {currentLanguage}
                        </div>
                   </div>
                  </HtmlTooltip>
                </div>
              </Grid>
          ) : null}
     
          <Grid sx={{display:{xs:'block',sm:'block',md:'none'}}} item xs={1} sm={1} md={1} lg={1} >
            <div className={` menu-action ${themeType === 'Color' ? 'button-black' : ''}`}>
              <IconButton
                className={'menu ' + classes.icon}
                aria-label="Delete"
                onClick={(e) => {
                  openSideNav();
                }}
                style={{ color: getColorIcon(), margin: '0', padding: '0' }}
              >
                <Menu className={classes.icon} />
              </IconButton>
            </div>
          </Grid>
        </Grid>   
      </Container>
    </Root>
  );
};

export default NavBar;

const HtmlTooltip = Tooltip;
