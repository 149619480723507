import React, { useRef, useEffect, useState, CSSProperties } from 'react';
import '../../styles/sass/flipcard/_contentcard.scss';
import ArrowFlip from '@mui/icons-material/Phonelink';
import useWindowDimensions from '../Background/WindowSize';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Link } from 'react-router-dom';

interface ContentCardProps {
  text: string;
  func: Function;
  onClickArea?: Function;
  size: string;
  URL?: string;
  link?: boolean;
  description?: string;
  id: string;
  isOpenState?: boolean | undefined;
  linkUrl?: string;
  linkText?: string;
}

const ContentCardI: React.FC<ContentCardProps> = ({
  size,
  link,
  onClickArea,
  func,
  text,
  id,
  isOpenState,
  linkUrl,
  linkText,
}: ContentCardProps) => {
  const { height, width } = useWindowDimensions();

  let cardRef = useRef<HTMLDivElement>(null);
  let [currentHeight, setCurrentHeight] = useState(0);
  let runMainFunc = true;

  useEffect(() => {
    let elm = cardRef.current as Element;
    setCurrentHeight(elm.scrollWidth);
  }, [currentHeight, setCurrentHeight, window.innerWidth, window.innerHeight, height, width]);

  let sizeStyle = size === 'STD' ? 'content-cardSTD' : 'content-cardAllSizes';

  let styleCard = {
    borderStyle: 'solid',
    borderColor: 'gray',
    borderWidth: '1px',
    backgroundColor: '#D3D3D3',
    height: `${currentHeight}px`,
  };

  let isLink = link === true;

  const handleClickBottom = (e: any) => {
    runMainFunc = false;
    if (onClickArea) {
      onClickArea(e);
    }
  };

  const handleClickAll = (e: any) => {
    if (runMainFunc) {
      func();
    }
    runMainFunc = true;
  };

  let lgFontStyle: CSSProperties = {
    marginBottom: '5px',
    whiteSpace: 'pre-line',
    fontSize: '1.5em',
  };

  let mdFontStyle: CSSProperties = {
    marginBottom: '5px',
    whiteSpace: 'pre-line',
    fontSize: '1.2em',
  };

  let stdFontStyle: CSSProperties = {
    marginBottom: '5px',
    whiteSpace: 'pre-line',
  };

  let fontConfig: CSSProperties = size === 'BIG' ? lgFontStyle : size === 'MEDIUM' ? mdFontStyle : stdFontStyle;

  return (
    <div
      ref={cardRef}
      className={sizeStyle}
      style={styleCard}
      onClick={(e) => {
        e.preventDefault();
        handleClickAll(e);
      }}
    >
      <div className="click-area">
        <div id={'text-back' + id} className="text-back">
          <div style={fontConfig}>{text}</div>
          {linkText && linkUrl ? <Link to={linkUrl}>{linkText}</Link> : null}
        </div>
      </div>
      <div className="demo-bottomright">
        {isLink ? (
          <ArrowFlip style={{ fill: '#00a1df' }} onClick={() => func()} />
        ) : (
          <ChevronRightIcon fontSize="large" style={{ fill: '#00a1df' }} />
        )}
      </div>
      {size === 'STD' ? (
        <div className="bottom">
          {isOpenState ? (
            <ExpandLessIcon
              fontSize="large"
              style={{ fill: '#00a1df' }}
              onClick={(e) => {
                e.preventDefault();
                handleClickBottom(e);
              }}
            />
          ) : (
            <ExpandMoreIcon
              fontSize="large"
              style={{ fill: '#00a1df' }}
              onClick={(e) => {
                e.preventDefault();
                handleClickBottom(e);
              }}
            />
          )}
        </div>
      ) : null}
    </div>
  );
};
export default ContentCardI;
