import React, { useState, useEffect, useRef } from 'react';
import CarouselItem from './CarouselItem';
import CarouselDots from './CarouselDots';
import { getGenericParams } from '../../utils/utils';
import '../../styles/sass/carousel/_carousel.scss';
import Slide from '././lib/components/slideshow/slide';
import { CarouselProps } from '../../interfaces/models';

const Carousel: React.FC<CarouselProps> = ({ children, parameters, hideBrightsoftLoader }: CarouselProps) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [hasAutoHeightProperty, setHasAutoHeightProperty] = useState(false);
  let cardRef = useRef<Slide>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (currentSlide + 1 < children.length) {
        setCurrentSlide(currentSlide + 1);
      } else {
        setCurrentSlide(0);
      }
    }, 8000);
    
    return () => clearTimeout(timer);
  });
  useEffect(()=> {
    checkAutoHeightProperty();  
  },[])

  useEffect(()=> {
    if(hasAutoHeightProperty){
      window.addEventListener("resize", calculateTopDistance);
      return () => {
        window.removeEventListener("resize", calculateTopDistance);
      }
    }
      
  },[hasAutoHeightProperty])

  

  const checkAutoHeightProperty = () => {
    children.forEach((child)=> {
      let parameterAutoHeight = child.parameters.find(p => p.type === 'AutoHeightMobile')
      if (parameterAutoHeight) {
        setHasAutoHeightProperty(true);
        console.info("Has auto height property ",true)
        return 0;
      }
    })
    return 0;
  }
  const handleCarouselDotsClick = (e: Event, index: number) => {
    setCurrentSlide(index);
    cardRef.current ? cardRef.current.goTo(index) : console.log();
  };

  const calculateTopDistance = () => {
    let carouselItem = document.getElementById("carousel-item-0");
    let distance = 0;
    if (carouselItem) {
      distance = carouselItem.clientHeight;
    }
    let elementDots = document.getElementById("dots-container-id");
    if (elementDots) {
      if( window.innerHeight > distance ){
        elementDots.style.top =  distance+'px';   
      }else {
        elementDots.style.top = "calc(100vh - 42px)";
      }
    }
  
  }


  const properties = {
    duration: 7000,
    transitionDuration: 450,
    infinite: true,
    indicators: true,
    autoplay: true,
    arrows: true,

    onChange: (oldIndex: number, newIndex: number) => {
      setCurrentSlide(newIndex);
    },
  };

  
  return (
    <div>
      {children && children.length > 1 ? (
        <Slide {...properties} ref={cardRef}>
          {children?.length > 0 &&
            children.map((component, index) => {
              let ciParmas = getGenericParams(component);
              return (
                <div key={component.key}>
                  <CarouselItem {...ciParmas} Id={index} show={true} >
                    {component.children}
                  </CarouselItem>
                </div>
              );
            })}
        </Slide>
      ) : (
        children?.length > 0 &&
        children.map((component, index) => {
          let ciParmas = getGenericParams(component);
          return (
            <div key={component.key}>
              <CarouselItem {...ciParmas} Id={index} show={true} >
                {component.children}
              </CarouselItem>
            </div>
          );
        })
      )}

      {children.length >= 2 ? (
        <div id="dots-container-id" className="dots-container">
          <CarouselDots slides={children}  handleClick={handleCarouselDotsClick} currentSlide={currentSlide} />
        </div>
      ) : null}
    </div>
  );
};

export default Carousel;
