import React, { useEffect, useState } from 'react';
import { CarouselItemTextDef } from '../../interfaces/interfaces';
import Button from '@mui/material/Button';
import '../../styles/sass/carousel/_carouselitemtext.scss';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { ComponentClassType } from '../../interfaces/enums';
import { useMediaQuery } from '@mui/material';
import HTMLRender from '../HTMLRender/HTMLRender';

/* eslint-disable complexity */
export const CarouselItemText: React.FC<CarouselItemTextDef> = ({
  Title,
  Text,
  LinkText,
  LinkUrl,
  FontTitle,
  FontText,
  ColorText,
  ColorTitle,
  BackgroundColor,
  LineHeightTitle,
  LineHeightText,
  FontWeightTitle,
  FontWeightText,
  FontFamilyTitle,
  FontFamilyText,
  ColorTextButton,
  FontTextButton,
  BorderRadius,
  children,
  Padding,
  PDFUrl,
  WithoutUIButton
}: CarouselItemTextDef) => {
  const isHashLink = LinkUrl?.includes('#');
  const lineHeightTitle = LineHeightTitle ? LineHeightTitle : '';
  const lineHeightText = LineHeightText ? LineHeightText : '';
  const fontWeightTitle = FontWeightTitle?.toLowerCase() === 'true' ? 'bolder' : 'lighter';
  const fontWeightText = FontWeightText?.toLowerCase() === 'true' ? 'bolder' : 'lighter';
  const [realFontSizeButton, setRealFontSizeButton] = useState<string>('');
  const [link, setLink] = useState(LinkUrl);
  const isWithUIButton = WithoutUIButton?.toLowerCase() === 'true' ? false : true; 
  const scrollWithOffset = (el: any) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -160;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };
  const isExternalLink = link?.includes('http:/') || link?.includes('https:/');

  useEffect(() => {
    let link = LinkUrl?.includes('wa.link') ? 'https://' + LinkUrl : LinkUrl;
    setLink(link);
  }, [LinkUrl]);

  
  const BackgroundColorParameter = BackgroundColor ? BackgroundColor : '';
  const fontFamilyText = FontFamilyText ? FontFamilyText : '';
  const colorTextButton = ColorTextButton ? ColorTextButton : '';
  const borderRadius = BorderRadius ? BorderRadius : '';
  const fontFamilyTitle = FontFamilyTitle ? FontFamilyTitle : '';
  const fontSizeText =
    FontText === 'Small'
      ? '17'
      : FontText === 'Medium'
      ? '21'
      : FontText === 'Big'
      ? '26'
      : FontText === 'ExtraLarge'
      ? '42'
      : '';
  const fontSizeTitle =
    FontTitle === 'Small'
      ? '17'
      : FontTitle === 'Medium'
      ? '21'
      : FontTitle === 'Big'
      ? '30'
      : FontTitle === 'ExtraLarge'
      ? '52'
      : '';

  const fontSizeButton =
    FontTextButton === 'Small'
      ? '17'
      : FontTextButton === 'Medium'
      ? '21'
      : FontTextButton === 'Big'
      ? '30'
      : FontTextButton === 'ExtraLarge'
      ? '52'
      : '';
  const isRenderHTMLText = () => {
    let isHTMLComponent = children && children.length > 0 && children[0].type === ComponentClassType.HTMLRender;
    return isHTMLComponent;
  };

  const descompFontSize = (value: string) => {
    if (value.includes('px') || value.includes('em') || value.includes('rem')) {
      let fontNumber = { pxValue: 17, value: 17 };
      let lengthUnit = value.includes('rem') ? 3 : 2;
      try {
        fontNumber.value = parseInt(value.slice(0, value.length - lengthUnit), 10);
        fontNumber.pxValue = value.includes('px') ? fontNumber.value : fontNumber.value * 16;
      } catch (error) {
        console.error(error);
      }
      return fontNumber;
    } else {
      try {
        let fontNumber = { value: parseInt(value, 10) || 17, pxValue: parseInt(value, 10) };
        return fontNumber;
      } catch (error) {
        console.error(error);
      }
    }
    return { pxValue: 17, value: 17 };
  };
  const matches = useMediaQuery('(max-width:400px)');

  useEffect(() => {
    setRealFontSizeButton(getFontSizeButton());
  }, [matches]);
  const getFontSizeButton = () => {
    let fontSize = matches ? descompFontSize(fontSizeButton || '').pxValue / 15 + 3 + 'vw' : fontSizeButton + 'px';
    return fontSize;
  };

  const renderButton = () => {
    return (
      <Button
        name="linkButton"
        style={{
          backgroundColor: BackgroundColorParameter,
          fontSize: realFontSizeButton,
          color: colorTextButton,
          borderRadius: borderRadius,
          fontFamily: fontFamilyText,
          fontWeight: fontWeightText,
          padding: Padding ? Padding : '8px 30px',
        }}
      >
        {LinkText}
      </Button>
    );
  };
  
  const renderLink = () => {
    if (PDFUrl && PDFUrl !== '') return <a className='pdf-click-area' href={PDFUrl} target="_blank" /> 
    else if (isExternalLink) {
      return link ? (
        <a href={link} target="_blank" className={!isWithUIButton ? 'pdf-click-area' : ''}>
          {isWithUIButton ? renderButton() : <div/>}
        </a>
      ) : null;
    } else if (isHashLink) {
      return LinkUrl ? (
        <HashLink to={LinkUrl} scroll={scrollWithOffset} className={!isWithUIButton ? 'pdf-click-area' : ''}>
          {isWithUIButton ? renderButton() : <div/>}
        </HashLink>
      ) : null;
    } else {
      return LinkUrl ? <Link to={LinkUrl} className={!isWithUIButton ? 'pdf-click-area' : ''}>{isWithUIButton ? renderButton() : <div/>}</Link> : null;
    }
  };
  return (
    <div className="carousel-item-text">
      <div className="text-block">
        {isRenderHTMLText() ? (
          <div className="text" style={{ lineHeight: lineHeightText, fontFamily: fontFamilyText }}>
            <HTMLRender paramArray={children ? children[0].parameters : []} />
          </div>
        ) : null}

        {Title !== '' && FontTitle !== '' && ColorTitle !== '' ? (
          <h3
            className="title carousel"
            style={{
              fontSize: `${fontSizeTitle}px`,
              color: ColorTitle,
              lineHeight: lineHeightTitle,
              fontWeight: fontWeightTitle,
              fontFamily: fontFamilyTitle,
            }}
            dangerouslySetInnerHTML={{ __html: Title as string }}
          />
        ) : Title !== '' && FontTitle !== '' && ColorTitle === '' ? (
          <h3
            className="title"
            style={{
              fontSize: `${fontSizeTitle}px`,
              lineHeight: lineHeightTitle,
              fontWeight: fontWeightTitle,
              fontFamily: fontFamilyTitle,
            }}
            dangerouslySetInnerHTML={{ __html: Title as string }}
          />
        ) : Title !== '' && FontTitle === '' && ColorTitle !== '' ? (
          <h3
            className="title"
            style={{
              fontSize: `${fontSizeTitle}px`,
              color: ColorTitle,
              fontWeight: fontWeightTitle,
              lineHeight: lineHeightTitle,
              fontFamily: fontFamilyTitle,
            }}
            dangerouslySetInnerHTML={{ __html: Title as string }}
          />
        ) : Title !== '' && FontTitle === '' && ColorTitle === '' ? (
          <h3
            className="title"
            style={{
              fontSize: `${fontSizeTitle}px`,
              lineHeight: lineHeightTitle,
              fontWeight: fontWeightTitle,
              fontFamily: fontFamilyTitle,
            }}
            dangerouslySetInnerHTML={{ __html: Title as string }}
          />
        ) : null}
        {Text !== '' && FontText !== '' && ColorText !== '' ? (
          <p
            className="text"
            style={{
              fontSize: `${fontSizeText}px`,
              color: ColorText,
              lineHeight: lineHeightText,
              fontWeight: fontWeightTitle,
              fontFamily: fontFamilyText,
            }}
            dangerouslySetInnerHTML={{ __html: Text as string }}
          />
        ) : Text !== '' && FontText !== '' && ColorText === '' ? (
          <p
            className="text"
            style={{ fontSize: `${fontSizeText}px`, lineHeight: lineHeightText, fontFamily: fontFamilyText }}
            dangerouslySetInnerHTML={{ __html: Text as string }}
          />
        ) : Text !== '' && FontText === '' && ColorText !== '' ? (
          <p
            className="text"
            style={{ color: ColorText, lineHeight: lineHeightText, fontFamily: fontFamilyText }}
            dangerouslySetInnerHTML={{ __html: Text as string }}
          />
        ) : Text !== '' && FontText === '' && ColorText === '' ? (
          <p
            className="text"
            style={{ lineHeight: lineHeightText, fontFamily: fontFamilyText }}
            dangerouslySetInnerHTML={{ __html: Text as string }}
          />
        ) :null }
        {renderLink()}
      </div>
    </div>
  );
};
