import { GridSize } from '@mui/material';
import { ReactNode } from 'react';
import { CandidateModel, ComponentViewModel, ContactModel, ViewModel } from './models';

export interface HomeViewProps {
  homeViewDef: HomeViewDef;
}

export interface ViewDef {
  FriendlyUrl: string;
  Id: number;
  KeyWords: string;
  Name: string;
  components: ComponentDef[];
}

const emptyComponents: ComponentDef[] = [];

export const ViewDefInit = {
  FriendlyUrl: '',
  Id: 0,
  KeyWords: '',
  Name: '',
  components: [...emptyComponents],
};

export interface HomeViewDef {
  FriendlyUrl: string;
  Id: number;
  KeyWords: string;
  Name: string;
  components: ComponentDef[];
}

export interface ComponentDef {
  Id: number;
  ParentId: number;
  Key: string;
  Parameters: ParamProps[];
  Type: string;
  Components: ComponentDef[];
}

export interface CarouselItemDef {
  Id: number;
  Title?: string;
  Text?: string;
  ImageUrl?: string;
  ImageMobileUrl?:string;
  ImageWidth?: string;
  ImageHeight?: string;
  LinkUrl?: string;
  LinkText?: string;
  show?: boolean;
  Theme?: string;
  FontTitle?: string;
  ColorTitle?: string;
  FontText?: string;
  ColorText?: string;
  BackgroundColor?: string;
  LineHeightTitle?: string;
  LineHeightText?: string;
  FontWeightTitle?: string;
  FontWeightText?: string;
  FontFamilyTitle?: string;
  FontFamilyText?: string;
  BorderRadius?: string;
  FontTextButton?: string;
  ColorTextButton?: string;
  children?: ComponentViewModel[];
  Padding?: string;
  ObjectFit?: string;
  ImageWidthMobile?:string;
  ImageHeightMobile?:string;
  PDFUrl?: string;
  WithoutUIButton?: string;
  AutoHeightMobile?:string;
}

export interface CarouselItemTextDef {
  Title?: string;
  Text?: string;
  LinkUrl?: string;
  LinkText?: string;
  FontTitle?: string;
  ColorTitle?: string;
  FontText?: string;
  ColorText?: string;
  BackgroundColor?: string;
  LineHeightText?: string;
  LineHeightTitle?: string;
  FontWeightTitle?: string;
  FontWeightText?: string;
  FontFamilyTitle?: string;
  FontFamilyText?: string;
  BorderRadius?: string;
  FontTextButton?: string;
  ColorTextButton?: string;
  children?: ComponentViewModel[] | null;
  Padding?: string;
  FlexibleFontSizeText?: string;
  FlexibleFontSizeTitle?: string;
  ObjectFit?: string;
  PDFUrl?: string;
  WithoutUIButton?:string;
}

const emptyLinkParamList: LinkParam[] = [];

export const LinkParamInit = {
  Link: '',
  SubLinkList: [...emptyLinkParamList],
  Text: '',
  Theme: '',
  Type: '',
  ViewId: '',
  Key: '',
  Order: 0,
};

export interface LinkParam {
  Link: string;
  SubLinkList: LinkParam[];
  Text: string;
  Theme: string;
  ViewId: string;
  Level?: number;
  Type?: string;
  Key?: string;
  Order: number;
  ShowSubMenu ?: boolean;
  HideTitle ?: boolean;
}

export interface ParamProps {
  GroupId: string;
  Id: number;
  Name: string;
  Value: string;
}

export interface linkObject {
  Text: string;
  Url: string;
  Components: ComponentDef[];
  Level: string;
}

export interface GenericparamDef {
  Description?: string;
  Columns?: GridSize;
  Title?: string;
  Text?: string;
  ImageUrl?: string;
  ImageWidth?: string;
  ImageHeight?: string;
  Link?: string;
  LinkUrl?: string;
  LinkText?: string;
  SubLinkList?: LinkParam[];
  ViewId?: string;
  Size?: string;
  Theme?: string;
  Type?: string;
  Language?: string;
  BreakLine?: string;
  Error?: string;
  Error1?: string;
  FontTitle?: string;
  AlignmentTitle?: string;
  ColorTitle?: string;
  FontText?: string;
  AlignmentText?: string;
  ColorText?: string;
  MaxLengthText?: string;
  CVMessage?: string;
  PDFUrl?: string;
  Contact?: string;
  Job?: string;
  Phone?: string;
  Email?: string;
  Address?: string;
  Address1?: string;
  Address2?: string;
  City?: string;
  Country?: string;
  Iframe?: string;
  HideTitle?: string;
  WithoutImage?: string;
  Stylized?: string;
  BackgroundColor?: string;
  Margin?: string;
  LineHeightTitle?: string;
  LineHeightText?: string;
  FontWeightTitle?: string;
  FontFamilyTitle?: string;
  FontFamilyText?: string;
  Name?: string;
  Inlined?: string;
  HTMLText?: string;
  MaxWidth?: string;
  IsCopyright?: string;
  ColorMenuIcon?: string;
  FontWeightText?: string;
  FontTextButton?: string;
  ColorTextButton?: string;
  BorderRadius?: string;
  EmailIcon?: string;
  VerticalAlignment?: string;
  Required?: string;
  TargetForm?: string;
  FooterStyle?: string;
  Direction?: string;
  MetaTagTitle?: string;
  MetaTagDomain?: string;
  WebIcon?: string;
  MaxHeight?: string;
  Border?: string;
  TypeInput?: string;
  ErrorEmpty?: string;
  Date?: string;
  Schedule?: string;
  Cost?: string;
  Place?: string;
  Padding?: string;
  FlexibleFontSizeText?: string;
  FlexibleFontSizeTitle?: string;
  ObjectFit?: string;
  TitleFormat?: string;
  MainActivityIndicator?: string;
  Lg?: string;
  Xs?: string;
  ImageMobileUrl?: string;
  ImageHeightMobile?:string;
  ImageWidthMobile?:string;
  MinWidth?: string;
  WithoutUIButton?:string;
  AutoHeightMobile?:string;
}

export interface CountryDef {
  name: string;
  code: string;
}

export interface JobDef {
  id: string;
  name: string;
  groupId: string;
}

export interface CareersFormDef {
  Name: string;
  Email: string;
  Phone: string;
  Country: string;
  CVUrl: string;
  HideCaptcha: string;
  ConfirmationMessage: string;
}

/*
New interfaces: 
*/

const emptyComp: Array<ComponentViewModel> = [];

export const IViewInit = {
  id: '',
  name: '',
  components: [...emptyComp],
};

// export interface IComponent {
//     id?: string;
//     // ParentId: number;
//     key: string;
//     type: string;
//     parameters: Array<IParameter>;
//     children: Array<IComponent>;
//     index: number;
// }

// export interface IParameter {
//   name: string;
//   value: string;
//   groupId: string;
//   id?: string;
//   type?: string;
//   componentId?: string;
//   style?: string;
//   componentChangeId?: string;
// }

// export interface ParameterChanges {
//   createdAt: string;
//   email: string;
//   groupId: string;
//   id: string;
//   newValue: string;
//   oldValue: string;
//   status: string;
//   userId: string;
//   type: string;
//   isChecked: boolean;
//   componentChangeId: string;
//   changeType: string;
//   rootViewName: string;
// }

export interface ILink {
  text: string;
  url: string;
  children: ComponentViewModel[];
  level: string;
}

export interface IGenericParam {
  Description?: string;
  Columns?: GridSize;
  Title?: string;
  Text?: string;
  ImageUrl?: string;
  ImageWidth?: string;
  ImageHeight?: string;
  Link?: string;
  LinkUrl?: string;
  LinkText?: string;
  SubLinkList?: LinkParam[];
  ViewId?: string;
  Size?: string;
  Theme?: string;
  Type?: string;
  Language?: string;
  BreakLine?: string;
  Error?: string;
  Error1?: string;
  FontTitle?: string;
  AlignmentTitle?: string;
  ColorTitle?: string;
  FontText?: string;
  AlignmentText?: string;
  ColorText?: string;
  CVMessage?: string;
  PDFUrl?: string;
  Contact?: string;
  Job?: string;
  Phone?: string;
  Email?: string;
  Address?: string;
  Address1?: string;
  Address2?: string;
  City?: string;
  Country?: string;
  Iframe?: string;
  HideTitle?: string;
  WithoutImage?: string;
  Stylized?: string;
  BackgroundColor?: string;
  Margin?: string;
  LineHeightText?: string;
  LineHeightTitle?: string;
  FontWeightTitle?: string;
  FontFamilyTitle?: string;
  FontFamilyText?: string;
  Name?: string;
  Inlined?: string;
  HMTLText?: string;
  MaxWidth?: string;
  IsCopyright?: string;
  ColorMenuIcon?: string;
  FontWeightText?: string;
  FontTextButton?: string;
  ColorTextButton?: string;
  BorderRadius?: string;
  EmailIcon?: string;
  VerticalAlignment?: string;
  Required?: string;
  TargetForm?: string;
  FooterStyle?: string;
  Direction?: string;
  MetaTagTitle?: string;
  MetaTagDomain?: string;
  WebIcon?: string;
  MaxHeight?: string;
  Border?: string;
  TypeInput?: string;
  ErrorEmpty?: string;
  Cost?: string;
  Date?: string;
  Schedule?: string;
  Place?: string;
  Padding?: string;
  FlexibleFontSizeText?: string;
  FlexibleFontSizeTitle?: string;
  TitleFormat?: string;
  MainActivityIndicator?: string;
  Lg?: string;
  Xs?: string;
  ImageMobileUrl?: string;
  ImageHeightMobile?:string;
  ImageWidthMobile?:string;
  MinWidth?: string;
}

// export interface UserModel {
//   id?: string;
//   email: string;
//   firstName: string;
//   forgetPasswordCode: string;
//   lastName: string;
//   preferredGroupId: string;
//   roleName: string;
// }

export type IListItem = ContactModel | ViewModel | CandidateModel | IChanges | IChangesCheck;

// export interface ICareers {
//   name: string;
//   email: string;
//   phone: string;
//   country: string;
//   documentUrl: string;
//   createdAt: string;
//   id: string;
// }

// export interface IContact {
//   company: string;
//   description: string;
//   createdAt: string;
//   name: string;
//   email: string;
//   phone: string;
//   country: string;
//   id: string;
// }

export interface IChangesUser {
  userId?: string;
  userName?: string;
  email?: string;
  groupId?: string;
}

export interface IChanges extends IChangesUser {
  id?: string;
  type?: string;
  checkStatus?: string;
  sendStatus?: boolean;
  createdAt?: string;
}

// export interface IParameterChanges extends IChanges {
//   newValue: string;
//   oldValue: string;
//   componentId?: string;
//   parameterId?: string;
//   changeType: string;
//   rootViewName: string;
//   componentChangeId?: string;
// }

// export interface IComponentChanges extends IChanges {
//   key?: string;
//   parentId?: string;
//   viewId?: string;
//   parameterChanges?: Array<IParameterChanges>;
//   index?: number;
//   componentId?: string;
//   componentType?: string;
//   changeType: string;
// }

// export interface IComponentsChanges extends IChangesUser {
//   componentChanges: IComponentChanges[];
// }

// export interface IParametersChanges extends IChangesUser {
//   parameters: IParameterChanges[];
// }

export interface IChangesCheck extends IChanges {
  isChecked?: TSelection;
}

// export interface IComponentChangesA {
//   changeType: string;
//   componentType: string;
//   createdAt: string;
//   email: string;
//   id: string;
//   index: number;
//   key: string;
//   parameterChanges: Array<IParameterChanges>;
//   status: string;
//   userId: string;
//   viewId: string;
//   isChecked: boolean;
//   rootViewName: string;
//   language: string;
// }

export type TSelection = boolean | undefined;

/**
 *
 * UI Interfaces
 *
 */

export interface IToolItem {
  name: string;
  icon: ReactNode;
  eventHandler: (e: any) => void;
}

export interface IStatus {
  listIds: Array<string>;
}
