import React, { useEffect, useState } from 'react';
import '../../styles/sass/_loading.scss';

interface FullScreenLoadingProps {
  state: boolean;
  mainActivityIndicator: string;
  hideBrightsoftLoader: boolean;
}

const FullScreenLoading: React.FC<FullScreenLoadingProps> = (props: FullScreenLoadingProps) => {
  const [loading, setLoading] = useState(false);
  const [activityIndicator, setActivityIndicator] = useState(<div className="spinner-6" />);

  useEffect(() => {
    setActivityIndicator(
      props.hideBrightsoftLoader ? <div className="white-full-screen" /> : <div className="spinner-6" />,
    );
    getMainActivityIndicator();
  }, [props.mainActivityIndicator]);

  useEffect(() => {
    props.mainActivityIndicator !== '' && setLoading(props.state);
  }, [props.state]);

  const addCSSFile = () => {
    let head = document.getElementsByTagName('HEAD')[0];
    let link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = props.mainActivityIndicator;
    head.appendChild(link);
  };

  const getMainActivityIndicator = () => {
    if (props.mainActivityIndicator.includes('.css')) {
      addCSSFile();
      setActivityIndicator(<div className="spinner-6" />);
    } else {
      props.mainActivityIndicator !== ''
        ? setActivityIndicator(<img src={props.mainActivityIndicator} width={150} height={150} />)
        : setActivityIndicator(<div className="spinner-6" />);
    }
  };

  return loading ? (
    <div className="fp-fullscreen-container">
      <div className="fp-loader">{activityIndicator}</div>
    </div>
  ) : null;
};
export default FullScreenLoading;
