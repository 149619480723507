import React, { useState, useEffect } from 'react';
import '../../styles/sass/_background.scss';
import BlockRow from './BlockRow';

interface BackGroundProps {
  type: string;
  nroRows: number;
  height: number;
}
const BackGround: React.FC<BackGroundProps> = (props: BackGroundProps) => {
  const [Height, setHeight] = useState(0);
  useEffect(() => {
    setHeight(props.height);
  }, [props.height]);

  if (props.type === 'lg') {
    return (
      <div className="columnp-container clearfix" style={{ height: `${Height}px` }}>
        <BlockRow type={props.type} nroColumns={20} nroRows={props.nroRows - 1} />
      </div>
    );
  } else if (props.type === 'md') {
    return (
      <div className="columnp-container clearfix" style={{ height: `${Height}px` }}>
        <BlockRow type={props.type} nroColumns={15} nroRows={props.nroRows - 1} />
      </div>
    );
  } else {
    return (
      <div className="columnp-container clearfix" style={{ height: `${Height}px` }}>
        <BlockRow type={props.type} nroColumns={10} nroRows={props.nroRows - 1} />
      </div>
    );
  }
};
export default BackGround;
