import {createRoot} from 'react-dom/client';
// import * as serviceWorker from "./serviceWorker";
import './styles/sass/index.scss';
import App from './App';
import ReactGA from 'react-ga';
import MyProvider from './cms/contextApi/MyProvider';
import { SnackbarProvider } from 'notistack';
// import './i18next';
import TagManager from 'react-gtm-module';
import { BrowserRouter as Router } from 'react-router-dom';
import ImageComponent, { ImageComponentProps } from './components/ImageComponent/ImageComponent';
import InitDataService from './services/InitDataService';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';


const tagManagerArgs = {
  gtmId: 'GTM-536NT4S',
};

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

const startApp = async () => {
  
  let initAppData = await InitDataService.getInstance().getInitAppData();
  let appSettings = initAppData.get("AppSettings");
  console.log("This is app settings => ",appSettings);
  if(appSettings.googleManagerToken &&  typeof appSettings.googleManagerToken  === "string" && appSettings.googleManagerToken !== ''){
    // Token google manager: G-HZ3KWKPB0D
      ReactGA.initialize(appSettings.googleManagerToken as string);
      TagManager.initialize(tagManagerArgs);      
    }
    root.render(
      <MyProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <SnackbarProvider maxSnack={3}>
            <Router>
              <App 
              isHideBrightsoftLoader = {appSettings.hideBrightsoftLoader }
              mainActivityIndicator={appSettings.mainActivityIndicator || ''} 
              metaTagTitle={appSettings.websiteTitle || 'Brightsoft'} 
              titleFormat={appSettings.titleFormat}
              languages={appSettings.languages}
              metaTagDomain={appSettings.domain}
              hideBackground = {appSettings.hideBackground}
              />
              
            </Router>
          </SnackbarProvider>
        </LocalizationProvider>
      </MyProvider>
    );
 
}




startApp();

// serviceWorker.unregister();

const startApp2 = () => {
  ReactGA.initialize('G-HZ3KWKPB0D');
  TagManager.initialize(tagManagerArgs);
  let parameters: ImageComponentProps = {
    params: [
      {
        id: 'testImageUrl',
        type: 'ImageUrl',
        value: 'https://png.pngtree.com/png-vector/20190419/ourmid/pngtree-vector-location-icon-png-image_956422.jpg',
        groupId: 'ES',
        componentId: 'imageLink1',
        style: '',
        disabled: '',
      },
      {
        id: 'testLinkUrl',
        type: 'LinkUrl',
        value: 'https://goo.gl/maps/sC6nh88ApwAWci1f9',
        groupId: 'ES',
        componentId: 'imageLink1',
        style: '',
        disabled: '',
      },
      {
        id: 'testDescription',
        type: 'Description',
        value: 'Av. Julio Patiño entre 14 y 15 de Calacoto Nro. 835 Edificio, Victor Eduardo\nLa Paz - Bolivia',
        groupId: 'ES',
        componentId: 'imageLink1',
        style: '',
        disabled: '',
      },
    ],
  };
  root.render(<ImageComponent params={parameters.params} />);
};
// startApp2();
