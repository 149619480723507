import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ComponentViewModel, ParameterModel } from '../../interfaces/models';
import '../../styles/sass/Blog/_blogLayout.scss';
import slugify from 'react-slugify';
import { Title } from '@mui/icons-material';
import ButtonComponent from '../ButtonComponent';
import { useMediaQuery } from '@mui/material';
import PictureComponent from '../PictureComponent';
interface BlogProps {
  paramArray: ParameterModel[];
  children?: ComponentViewModel[] | undefined;
}

/* eslint-disable complexity */
const BlogComponent: React.FC<BlogProps> = (props: BlogProps) => {
  const [imageUrl, setImageUrl] = useState('');
  const [imageWidth, setImageWidth] = useState('');
  const [imageHeight, setImageHeight] = useState('');
  const [articleIcon, setArticleIcon] = useState('');
  const [article, setArticle] = useState('');
  const [title, setTitle] = useState('');
  const [fontText, setFontText] = useState('17px');
  const [colorText, setColorText] = useState('');
  const [fontFamilyText, setFontFamilyText] = useState(' ');
  const [fontTitle, setFontTitle] = useState('17px');
  const [colorTitle, setColorTitle] = useState('');
  const [FontFamilyTitle, setFontFamilyTitle] = useState(' ');
  const [FontWeightTitle, setFontWeightTitle] = useState('');
  const [link, setLinkUrl] = useState('');
  const [ImageMobileUrl,setImageMobileUrl] = useState('');
  const [ImageWidthMobile, setImageWidthMobile] = useState('');
  const [ImageHeightMobile, setImageHeightMobile] = useState('');
  useEffect(() => {
    setValues();
  }, [props]);

  const setValues = () => {
    if (props?.paramArray?.length > 0) {
      props.paramArray.forEach((param, index) => {
        const theType = param.type;
        switch (theType) {
          case 'Title': {
            setTitle(param.value);
            break;
          }
          case 'Article': {
            setArticle(param.value);
            break;
          }
          case 'FlexibleFontSizeText': {
            setFontText(param.value);
            break;
          }
          case 'ColorText': {
            setColorText(param.value);
            break;
          }
          case 'FontFamilyText': {
            setFontFamilyText(param.value);
            break;
          }
          case 'FlexibleFontSizeTitle': {
            setFontTitle(param.value);
            break;
          }
          case 'ColorTitle': {
            setColorTitle(param.value);
            break;
          }
          case 'FontFamilyTitle': {
            setFontFamilyTitle(param.value);
            break;
          }
          case 'FontWeightTitle': {
            setFontWeightTitle(param.value);
            break;
          }
          case 'LinkUrl': {
            setLinkUrl(param.value);
            break;
          }
          case 'ArticleIcon': {
            setArticleIcon(param.value);
            break;
          }
          case 'ImageUrl': {
            setImageUrl(param.value);
            break;
          }
          case 'ImageWidth': {
            setImageWidth(param.value);
            break;
          }
          case 'ImageHeight': {
            setImageHeight(param.value);
            break;
          }
          case 'ImageMobileUrl': {
           
            setImageMobileUrl(param.value);
            break;
          }
          case 'ImageWidthMobile': {
            setImageWidthMobile(param.value ? param.value : imageWidth);
            break;
          }
          case 'ImageHeightMobile': {
            setImageHeightMobile(param.value ? param.value : imageHeight);
            break;
          }
          default:
            break;
        }
      });
    }
  };

  const fontWeightTitle = FontWeightTitle?.toLowerCase() === 'true' ? 'bolder' : 'lighter';

  const renderActionElement = () => {
    let length = props?.children?.length || 0;
    let child = props?.children ? props?.children[0] : { parameters: [] };
    let actionComponent =
      length > 0 ? (
        <ButtonComponent parameters={child.parameters} />
      ) : (
        <a target="_blank" href={link}>
          <img src={articleIcon} className="icon-article" loading='lazy'/>
        </a>
      );
    return actionComponent;
  };

  const descompFontSize = (value: string) => {
    if (value.includes('px') || value.includes('em') || value.includes('rem')) {
      let fontNumber = { pxValue: 17, value: 17 };
      let lengthUnit = value.includes('rem') ? 3 : 2;
      try {
        fontNumber.value = parseInt(value.slice(0, value.length - lengthUnit), 10);
        fontNumber.pxValue = value.includes('px') ? fontNumber.value : fontNumber.value * 16;
      } catch (error) {
        console.error(error);
      }
      return fontNumber;
    } else {
      try {
        let fontNumber = { value: parseInt(value, 10) || 17, pxValue: parseInt(value, 10) };
        return fontNumber;
      } catch (error) {
        console.error(error);
      }
    }
    return { pxValue: 17, value: 17 };
  };

  const getFontSize = (type: string) => {
    let flexibleSizes = new Map<string, string>([
      ['FontSizeTitle', fontTitle],
      ['FontSizeText', fontText],
    ]);
    let fontSize = matches
      ? descompFontSize(flexibleSizes.get(type) || '').pxValue / 15 + 3 + 'vw'
      : flexibleSizes.get(type);
    return fontSize;
  };

  const matches = useMediaQuery('(max-width:400px)');

  const matchesSize = useMediaQuery('(min-width:600px)');

  useEffect(()=> {
    if(window.innerWidth <= 414 && ImageWidthMobile && ImageHeightMobile){
      setImageWidth(ImageWidthMobile ? structuredClone(ImageWidthMobile) : structuredClone(imageWidth));
      setImageHeight(ImageHeightMobile ? structuredClone(ImageHeightMobile) : structuredClone(imageHeight));  
    }
  },[matchesSize])

  useEffect(()=> {
    if(window.innerWidth <= 414 && ImageWidthMobile ){
      setImageWidth(ImageWidthMobile ? structuredClone(ImageWidthMobile) : structuredClone(imageWidth));
    }
  },[imageWidth]);

  useEffect(()=> {
    if(window.innerWidth <= 414 &&  ImageHeightMobile){
      setImageHeight(ImageHeightMobile ? structuredClone(ImageHeightMobile) : structuredClone(imageHeight));  
    }
  },[imageHeight]);

  const getClassName  = () => {
   const className = ImageMobileUrl || ImageMobileUrl === '' ? 'picture-article' : 'img-article';
   return className;
  };
  const renderBlogComponent = () => {
    return (
      <div className="article-container">
        <PictureComponent imageMobileUrl={ImageMobileUrl} imageUrl={imageUrl} imageWidth={imageWidth} pictureClassName='picture-article'>
          <img
            src={imageUrl}
            className={getClassName()}
            style={{ marginBottom: matches ? '10px' : '' }}
            width={imageWidth}
            height={imageHeight}
            loading='lazy'
          />  
        </PictureComponent>
        <div className="content">
          <a target="_blank" href={link} className="article-title">
            <h2
              className="article-title"
              style={{
                fontSize: getFontSize('FontSizeTitle'),
                fontWeight: fontWeightTitle,
                fontFamily: FontFamilyTitle,
                color: colorTitle,
              }}
            >
              {title}
            </h2>
          </a>
          <p
            className="article-text"
            style={{ fontSize: getFontSize('FontSizeText'), fontFamily: fontFamilyText, color: colorText }}
          >
            {article}
          </p>
          <div className="icon-container">{renderActionElement()}</div>
        </div>
      </div>
    );
  };

  return <div className="blog-component">{renderBlogComponent()}</div>;
};
export default BlogComponent;
