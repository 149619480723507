import { Slide } from '@mui/material';
import React from 'react';
import '../../styles/sass/carousel/_carouseldots.scss';

interface CarouselDotsProps {
  slides: any[];
  handleClick: (e: any, index: number) => void;
  currentSlide: number;
}

const CarouselDots: React.FC<CarouselDotsProps> = ({ slides, handleClick, currentSlide }: CarouselDotsProps) => {
  return (
    <div className="carousel-dots">
      {slides.map((slide, index) => {
        return (
          <label key={index} className="container">
            <input type="radio" onChange={(e) => handleClick(e, index)} name="radio" checked={index === currentSlide} />
            <span className="checkmark" title={slide} />
          </label>
        );
      })}
    </div>
  );
};

export default CarouselDots;
