import React, { useEffect, useState } from 'react';
import '../styles/sass/_activityComponent.scss';
import slugify from 'react-slugify';
import { ComponentViewModel } from '../interfaces/models';
import { Alignment, ComponentClassType } from '../interfaces/enums';
import ButtonComponent from './ButtonComponent';
import { useMediaQuery } from '@mui/material';
import PictureComponent from './PictureComponent';

interface ActivityComponentProps {
  title?: string;
  text?: string;
  imageUrl?: string;
  imageWidth?: string;
  imageHeight?: string;
  fontTitle?: string;
  colorTitle?: string;
  fontText?: string;
  colorText?: string;
  fontFamilyTitle?: string;
  fontFamilyText?: string;
  date?: string;
  schedule?: string;
  cost?: string;
  place?: string;
  flexibleFontSizeTitle?: string;
  flexibleFontSizeText?: string;
  alignmentText?: string;
  alignmentTitle?: string;
  margin?: string;
  children?: ComponentViewModel[] | null;
  fontWeightTitle ?: string;
  fontWeightText ?: string;
  imageMobileUrl ?: string;
  imageWidthMobile ?: string;
  imageHeightMobile ?: string;
}

/* eslint-disable complexity */
const ActivityComponent: React.FC<ActivityComponentProps> = ({
  title,
  text,
  imageUrl,
  imageWidth,
  imageHeight,
  fontTitle,
  fontText,
  colorText,
  colorTitle,
  fontFamilyText,
  fontFamilyTitle,
  date,
  schedule,
  cost,
  place,
  children,
  flexibleFontSizeText,
  flexibleFontSizeTitle,
  alignmentText,
  alignmentTitle,
  margin,
  fontWeightTitle,
  fontWeightText,
  imageMobileUrl,
  imageHeightMobile,
  imageWidthMobile,
}: ActivityComponentProps) => {
  const [fontSizeMap, setFontSizeMap] = useState<Map<string, string>>();
  const constToReduceText = 5;
  const fontSizeText =
    fontText === 'Small'
      ? 17
      : fontText === 'Medium'
      ? 21
      : fontText === 'Big'
      ? 31
      : fontText === 'ExtraLarge'
      ? 42
      : 17;
  const fontSizeTitle =
    fontTitle === 'Small'
      ? 17
      : fontTitle === 'Medium'
      ? 21
      : fontTitle === 'Big'
      ? 31
      : fontTitle === 'ExtraLarge'
      ? 55
      : 17;
  

  const descompFontSize = (value: string) => {
    if (value.includes('px') || value.includes('em') || value.includes('rem')) {
      let fontNumber = { pxValue: 17, value: 17 };
      let lengthUnit = value.includes('rem') ? 3 : 2;
      try {
        fontNumber.value = parseInt(value.slice(0, value.length - lengthUnit), 10);
        fontNumber.pxValue = value.includes('px') ? fontNumber.value : fontNumber.value * 16;
      } catch (error) {
        console.error(error);
      }
      return fontNumber;
    } else {
      try {
        let fontNumber = { value: parseInt(value, 10) || 17, pxValue: parseInt(value, 10) };
        return fontNumber;
      } catch (error) {
        console.error(error);
      }
    }
    return { pxValue: 17, value: 17 };
  };

  const theFontSizeText =
    flexibleFontSizeText && flexibleFontSizeText !== '' ? flexibleFontSizeText : fontSizeText + 'px';

  const matchesSize = useMediaQuery('(min-width:600px)');

  const [ImageWidth, setImageWidth] = useState<string>(imageWidth ? imageWidth : '');
  const [ImageHeight, setImageHeight] = useState<string>(imageHeight ? imageHeight : '');
  useEffect(()=> {
    if(window.innerWidth <= 414){
      setImageWidth(imageWidthMobile ? imageWidthMobile : '');
      setImageHeight(imageHeightMobile ? imageHeightMobile : '');  
    }
  },[matchesSize])
  const getFontSize = () => {
    let flexibleSizes = new Map<string, string>([
      [
        'FontSizeTitle',
        String(flexibleFontSizeTitle && flexibleFontSizeTitle !== '' ? flexibleFontSizeTitle : fontSizeTitle + 'px'),
      ],
      [
        'FontSizeText',
        String(flexibleFontSizeText && flexibleFontSizeText !== '' ? flexibleFontSizeText : fontSizeText + 'px'),
      ],
      ['FontSizeMinText', String(descompFontSize(String(theFontSizeText)).value - constToReduceText) + 'px'],
    ]);

    let auxFontSizeTitle =
      (matches
        ? descompFontSize(flexibleSizes.get('FontSizeTitle') || '').pxValue / 15 + 3 + 'vw'
        : flexibleSizes.get('FontSizeTitle')) || '17px';
    let auxFontSizeText =
      (matches
        ? descompFontSize(flexibleSizes.get('FontSizeText') || '').pxValue / 15 + 3 + 'vw'
        : flexibleSizes.get('FontSizeText')) || '17px';
    let auxFontSizeMinText =
      (matches
        ? descompFontSize(flexibleSizes.get('FontSizeMinText') || '').pxValue / 15 + 3 + 'vw'
        : flexibleSizes.get('FontSizeMinText')) || '17px';
    flexibleSizes.set('FontSizeTitle', auxFontSizeTitle);
    flexibleSizes.set('FontSizeText', auxFontSizeText);
    flexibleSizes.set('FontSizeMinText', auxFontSizeMinText);
    setFontSizeMap(flexibleSizes);
  };

  const matches = useMediaQuery('(max-width:400px)');

  useEffect(() => {
    getFontSize();
  }, [matches]);

  const getAlignment = (alignment: string | undefined): Alignment => {
    let alignmentDict = new Map<string, Alignment>([
      ['left', Alignment.left],
      ['center', Alignment.center],
      ['right', Alignment.right],
    ]);
    let align = alignmentDict.get(alignment ? alignment.toLowerCase() : '') || Alignment.left;
    return align;
  };

  const getClassName = () => {
    const className = !imageMobileUrl || imageMobileUrl === ''  ? "picture-activity-component" : "picture-img-activity-component";
    return className;
  }
  const renderActivity = () => {
    return (
      <div className="activity-main-content">
        <PictureComponent imageMobileUrl={imageMobileUrl} pictureClassName='picture-activity-component' imageWidth={imageWidth} imageUrl={imageUrl ? imageUrl : ''}>
         <img 
            className= {getClassName()}
            src={imageUrl}
            width={ImageWidth}
            height={ImageHeight}
            alt="activity image"
            loading='lazy'
          />
        </PictureComponent>  
        <div className="content-section">
          <h2
            className="title-section"
            style={{
              fontSize: fontSizeMap?.get('FontSizeTitle'),
              color: colorTitle,
              fontFamily: fontFamilyTitle,
              textAlign: getAlignment(alignmentTitle),
              fontWeight: fontWeightTitle?.toLowerCase() === 'true' ? 'bold' : ''
            }}
          >
            {title}
          </h2>
          <div
            className="reference-section"
            style={{
              fontSize: fontSizeMap?.get('FontSizeText'),
              color: colorText,
              fontFamily: fontFamilyText,
              textAlign: getAlignment(alignmentText),
              fontWeight: fontWeightText?.toLowerCase() === 'true' ? 'bold' : ''
            }}
          >
            {date ? (
              <div>
                <span className="subtitle-reference">Fecha:</span>
                &nbsp;
                <span>{date}</span>
              </div>
            ) : null}
            {place ? (
              <div>
                <span className="subtitle-reference">Lugar:</span>
                &nbsp;
                <span>{place}</span>
              </div>
            ) : null}
            {schedule ? (
              <div>
                <span className="subtitle-reference">Horario:</span>
                &nbsp;
                <span>{schedule}</span>
              </div>
            ) : null}
            {cost ? (
              <div>
                <span className="subtitle-reference">Costo:</span>
                &nbsp;
                <span>{cost}</span>
              </div>
            ) : null}
          </div>
          <div
            className="text-section"
            style={{
              fontSize: fontSizeMap?.get('FontSizeMinText'),
              color: colorText,
              fontFamily: fontFamilyText,
              textAlign: getAlignment(alignmentText),
            }}
          >
            <p>{text}</p>
          </div>
          <div className={getNumberButtons() === 1 ? ' button-actions-left ' : ' button-actions'}>{renderButton()}</div>
        </div>
      </div>
    );
  };

  const getNumberButtons = () => {
    let numberButtons = children ? children?.length : 0;
    return numberButtons;
  };

  const renderButton = () => {
    return children?.map((child, index) => {
      return child.type === ComponentClassType.ButtonComponent ? (
        <div style={{ margin: '5px' }} key={'activity-' + index + title}>
          <ButtonComponent parameters={child.parameters} />
        </div>
      ) : null;
    });
  };
  return (
    <div className={`activity-component `} id={slugify(title)} style={{ margin: margin }}>
      {renderActivity()}
    </div>
  );
};

export default ActivityComponent;
