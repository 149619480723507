import React, { useState, useEffect } from 'react';
import { ComponentViewModel, ParameterModel } from '../../interfaces/models';
import '../../styles/sass/Blog/_blogLayout.scss';
import { ComponentClassType } from '../../interfaces/enums';
import BlogSectionComponent from './BlogSectionComponent';
interface BlogLayoutProps {
  paramArray: ParameterModel[];
  children?: ComponentViewModel[] | undefined;
}

/* eslint-disable complexity */
const BlogLayoutComponent: React.FC<BlogLayoutProps> = (props: BlogLayoutProps) => {
  const [searchIcon, setSearchIcon] = useState('');
  const [searchPlaceholder, setSearchPlaceholder] = useState('');
  const [fontText, setFontText] = useState('Small');
  const [colorText, setColorText] = useState('');
  const [fontFamilyText, setFontFamilyText] = useState(' ');
  const [fontWeightText, setFontWeightText] = useState('');
  const [textFilter, setTextFilter] = useState('');
  const [fontSizeText, setFontSizeText] = useState(17);
  const [paddingLeft, setPaddingLeft] = useState(17);

  const actions: { [key: string]: (value: any) => void } = {
    SearchIcon: setSearchIcon,
    SearchText: setSearchPlaceholder,
    FontText: setFontText,
    ColorText: setColorText,
    FontFamilyText: setFontFamilyText,
    FontWeightText: setFontWeightText,
  };

  /**
   * Sets values based on an array of objects.
   * @param {Array<{ type: string, value: any }>} paramArray - An array of objects containing a type and value to be set.
   * @returns {void}
   */
  const setValues = (paramArray: Array<{ type: string; value: any }>): void => {
    if (!paramArray?.length) return;

    paramArray.forEach(({ type, value }) => {
      const action = actions[type];
      if (action) action(value);
      else {
        console.error('Parameter not found', type);
      }
    });
    setFontSizeText(fontSizes[fontText]);
    setPaddingLeft(fontSizes[fontText]);
  };

  const fontSizes: { [index: string]: number } = {
    Small: 17,
    Medium: 21,
    Big: 31,
    ExtraLarge: 42,
  };

  useEffect(() => {
    setValues(props.paramArray);
  }, [props.paramArray]);

  const handleChange = (event: any) => {
    setTextFilter(event.target.value);
  };

  const renderSearcher = () => {
    return (
      <div className="searcher-container">
        <div>
          <img
            src={searchIcon}
            className="searcher-img"
            style={{ width: fontSizeText + 'px', height: fontSizeText + 'px' }}
            width={fontSizeText}
            height={fontSizeText}
          />
        </div>
        <input
          placeholder={searchPlaceholder}
          type="text"
          className="searcher-input"
          style={{
            fontFamily: fontFamilyText,
            fontSize: fontSizeText + 'px',
            color: colorText,
            fontWeight: fontWeightText,
            paddingLeft: paddingLeft + 9 + 'px',
            width: fontSizeText * 2 + 200 + 'px',
          }}
          onChange={handleChange}
        />
      </div>
    );
  };

  const renderSection = () => {
    let renderSection = props?.children?.map((child) => {
      return child.type === ComponentClassType.BlogSectionComponent ? (
        <BlogSectionComponent paramArray={child.parameters} textFilter={textFilter} key={'blog-section-' + child.id}>
          {child.children}
        </BlogSectionComponent>
      ) : null;
    });
    return renderSection;
  };
  return (
    <div className="blog" style={{ marginTop: '50px' }}>
      {searchIcon !== '' && searchPlaceholder !== '' ? renderSearcher() : null}
      <div>{renderSection()}</div>
    </div>
  );
};
export default BlogLayoutComponent;
