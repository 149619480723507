import React, { useEffect } from 'react';

interface RouteNotFoundProps{
  setLoading:(lang: boolean) => void;
}
const NotFound: React.FC<RouteNotFoundProps> = ({setLoading} : RouteNotFoundProps) => {
  // console.log('Page Not Found: ' + window.location.href + (props.source ? ' ' + props.source : ''));
  useEffect(()=>{
      setLoading(false);
    
  },[])
  return (
    <div className="notfound">
      <p className="margin-xs">
        There&apos;s nothing here, maybe the page you&apos;re looking for is not found or never existed. Try the home
        page:&nbsp;
        <a href="/">Home</a>
      </p>
    </div>
  );
};

export default NotFound;
