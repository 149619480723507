enum ComponentChangeType {
  Add = 'add',
  Delete = 'delete',
  UpdateIndex = 'updateIndex',
}
export { ComponentChangeType };

enum ViewChangeType {
  Add = 'add',
  Delete = 'delete',
  Rename = 'rename',
}
export { ViewChangeType };

enum ComponentClassType {
  Row = 'Row',
  TextBlock = 'TextBlock',
  TextInline = 'TextInline',
  TextBlockImage = 'TextBlockImage',
  ImageComponent = 'ImageComponent',
  FlipCard = 'FlipCard',
  RowFlipCard = 'RowFlipCard',
  DetailCard = 'DetailCard',
  LinkCard = 'LinkCard',
  ItemFooter = 'ItemFooter',
  FormData = 'FormData',
  FormField = 'FormField',
  MenuLink = 'MenuLink',
  DetailItem = 'DetailItem',
  MapView = 'MapView',
  CountryField = 'CountryField',
  Language = 'Language',
  LanguageItem = 'LanguageItem',
  Footer = 'Footer',
  ContactUsForm = 'ContactUsForm',
  CareersForm = 'CareersForm',
  Header = 'Header',
  Parent = 'Parent',
  Carousel = 'Carousel',
  CarouselItem = 'CarouselItem',
  Blog = 'Blog',
  Address = 'Address',
  Data = 'Data',
  Background = 'Background',
  FormComponent = 'FormComponent',
  InputSubmitComponent = 'InputSubmitComponent',
  InputRadioStylized = 'InputRadioStylized',
  InputTextComponent = 'InputTextComponent',
  InputTextAreaComponent = 'InputTextAreaComponent',
  HorizontalRule = 'HorizontalRule',
  HTMLRender = 'HTMLRender',
  ButtonComponent = 'ButtonComponent',
  InputCheckboxComponent = 'InputCheckboxComponent',
  ItemCheckbox = 'ItemCheckbox',
  InputFileComponent = 'InputFileComponent',
  ImageCollection = 'ImageCollection',
  BlogLayout = 'BlogLayout',
  ActivityComponent = 'ActivityComponent',
  BlogSectionComponent = 'BlogSectionComponent',
  FlexibleContainer = 'FlexibleContainer'
}
export { ComponentClassType };

enum ParameterChangeType {
  Add = 'add',
  Delete = 'delete',
  Update = 'update',
}
export { ParameterChangeType };

enum ChangeStatus {
  Waiting = 'Waiting',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Created = 'Created',
}
export { ChangeStatus };


enum TypePicker {
  Date = 'Date',
  Time = 'Time',
  DateTime = 'DateTime'
}
export {TypePicker}

enum FontType  {
  Title= 'Title',
  Text = 'Text'
}
export {FontType}

enum Alignment {
  left = 'left',
  center = 'center',
  right = 'right'
}
export {Alignment}

enum ObjectFitType {
  fill = 'fill',
  contain = 'contain',
  cover = 'cover',
  none = 'none'
}
export {ObjectFitType}