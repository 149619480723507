import React, { useState, useEffect } from 'react';
import '../../styles/sass/_flipCard.scss';
import ContentCard from './ContentCard';
import { ParameterModel } from '../../interfaces/models';
import ReactCardFlip from '../../ReactCardFlip';

interface FlipCardProps {
  paramArray: ParameterModel[];
  windowSize: string;
  id: string;
}

const LinkCard: React.FC<FlipCardProps> = (props: FlipCardProps) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [url, setUrl] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [size, setSize] = useState('');
  const [newTab, setNewTab] = useState(false);

  useEffect(() => {
    console.log("Parameters ",props.paramArray);
    const setValues = () => {
      setNewTab(false);
      if (props?.paramArray?.length) {
        props.paramArray.forEach((param, index) => {
          if (param.type === 'Title') {
            setTitle(param.value);
          }

          if (param.type === 'URL' || param.type === 'LinkUrl') {
            setUrl(param.value);
          }

          if (param.type === 'ImageUrl') {
            setImageUrl(param.value);
          }

          if (param.type === 'Size') {
            setSize(param.value);
          }

          if (param.type === 'Text') {
            setDescription(param.value);
          }

          if (param.type === 'LinkNewTab' && param.value && param.value.length > 0 && param.value !== 'false') {
            setNewTab(true);
          }
        });
      }
    };

    setValues();
  }, [props]);

  const redirectUrl = () => {
    document.getElementById('linkcard-click-area' + url)?.click();
    let elementPost = document.getElementById('linkcard-click-area-click' + url);
    if (elementPost) {
      elementPost.id = 'linkcard-click-area' + url;
    }
  };
  function print() {}

  return (
    <div className={size === 'BIG' ? 'div-parent' : size === 'MEDIUM' ? 'div-parent1' : 'div-parent2'}>
      <ReactCardFlip isFlipped={false}>
        <div key="front" className="div-front" style={{ cursor: 'pointer' }} onClick={redirectUrl}>
          <ContentCard
            id={props.id}
            windowSize={props.windowSize}
            isLink={true}
            isLinkNewTab={newTab}
            func={print}
            text={title}
            imageUrl={imageUrl}
            cardSize={size}
            linkUrl={url}
            description={description}
            imageMobileUrl=""
          />
        </div>
        <div key="back" />
      </ReactCardFlip>
    </div>
  );
};

export default LinkCard;
