import React from 'react';
import '../../styles/sass/_background.scss';
import { Spring } from 'react-spring/renderprops';
import VisibilitySensor from 'react-visibility-sensor';

const h2Styles = {};
function randomIntFromInterval(min: number, max: number) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}
interface BlockProps {
  header: boolean;
  type: string;
}

const Block: React.FC<BlockProps> = (props: BlockProps) => {
  let value: number = randomIntFromInterval(0, 3);
  let value2: number = randomIntFromInterval(0, 5);
  let listColor: string[] = ['block-color-one', 'block-color-two', 'block-color-three', 'block-color-four'];
  let listTop: string[] = [' top', ' top2', ' top3', ' top4', ' top5', ' top6'];

  let style: string =
    props.type === 'lg'
      ? `block3 block-1x ${listColor[value]}`
      : props.type === 'md'
      ? `block3 block-2x ${listColor[value]}`
      : `block3 block-3x ${listColor[value]}`;

  if (props.header) {
    style += listTop[value2];
  }
  return (
    <VisibilitySensor partialVisibility>
      {({ isVisible } : {isVisible : boolean} ) => (
        <Spring
          delay={10}
          to={{
            opacity: isVisible ? 0.1 : 0,
            transform: isVisible ? 'translateY(0)' : 'translateY(1px)',
          }}
        >
          {(props) => <div className={style} style={{ ...h2Styles, ...props }} />}
        </Spring>
      )}
    </VisibilitySensor>
  );
};

export default Block;
