import React from 'react';
import '../styles/sass/_textblockimage.scss';
import { Grid } from '@mui/material';
import slugify from 'react-slugify';
import PictureComponent from './PictureComponent';

interface TextBlockImageProps {
  title?: string;
  text?: string;
  imageUrl?: string;
  imageWidth?: string;
  imageHeight?: string;
  theme?: string;
  size?: string;
  breakLine?: string;
  fontTitle?: string;
  colorTitle?: string;
  fontText?: string;
  colorText?: string;
  hideTitle?: string;
  imageMobileUrl?: string;
}

/* eslint-disable complexity */
const TextBlockImage: React.FC<TextBlockImageProps> = ({
  title,
  text,
  imageUrl,
  imageWidth,
  imageHeight,
  theme,
  size,
  breakLine,
  fontTitle,
  fontText,
  colorText,
  colorTitle,
  hideTitle,
  imageMobileUrl,
}: TextBlockImageProps) => {
  const small = {
    margins: { lg: 1, md: 1 },
    content: { lg: 10, md: 10 },
  };

  const normal = {
    margins: {},
    content: {},
  };
  let configMargin = size === 'small' ? small.margins : normal.margins;
  let configContent = size === 'small' ? small.content : normal.content;

  const renderTitle = () => {
    if (hideTitle === 'true') {
      return null;
    } else {
      if (title && title !== '' && fontTitle !== '' && colorTitle !== '') {
        return (
          <h3
            className="title"
            style={{ fontSize: `${fontTitle}px`, color: colorTitle }}
            dangerouslySetInnerHTML={{ __html: title as string }}
          />
        );
      } else if (title && title !== '' && fontTitle !== '' && colorTitle === '') {
        return (
          <h3
            className="title"
            style={{ fontSize: `${fontTitle}px` }}
            dangerouslySetInnerHTML={{ __html: title as string }}
          />
        );
      } else if (title && title !== '' && fontTitle === '' && colorTitle !== '') {
        return (
          <h3 className="title" style={{ color: colorTitle }} dangerouslySetInnerHTML={{ __html: title as string }} />
        );
      } else if (title && title !== '' && fontTitle === '' && colorTitle === '') {
        return <h3 className="title" dangerouslySetInnerHTML={{ __html: title as string }} />;
      } else {
        return null;
      }
    }
  };

  const renderText = () => {
    if (text && text !== '' && fontText !== '' && colorText !== '') {
      return (
        <p
          className="description"
          style={{ fontSize: `${fontText}px`, color: colorText }}
          dangerouslySetInnerHTML={{ __html: text as string }}
        />
      );
    } else if (text && text !== '' && fontText !== '' && colorText === '') {
      return (
        <p
          className="description"
          style={{ fontSize: `${fontText}px` }}
          dangerouslySetInnerHTML={{ __html: text as string }}
        />
      );
    } else if (text && text !== '' && fontText === '' && colorText !== '') {
      return (
        <p className="description" style={{ color: colorText }} dangerouslySetInnerHTML={{ __html: text as string }} />
      );
    } else if (text && text !== '' && fontText === '' && colorText === '') {
      return <p className="description" dangerouslySetInnerHTML={{ __html: text as string }} />;
    } else {
      return null;
    }
  };

  return (
    <div className={`text-block-image ${theme}`} id={slugify(title)}>
      <Grid container>
        <Grid item {...configMargin}>
          &nbsp;
        </Grid>
        <Grid item {...configContent}>
          {theme !== 'blue' ? (
            <Grid container className="text-block-image-grid">
              {imageUrl ? (
                <Grid item lg={2} md={3} sm={4} xs={12}>
                  <PictureComponent imageUrl={imageUrl} imageWidth={imageWidth} imageMobileUrl={imageMobileUrl}>
                    <img
                      src={imageUrl}
                      alt={'Image of ' + title + ' in text block'}
                      width={imageWidth}
                      height={imageHeight}
                      loading='lazy'
                    />
                  </PictureComponent>
                </Grid>
              ) : (
                <Grid item lg={1} md={2} sm={3} xs={10} />
              )}
              <Grid item lg={10} md={9} sm={8} xs={12}>
                <div className="right">
                  <div className="center-info">
                    {renderTitle()}
                    {renderText()}
                  </div>
                </div>
              </Grid>
            </Grid>
          ) : (
            <Grid container className="text-block-image-grid">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div className="right">
                  <div className="center-info">
                    {hideTitle === 'true' ? null : <h3 className="title"> {title} </h3>}
                    <p className="description"> {text} </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item {...configMargin}>
          &nbsp;
        </Grid>
      </Grid>
    </div>
  );
};

export default TextBlockImage;
