import React, { useState, useEffect } from 'react';
import '../../styles/sass/_flipCard.scss';
import ContentCard from './ContentCard';
import ContentCardI from './ContentCardI';
import { ParameterModel } from '../../interfaces/models';
import ReactCardFlip from '../../ReactCardFlip';
import slugify from 'react-slugify';
import { useMediaQuery } from '@mui/material';

interface FlipCardProps {
  paramArray: ParameterModel[];
  isOpen?: boolean;
  onClickArea?: Function;
  windowSize?: string;
  id: string;
  isOpenState?: boolean | undefined;
}

const FlipCard: React.FC<FlipCardProps> = ({ paramArray, onClickArea, id, windowSize, isOpenState }: FlipCardProps) => {
  const iniState = false;
  const [isFlipped, setFlipped] = useState(iniState);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [imageWidth, setImageWidth] = useState('');
  const [imageHeight, setImageHeight] = useState('');
  const [linkUrl, setLinkUrl] = useState('');
  const [linkText, setLinkText] = useState('');
  const [size, setSize] = useState('');
  const [svgURL, setSvgURL] = useState('');

   
  const [ImageMobileUrl,setImageMobileUrl] = useState('');
  const [ImageWidthMobile, setImageWidthMobile] = useState('');
  const [ImageHeightMobile, setImageHeightMobile] = useState('');


  useEffect(() => {
    
    const setValues = () => {
      if (paramArray?.length) {
        paramArray.forEach((param) => {
          switch (param.type) {
            case 'Title':
              setTitle(param.value);
              break;
            case 'Text':
              setDescription(param.value);
              break;
            case 'LinkText':
              setLinkText(param.value);
              break;
            case 'LinkUrl':
              setLinkUrl(param.value);
              break;
            case 'Size':
              setSize(param.value);
              break;
            case 'SvgUrl':
              setSvgURL(param.value);
              break;
            case 'ImageUrl': {
              setImageUrl(param.value);
              break;
            }
            case 'ImageWidth': {
              setImageWidth(param.value);
              break;
            }
            case 'ImageHeight': {
              setImageHeight(param.value);
              break;
            }
            case 'ImageMobileUrl': {
              setImageMobileUrl(param.value);
              break;
            }
            case 'ImageWidthMobile': {
              setImageWidthMobile(param.value ? param.value : imageWidth);
              break;
            }
            case 'ImageHeightMobile': {
              setImageHeightMobile(param.value ? param.value : imageHeight);
              break;
            }
            default:
              break;
          }
        });
      }
    };
    
    setValues();
  }, [paramArray]);

  useEffect(()=> {

    if(window.innerWidth <= 414){
      if(ImageMobileUrl !== ''){
        setImageUrl(ImageMobileUrl);
      }
    }
  },[imageUrl])

  const matchesSize = useMediaQuery('(max-width:900px)');

  useEffect(()=> {
    if(window.innerWidth <= 414 && ImageWidthMobile && ImageHeightMobile){
      setImageWidth(ImageWidthMobile ? structuredClone(ImageWidthMobile) : structuredClone(imageWidth));
      setImageHeight(ImageHeightMobile ? structuredClone(ImageHeightMobile) : structuredClone(imageHeight));  
    }
  },[matchesSize])

  useEffect(()=> {
    if(window.innerWidth <= 414 && ImageWidthMobile ){
      setImageWidth(ImageWidthMobile ? structuredClone(ImageWidthMobile) : structuredClone(imageWidth));
    }
  },[imageWidth]);

  useEffect(()=> {
    if(window.innerWidth <= 414 &&  ImageHeightMobile){
      setImageHeight(ImageHeightMobile ? structuredClone(ImageHeightMobile) : structuredClone(imageHeight));  
    }
  },[imageHeight]);

  const handleClickArea = (e: Event) => {
    if (onClickArea) {
      onClickArea(e);
    }
  };

  const getTypeBgSize = () => {
    let typeBgSize = 'cover';
    let imageName = imageUrl.toLocaleLowerCase();

    if (imageName.includes('.svg')) {
      typeBgSize = 'auto';
    } else if (
      imageName.includes('.gif') ||
      imageName.includes('.jpg') ||
      imageName.includes('.jpeg') ||
      imageName.includes('.png') ||
      imageName.includes('.webp')
    ) {
      typeBgSize = 'contain';
    }
    return typeBgSize;
  };

  return (
    <div id={slugify(title)} style={{ cursor: 'pointer' }} >
      <ReactCardFlip isFlipped={isFlipped}>
        <div key="front" className="div-front">
          <ContentCard
            id={id}
            text={title}
            imageUrl={imageUrl}
            imageWidth={imageWidth}
            imageHeight={imageHeight}
            func={() => setFlipped(true)}
            onClickArea={(e: Event) => handleClickArea(e)}
            cardSize={size}
            windowSize={windowSize}
            isOpenState={isOpenState}
            bgSizeCard={getTypeBgSize()}
            imageMobileUrl={ImageMobileUrl}
          />
        </div>
        <div key="back">
          <ContentCardI
            id={id}
            text={description}
            func={() => setFlipped(false)}
            onClickArea={(e: Event) => handleClickArea(e)}
            size={size}
            linkUrl={linkUrl}
            linkText={linkText}
            isOpenState={isOpenState}
          />
        </div>
      </ReactCardFlip>
    </div>
  );
};

export default FlipCard;
