/* eslint-disable complexity */
import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Grid, { GridSize, GridProps, GridSpacing } from '@mui/material/Grid';
import { getGenericParams } from '../../utils/utils';
import FlipCard from './FlipCard';
import LinkCard from './LinkCard';
import DetailCard from '../DetailCard/DetailCard';
import DataCard from '../DataCard';
import TextBlock from '../TextBlock';
import RowComponent from '../RowComponent';
import TextBlockImage from '../TextBlockImage';
import MapViewComponent from '../MapViewComponent';
import Address from '../Address';
import { ComponentViewModel, ParameterModel } from '../../interfaces/models';
import { ComponentClassType } from '../../interfaces/enums';
import ImageComponent from '../ImageComponent/ImageComponent';
import TextInline from '../TextInline';
import HTMLRender from '../HTMLRender/HTMLRender';
import ButtonComponent from '../ButtonComponent';
import BlogLayoutComponent from '../Blog/BlogLayoutComponent';
import ActivityComponent from '../ActivityComponent';
import DetailItem from '../DetailCard/DetailItem';
import useWindowDimensions from '../Background/WindowSize';
import { useMediaQuery, useTheme } from '@mui/material';
import { isEqual } from 'lodash';
import slugify from 'react-slugify';
import { Padding } from '@mui/icons-material';

interface RowProps {
    Columns?: GridSize;
    Components?: ComponentViewModel[];
    parameters: ParameterModel[];
    isOpen: boolean;
    Row: boolean;
    IsFooter?: boolean;
    Justify?: string | undefined;
    RowInRow?: boolean;
    componentKey: string;
}

interface RowFlipCard {
    size: string;
    Component: ComponentViewModel;
}

const oneColumn: GridProps = {
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
};

const twoColumns: GridProps = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 6,
};

const threeColumns: GridProps = {
    xs: 12,
    sm: 6,
    md: 4,
    lg: 4,
};

const fourColumns: GridProps = {
    xs: 12,
    sm: 6,
    md: 4,
    lg: 3,
};

const autoColumns: GridProps = {
    xs: 'auto',
    sm: 'auto',
    md: 'auto',
    lg: 'auto',
};

const RowFlipCard: React.FC<RowProps> = (rowProps: RowProps) => {
    const [columnConfig, setColumnConfig] = useState(oneColumn);
    const [BackgroundColor, setBackgroundColor] = useState('');
    const [padding, setPadding] = useState('');
    const [margin, setMargin] = useState('');
    const [BorderRadius, setBorderRadius] = useState('');
    const [IsCopyright, setIsCopyright] = useState(false);
    const [AlignmentText, setAlignmentText] = useState('');
    const [Lg, setLg] = useState<GridSize>(0);
    const [Xs, setXs] = useState<GridSize>(0);
    const windowSize = useWindowDimensions();
    const matches = useMediaQuery('(max-width:400px)');
    const [isFooter, setIsFooter] = useState(false);
    const [addressStyle, setAddressStyle] = useState({});

    useEffect(() => {
        let emptyObject = {};
        if (rowProps.IsFooter && !isEqual(emptyObject, rowProps.IsFooter)) {
            setIsFooter(rowProps.IsFooter);
        }
    }, [rowProps.IsFooter]);

    useEffect(() => {
        let newColumnConfig =
            rowProps.Columns === 1
                ? oneColumn
                : rowProps.Columns === 2
                    ? twoColumns
                    : rowProps.Columns === 3
                        ? threeColumns
                        : rowProps.Columns === 4
                            ? fourColumns
                            : autoColumns;

        setColumnConfig(newColumnConfig);
    }, [rowProps.isOpen, rowProps.Columns]);

    useEffect(() => {
        if (rowProps.Components) {
            if (rowProps.Components[0] && rowProps.Components[0].type === 'Row') {
                rowProps.Components[0].parameters.forEach((parameter) => {
                    if (parameter.type === 'Margin') {
                        setMargin(parameter.value);
                    } else if (parameter.type === 'BackgroundColor') {
                        setBackgroundColor(parameter.value);
                    } else if (parameter.type === 'Padding') {
                        setPadding(parameter.value);
                    } else if (parameter.type === 'BorderRadius') {
                        setBorderRadius(parameter.value);
                    }
                });
            }
        }

        rowProps.parameters.forEach((parameter) => {
            if (parameter.type === 'IsCopyright') {
                setIsCopyright(parameter.value.toLowerCase() === 'true' ? true : false);
            }
        });

        if (rowProps.Row) {
            rowProps.parameters.forEach((parameter) => {
                switch (parameter.type) {
                    case 'Margin':
                        setMargin(parameter.value);
                        break;
                    case 'BackgroundColor':
                        setBackgroundColor(parameter.value);
                        break;
                    case 'Padding':
                        setPadding(parameter.value);
                        break;
                    case 'BorderRadius':
                        setBorderRadius(parameter.value);
                        break;
                    case 'AlignmentText':
                        setAlignmentText(parameter.value);
                        break;
                    case 'Lg':
                        try {
                            let parameterValue = parseInt(parameter.value, 10);
                            setLg(parameterValue);
                        } catch (error) {
                            console.error(error);
                        }
                        break;
                    case 'Xs':
                        try {
                            let parameterValue = parseInt(parameter.value, 10);
                            setXs(parameterValue);
                        } catch (error) {
                            console.error(error);
                        }
                        break;
                    default:
                        break;
                }
            });
        }
    }, []);

    if (rowProps.Row) {
        /**
         * Returns the justify-content value based on the components in rowProps
         * and the AlignmentText prop.
         * @returns {string} The justify-content value.
         */
        const getJustifyContent = () => {
            const { Components } = rowProps;

            // Check if row contains an ImageComponent.
            const containImageComponent = Components?.findIndex(
                (component) => component.type === ComponentClassType.ImageComponent,
            );

            // Check if row contains a TextInline component.
            const containTextInlineComponent = Components?.findIndex(
                (component) => component.type === ComponentClassType.TextInline,
            );

            // Get the DetailCard component, if it exists.
            const componentDetail = Components?.find((component) => component.type === ComponentClassType.DetailCard);

            // Check if row contains a ButtonComponent.
            const componentButton = Components?.findIndex(
                (component) => component.type === ComponentClassType.ButtonComponent,
            );

            // Check the AlignmentText prop to see if it's left, right, or center.
            if (AlignmentText) {
                const alignmentTextLower = AlignmentText.toLowerCase();
                if (alignmentTextLower === 'left') {
                    return 'start';
                }
                if (alignmentTextLower === 'right') {
                    return 'end';
                }
                if (alignmentTextLower === 'center') {
                    return 'center';
                }
            }

            // If the row contains an ImageComponent or ButtonComponent, center the content.
            if (containImageComponent !== -1 || componentButton !== -1) {
                return 'center';
            }

            // If the row contains a TextInline component, evenly space the content.
            if (containTextInlineComponent !== -1) {
                return 'space-evenly';
            }

            // If the row contains a DetailCard component, check the WithoutImage parameter.
            if (componentDetail) {
                const parameterWithoutImage = componentDetail.parameters.find((parameter) => parameter.type === 'WithoutImage');
                if (parameterWithoutImage) {
                    return parameterWithoutImage.value.toLowerCase() === 'true' ? 'space-evenly' : '';
                }
            }

            // If none of the above conditions are met, return an empty string.
            return '';
        };

        const isCardWithoutImage = (numberAux: GridSpacing) => {
            let component = rowProps.Components?.find((comp) => {
                return comp.type === ComponentClassType.DetailCard;
            });

            if (component) {
                let parameterWithoutImage = component.parameters.find((parameter) => {
                    return parameter.type === 'WithoutImage';
                });

                if (parameterWithoutImage) {
                    return 5;
                }
            }
            return numberAux;
        };
        const theme = useTheme();
        const matchMobile = useMediaQuery(theme.breakpoints.down('md'));
        
        const getPadding = () => {
            if (isFooter && matchMobile) return '0';
            let getTextInline = rowProps.Components?.find((comp) => {return comp.type === ComponentClassType.TextInline});
            if (getTextInline) {
                let parameterIsCopy = getTextInline.parameters.find((param) => {
                    return param.type === 'IsCopyright';
                });

                return parameterIsCopy?.value.toLowerCase() === 'true' ? '' : padding;
            }

            
            return padding;
        };

        const getAlignItems = () =>
            rowProps.RowInRow ? 'center' : Lg === 0 ? (windowSize.width > 780 ? 'end' : 'center') : '';
        const getAddressStyle = () => {
            return matchMobile
                ? {
                    width: '100%',
                }
                : {
                    width: Lg === 12 ? 'calc(100% - 24px)' : '',
                    paddingTop: '24px',
                    margin: margin ? margin : '0px 0px 0px 40px'
                };
        };

        useEffect(()=> {
            setAddressStyle(getAddressStyle());
        },[margin])

        
        
        return (
            <Container
                className="row-flip-card-container"
                fixed
                style={{
                    margin: IsCopyright ? '0' : '',
                    display: 'flex',
                    flexDirection: 'column',
                    flex: '1 1 auto',
                    padding: getPadding(),
                    maxWidth: 'none',
                    backgroundColor: rowProps.RowInRow && BackgroundColor !== '' ? BackgroundColor : '',
                    borderRadius: rowProps.RowInRow && BorderRadius !== '' ? BorderRadius : '',
                }}
            >
                <Grid
                    container
                    className={
                        'row-flip-card-isrow-grid-container--child' + (rowProps.Justify ? rowProps.Justify : getJustifyContent())
                    }
                    spacing={isCardWithoutImage(3)}
                    style={{
                        display: 'flex',
                        flexDirection: rowProps.Row ? 'row' : 'column',
                        justifyContent: rowProps.Justify ? rowProps.Justify : getJustifyContent(),
                        height: '100%',
                        alignItems: getAlignItems(),
                        alignContent: Lg === 0 ? (windowSize.width > 780 ? 'end' : 'center') : '',
                        padding: (isFooter && matchMobile) ? '0' : (rowProps.RowInRow && padding !== '' ? padding : ''),
                        width: matchMobile ? '100%' : '',
                        margin: matchMobile ? '0px' : '',
                    }}
                >
                    {rowProps.Components
                        ? // eslint-disable-next-line complexity
                        rowProps.Components.map((childComponent, index) => {
                            const componentViewModel: ComponentViewModel = {
                                parameters: childComponent.parameters,
                                type: ComponentClassType.TextBlock,
                                children: [],
                                key: '',
                                index: 0,
                                id: '',
                            };

                            const genericParams = getGenericParams(componentViewModel);

                            return childComponent.type === ComponentClassType.TextInline ? (
                                <TextInline paramArray={childComponent.parameters} isFooterComponent={isFooter} components={childComponent.children} />
                            ) : childComponent.type === ComponentClassType.ButtonComponent ? (
                                <div style={{ margin: rowProps.Row ? margin : genericParams.Margin }}>
                                    <ButtonComponent {...componentViewModel} />
                                </div>
                            ) : childComponent.type === ComponentClassType.Address ? (
                                <div style= {addressStyle}>
                                    <Address
                                        {...rowProps}
                                        contact={genericParams.Contact}
                                        job={genericParams.Job}
                                        phone={genericParams.Phone}
                                        email={genericParams.Email}
                                        address={genericParams.Address}
                                        address1={genericParams.Address1}
                                        address2={genericParams.Address2}
                                        city={genericParams.City}
                                        country={genericParams.Country}
                                        link={genericParams.Link}
                                        iframe={genericParams.Iframe}
                                        color={genericParams.ColorText}
                                        fontFamily={genericParams.FontFamilyText}
                                        fontSize={genericParams.FontText}
                                        margin={genericParams.Margin}
                                    />
                                </div>
                            ) : Lg !== 0 && Xs !== 0 ? (
                                <Grid
                                    className={'row-flip-card-isrow-child-grid-' + componentViewModel.key + '-' + rowProps.componentKey}
                                    key={index}
                                    item
                                    {...columnConfig}
                                    style={{
                                        maxWidth: matches ? 'calc(100vw - 48px)' : '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        margin: rowProps.Row ? margin : genericParams.Margin,
                                        backgroundColor: rowProps.Row ? BackgroundColor : genericParams.BackgroundColor,
                                        padding:  rowProps.Row ? padding : genericParams.Padding,
                                        borderRadius: rowProps.Row ? BorderRadius : genericParams.BorderRadius,
                                        width:
                                            (childComponent && childComponent.type === ComponentClassType.BlogLayout) ||
                                                rowProps.Components?.length === 1
                                                ? '100%'
                                                : '',
                                    }}
                                    lg={Lg}
                                    xs={Xs}
                                >
                                    {getChildComponent()}
                                </Grid>
                            ) : (
                                <Grid
                                    className={
                                        'row-flip-card-isrow-child-grid-' + slugify(componentViewModel.id) + '-' + rowProps.componentKey
                                    }
                                    key={index}
                                    item
                                    {...columnConfig}
                                    style={{
                                        maxWidth: matches ? 'calc(100vw - 48px)' : '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        margin: rowProps.Row ? margin : genericParams.Margin,
                                        backgroundColor: rowProps.Row ? BackgroundColor : genericParams.BackgroundColor,
                                        padding: (rowProps.Row ? padding : genericParams.Padding),
                                        borderRadius: rowProps.Row ? BorderRadius : genericParams.BorderRadius,
                                        paddingLeft: (isFooter && matchMobile) ? '0' : '',
                                        width:
                                            (childComponent && childComponent.type === ComponentClassType.BlogLayout) ||
                                                rowProps.Components?.length === 1
                                                ? '100%'
                                                : '',
                                    }}
                                >
                                    {getChildComponent()}
                                </Grid>
                            );

                            function getChildComponent(): React.ReactNode {
                                return childComponent.type === ComponentClassType.DetailCard ? (
                                    <DetailCard
                                        id={'detail-card-' + (childComponent.id ? childComponent.id : childComponent.key)}
                                        {...genericParams}
                                        ComponentList={childComponent.children}
                                        ParamArray={childComponent.parameters}
                                        isOpen={rowProps.isOpen}
                                        key={childComponent.id}
                                    />
                                ) : childComponent.type === ComponentClassType.Data ? (
                                    <DataCard paramArray={childComponent.parameters} />
                                ) : childComponent.type === ComponentClassType.TextBlock ? (
                                    <RowComponent>
                                        <TextBlock paramArray={childComponent.parameters} />
                                    </RowComponent>
                                ) : childComponent.type === ComponentClassType.Row ? (
                                    <RowFlipCard
                                        parameters={childComponent.parameters}
                                        Columns={getGenericParams(childComponent).Columns}
                                        Components={childComponent.children}
                                        isOpen={rowProps.isOpen}
                                        Row={true}
                                        Justify={windowSize.width > 780 ? undefined : 'center'}
                                        componentKey={childComponent.key + '-childrow'}
                                        RowInRow={true}
                                        IsFooter={isFooter}
                                    />
                                ) : childComponent.type === ComponentClassType.ImageComponent ? (
                                    <RowComponent component={childComponent} isFooter={rowProps.IsFooter} >
                                        <ImageComponent {...componentViewModel} params={childComponent.parameters} isFooter={rowProps.IsFooter} />
                                    </RowComponent>
                                ) : childComponent.type === ComponentClassType.ButtonComponent ? (
                                    <RowComponent>
                                        <ButtonComponent {...componentViewModel} />
                                    </RowComponent>
                                ) : childComponent.type === ComponentClassType.TextBlockImage ? (
                                    <RowComponent>
                                        <TextBlockImage
                                            {...componentViewModel}
                                            title={genericParams.Title}
                                            text={genericParams.Text}
                                            imageUrl={genericParams.ImageUrl}
                                            theme={genericParams.Theme}
                                            size={genericParams.Size}
                                            breakLine={genericParams.BreakLine}
                                            fontTitle={genericParams.FontTitle}
                                            colorTitle={genericParams.ColorTitle}
                                            fontText={genericParams.FontText}
                                            colorText={genericParams.ColorText}
                                            imageMobileUrl={genericParams.ImageMobileUrl}
                                        />
                                    </RowComponent>
                                ) : childComponent.type === ComponentClassType.BlogLayout ? (
                                    <RowComponent component={childComponent}>
                                        <BlogLayoutComponent paramArray={childComponent.parameters}>
                                            {childComponent.children}
                                        </BlogLayoutComponent>
                                    </RowComponent>
                                ) : childComponent.type === ComponentClassType.TextInline ? (
                                    <RowComponent>
                                        <TextInline paramArray={childComponent.parameters} isFooterComponent={isFooter} components={componentViewModel.children}/>
                                    </RowComponent>
                                ) : childComponent.type === ComponentClassType.HTMLRender ? (
                                    <RowComponent isFooter={rowProps.IsFooter} >
                                        <HTMLRender paramArray={childComponent.parameters} />
                                    </RowComponent>
                                ) : childComponent.type === ComponentClassType.DetailItem ? (
                                    <RowComponent>
                                        <DetailItem
                                            Title={genericParams.Title}
                                            FontFamilyTitle={genericParams.FontFamilyTitle}
                                            ColorTitle={genericParams.ColorTitle}
                                            ImageUrl={genericParams.ImageUrl}
                                            ImageWidth={genericParams.ImageWidth}
                                            ImageHeight={genericParams.ImageHeight}
                                            FontTitle={genericParams.FontTitle}
                                            link={genericParams.Link}
                                            ImageMobileUrl={genericParams.ImageMobileUrl}
                                            ImageHeightMobile={genericParams.ImageHeightMobile}
                                            ImageWidthMobile={genericParams.ImageWidthMobile}
                                        />
                                    </RowComponent>
                                ) : childComponent.type === ComponentClassType.ActivityComponent ? (
                                    <RowComponent>
                                        <ActivityComponent
                                            title={genericParams.Title}
                                            text={genericParams.Text}
                                            imageUrl={genericParams.ImageUrl}
                                            imageWidth={genericParams.ImageWidth}
                                            imageHeight={genericParams.ImageHeight}
                                            fontFamilyText={genericParams.FontFamilyText}
                                            fontFamilyTitle={genericParams.FontFamilyTitle}
                                            colorText={genericParams.ColorText}
                                            colorTitle={genericParams.ColorTitle}
                                            fontText="Medium"
                                            fontTitle="Big"
                                            cost={genericParams.Cost}
                                            place={genericParams.Place}
                                            schedule={genericParams.Schedule}
                                            date={genericParams.Date}
                                            flexibleFontSizeText={genericParams.FlexibleFontSizeText}
                                            flexibleFontSizeTitle={genericParams.FlexibleFontSizeTitle}
                                            alignmentText={genericParams.AlignmentText}
                                            alignmentTitle={genericParams.AlignmentTitle}
                                            margin={genericParams.Margin}
                                            fontWeightTitle={genericParams.FontWeightTitle}
                                            fontWeightText={genericParams.FontWeightText}
                                            imageHeightMobile={genericParams.ImageHeightMobile}
                                            imageWidthMobile={genericParams.ImageWidthMobile}
                                            imageMobileUrl={genericParams.ImageMobileUrl}
                                        >
                                            {childComponent.children}
                                        </ActivityComponent>
                                    </RowComponent>
                                ) : childComponent.type === ComponentClassType.Address ? (
                                    <RowComponent>
                                        <Address
                                            {...rowProps}
                                            contact={genericParams.Contact}
                                            job={genericParams.Job}
                                            phone={genericParams.Phone}
                                            email={genericParams.Email}
                                            address={genericParams.Address}
                                            address1={genericParams.Address1}
                                            address2={genericParams.Address2}
                                            city={genericParams.City}
                                            country={genericParams.Country}
                                            link={genericParams.Link}
                                            iframe={genericParams.Iframe}
                                            color={genericParams.ColorText}
                                            fontFamily={genericParams.FontFamilyText}
                                            fontSize={genericParams.FontText}
                                            margin={genericParams.Margin}
                                        />
                                    </RowComponent>
                                ) : childComponent.type === ComponentClassType.MapView ? (
                                    <MapViewComponent
                                        theme={genericParams.Theme}
                                        zoom={13}
                                        lat={-17.3815909}
                                        long={-66.1550717}
                                        popupMessage={!genericParams.Text ? 'Visit us here' : genericParams.Text}
                                    />
                                ) : null;
                            }
                        })
                        : null}
                </Grid>
            </Container>
        );
    } else {
        const allComponents = rowProps.Components;
        if (allComponents && allComponents.length > 0) {
            allComponents.forEach((aComponent: any) => {
                aComponent.genericParameters = getGenericParams(aComponent);
                let foundSize: any = aComponent.genericParameters.Size;
                if (!foundSize) {
                    foundSize = Number(foundSize);
                } else {
                    foundSize = 1;
                }
                aComponent.genericParameters.Size = foundSize;
            });

            return (
                <Container fixed className="row-flipcard-container-notrow">
                    <Grid
                        container
                        spacing={1}
                        alignItems="stretch"
                        justifyContent="center"
                        className="row-flipcard-grid-container-notrow"
                    >
                        {allComponents.map((component, index) => {
                            let size = getGenericParams(component).Size;
                            let uiSize = '';
                            switch (size) {
                                case 'SMALL':
                                    uiSize = 'xs';
                                    break;
                                case 'MEDIUM':
                                    uiSize = 'md';
                                    break;
                                case 'BIG':
                                    uiSize = 'lg';
                                    break;
                                default:
                                    break;
                            }

                            let gridConfigBig: GridProps;
                            let gridConfigMedium: GridProps;
                            let gridConfigSmall: GridProps;
                            let gridConfigSTD: GridProps;

                            if (uiSize === 'lg') {
                                gridConfigBig = {
                                    xs: 12,
                                    md: 12,
                                    lg: 6,
                                };
                                gridConfigMedium = {
                                    xs: 12,
                                    md: 8,
                                    lg: 4,
                                };
                                gridConfigSmall = {
                                    xs: 12,
                                    md: 4,
                                    lg: 2,
                                };
                                gridConfigSTD = {
                                    xs: 12,
                                    md: 2,
                                    lg: 2,
                                };
                            } else if (uiSize === 'md') {
                                gridConfigBig = {
                                    xs: 12,
                                    md: 12,
                                    lg: 6,
                                };
                                gridConfigMedium = {
                                    xs: 8,
                                    md: 8,
                                    lg: 4,
                                };
                                gridConfigSmall = {
                                    xs: 4,
                                    md: 4,
                                    lg: 2,
                                };
                                gridConfigSTD = {
                                    xs: 12,
                                    md: 3,
                                    lg: 2,
                                };
                            } else {
                                gridConfigBig = {
                                    xs: 12,
                                    md: 12,
                                    lg: 6,
                                };
                                gridConfigMedium = {
                                    xs: 12,
                                    md: 8,
                                    lg: 4,
                                };
                                gridConfigSmall = {
                                    xs: 12,
                                    md: 4,
                                    lg: 2,
                                };
                                gridConfigSTD = {
                                    xs: 12,
                                    md: 3,
                                    lg: 2,
                                };
                            }

                            let gridConfig: GridProps =
                                size === 'BIG'
                                    ? gridConfigBig
                                    : size === 'MEDIUM'
                                        ? gridConfigMedium
                                        : size === 'SMALL'
                                            ? gridConfigSmall
                                            : gridConfigSTD;

                            return (
                                <Grid key={index} item {...gridConfig}>
                                    {component.type === ComponentClassType.FlipCard ? (
                                        <FlipCard
                                            windowSize={uiSize}
                                            paramArray={component.parameters}
                                            id={component.id ? component.id : component.key}
                                        />
                                    ) : component.type === ComponentClassType.LinkCard ? (
                                        <LinkCard
                                            id={component.id ? component.id : component.key}
                                            windowSize={uiSize}
                                            paramArray={component.parameters}
                                        />
                                    ) : null}
                                </Grid>
                            );
                        })}
                    </Grid>
                </Container>
            );
        } else {
            return null;
        }
    }
};

export default RowFlipCard;
