import React, { useEffect, useState } from 'react';
import '../../styles/sass/nav/_submenucontainer.scss';
import {  Container, Grid, Box } from '@mui/material';
import { LinkParam } from '../../interfaces/interfaces';
import SubMenuList from './SubMenuList';

interface SubMenuContainerProps {
  name?: string;
  lastLinkHovered: string;
  isShowSubMenu?: boolean;
  itemList: LinkParam[];
  spaceOnNav: number;
  leftOffsets: (number | undefined)[];
  colorText: string;
  backgroundColorParameters: string[];
  FontFamilyText: string;
  setIndexTitle:(index: number) => void;
}

const SubMenuContainer: React.FC<SubMenuContainerProps> = ({
  name,
  lastLinkHovered,
  isShowSubMenu,
  itemList,
  spaceOnNav,
  leftOffsets,
  colorText,
  backgroundColorParameters,
  FontFamilyText,
  setIndexTitle
}: SubMenuContainerProps) => {
  const [leftOffset, setLeftOffset] = useState<number>(0);
  const [subMenuHeight, setSubMenuHeight] = useState<number>(225);
  
  const getBackgroundColorSubMenu = () => {
    if (backgroundColorParameters && backgroundColorParameters.length > 0) {
      return backgroundColorParameters.length > 2 ? backgroundColorParameters[2] : backgroundColorParameters[0];
    } else {
      return '';
    }
  };
 
  return (
    <Box sx={{display:{xs:'none',sm:'none',md:'block'}}}>
      {isShowSubMenu ? (
        <div
          className="sub-menu-container sub-menu-content"
          style={{ height: `${subMenuHeight}px`, backgroundColor: getBackgroundColorSubMenu() }}
        >
          <Container fixed >
            <Grid container>
             <Grid item style={{ left: `${leftOffset}px`, position: 'absolute', minWidth: '170px' }} sx={{display:{xs:'none',sm:'none',md:'block'}}}>
                  <div className="nav-links">
                    <div className="nav-links-space" style={{ width: spaceOnNav }} />
                    {itemList.map((item, index) => {
                      if (item.Text === lastLinkHovered) {
                        if (leftOffset !== leftOffsets[index]) {
                          setLeftOffset(leftOffsets[index]!);
                        }

                        return (
                          <SubMenuList
                            subItem={false}
                            key={index}
                            name={item.Text}
                            itemList={item.SubLinkList}
                            setSubMenuHeight={setSubMenuHeight}
                            colorText={colorText}
                            FontFamilyText={FontFamilyText}
                            parentIndex={index}
                            setIndexTitle={setIndexTitle}
                          />
                        );
                      } else {
                        return null;
                      }
                    })}
                  </div>
                </Grid>
              <Grid item xs={1} sm={1} md={1} lg={1} sx={{display:{sm:'block',md:'none',lg:'none'}}}>
                <div className="menu-mobile-temp" />
              </Grid>
            </Grid>
          </Container>
        </div>
      ) : null}
    </Box>
  );
};

export default SubMenuContainer;
