import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ParameterModel } from '../interfaces/models';
import '../styles/sass/_textblock.scss';
import '../styles/sass/_alignment.scss';
import slugify from 'react-slugify';
import { FontType } from '../interfaces/enums';
import { useMediaQuery } from '@mui/material';
interface TextBlockProps {
    paramArray: ParameterModel[];
}

/* eslint-disable complexity */
const TextBlock: React.FC<TextBlockProps> = (props: TextBlockProps) => {
    const [title, setTitle] = useState('');
    const [linkUrl, setLinkUrl] = useState('');
    const [linkText, setLinkText] = useState('');
    const [description, setDescription] = useState('');
    const [img, setImg] = useState('');
    const [fontTitle, setFontTitle] = useState('');
    const [alignmentTitle, setAlignmentTitle] = useState('');
    const [fontText, setFontText] = useState('');
    const [alignmentText, setAlignmentText] = useState('');
    const [colorTitle, setColorTitle] = useState('');
    const [colorText, setColorText] = useState('');
    const [hideTitle, setHideTitle] = useState(false);
    const [LineHeightTitle, setLineHeightTitle] = useState('');
    const [LineHeightText, setLineHeightText] = useState('');
    const [FontWeightTitle, setFontWeightTitle] = useState('false');
    const [FontFamilyText, setFontFamilyText] = useState(' ');
    const [FontFamilyTitle, setFontFamilyTitle] = useState(' ');
    const [FlexibleFontSizeText, setFlexibleFontSizeText] = useState('');
    const [FlexibleFontSizeTitle, setFlexibleFontSizeTitle] = useState('');
    const [LetterSpacingText, setLetterSpacingText] = useState('');
    const [LetterSpacingTitle, setLetterSpacingTitle] = useState('');
    const [Margin, setMargin] = useState(' ');

    // Dictionary mapping keys to respective functions.    
    const valueDict: any = {
        'Title': setTitle,
        'Text': setDescription,
        'LinkText': setLinkText,
        'LinkUrl': setLinkUrl,
        'URL': setImg,
        'FontTitle': setFontTitle,
        'AlignmentTitle': setAlignmentTitle,
        'ColorTitle': setColorTitle,
        'FontText': setFontText,
        'AlignmentText': setAlignmentText,
        'ColorText': setColorText,
        'LineHeightTitle': setLineHeightTitle,
        'LineHeightText': setLineHeightText,
        'FontWeightTitle': setFontWeightTitle,
        'FontFamilyTitle': setFontFamilyTitle,
        'FontFamilyText': setFontFamilyText,
        'HideTitle': (value: any) => setHideTitle(value === 'true'),
        'FlexibleFontSizeText': setFlexibleFontSizeText,
        'FlexibleFontSizeTitle': setFlexibleFontSizeTitle,
        'Margin': setMargin,
        'LetterSpacingTitle': setLetterSpacingTitle,
        'LetterSpacingText': setLetterSpacingText,
    };

    /**
     * Sets values based on a dictionary of keys and respective functions to be called.
     */
    const setValues = () => {
        // Process each parameter in the array and call the corresponding function.
        if (props?.paramArray?.length > 0) {
            props.paramArray.forEach((param) => {
                const theType = param.type;
                const setValue = valueDict[theType];
                if (setValue) {
                    setValue(param.value);
                }
            });
        }
    };

    useEffect(() => {
        setValues();
    }, [props]);


    const fontWeightTitle = FontWeightTitle?.toLowerCase() === 'true' ? 'bolder' : 'lighter';
    const fontSizes: Record<string, string> = {
        Small: '17',
        Medium: '21',
        Big: '31',
        ExtraLarge: '42'
    };

    const fontSizeText: string = fontSizes[fontText] || '';

    const fontTitleSizes: Record<string, string> = {
        Small: '17',
        Medium: '21',
        Big: '31',
        ExtraLarge: '55'
    };

    const fontSizeTitle: string = fontTitleSizes[fontTitle] || '';

    /**
     * Get the font size based on the provided type
     * @param type - The type of font (Title or Text)
     * @returns The font size in either vw or px
     */
    const getFontSize = (type: FontType) => {
        // Define Title and Text with FlexibleFontSizeTitle and FlexibleFontSizeText or default font sizes
        const { Title, Text } = {
            Title: FlexibleFontSizeTitle || `${fontSizeTitle}px`,
            Text: FlexibleFontSizeText || `${fontSizeText}px`
        };

        // Get the pixel value of the font size
        const pxValue = descompFontSize({ Title, Text }[type]).pxValue;

        // Determine if the font size should be in vw or px based on the screen size
        const fontSize = matches ? `${pxValue / 15 + 3}vw` : `${pxValue}px`;

        return fontSize;
    };



    /**
     * Parses a string representing a font size and returns an object with two properties:
     * - value: the numeric value of the font size (in pixels if no unit is specified)
     * - pxValue: the numeric value of the font size in pixels
     *
     * @param value A string representing the font size (e.g. "16px", "1em", "0.8rem")
     * @returns An object with two properties: value and pxValue
     */
    const descompFontSize = (value: string) => {
        const length = value.length;
        const hasLengthUnit = value.includes('px') || value.includes('em') || value.includes('rem');
        const defaultValue = { value: 17, pxValue: 17 };

        // If the value does not include a length unit (e.g. "17"), parse it as an integer
        if (!hasLengthUnit) {
            try {
                const integerValue = parseInt(value, 10);
                return { value: integerValue || defaultValue.value, pxValue: integerValue || defaultValue.pxValue };
            } catch (error) {
                console.error(error);
            }
        }

        // Determine the length of the length unit ("px", "em", or "rem")
        const lengthUnit = value.includes('rem') ? 3 : 2;

        // Extract the numeric value from the string (excluding the length unit)
        const numberValue = parseInt(value.slice(0, length - lengthUnit), 10);

        // If the extracted value is not a number, return the default value
        if (isNaN(numberValue)) {
            console.error('Invalid value:', value);
            return defaultValue;
        }

        // Calculate the value in pixels based on the length unit
        const pxValue = value.includes('px') ? numberValue : numberValue * 16;

        // Return an object with the numeric value and the value in pixels
        return { value: numberValue, pxValue };
    };



    const matches = useMediaQuery('(max-width:400px)');

    /**
     * Renders the title of the component.
     * @returns {React.ReactNode|null} The JSX element containing the title, or null if the title should be hidden.
     */
    const renderTitle = (): React.ReactNode | null => {
        const hasTitle: boolean = title !== '';

        // Hide the title if the user has specified to do so or if there is no title
        if (hideTitle || !hasTitle) {
            return null;
        }

        // Determine the CSS classes and styles for the title element
        const titleClass: string = hasTitle ? 'title 1 ' : 'title ';
        const titleColor: string = colorTitle || 'inherit';
        const titleDisplay: string = hideTitle ? 'none' : '';

        // Return the title element with the appropriate CSS classes, styles, and inner HTML
        return (
            <h3
                className={titleClass + alignmentTitle}
                style={{
                    fontSize: getFontSize(FontType.Title),
                    color: titleColor,
                    display: titleDisplay,
                    lineHeight: LineHeightTitle,
                    fontWeight: fontWeightTitle,
                    fontFamily: FontFamilyTitle,
                    letterSpacing: LetterSpacingTitle,
                }}
                dangerouslySetInnerHTML={{ __html: title }}
            />
        );
    };


    const renderText = () => {
        if (description && colorText) {
            return (
                <p
                    className={'description ' + alignmentText}
                    style={{
                        fontSize: getFontSize(FontType.Text),
                        color: colorText,
                        lineHeight: LineHeightText,
                        fontFamily: FontFamilyText,
                        letterSpacing: LetterSpacingText,
                    }}
                    dangerouslySetInnerHTML={{ __html: description }}
                />
            );
        } else if (description && !colorText) {
            return (
                <p
                    className={'description ' + alignmentText}
                    style={{
                        fontSize: getFontSize(FontType.Text),
                        lineHeight: LineHeightText,
                        fontFamily: FontFamilyText,
                        letterSpacing: LetterSpacingText,
                    }}
                    dangerouslySetInnerHTML={{ __html: description }}
                />
            );
        } else {
            return null;
        }
    };

    return (
        <div className="text-block" id={slugify(title)} style={{ margin: Margin }}>
            {renderTitle()}
            {renderText()}
            {linkText && linkUrl && <Link to={linkUrl}>{linkText}</Link>}
            {img && <p className="description">{img}</p>}
        </div>
    );
};
export default TextBlock;
