 import { ParameterChangeType, ComponentClassType, ComponentChangeType, ChangeStatus, ViewChangeType } from './enums';

export type List<T> = Array<T>;
export type Guid = string;

export interface ParameterModel {
  id: string;
  type: string;
  value: string;
  groupId: string;
  componentId: string;
  style?: string;
  disabled?: string;
}

export interface CMSParameterChangesModel {
  id?: string;
  type: string;
  newValue: string;
  oldValue: string;
  groupId: string;
  componentId?: string;
  parameterId: string | null;
  userId?: string;
  email?: string;
  status: ChangeStatus;
  createdAt: Date;
  componentChangeId?: string;
  changeType: ParameterChangeType;
  rootViewName?: string;
  isChecked?: boolean;
}
export interface ParameterChangesDto {
  componentId?: Guid;
  parameterId?: Guid | null;
  newValue?: string;
  oldValue?: string;
  type: string;
  groupId: string;
  changeType: ParameterChangeType;
  rootViewName?: string;
  componentChangeId?: string;
}
export interface ParametersChangesDto {
  parameters: Array<ParameterChangesDto>;
}
export interface ChangesIdsListDto {
  idsList: Array<string>;
}
export interface UpdateValueParametersChangesDto {
  parameters: Array<UpdateValueParameterChangesDto>;
}
export interface UpdateValueParameterChangesDto {
  id: string;
  newValue: string;
}
export interface CMSComponentChangesModel {
  id: string;
  componentId: string;
  componentType: ComponentClassType;
  key: string;
  parentId?: string;
  viewId?: string;
  userId?: string;
  email?: string;
  status?: ChangeStatus;
  createdAt?: Date;
  index: number;
  language?: string;
  changeType: ComponentChangeType;
  parameterChanges: Array<CMSParameterChangesModel>;
  rootViewName: string;
  isChecked?: boolean;
}
export interface CMSViewChangesModel {
  id: string;
  viewId?: string;
  userId?: string;
  email?: string;
  status?: ChangeStatus;
  createdAt?: Date;
  language?: string;
  changeType: ViewChangeType;
  componentChanges: Array<CMSComponentChangesModel>;
  isChecked?: boolean;
}
export interface ComponentChangesDto {
  componentType: ComponentClassType;
  key?: string;
  parentId?: Guid;
  viewId?: Guid;
  componentId: Guid;
  index?: number;
  changeType: ComponentChangeType;
  parameterChanges?: Array<ParameterChangesDto>;
}
export interface ComponentsChangesDto {
  componentChanges: Array<ComponentChangesDto>;
  viewName: string;
}
export interface ViewChangesDto{
  key: string;
  name: string;
  viewId?: Guid;
  changeType: ViewChangeType;
  componentChanges ?: Array<ComponentChangesDto>;

}
export interface ViewsChangesDto {
  viewChanges: Array<ViewChangesDto>;
  UserId?: Guid;
  Email?: string;
}

export interface ViewDto {
  name: string
}
export interface UserModel {
  id: Guid;
  roleId: Guid;
  roleName: string;
  email: string;
  isReactivated: boolean;
  firstName: string;
  lastName: string;
  lastPasswordUpdate: Date;
}
export interface LoginResultModel {
  userId: string;
  accessToken: string;
  refreshToken: string;
  user: UserModel;
}
export interface LoginCredentialsDto {
  username: string;
  password: string;
}
export interface RegisterDto extends LoginCredentialsDto {
  firstName: string;
  lastName: string;
}
export interface ChangePasswordModel {
  accessToken: string;
}
export interface ChangePasswordDto {
  oldPassword: string;
  newPassword: string;
}
export interface RecoverPasswordDto {
  email: string;
}
export interface ResetPasswordDto {
  forgetPasswordCode: string;
  newPassword: string;
}
export interface RefreshTokenDto {
  refreshToken: string;
}
export interface RefreshTokenModel {
  accessToken: string;
  refreshToken: string;
}
export interface ViewModel {
  id: string;
  name: string;
  language: undefined | string;
}
export interface InitDataModel {
  views: Array<ViewModel>;
  menuView: FullViewModel;
}

export interface InitDataModelWithComponent {
  views: Array<ViewModel>;
  menuView: FullViewModel;
  component: ComponentViewModel;
}
export interface FullViewModel extends ViewModel {
  components: Array<ComponentViewModel>;
}
export interface BaseComponentModel {
  type: ComponentClassType;
  key: string;
  index: number;
  id: string;
}

export interface CarouselProps extends ComponentViewModel {
  hideBrightsoftLoader: boolean;
}
export interface ComponentViewModel extends BaseComponentModel{
  parameters: Array<ParameterModel>;
  children: Array<ComponentViewModel>;
  style?: string;
  viewId?: string;
  parentId?: string;
  disabled?: string;
}

export interface ContactDto {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  country: string;
  company: string;
  description: string;
  createdAt: Date;
}

export interface CaptchaParams{
  token:string;
  hideCaptcha:boolean;
}
export interface ContactToken {
  contact:ContactDto;
  token:string;
  hideCaptcha:boolean;
}

export interface CandidateToken {
  token:string;
  candidate:CandidateDto;
  hideCaptcha:boolean;
}

export interface ContactModel extends ContactDto {
  id: Guid;
}
export interface UserListDto {
  firstName: string;
  lastName: string;
  username: string;
}

export interface UserListModel extends UserListDto {
  id: Guid;
}

export interface CandidateDto {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  job: string;
  documentUrl: string;
  description: string;
  createdAt: Date;
}

export interface CandidateModel extends CandidateDto {
  id: Guid;
}

export interface AppSettings {
  facebookPixelToken: string;
  googleManagerToken: string;
  hideBrightsoftLoader: boolean;
  languages: string[];
  hideCandidates: boolean;
  hideContacts: boolean;
  hideBackground: boolean;
  mainActivityIndicator: string;
  websiteTitle: string;
  domain: string;
  titleFormat: string;
  hideLanguage: boolean; 
  recursiveInitialFunction: boolean;
}

export interface ErrorModel {
  errorCode: string;
  userMessage: string;
  interfaceName: string;
  stacktrace: string;
  developerMessage: string;
}

export interface ValidationErrorModel extends ErrorModel {
  validations: Array<FieldValidationModel>;
}

export interface FieldValidationModel {
  field: string;
  userMessage: string;
}

export interface FileModel {
  name: string;
  url: string;
}

export interface ResizeImage {
  imageUrl: string;
  recommendedWidth: number;
}

export interface SizeRedimensions {
  reduced: ImageModel;
  recommended: ImageModel;
  enlarged: ImageModel;
  sameImage: ImageModel;
}

export interface ImageModel {
  url: string;
  width: number;
  height: number;
}

// UI only not end point interfaces
export interface deleteInfo {
  id: string;
  type: string;
  key: string;
}

export interface updateIndexInfo {
  id: string;
  index: number;
  type: ComponentClassType;
}

export const instanceOfLoginResultModel = (object: any): object is LoginResultModel => {
  return 'accessToken' in object;
};

export const instanceOfChangePasswordModel = (object: any): object is ChangePasswordModel => {
  return 'accessToken' in object;
};

export const instanceOfValidationErrorModel = (object: any): object is ValidationErrorModel => {
  if (typeof object === 'object') {
    return 'errorCode' in object;
  }
  return false;
};
