import React from 'react';
import { FileModel, instanceOfValidationErrorModel, ResizeImage, SizeRedimensions, ValidationErrorModel } from '../../interfaces/models';
import { ConsumeRestAPIEndPoint, RequestOptionsFiles } from '../ApiRestServiceCommon';

export const sendFile = async (event: React.ChangeEvent<HTMLInputElement>, category: string) => {
  let res = '';
  if (event?.target && event.target.files && event.target.files.length > 0 && event.target.files[0]) {
    const theFile: File = event.target.files[0];
    const formData = new FormData();
    formData.append('File', theFile);
    formData.append('Category', category);

    let requestOptionsFiles = RequestOptionsFiles('POST', formData);
    const uploadFileUrl = '/api/v1/Files';
    const result: FileModel | ValidationErrorModel | null = await ConsumeRestAPIEndPoint(
      uploadFileUrl,
      requestOptionsFiles,
    );

    if (!result || instanceOfValidationErrorModel(result)) {
      return res;
    }
    res = result.url;
  }
  return res;
};

export const resizeImage = async (imageUrl: string, recommendedWidth: number | undefined, imageInformation: {currentWidth: number, currentHeight: number, isMobile: boolean}) => {
  let res = null;
  if (imageUrl !== '' ) {
    let resizeImage : ResizeImage = {
      imageUrl: imageUrl,
      recommendedWidth: recommendedWidth || 0,
    }


    const formData = new FormData();
    formData.append('ImageUrl', imageUrl);
    formData.append('RecommendedWidth', String(recommendedWidth));
    formData.append('CurrentWidth',String(imageInformation.currentWidth));
    formData.append('CurrentHeight',String(imageInformation.currentHeight));
    formData.append('IsMobile',String(imageInformation.isMobile));
    let requestOptionsFiles = RequestOptionsFiles('POST', formData);
    const uploadFileUrl = '/api/v1/Files/ResizeImage';
    const result: SizeRedimensions | ValidationErrorModel | null = await ConsumeRestAPIEndPoint(
      uploadFileUrl,
      requestOptionsFiles,
    );

    if (!result || instanceOfValidationErrorModel(result)) {
      return res;
    }
    res = result;
  }
  return res;
};

export const removeFile = async (name: string | undefined, category: string) => {
  // console.log('removeFile',);
  let res = '';
  let url = `/api/v1/Files`;

  if (name) {
    const formData = new FormData();
    formData.append('FileName', name);
    formData.append('Category', category);

    let requestOptionsFiles = await RequestOptionsFiles('DELETE', formData);
    const data = await ConsumeRestAPIEndPoint(url, requestOptionsFiles);
  }
  return res;
};

export const getFontsAvailable = async () => {
  let allFontsAvailable = [
    'Roboto',
    'Sonika',
    'Geometria',
    'Ubuntu-Regular',
    'Ubuntu-Light',
    'Philosopher-Regular',
    'Philosopher-Bold',
    'Montserrat-SemiBold',
    'Montserrat-Regular',
    'Montserrat-Bold',
    'Century Gothic',
    'Century Gothic Bold'
  ];
  
  return allFontsAvailable;
}

export const getHideFields = () => {
  let hideFields = [
    'FlexibleFontSizeText',
    'FlexibleFontSizeTitle',
    'ColorTitle',
    'ColorText',
    'FontFamilyText',
    'FontFamilyTitle',
    'FontWeightText',
    'FontWeightTitle',
    'AlignmentText',
    'AlignmentTitle',
    'ImageMobileUrl',
    'ImageHeightMobile',
    'ImageWidthMobile',
    'ImageWidth',
    'ImageHeight'
  ];
  
  return hideFields;
}



