import React, { useState, useEffect } from 'react';
import '../../styles/sass/nav/_nav.scss';
import { LinkParam } from '../../interfaces/interfaces';
import NavBar from './NavBar';
import SubMenuContainer from './SubMenuContainer';
import { orderHeaderComponents } from '../../services/ViewServices';
import { useLocation } from 'react-router-dom';

interface NavProps {
  LinkParamList: LinkParam[];
  ImageUrlParameterList: string[];
  allParamsList: { [key: string]: string };
  activeView: string;
  openSideNav: () => void;
  setCurrentLanguage: (lang: string) => void;
  currentLanguage: string | undefined;
  ColorParameter: string;
  ColorTitleParameter: string[];
  backgroundColorParameters: string[];
  FontFamilyText: string;
  ColorMenuParameters: string[];
  ObjectFit: string;
  HideLanguage: boolean;
  Fixed: boolean;
  FullWidth: boolean;
  showLanguages: boolean;
  Margin: string;
  setShowLanguages: () => void;
  setIndexTitle: (index: number) => void;
  setPageTitleWithCurrentIndex: () => void;
}

const Nav: React.FC<NavProps> = ({
  LinkParamList,
  ImageUrlParameterList,
  allParamsList,
  activeView,
  openSideNav,
  setCurrentLanguage,
  currentLanguage,
  ColorParameter,
  ColorTitleParameter,
  backgroundColorParameters,
  FontFamilyText,
  ColorMenuParameters,
  ObjectFit,
  HideLanguage,
  Fixed,
  FullWidth,
  showLanguages,
  Margin,
  setShowLanguages,
  setIndexTitle,
  setPageTitleWithCurrentIndex
}: NavProps) => {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [isShowSubmenu, setIsShowSubmenu] = useState(false);
  const [lastLinkHovered, setLastLinkHovered] = useState('');
  const [spaceOnNav, setSpaceOnNav] = useState<number>(0);
  const [linkParam, setLinkParam] = useState<LinkParam[]>([]);
  const [leftOffsets, setLeftOffsets] = useState<(number | undefined)[]>([]);

  useEffect(() => {
    setLinkParam(orderHeaderComponents(LinkParamList));
  }, [LinkParamList]);

  useEffect(() => {
    const evaluateShowSubMenu = (): boolean => {
      let res = false;
      LinkParamList.forEach((e) => {
        if(e.SubLinkList.length === 0 && !e.ShowSubMenu ){
          return res;
        }
          
        if (isMouseOver && e.Text === lastLinkHovered) {
          res = e.SubLinkList && e.SubLinkList.length >= 0;
        }
      });
      return res;
    };
    setIsShowSubmenu(evaluateShowSubMenu());
  }, [lastLinkHovered, isMouseOver, LinkParamList]);

  

  const getFixedValue = () => {
    return Fixed ? 'fixed' : 'static';
  };


  return (
    <nav
      className="Nav"
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => {
        setIsMouseOver(false);
        setLastLinkHovered('');
      }}
      style={{ position: getFixedValue() }}
    >
      <NavBar
        linkParamList={linkParam}
        imageUrlParameterList={ImageUrlParameterList}
        allParamsList={allParamsList}
        activeView={activeView}
        isShowSubmenu={isShowSubmenu}
        setLastLinkHovered={(a) => {
          setLastLinkHovered(a);
        }}
        openSideNav={openSideNav}
        setCurrentLanguage={(a) => {
          setCurrentLanguage(a);
        }}
        isMouseHovered={isMouseOver}
        setSpaceOnNav={setSpaceOnNav}
        lastLinkHovered={lastLinkHovered}
        currentLanguage={currentLanguage}
        setLeftOffsets={setLeftOffsets}
        colorTextParameter={ColorParameter}
        colorTitleParameter={ColorTitleParameter}
        backgroundColorParameters={backgroundColorParameters}
        FontFamilyText={FontFamilyText}
        ColorMenuParameters={ColorMenuParameters}
        ObjectFit={ObjectFit}
        HideLanguage={HideLanguage}
        FullWidth={FullWidth}
        showLanguages={showLanguages}
        Margin={Margin}
        setShowLanguages={setShowLanguages}
        setIndexTitle={setIndexTitle}
        setPageTitleWithCurrentIndex={setPageTitleWithCurrentIndex}
      />
      <SubMenuContainer
        lastLinkHovered={lastLinkHovered}
        isShowSubMenu={isShowSubmenu}
        itemList={linkParam}
        spaceOnNav={spaceOnNav}
        leftOffsets={leftOffsets}
        colorText={ColorParameter}
        backgroundColorParameters={backgroundColorParameters}
        FontFamilyText={FontFamilyText}
        setIndexTitle={setIndexTitle}
      />
    </nav>
  );
};

export default Nav;
