/* eslint-disable complexity */
import React, { useState, useEffect } from 'react';
import TextBlock from './TextBlock';
import '../styles/sass/_componentview.scss';
import { GridSize } from '@mui/material/Grid';
import TextBlockImage from './TextBlockImage';
import Address from './Address';
import DataCard from './DataCard';
import { getGenericParams } from '../utils/utils';
import RowComponent from './RowComponent';
import RowFlipCard from './Card/RowFlipCard';
import DetailCard from './DetailCard/DetailCard';
import Carousel from '../components/Carousel/Carousel';
import ContactUsForm from './Form/ContactUsForm';
import TextInline from './TextInline';
import CareersForm from './Form/CareersForm';
import { CarouselProps, ComponentViewModel, ParameterModel } from '../interfaces/models';
import { ComponentClassType } from '../interfaces/enums';
import ImageComponent from './ImageComponent/ImageComponent';
import FormComponent from './Form/FormComponent';
import HTMLRender from './HTMLRender/HTMLRender';
import BlogLayoutComponent from './Blog/BlogLayoutComponent';
import ActivityComponent from './ActivityComponent';
import FlexibleContainer from './FlexibleContainer';

interface ComponentViewProps {
  componentViewModel: ComponentViewModel;
  // id: number;
 
  componentKey: string;
  id: string;
  // ParentId: number;
  isOpen: boolean;
  hideBrightsoftLoader: boolean;
  // type:string;
}

const ComponentView: React.FC<ComponentViewProps> = ({
  componentViewModel,
 
  componentKey,
  id,
  isOpen,
  hideBrightsoftLoader,
}: ComponentViewProps) => {
  // Optional Parameter
  const [columns, setColumns] = useState(12);
  const [margin, setMargin] = useState(15);

 

  useEffect(() => {
    const setValues = () => {
      if (componentViewModel.parameters?.length > 0) {
        componentViewModel.parameters.forEach((param) => {
          // todo fix Colums -> Columns
          if (param.type === 'Columns') {
            setColumns((Number(param.value) % 12) + 1);
          }
        });
      }
    };
    setValues();
  }, [componentViewModel.parameters]);

  /*
   const componentViewModel: ComponentViewModel = {
    parameters: parameters,
    id: '',
    type: 'TextBlock' as unknown as ComponentClassType,
    children, // : Components,
    key: id,
    index: 0,
    // ParentId
  };
  */

  const carouselProps: CarouselProps = {
    parameters: componentViewModel.parameters,
    id: '',
    type: 'TextBlock' as unknown as ComponentClassType,
    children: componentViewModel.children, // : Components,
    key: id,
    index: 0,
    hideBrightsoftLoader: hideBrightsoftLoader,
  };

  const params = getGenericParams(componentViewModel);

  useEffect(() => {
    const setValues = () => {
      if (componentViewModel?.parameters && componentViewModel?.parameters.length > 0) {
        componentViewModel.parameters.forEach((param, index) => {
          if (param.type === 'Margin') {
            setMargin(Number(param.value));
          }
        });
      }
    };
    setValues();
  }, [componentViewModel, margin]);

  const searchParameterColor = () => {
    let backgroundColor;
    if (componentViewModel.parameters) {
      backgroundColor = componentViewModel.parameters.find((parameter) => {
        return parameter.type === 'BackgroundColor';
      });
    }
    if (backgroundColor) {
      return backgroundColor.value;
    } else {
      return '';
    }
  };

  const getPaddingParameter = () => {
    let parameterPadding = componentViewModel.parameters.find((parameter) => {
      return parameter.type === 'Padding';
    });
    if (parameterPadding) {
      return parameterPadding.value;
    } else {
      return margin < 15 ? `0 0 ${margin}px 0` : `0 0 15px 0`;
    }
  };

  let styleCard = {
    padding: getPaddingParameter(),
    backgroundColor: searchParameterColor(),
  };

  

  return componentViewModel.type === ComponentClassType.Footer ? null : (
    <div className={`component-view ${params.Theme}`} style={styleCard}>
      {componentViewModel.type === ComponentClassType.Carousel ? (
        <Carousel {...carouselProps} />
      ) : componentViewModel.type === ComponentClassType.TextBlock ? (
        <RowComponent key={componentViewModel.id}>
          <TextBlock paramArray={componentViewModel.parameters}/>
        </RowComponent>
      ) : componentViewModel.type === ComponentClassType.TextInline ? (
        <RowComponent>
          <TextInline paramArray={componentViewModel.parameters} isFooterComponent={false} components={componentViewModel.children} />
        </RowComponent>
      ) : componentViewModel.type === ComponentClassType.FlexibleContainer ? (
          <FlexibleContainer paramArray={componentViewModel.parameters} components={componentViewModel.children} />
      ) : componentViewModel.type === ComponentClassType.HTMLRender ? (
        <RowComponent>
          <HTMLRender paramArray={componentViewModel.parameters} />
        </RowComponent>
      ) : componentViewModel.type === ComponentClassType.RowFlipCard ? (
        <RowFlipCard
          parameters={componentViewModel.parameters}
          Columns={columns as GridSize}
          Components={componentViewModel.children}
          isOpen={isOpen}
          Row={false}
          componentKey={componentKey}
          key={componentViewModel.id}
        />
      ) : componentViewModel.type === ComponentClassType.Row ? (
        <RowFlipCard
          parameters={componentViewModel.parameters}
          Columns={columns as GridSize}
          Components={componentViewModel.children}
          isOpen={isOpen}
          Row={true}
          componentKey={componentKey}
          key={componentViewModel.id}
        />
      ) : componentViewModel.type === ComponentClassType.TextBlockImage ? (
        <RowComponent>
          <TextBlockImage
            {...componentViewModel}
            title={params.Title}
            text={params.Text}
            imageUrl={params.ImageUrl}
            theme={params.Theme}
            size={params.Size}
            breakLine={params.BreakLine}
            hideTitle={params.HideTitle}
            imageMobileUrl={params.ImageMobileUrl}
          />
        </RowComponent>
      ) : componentViewModel.type === ComponentClassType.ActivityComponent ? (
        <RowComponent>
          <ActivityComponent
            {...componentViewModel}
            title={params.Title}
            text={params.Text}
            imageUrl={params.ImageUrl}
            imageWidth={params.ImageWidth}
            imageHeight={params.ImageHeight}
            fontFamilyText={params.FontFamilyText}
            fontFamilyTitle={params.FontFamilyTitle}
            colorText={params.ColorText}
            colorTitle={params.ColorTitle}
            fontText={params.FontText}
            fontTitle={params.FontTitle}
            cost={params.Cost}
            place={params.Place}
            schedule={params.Schedule}
            date={params.Date}
            flexibleFontSizeText={params.FlexibleFontSizeText}
            flexibleFontSizeTitle={params.FlexibleFontSizeTitle}
            alignmentText={params.AlignmentText}
            alignmentTitle={params.AlignmentTitle}
            margin={params.Margin}
            fontWeightTitle={params.FontWeightTitle}
            fontWeightText={params.FontWeightText}
            imageHeightMobile={params.ImageHeightMobile}
            imageMobileUrl={params.ImageMobileUrl}
            imageWidthMobile={params.ImageWidthMobile}
          />
        </RowComponent>
      ) : componentViewModel.type === ComponentClassType.BlogLayout ? (
        <RowComponent>
          <BlogLayoutComponent paramArray={componentViewModel.parameters}>{componentViewModel.children}</BlogLayoutComponent>
        </RowComponent>
      ) : componentViewModel.type === ComponentClassType.ImageComponent ? (
        <RowComponent>
          <ImageComponent {...componentViewModel} params={componentViewModel.parameters} />
        </RowComponent>
      ) : componentViewModel.type === ComponentClassType.Address ? (
        <RowComponent>
          <Address
            {...componentViewModel}
            contact={params.Contact}
            job={params.Job}
            phone={params.Phone}
            email={params.Email}
            address={params.Address}
            city={params.City}
            country={params.Country}
            link={params.Link}
            iframe={params.Iframe}
            color={params.ColorText}
            fontFamily={params.FontFamilyText}
            fontSize={params.FontText}
            margin={params.Margin}
          />
        </RowComponent>
      ) : componentViewModel.type === ComponentClassType.DetailCard ? (
        <DetailCard id={id} {...params} ComponentList={componentViewModel.children} ParamArray={componentViewModel.parameters} isOpen={isOpen} key={componentViewModel.id} />
      ) : componentViewModel.type === ComponentClassType.Data ? (
        <DataCard paramArray={componentViewModel.parameters} />
      ) : componentViewModel.type === ComponentClassType.CareersForm ? (
        <CareersForm form={componentViewModel} />
      ) : componentViewModel.type === ComponentClassType.ContactUsForm ? (
        <ContactUsForm form={componentViewModel} />
      ) : componentViewModel.type === ComponentClassType.FormComponent ? (
        <FormComponent form={componentViewModel} />
      ) : componentViewModel.type === ComponentClassType.Header ? null : componentViewModel.type === ComponentClassType.Background ? null : ( // don't render any Footer here.
        // default show Unimplemented component as raw data.
        <ComponentViewRaw {...componentViewModel} />
      )}
    </div>
  );
};

export default ComponentView;

const ComponentViewRaw: React.FC<ComponentViewModel> = ({ parameters, type, children, key }: ComponentViewModel) => {
  return (
    <div className="component-view-raw">
      <h3>{type}</h3>
      <ul>
        {parameters?.map((parameter, index) => (
          <ParameteresRaw key={index} {...parameter} />
        ))}
      </ul>
    </div>
  );
};

export const ParameteresRaw: React.FC<ParameterModel> = ({ type: name, value }: ParameterModel) => {
  return (
    <div className="parameter-view">
      <li>
        &nbsp; Parameters:
        <ul>
          <li>Name: {name}</li>
          <li>Value: {value}</li>
        </ul>
      </li>
    </div>
  );
};
