import React,{ lazy, Suspense, useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router';
import WebApp from './WebApp';
import SnackBarComponent from './components/SnackBar/SnackBarComponent';
import { Helmet } from 'react-helmet';
import './cms/App.css'
import FullScreenLoading from './components/Loading/FullscreenLoading';

const CMSApp = lazy(() => import('./CMSApp').then(({ CMSApp }) => ({ default: CMSApp })));

interface AppProps {
  isHideBrightsoftLoader: boolean;
  mainActivityIndicator: string;
  titleFormat: string;
  metaTagDomain: string;
  metaTagTitle: string;
  languages: string[];
  hideBackground: boolean;
}


// it could be your App.tsx file or theme file that is included in your tsconfig.json
import { createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { ThemeProvider } from '@emotion/react';

const theme = createTheme({
  palette: {
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
      dark: grey[400]
    },
  },
});

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    neutral?: PaletteOptions['primary'];
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}

const App: React.FC<AppProps> = (props: AppProps) => {
  const [loading, setLoading] = useState(false);
  const [pageTitle, setPageTitle] = useState('');
  const theLocation = useLocation();
  const [pageDescription, setDescription] = useState('DescriptionFirst');
  const namePageContainer = theLocation.pathname.split('/');
  const descriptionDictionary: { [id: string]: string } = {
    Services: `Software as a service is a software distribution model in which an external provider hosts applications and makes them available to customers over the internet.${props.metaTagTitle} has experience in various fields and technologies, which allow us to offer multiple options to clients.`,
    Technologies: `${props.metaTagTitle} manages a group of specialized tools for the development of its projects in each of the areas in which the company specializes.`,
    Company: `${props.metaTagTitle} is a company characterized by the search for innovative solutions and the creation of value made up of a multidisciplinary team, highly trained and constantly growing. We are technology lovers, we seek to help our colleagues grow and improve the efficiency of companies through innovation, safety and agility in the development of products or strategies.`,
    Careers:
      'We are a community of brilliant minds that combine creativity, strategic thinking, and innovative technology to solve problems. We are looking for passionate, hard-working and enthusiastic people at the prospect of producing great work.',
    'Contact-us': `At ${props.metaTagTitle} we are committed to quality service, we will gladly answer all your questions. A whole team of specialists at your service. We put at your disposal our contact form, telephone and email so that you can contact us with any questions.`,
  };

  const setPageTitleLogic = () => {
    if (namePageContainer.length <= 2 && location.pathname === "/") {
      let pageAttributes: string =
        theLocation.pathname.substring(1).charAt(0).toUpperCase() + theLocation.pathname.substring(2);
      const newTitle  = props.titleFormat === '' ? ` ${props.metaTagTitle} ${pageAttributes}` : `${getTitleWithFormat(pageAttributes)}`;
      setPageTitle(newTitle);
      setDescription(descriptionDictionary[pageAttributes]);
    }
  };

  const setPageTitleWithFlat = (pageTitle: string) => {
    let page = pageTitle.charAt(0).toUpperCase() + pageTitle.substring(1);
    const newTitle  = props.titleFormat === '' ? ` ${props.metaTagTitle} ${page}` : `${getTitleWithFormat(page)}`;
    setPageTitle(newTitle);
    setDescription(descriptionDictionary[page]);
  };
 

  useEffect(() => {
    setPageTitleLogic();
    window.scrollTo(0, 0);
  }, [theLocation.pathname]);

  useEffect(() => {
    setLoading(true);
  }, []);

  const getTitleWithFormat = (page: string): string => {
    let titleWithFormat = `title ${props.titleFormat} page`;
    titleWithFormat = titleWithFormat.replace('title', props.metaTagTitle);
    titleWithFormat = titleWithFormat.replace('page', page !== '' ? page : 'Home');
    return titleWithFormat;
  };

  
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="og:description" content={pageDescription} />
        <link href={props.metaTagDomain} rel="canonical" />
        <link href={props.metaTagDomain} rel="home" />
        <link rel="preload" as="image" href="/uploads/Images/spinner.svg" type="image/svg" />
        <meta
          name="description"
          content={
            props.metaTagTitle +
            ' is a software technology company specialized in software development, quality assurance, and value added IT services.'
          }
        />
        <meta itemProp="name" content={props.metaTagTitle} />
        <meta
          itemProp="description"
          content={
            props.metaTagTitle +
            ' is a software technology company specialized in software development, quality assurance, and value added IT services.'
          }
        />
        <meta
          itemProp="image"
          content="https://scontent.flpb1-1.fna.fbcdn.net/v/t1.6435-9/90116958_2780972928686502_7206508690902351872_n.png?_nc_cat=104&amp;ccb=1-3&amp;_nc_sid=e3f864&amp;_nc_ohc=XUQ0IiCbZXAAX8PnPeu&amp;_nc_ht=scontent.flpb1-1.fna&amp;oh=0cce1f797c98a3c56bdd03ee61df3cf5&amp;oe=60FA815D"
        />
        <meta name="og:url" content={props.metaTagDomain} />
        <meta name="og:type" content="website" />
        <meta name="og:title" content={props.metaTagTitle} />
        <meta
          name="og:description"
          content={
            props.metaTagTitle +
            ' is a software technology company specialized in software development, quality assurance, and value added IT services.'
          }
        />
        <meta
          name="og:image"
          content="https://scontent.flpb1-1.fna.fbcdn.net/v/t1.6435-9/90116958_2780972928686502_7206508690902351872_n.png?_nc_cat=104&amp;ccb=1-3&amp;_nc_sid=e3f864&amp;_nc_ohc=XUQ0IiCbZXAAX8PnPeu&amp;_nc_ht=scontent.flpb1-1.fna&amp;oh=0cce1f797c98a3c56bdd03ee61df3cf5&amp;oe=60FA815D"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={props.metaTagTitle} />
        <meta
          name="twitter:description"
          content={
            props.metaTagTitle +
            ' is a software technology company specialized in software development, quality assurance, and value added IT services.'
          }
        />
        <meta
          name="twitter:image"
          content="https://scontent.flpb1-1.fna.fbcdn.net/v/t1.6435-9/90116958_2780972928686502_7206508690902351872_n.png?_nc_cat=104&amp;ccb=1-3&amp;_nc_sid=e3f864&amp;_nc_ohc=XUQ0IiCbZXAAX8PnPeu&amp;_nc_ht=scontent.flpb1-1.fna&amp;oh=0cce1f797c98a3c56bdd03ee61df3cf5&amp;oe=60FA815D"
        />
      </Helmet>

      <div className="App-root">
        <SnackBarComponent />
        <ThemeProvider theme={theme}>
          <Routes>
            <Route path="/cms/*" element={
              <Suspense fallback={<FullScreenLoading state={loading} mainActivityIndicator={props.mainActivityIndicator} hideBrightsoftLoader = {props.isHideBrightsoftLoader}/>}>
                <CMSApp mainActivityIndicator={props.mainActivityIndicator} parentSetLoading={setLoading} hideBrightsoftLoader = {props.isHideBrightsoftLoader} hideBackground = {props.hideBackground} />
              </Suspense>
            } />
              
            <Route 
              path="/*" 
              element={<WebApp 
              mainActivityIndicator={props.mainActivityIndicator} 
              hideBrightsoftLoader = {props.isHideBrightsoftLoader} 
              languages = {props.languages}
              hideBackground = {props.hideBackground}
              setPageTitle={setPageTitleWithFlat} />} 
            />
          </Routes>
        </ThemeProvider>
      </div>
    </>
  );
};

export default App;
