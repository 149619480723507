import React, { useEffect, useState } from 'react';
import '../../styles/sass/_horizontalrule.scss';
import { ParameterModel } from '../../interfaces/models';

interface HorizontalRuleProps {
  parameters: ParameterModel[];
  type: string;
}
const HorizontalRule: React.FC<HorizontalRuleProps> = ({ parameters, type }: HorizontalRuleProps) => {
  const [Color, setColor] = useState('');
  const [Padding, setPadding] = useState('');
  const [Direction,setDirection] = useState('Horizontal');
  useEffect(() => {
    parameters.forEach((parameter) => {
      if (parameter.type === 'ColorText') {
        setColor(parameter.value);
      } else if (parameter.type === 'Padding') {
        setPadding(parameter.value);
      } else if (parameter.type === 'Direction') {
        setDirection(parameter.value);
      }
    });
  });
  return (
    Direction === 'Horizontal' || type === 'sm' ? 
      <div className="HorizontalRule">
        <hr style={{ borderBottom: '2px solid ' + Color, padding: Padding }} />
      </div>
    :
    Direction === 'Vertical' && type !== 'sm' ?
    <div className='VerticalRule' style={{ backgroundColor:Color, padding: Padding }}/>
    :
    null
  );
};

export default HorizontalRule;
