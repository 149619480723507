import React, { useRef, useEffect, useState, CSSProperties } from 'react';
import '../../styles/sass/flipcard/_contentcard.scss';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link } from 'react-router-dom';
import useWindowDimensions from '../Background/WindowSize';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { HashLink } from 'react-router-hash-link';
import PictureComponent from '../PictureComponent';

interface ContentCardProps {
  imageUrl: string;
  imageMobileUrl?: string;
  imageWidth?: string;
  imageHeight?: string;
  text: string;
  func: Function;
  onClickArea?: Function;
  cardSize: string;
  linkUrl?: string;
  isLink?: boolean;
  isLinkNewTab?: boolean;
  description?: string;
  windowSize?: string;
  id: string;
  isOpenState?: boolean | undefined;
  bgSizeCard?: string | undefined;
}

// eslint-disable-next-line complexity
const ContentCard: React.FC<ContentCardProps> = ({
  imageUrl,
  imageMobileUrl,
  imageWidth,
  imageHeight,
  text,
  func,
  onClickArea,
  cardSize,
  linkUrl,
  isLink,
  isLinkNewTab,
  description,
  windowSize,
  id,
  isOpenState,
  bgSizeCard,
}: ContentCardProps) => {
  const { height, width } = useWindowDimensions();
  const url = linkUrl ? linkUrl : '/';
  const linkNewTab = isLinkNewTab ? '_blank' : '';
  const backColor = bgSizeCard?.includes('contain') ? '#fafafa' : '';
  let cardRef = useRef<HTMLDivElement>(null);
  let [currentHeight, setCurrentHeight] = useState(0);
  let runMainFunc = true;

  useEffect(() => {
    let elm = cardRef.current as Element;
    setCurrentHeight(elm.scrollWidth);
  }, [currentHeight, setCurrentHeight, window.innerWidth, window.innerHeight, height, width]);

  const isOnlyImage = () => {
    return text === '' || text.trim() === '';
  };

  let contentCardClass = cardSize === 'STD' ? 'content-cardSTD' : 'content-cardAllSizes';

  let contentCardClass2 =
    (cardSize === 'STD' ? 'content-cardSTD2' : 'content-cardAllSizes2') + (isOnlyImage() ? ' content-only-image' : '');

  let styleSvg =
    windowSize === 'sm'
      ? {
          height: '30%',
          width: '30%',
          fill: 'black',
          display: 'block',
          margin: 'auto',
          backgroundSize: bgSizeCard,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }
      : {
          height: '100px',
          width: '100px',
          fill: 'black',
          display: 'block',
          margin: 'auto',
          backgroundSize: bgSizeCard,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        };

  let styleButton = {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    border: 'none',
    paddingTop: '5%',
    cursor: 'pointer',
    margin: 'auto',
  };

  let styleButton2 = {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    border: 'none',
    paddingTop: '7%',
    cursor: 'pointer',
    margin: 'auto',
  };

  let styleCard = cardSize === 'STD' ? {} : { height: `${currentHeight}px` };

  const getBackgroundStyle = (): CSSProperties => {
    const backgroundStyleUrl = "url('" + imageUrl + "')";

    /* console.log(
      'contentCard.getBackgroundStyle: imageUrl=' +
        imageUrl +
        ' cardSize=' +
        cardSize +
        ' generatedUrlImage: ' +
        backgroundStyleUrl,
    ); */

    return cardSize !== 'STD'
      ? {
          backgroundColor: backColor,
          backgroundImage: backgroundStyleUrl,
          height: `${currentHeight}px`,
          backgroundSize: bgSizeCard,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }
      : { backgroundColor: '#002d73', height: `${currentHeight}px` };
  };

  const cardSizeControl =
    cardSize === 'STD'
      ? {
          backgroundColor: backColor,
          backgroundImage: 'url(' + imageUrl + ')',
          backgroundSize: bgSizeCard,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }
      : {};

  const handleClickBottom = (e: any) => {
    runMainFunc = false;
    if (onClickArea) {
      onClickArea(e);
    }
  };

  const handleClickAll = (e: any) => {
    if (!isOnlyImage()) {
      if (runMainFunc) {
        func();
      }
      runMainFunc = true;
    }
  };

  const isHashLink = () => {
    return url.includes('#');
  };

  const scrollWithOffset = (el: any) => {
    const yCoordinate = el.getBoundingClientRect().top;
    window.scrollTo({ top: yCoordinate - 80 });
  };

  const changeId = () => {
    let elementCard = document.getElementById('linkcard-click-area' + url);
    if (elementCard) {
      elementCard.id = 'linkcard-click-area-click' + url;
    }
  };

 
  const buttonElement = () => {
    return cardSize === 'STD' ? (
      <button name="stdImageButton" style={styleButton2}>
        <div className="div-click-area" style={{ display: 'block' }}>
          <div>
            <PictureComponent imageUrl={imageUrl} imageWidth={imageWidth} imageMobileUrl={imageMobileUrl}>
              <img
                alt={'Image of ' + text + ' in content card'}
                src={imageUrl}
                style={styleSvg}
                width={imageWidth}
                height={imageHeight}
                loading='lazy'
              />
            </PictureComponent>
          </div>
          <div id={'text-center-' + id} className="text-center">
            {text}
          </div>
        </div>
      </button>
    ) : (
      <button name="standardTextButton" style={styleButton}>
        <div id={'text-center-' + id} className="text-center font-shadow">
          {text}
        </div>
      </button>
    );
  };

  return (
    <div
      style={{ position: 'relative' }}
      id={'content-card-' + id}
      title={description}
      
      onClick={(e) => {
        e.preventDefault();
        handleClickAll(e);
      } }
    >
      <div id={'content-card-bg-' + id} style={getBackgroundStyle()} ref={cardRef} className={contentCardClass2} />

      <div id={'content-card-content-' + id} className={contentCardClass} style={ {height: currentHeight + 'px'} }>
        <div id={'content-card-click-area-' + id} className="click-area">
          {isLink ? (
            isHashLink() ? (
              <HashLink
                className="full-link"
                style={cardSizeControl}
                to={url}
                target={isLinkNewTab ? '_blank' : ''}
                scroll={(el) => scrollWithOffset(el)}
                id={'linkcard-click-area' + url}
                onClick={changeId}
              >
                {cardSize === 'STD' ? (
                  <>
                    <div>
                      <PictureComponent imageUrl={imageUrl} imageWidth={imageWidth}>
                        <img
                          alt="STD card image"
                          src={imageUrl}
                          style={styleSvg}
                          width={imageWidth}
                          height={imageHeight}
                          loading='lazy'
                        />
                      </PictureComponent>
                    </div>
                    <div id={'text-center-' + id} className="text-center">
                      {text}
                    </div>
                  </>
                ) : (
                  <div id={'text-center-' + id} className="text-center font-shadow">
                    {text}
                  </div>
                )}
              </HashLink>
            ) : (
              <Link
                className="full-link"
                style={cardSizeControl}
                to={url}
                target={isLinkNewTab ? '_blank' : ''}
                id={'linkcard-click-area' + url}
                onClick={changeId}
              >
                {cardSize === 'STD' ? (
                  <>
                    <div>
                      <PictureComponent imageUrl={imageUrl} imageWidth={imageWidth}> 
                        <img
                          alt="card size std image"
                          src={imageUrl}
                          style={styleSvg}
                          width={imageWidth}
                          height={imageHeight}
                          loading='lazy'
                        />
                      </PictureComponent>
                    </div>
                    <div id={'text-center-' + id} className="text-center">
                      {text}
                    </div>
                  </>
                ) : (
                  <div id={'text-center-' + id} className="text-center font-shadow">
                    {text}
                  </div>
                )}
              </Link>
            )
          ) : (
            buttonElement()
          )}
        </div>

        {!isOnlyImage() ? (
          <div className={isLink ? 'card-right-arrow' : ''}>
            {isLink ? (
              isHashLink() ? (
                <HashLink
                  style={{ textDecoration: 'none' }}
                  to={url}
                  target={linkNewTab}
                  scroll={(el) => scrollWithOffset(el)}
                  id={'linkcard-right-arrow' + url}
                >
                  <OpenInNewIcon style={{ fill: '#00a1df' }} />
                </HashLink>
              ) : (
                <Link style={{ textDecoration: 'none' }} to={url} target={linkNewTab} id={'linkcard-right-arrow' + url}>
                  <OpenInNewIcon style={{ fill: '#00a1df' }} />
                </Link>
              )
            ) : (
              <ChevronRightIcon fontSize="large" style={{ fill: '#00a1df' }} onClick={() => func()} />
            )}
          </div>
        ) : null}

        {cardSize === 'STD' ? (
          <div className="bottom">
            {isOpenState && isOpenState !== undefined ? (
              <ExpandLessIcon
                fontSize="large"
                style={{ fill: '#00a1df' }}
                onClick={(e) => {
                  e.preventDefault();
                  handleClickBottom(e);
                }}
              />
            ) : (
              <ExpandMoreIcon
                fontSize="large"
                style={{ fill: '#00a1df' }}
                onClick={(e) => {
                  e.preventDefault();
                  handleClickBottom(e);
                }}
              />
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default ContentCard;
