import React, { useEffect, useState, FormEvent, useRef, createRef, MutableRefObject } from 'react';
import { styled } from '@mui/material/styles';
import RowComponent from '../RowComponent';
import { GenericparamDef } from '../../interfaces/interfaces';
import { getGenericParams, getGroupId, isValidEmail, isValidPhone } from '../../utils/utils';
import { jobList } from '../../data/formsData';
import { candidatePost } from '../../services/ViewServices';
import { useSnackbar } from 'notistack';
import { CandidateModel, ComponentViewModel } from '../../interfaces/models';
import { sendFile, removeFile } from '../../services/cms/fileService';
import { v4 as uuidv4 } from 'uuid';
import {
  Theme,
  Grid,
  TextField,
  MenuItem,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { Link } from 'react-router-dom';
import '../../styles/sass/form/_careersform.scss';
import AppSettingsService from '../../services/AppSettingsService';
import { validateAndSendForm } from '../../services/cms/recaptchaService';
import Slide from '@mui/material/Slide';
const PREFIX = 'CareersForm';

const classes = {
  field: `${PREFIX}-field`,
  fullfield: `${PREFIX}-fullfield`,
  inputLabel: `${PREFIX}-inputLabel`,
  selectedItem: `${PREFIX}-selectedItem`
};

const Root = styled('div')((
  { theme } : { theme : Theme}
) => ({
  [`& .${classes.field}`]: {
    margin: 'auto',
    padding: theme.spacing(1),
    [theme.breakpoints.between(1, 281)]: {
      width: '162px',
    },
    [theme.breakpoints.between(281, 321)]: {
      width: '202px',
    },
    [theme.breakpoints.between(321, 361)]: {
      width: '242px',
    },
    [theme.breakpoints.between(361, 376)]: {
      width: '252px',
    },
    [theme.breakpoints.between(376, 415)]: {
      width: '282px',
    },
    [theme.breakpoints.between(415, 541)]: {
      width: '277px',
    },
    [theme.breakpoints.between(541, 769)]: {
      width: '337px',
    },
    [theme.breakpoints.between(769, 1025)]: {
      width: '487px',
    },
    [theme.breakpoints.up(1025)]: {
      width: '517px',
    },
  },

  [`& .${classes.fullfield}`]: {
    margin: 'auto',
    paddingTop: '9px',
    paddingBottom: '9px',
    [theme.breakpoints.between(1, 281)]: {
      width: '184px',
    },
    [theme.breakpoints.between(281, 321)]: {
      width: '224px',
    },
    [theme.breakpoints.between(321, 361)]: {
      width: '264px',
    },
    [theme.breakpoints.between(361, 376)]: {
      width: '274px',
    },
    [theme.breakpoints.between(376, 415)]: {
      width: '304px',
    },
    [theme.breakpoints.between(415, 541)]: {
      width: '299px',
    },
    [theme.breakpoints.between(541, 769)]: {
      width: '359px',
    },
    [theme.breakpoints.between(769, 1025)]: {
      width: '509px',
    },
    [theme.breakpoints.up(1025)]: {
      width: '539px',
    },
  },

  [`& .${classes.inputLabel}`]: {
    top: '-1px',
    '&.shrink': {
      marginTop: '10px',
    },
  },

  [`& .${classes.selectedItem}`]: {
    backgroundColor: '#FFFFFF !important',
  }
}));

interface CareersFormProps {
  form: ComponentViewModel;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CareersForm: React.FC<CareersFormProps> = ({ form }: CareersFormProps) => {
  // if (!form || form.type !== ComponentClassType.CareersForm) return (null);

  const [firstNameInput, setNameInput] = useState<string>('');
  const [nameInputErrors, setNameInputErrors] = useState<Array<string>>([' ']);

  const [emailInput, setEmailInput] = useState<string>('');
  const [emailInputErrors, setEmailInputErrors] = useState<Array<string>>([]);

  const [phoneInput, setPhoneInput] = useState<string>('');
  const [phoneInputErrors, setPhoneInputErrors] = useState<Array<string>>([]);

  /*
      const [countryInput, setCountryInput] = useState('');
      const [countryInputErrors, setCountryInputErrors] = useState<Array<string>>([]);
      */

  const [jobInput, setJobInput] = useState('');
  const [jobInputErrors, setJobInputErrors] = useState<Array<string>>([]);

  const [cvInput, setCvInput] = useState<string>('');
  const [cvInputErrors, setCvInputErrors] = useState<Array<string>>([]);

  const [descriptionInput, setDescriptionInput] = useState(' ');
  const [lastNameInput, setLastNameInput] = useState(' ');

  const [formParams, setFormParams] = useState<GenericparamDef>({ Title: '' });

  const [paramList, setParamList] = useState<GenericparamDef[]>([]);
  const [formHasErrors, setFormHasErrors] = useState<boolean>(true);
  const [open, setOpen] = React.useState(false);
  const [groupId, setGroupId] = React.useState('EN');

  const { enqueueSnackbar } = useSnackbar();

  const [errorMessage, setErrorMessage ] = useState(""); 
  useEffect(() => {
    loadReCaptcha();
  }, []);

  useEffect(() => {
    setFormParams(getGenericParams(form));
    setGroupId(getGroupId(form));

    const componentMap: GenericparamDef[] = form.children.map((comp) => {
      return getGenericParams(comp);
    });

    setParamList(componentMap);
  }, [form]);

  const evaluateFormBeforeSubmit = () => {
    evalNameInput(firstNameInput);
    evalEmailInput(emailInput);
    evalPhoneInput(phoneInput);
    // evalCountryInput(countryInput);
    evalJobInput(jobInput);
    evalCVInput(cvInput);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    await validateAndSendForm(saveForm);    
  };

  const saveForm = async (token: string) => {
    const resetForm = () => {
      setNameInput('');
      setEmailInput('');
      setPhoneInput('');
      // setCountryInput('');
      setJobInput('');
      setCvInput('');
      setLink('');
      if(errorMessage !== "") setErrorMessage("");
    };

    if (
      firstNameInput !== '' &&
      emailInput !== '' &&
      phoneInput !== '' &&
      // countryInput !== '' ||
      jobInput !== '' &&
      cvInput !== ''
    ) {
      let titleParameter = form.parameters.find((p) => p.type === 'Title');
      let description = "";
      if(titleParameter){
        description = titleParameter.value;
      }
      
      setDescriptionInput(description);

      let candidatePostResult = await candidatePost(
        {
          firstNameInput,
          lastNameInput,
          emailInput,
          phoneInput,
          // countryInput,
          jobInput,
          cvInput,
          description,
        },
        {
          token: token,
          hideCaptcha: false,
        },
        resetForm,
      );
      
      let candidateParse = candidatePostResult as CandidateModel;

      if(candidateParse.id === undefined){
        if (candidatePostResult) setErrorMessage(String(candidatePostResult));
      }
      handleClickOpen();
    } else {
      evaluateFormBeforeSubmit();
      handleErrorLog();
    }
  };

  const handleErrorLog = () => {
    enqueueSnackbar('Invalid Data', { variant: 'error' });
  };

  const filterParamsByTitle = (param: GenericparamDef, title: string) => {
    return param.Title === title;
  };

  const getStringOfParamsByTitle = (paramList: GenericparamDef[], title: string): string => {
    let res: string;
    let filteredList = paramList.filter((aParam) => aParam.Title === title);
    let firstInList = filteredList[0];
    let obj: GenericparamDef = firstInList ? firstInList : {};

    res = obj.Text ? obj.Text : '';
    return res;
  };

  const getIdOfParamsByTitle = (paramList: GenericparamDef[], title: string): string => {
    let res: string;
    let filteredList = paramList.filter((aParam) => aParam.Title === title);
    let firstInList = filteredList[0];
    let obj: GenericparamDef = firstInList ? firstInList : {};

    res = obj.Title ? obj.Title : '';
    return res;
  };

  const getStringOfParamsByTitle1 = (paramList: GenericparamDef[], title: string): string => {
    // console.log(paramList);
    let res: string;
    let filteredList = paramList.filter((aParam) => aParam.Title === title);
    let firstInList = filteredList[0];
    let obj: GenericparamDef = firstInList ? firstInList : {};

    res = obj.Error ? obj.Error : '';
    return res;
  };

  const getStringOfParamsByTitle2 = (paramList: GenericparamDef[], title: string): string => {
    let res: string;
    let filteredList = paramList.filter((aParam) => aParam.Title === title);
    let firstInList = filteredList[0];
    let obj: GenericparamDef = firstInList ? firstInList : {};

    res = obj.Error1 ? obj.Error1 : '';
    return res;
  };

  const handleInputChange = (e: any) => {
    // debugger;
    const { name, value } = e.target;

    switch (name) {
      case 'Name':
        evalNameInput(value);
        break;
      case 'Email':
        evalEmailInput(value);
        break;
      case 'Phone':
        evalPhoneInput(value);
        break;
      /*
                  case 'Country':
                    evalCountryInput(value);
                    break;
                  */
      case 'Job':
        evalJobInput(value);
        break;
      case 'CV': {
        const auxCvInputErrors: string[] = evalCVInput(value);
        if (auxCvInputErrors.length < 1) {
          sendResumeFile(e);
        }
        break;
      }
    }
  };

  const evaluateList = (value: boolean, msg: string, list: Array<string>) => {
    value ? list.push(msg) : list.filter((i) => i !== msg);
  };

  const evalNameInput = (value: string) => {
    setNameInput(value);
    const msg_name_required = getStringOfParamsByTitle1(paramList, 'Name');
    const nameErrors: Array<string> = [];
    evaluateList(value === '', msg_name_required, nameErrors);
    setNameInputErrors(nameErrors);
  };

  const evalEmailInput = (value: string) => {
    setEmailInput(value);
    const msg_email_required = getStringOfParamsByTitle1(paramList, 'Email');
    const msg_email_invalid = getStringOfParamsByTitle2(paramList, 'Email');
    const emailErrors: Array<string> = [];
    evaluateList(value === '', msg_email_required, emailErrors);
    evaluateList(!isValidEmail(value) && value !== '', msg_email_invalid, emailErrors);
    setEmailInputErrors(emailErrors);
  };

  const evalPhoneInput = (value: string) => {
    setPhoneInput(value);
    const msg_phone_required = getStringOfParamsByTitle1(paramList, 'Phone');
    const msg_phone_invalid = getStringOfParamsByTitle2(paramList, 'Phone');

    const phoneErrors: Array<string> = [];
    evaluateList(value === '', msg_phone_required, phoneErrors);
    evaluateList(!isValidPhone(value), msg_phone_invalid, phoneErrors);
    setPhoneInputErrors(phoneErrors);
  };

  const evalJobInput = (value: string) => {
    setJobInput(value);
    const msg_job_required = getStringOfParamsByTitle1(paramList, 'Job');
    const jobErrors: Array<string> = [];
    evaluateList(value === '', msg_job_required, jobErrors);
    setJobInputErrors(jobErrors);
  };

  const evalCVInput = (value: string): string[] => {
    setCvInput(value);
    // const msg_email_required = "CV is required";
    const message_required = getStringOfParamsByTitle1(paramList, 'CVUrl');
    const message_filename_invalid = getStringOfParamsByTitle2(paramList, 'CVUrl');
    const cvErrors: Array<string> = [];
    evaluateList(value === '', message_required, cvErrors);

    const divisiones = value.replace('\\', '/').split('/');
    const fileNameLength = divisiones[divisiones.length - 1].length;
    evaluateList(fileNameLength > 64, message_filename_invalid, cvErrors);

    setCvInputErrors(cvErrors);
    return cvErrors;
  };

  useEffect(() => {
    const evaluateFormHasErrors = (): boolean => {
      const res =
        firstNameInput === '' ||
        emailInput === '' ||
        phoneInput === '' ||
        jobInput === '' ||
        cvInput === '' ||
        emailInputErrors.length > 0 ||
        nameInputErrors.length > 0 ||
        phoneInputErrors.length > 0 ||
        jobInputErrors.length > 0 ||
        cvInputErrors.length > 0;
      return res;
    };
    setFormHasErrors(evaluateFormHasErrors());
  }, [
    emailInputErrors,
    nameInputErrors,
    phoneInputErrors,
    /* countryInputErrors, */
    jobInputErrors,
    cvInputErrors,
  ]);

  // const baseUrl = process.env.REACT_APP_API_URL;
  const [link, setLink] = useState('');
  const [fileName, setFileName] = useState('');
  let cardRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const divisiones = link.split('/');
    setFileName(divisiones[divisiones.length - 1]);
  }, [link]);

  const sendResumeFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    let res = await sendFile(event, 'Resume');
    setLink(res);
    setCvInput(res);
  };

  const onInputClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    const element = event.target as HTMLInputElement;
    element.value = '';
  };

  const deleteResumeFile = async (fileName: string) => {
    await removeFile(fileName, 'Resume');
    evaluateFormBeforeSubmit();
    let aName = '';
    if (cardRef?.current?.files) {
      if (cardRef.current.files.length > 0) {
        aName = cardRef.current.files[0].name;
        if (!aName) aName = '';
      }
    }
    evalCVInput(aName);
  };

  const loadReCaptcha = async () => {
    let siteKey = await AppSettingsService.getInstance().getReCaptchaSiteKey();
    let apiScript = await AppSettingsService.getInstance().getRecaptchaApiScriptUrl();
    let existsRecaptcha = document.getElementById('recaptcha-script');
    if (!existsRecaptcha) {
      const script = document.createElement('script');
      script.id = 'recaptcha-script';
      script.src = apiScript + siteKey;
      document.body.appendChild(script);
    }
  };

 


  return (
    <Root className="careers-form" id={form.id ? form.id : uuidv4()}>
      <RowComponent>
        <Grid className="form-grid" container spacing={3}>
          <Grid item xs={12}>
            <h1>{formParams.Title}</h1>
          </Grid>
        </Grid>
        <Grid
          className="form-grid"
          container
          spacing={1}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} lg={6} className="form-grid-item">
            <TextField
              id={getIdOfParamsByTitle(paramList, 'Name')}
              className={`${classes.field} disable-highlight`}
              label="Name"
              value={firstNameInput}
              required
              name="Name"
              variant="standard"
              onChange={(e) => handleInputChange(e)}
              margin="normal"
              InputLabelProps={{
                classes: {
                  root: classes.inputLabel,
                  shrink: 'shrink',
                },
              }}
            />
            <div>
              {nameInputErrors.map((item, index) => (
                <p key={index} className="error-text-color">
                  {item}
                </p>
              ))}
            </div>
          </Grid>
          <Grid item xs={12} lg={6} className="form-grid-item">
            <TextField
              id={getIdOfParamsByTitle(paramList, 'Email')}
              className={`${classes.field} disable-highlight`}
              label={getStringOfParamsByTitle(paramList, 'Email')}
              value={emailInput}
              required
              // InputLabelProps={{ style: { marginLeft: "5px" } }}
              name="Email"
              onChange={(e) => handleInputChange(e)}
              margin="normal"
              variant='standard'
              InputLabelProps={{
                classes: {
                  root: classes.inputLabel,
                  shrink: 'shrink',
                },
              }}
              // inputProps={{ style: { marginLeft: "15px" } }}
            />
            <div>
              {emailInputErrors.map((item, index) => (
                <p key={index} className="error-text-color">
                  {item}
                </p>
              ))}
            </div>
          </Grid>

          <Grid item xs={12} lg={6} className="form-grid-item">
            <TextField
              select
              required
              label={getStringOfParamsByTitle(paramList, 'Job')}
              id={getIdOfParamsByTitle(paramList, 'Job')}
              className={`${classes.field} disable-highlight`}
              classes={{
                root: classes.selectedItem,
              }}
              value={jobInput}
              variant="standard"
              onChange={(e) => handleInputChange(e)}
              name="Job"
              InputLabelProps={{
                classes: {
                  root: classes.inputLabel,
                  shrink: 'shrink',
                },
              }}
              SelectProps={{
                MenuProps: { disableScrollLock: true },
              }}
            >
              {jobList
                .filter((j) => j.groupId === groupId)
                .map((item) => (
                  <MenuItem
                    key={item.id}
                    value={item.id}
                    classes={{
                      selected: classes.selectedItem,
                      root: classes.selectedItem,
                    }}
                  >
                    <p dangerouslySetInnerHTML={{ __html: item.name }} />
                  </MenuItem>
                ))}
            </TextField>

            <div>
              {jobInputErrors.map((item, index) => (
                <p key={index} className="error-text-color">
                  {item}
                </p>
              ))}
            </div>
          </Grid>
          <Grid item xs={12} lg={6} className="form-grid-item">
            <TextField
              id={getIdOfParamsByTitle(paramList, 'Phone')}
              className={`${classes.field} disable-highlight`}
              label={getStringOfParamsByTitle(paramList, 'Phone')}
              value={phoneInput}
              variant="standard"
              required
              name="Phone"
              onChange={(e) => handleInputChange(e)}
              margin="normal"
              InputLabelProps={{
                classes: {
                  root: classes.inputLabel,
                  shrink: 'shrink',
                },
              }}
            />

            <div>
              {phoneInputErrors.map((item, index) => (
                <p key={index} className="error-text-color">
                  {item}
                </p>
              ))}
            </div>
          </Grid>
        </Grid>

        <div className="form-grid-item" style={{ marginBottom: '10px' }}>
          <Grid
            className={`${classes.fullfield} form-grid`}
            container
            spacing={1}
            alignItems="flex-start"
            alignContent="center"
            justifyContent="flex-start"
          >
            <Grid
              item
              className="form-grid-item"
              style={{ width: '100%', padding: '0px!important', textAlign: 'left' }}
            >
              <form className="form-body" onSubmit={(e: FormEvent) => handleSubmit(e)}>
                <div className="field-file">
                  <Grid
                    className="form-grid"
                    container
                    spacing={2}
                    direction="row"
                    wrap="nowrap"
                    alignContent="center"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item>
                      <input
                        onClick={(a) => {
                          onInputClick(a);
                        }}
                        // hidden
                        className="file"
                        style={{ display: 'none' }}
                        type="file"
                        id="fileCV"
                        name="CV"
                        onChange={(a) => {
                          handleInputChange(a);
                        }}
                        accept=".doc,.docx,.pdf"
                      />
                      <Button className="button-styled" component="span">
                        <label style={{ cursor: 'pointer' }} htmlFor="fileCV">
                          {getStringOfParamsByTitle(paramList, 'CVUrl')}
                        </label>
                      </Button>
                    </Grid>
                    {link !== '' && link !== 'error' ? (
                      <>
                        <Grid item xs={12}>
                          <Link
                            style={{
                              color: 'white',
                              fontSize: '15px',
                              margin: 'auto',
                              display: 'block',
                            }}
                            target="_blank"
                            to={link}
                          >
                            {fileName}
                          </Link>
                        </Grid>
                        <Grid item>
                          <Button
                            name="cancelButton"
                            style={{
                              color: 'white',
                              minWidth: '0px',
                              width: '24px!important',
                              marginRight: '4px',
                            }}
                            onClick={() => {
                              deleteResumeFile(fileName);
                              setLink('');
                            }}
                          >
                            x
                          </Button>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item xs={12} />
                        <Grid item />
                      </>
                    )}
                  </Grid>
                </div>

                <div id="error-panel-id" className="form-error">
                  {cvInputErrors.map((item, index) => (
                    <p key={index} className="error-text-color">
                      {item}
                    </p>
                  ))}
                </div>
              </form>
            </Grid>
          </Grid>
        </div>

        <Grid className="form-grid" container spacing={1}>
          <Grid item xs={12} lg={12} className="form-grid-item">
            <form className="form-body" onSubmit={(e: FormEvent) => handleSubmit(e)}>
              <Button name="inputButton" disabled={formHasErrors} className={formHasErrors ? 'submit-disabled' : ''}>
                <input
                  style={{ cursor: 'pointer' }}
                  type="submit"
                  value={getStringOfParamsByTitle(paramList, 'Submit').toUpperCase()}
                />
              </Button>
            </form>
          </Grid>
        </Grid>
      </RowComponent>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {errorMessage !== "" ? errorMessage : formParams.CVMessage ? formParams.CVMessage : 'Your form has been sent'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="okButton" onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Root>
  );
};

export default CareersForm;
