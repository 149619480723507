import { BehaviorSubject } from 'rxjs';

const SnackBarNotifyBehavior = new BehaviorSubject({ isShow: false, message: '', type: 'info', durationHide: 3000 });

export const SnackBarNotify = {
  sendSnackBarNotify: (snackBarObj: any) => SnackBarNotifyBehavior.next(snackBarObj),
  clearSnackBarNotify: () =>
    SnackBarNotifyBehavior.next({ isShow: false, message: '', type: 'info', durationHide: 3000 }),
  getSnackBarNotify: () => SnackBarNotifyBehavior.getValue(),
  getSnackBarNotifyObservable: () => SnackBarNotifyBehavior.asObservable(),
};
