import React, { SetStateAction, useEffect, useLayoutEffect, useRef, useState } from 'react';
import '../styles/sass/_app.scss';
import View from '../components/View';
import { Routes, Route, useLocation,useNavigate } from 'react-router-dom';
import {  getFooter } from '../utils/utils';
import {
  getHeaderParams,
  getLinkParamsFlatten,
  getHeaderImageUrlParams,
  getHeaderColorParameter,
  getBackgroundColorParameters,
  getFontFamilyParameter,
  getMenuIconColorParameters,
  getFooterStyle,
  getColorTitleParameters,
  getObjectFit,
  getFixed,
  getFullWidth,
  getAllHeaderParams,
  getMargin,
} from '../services/ViewServices';
import { Drawer } from '@mui/material';
import SideNav from '../components/SideNav/SideNav';
import NotFound from './NotFound';
import Nav from './Navigation/Nav';
import { ComponentViewModel, FullViewModel, InitDataModel, ViewModel } from '../interfaces/models';
import { ComponentClassType } from '../interfaces/enums';
import { LinkParam } from '../interfaces/interfaces';
import FullScreenLoading from './Loading/FullscreenLoading';
import DynamicFooter from './Footer/DynamicFooter';
import InitDataService from '../services/InitDataService';

interface PageLayoutProps {
  initData: InitDataModel | undefined;
  width?: any;
  language: string | undefined;
  setLanguage: (language: SetStateAction<string>) => void;
  setLoading: (loading: boolean) => void;
  isPreview: boolean;
  mainActivityIndicator: string;
  hideBrightsoftLoader: boolean;
  hideBackground: boolean;
  setPageTitle?: ((page: string) => void) | null;
}

let initialView: FullViewModel = {
  id: '',
  name: '',
  components: [],
  language: undefined
};

let initialComponent: ComponentViewModel = {
  children: [],
  key: '',
  parameters: [],
  type: ComponentClassType.TextBlock,
  index: 0,
  id: '',
};

const PageLayout: React.FC<PageLayoutProps> = ({
  initData,
  width,
  language,
  setLanguage,
  setLoading,
  isPreview,
  mainActivityIndicator,
  hideBrightsoftLoader,
  hideBackground,
  setPageTitle
}: PageLayoutProps) => {
  const [activeView, setActiveView] = useState('home');
  const [componentfooter, setComponentfooter] = useState<ComponentViewModel>(initialComponent);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [routeList, setRouteList] = useState<LinkParam[]>([]);
  const [routeListFlatten, setRouteListFlatten] = useState<LinkParam[]>([]);
  const [type, setType] = useState('');
  const [viewStructure, setViewStructure] = useState<FullViewModel>(initialView);
  const [showComponents, setShowComponents] = useState(false);
  const [viewList, setViewList] = useState<ViewModel[]>([]);
  const [imageURLParams, setImageURLParams] = useState(['']);
  const [allHeaderParams, setAllHeaderParams] = useState({});
  const [colorParameter, setColorParameter] = useState('');
  const [colorTitleParameters, setColorTitleParameters] = useState(['']);
  const [backgroundColorParameters, setBackgroundColorParameters] = useState(['']);
  const [fontFamilyParameter, setFamilyParameter] = useState('');
  const [styleFooter, setStyleFooter] = useState('');
  const [colorMenuIconParameters, setColorMenuIcon] = useState(['']);
  const [numberComponenthashPrev,setNumberComponentHashPrev] = useState(-1);
  const [existDataStructure,setExistDataStructure] = useState(false);
  const [languageLayout,setLanguageLayout] = useState('ES');
  const [loadingPreview, setLoadingPreview] = useState(true);
  const [objectFit, setObjectFit] = useState('cover');
  const [margin, setMargin] = useState<string>('');
  const [hideLanguage, setHideLanguage] = useState(false);
  const [fixed, setFixed] = useState(true);
  const [fullWidth, setFullWidth] = useState(false);
  const [showLanguages, setShowLangauges] = useState(false);
  
  const [indexCurrentTitle, setIndexCurrentTitle] = useState(-1);
  const countDataRef = useRef(0);
  const initDataSetedRef = useRef(true);
  const location = useLocation();
  const history = useNavigate();
  useEffect(() => {
    setTitle();
    setToShowLanguages();
    getHideLanguage();
  }, []);

  const setTitle = async () => {
    let appSettings = await InitDataService.getInstance().getAppSettings();  
    document.title = appSettings.websiteTitle;
  }

  const getHideLanguage = async () => {
    let appSettings = await InitDataService.getInstance().getAppSettings();
    setHideLanguage(appSettings.hideLanguage);
  }

  const searchLanguage = () => {  
    let stringData = localStorage.getItem('ViewData');
    let data: ComponentViewModel[] = stringData ? JSON.parse(stringData) : [];
    for(let component of data){
        if(component.parameters.length > 0){
            return  component.parameters[0].groupId;
        }
       
    }
    return "ES";
  }

  
  useEffect(() => {
    initDataSetedRef.current = true;
    if (initData) {
      if (initData.menuView) {
        setViewStructure(initData.menuView);
        setComponentfooter(getFooter(initData.menuView.components));
        setViewList(initData.views);
      }
    }
    
  }, [initData]);

  const setToShowLanguages = () => {
    const intervalId = setInterval(()=> {
      let limitToWait = 3;
      if(!initDataSetedRef.current){
        countDataRef.current = countDataRef.current + 1;
      }else {
        countDataRef.current = 0;
      }
      if(countDataRef.current === limitToWait){
        clearInterval(intervalId);
        setShowLangauges(false);
        countDataRef.current = 0;
        initDataSetedRef.current = false;
        setTimeout(() => {
          setShowLangauges(true);
        }, 500);
      }
      initDataSetedRef.current = false;
    },300);
  
  }


  useEffect(() => {
    let orderedRouteList = getHeaderParams(viewStructure);
    setRouteList(orderedRouteList);
    let paramList = getHeaderImageUrlParams(viewStructure);
    setImageURLParams(paramList);

    let allParams = getAllHeaderParams(viewStructure);
    setAllHeaderParams(allParams);

    let linkParamsFlatten = getLinkParamsFlatten(viewStructure);
    setRouteListFlatten(linkParamsFlatten);
    let colorParam = getHeaderColorParameter(viewStructure);
    setColorParameter(colorParam);
    let colorTitleParams = getColorTitleParameters(viewStructure);
    setColorTitleParameters(colorTitleParams);
    let backgroundColorParameters = getBackgroundColorParameters(viewStructure);
    setBackgroundColorParameters(backgroundColorParameters);
    let fontFamily = getFontFamilyParameter(viewStructure);
    setFamilyParameter(fontFamily);
    let colorMenuParameters = getMenuIconColorParameters(viewStructure);
    setColorMenuIcon(colorMenuParameters);
    let footerStyleParameter = getFooterStyle(viewStructure);
    setStyleFooter(footerStyleParameter);
    let objectFit = getObjectFit(viewStructure);
    setObjectFit(objectFit);
    let margin = getMargin(viewStructure);
    setMargin(margin);
    let fixed = getFixed(viewStructure);
    setFixed(fixed);
    let fullWidth = getFullWidth(viewStructure);
    setFullWidth(fullWidth);
    changeHash();
  }, [viewStructure]);
  
  useEffect(() => {
    if(location.hash === ""){
      setLoading(true);
    }
  },[location.pathname])



  useLayoutEffect(() => {
    const res = width === 'xs' || width === 'sm' ? false : true;
    const res2 = width === 'xs' ? 'sm' : width === 'md' ? 'md' : width === 'sm' ? 'md' : 'lg';
    setIsOpen(res);
    setType(res2);
  }, [width]);

  useEffect(() => {
    
    if (location.hash) {
      setNumberComponentHashPrev(getHashIndexInMenuItems());
    }
  }, [languageLayout]);

  
  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    setIsOpenDrawer(open);
  };

  const changeHash = () => {
    if (location.hash && numberComponenthashPrev !== -1) {
      let headerComponent = viewStructure.components.find((component) => {
        return component.type === ComponentClassType.Header;
      });
      let menuLinkHash = headerComponent?.children[numberComponenthashPrev];
      let parameterLink = menuLinkHash?.parameters.filter((parameter) => {return parameter.type === 'Link'}) ;
      if(parameterLink && parameterLink.length > 0){
        history(parameterLink[0].value);
      }
    }
  };
  const getHashIndexInMenuItems = () => {
    let headerComponent = viewStructure.components.find((component) => {
      return component.type === ComponentClassType.Header;
    });
    let indexHashComponent = headerComponent?.children.findIndex((component) => {
      return component.parameters.find((parameter) => {
        return (
          parameter.value ===
          (location.pathname === '/'
            ? '/' + location.hash
            : location.pathname + location.hash || location.pathname.slice(0, 1) + location.hash)
        );
      });
    });
    return indexHashComponent ? indexHashComponent : -1;
  };



  const setMenuView = (header: FullViewModel, footer: ComponentViewModel) => {
    let dataComplete = false;
    if (header !== null) {
      setViewStructure(header);
      if (footer !== null) {
        dataComplete = true;
        setComponentfooter(footer);
      }
    }
    if (footer !== null && !dataComplete) {
      setComponentfooter(footer);
    }
    setExistDataStructure(true);
  };
  const openSideNav = () => {
    setIsOpenDrawer(true);
  };

  useEffect(() => {
    if(setPageTitle && indexCurrentTitle !== -1){
      
      setPageTitle(routeList[indexCurrentTitle].Text);

    }
  },[indexCurrentTitle]);

  useEffect(() => {
    setPageTitleWithIndex();
  },[routeList]);
  
  const setPageTitleWithIndex = () => {
     
    if (setPageTitle){
      if(indexCurrentTitle !== -1  && location.pathname !== '/'){
        setPageTitle(routeList[indexCurrentTitle].Text);
      }else if(location.pathname === '/'){
        setPageTitle('home');
        setIndexCurrentTitle(-1);
      }else {
        let titlePath: string =
        location.pathname.substring(1).charAt(0).toUpperCase() + location.pathname.substring(2);
        let findIndex = routeList.findIndex((link) =>  link.Text.toLowerCase() === titlePath.toLowerCase());
        setPageTitle(findIndex !== -1 ? routeList[findIndex].Text : titlePath);
        setIndexCurrentTitle(findIndex);
      }
    }
  };

  const setPageTitleWithCurrentIndex = () => {
    if(setPageTitle && indexCurrentTitle !== -1){
      setPageTitle(routeList[indexCurrentTitle].Text);
    }
  };


  const setIndexWithText = (text: string) => {
    const findIndex = routeList.findIndex((link) => link.Text === text);
    if(findIndex !== -1){
      setIndexCurrentTitle(findIndex);
    }
  };
  
  return (
    <React.Fragment key="PageLayout">
      {isPreview ?     <FullScreenLoading state={loadingPreview} hideBrightsoftLoader={hideBrightsoftLoader} mainActivityIndicator={mainActivityIndicator}/> : null}
      {showComponents && (
        <Nav
          LinkParamList={routeList}
          ImageUrlParameterList={imageURLParams}
          allParamsList={allHeaderParams}
          activeView={activeView}
          openSideNav={openSideNav}
          setCurrentLanguage={existDataStructure ? setLanguageLayout : (e) => setLanguage(e)}
          currentLanguage={ isPreview ? searchLanguage() : existDataStructure ? languageLayout : language}
          ColorParameter={colorParameter}
          ColorTitleParameter={colorTitleParameters}
          backgroundColorParameters={backgroundColorParameters}
          FontFamilyText={fontFamilyParameter}
          ColorMenuParameters={colorMenuIconParameters}
          ObjectFit={objectFit}
          HideLanguage={hideLanguage}
          Fixed={fixed}
          FullWidth={fullWidth}
          showLanguages={showLanguages}
          Margin={margin}
          setShowLanguages={setToShowLanguages}
          setIndexTitle={setIndexCurrentTitle}
          setPageTitleWithCurrentIndex={setPageTitleWithCurrentIndex}
          
        />
      )}
      <Routes>
        {generateRoutes(
          routeListFlatten,
          setActiveView,
          isOpen,
          type,
          existDataStructure ? languageLayout : language,
          viewList,
          setLoading,
          setShowComponents,
          setLanguage,
          isPreview,
          setMenuView,
          setLoadingPreview,
          mainActivityIndicator,
          hideBrightsoftLoader,
          hideBackground,
        )}
      </Routes>

      {componentfooter && showComponents ? (
        styleFooter.toLowerCase() === 'static' ? (
          <DynamicFooter
            currentLanguage={existDataStructure ? languageLayout : language}
            Component={componentfooter}
            isOpen={isOpen}
            type={type}
            setIndexWithText={setIndexWithText}
          />
        ) : (
          <DynamicFooter
            currentLanguage={existDataStructure ? languageLayout : language}
            Component={componentfooter}
            isOpen={isOpen}
            type={type}
            setIndexWithText={setIndexWithText}
          />
        )
      ) : null}

      <Drawer open={isOpenDrawer} onClose={toggleDrawer(false)}>
        <SideNav
          setIsOpen={(value) => setIsOpenDrawer(value)}
          itemList={routeList}
          activeView={activeView}
          setCurrentLanguage={ existDataStructure ? setLanguageLayout : setLanguage}
          currentLanguage={ isPreview ? searchLanguage() : existDataStructure ? languageLayout : language}
          ImageUrlParameterList={imageURLParams}
          allParamsList={allHeaderParams}
          HideLanguage={hideLanguage}
          ObjectFit={objectFit}
          showLanguages={showLanguages}
          setShowLanguages={setToShowLanguages}
          setIndexTitle={setIndexCurrentTitle}
          setPageTitleWithCurrentIndex={setPageTitleWithCurrentIndex}
        />
          
      </Drawer>
     
    </React.Fragment>
  );
};

export default (PageLayout);

/* eslint-disable */
const generateRoutes = (
  routeListFlatten: LinkParam[],
  setActiveView: React.Dispatch<React.SetStateAction<string>>,
  isOpen: boolean,
  type: string,
  language: string | undefined,
  viewList: ViewModel[],
  setLoading: (loading: boolean) => void,
  setShowComponents: React.Dispatch<React.SetStateAction<boolean>>,
  setLanguage: ( lang: string) => void,
  isPreview: boolean,
  SetMenuView: Function,
  setLoadingPreview: (loading: boolean) => void,
  mainActivityIndicator: string,
  hideBrightsoftLoader: boolean,
  hideBackground: boolean,
) => {
  const renderPreviewRoute = (item : LinkParam, index: number) =>{

    const viewId = item.ViewId;
    // this generates an error with context rendering
    // setActiveView(viewId);
    // console.log('Add Route %s', viewId);
    return(  
      <View
        isOpen={isOpen}
        activeView={viewId}
        type={type}
        language={language}
        viewList={viewList}
        setLoading={isPreview ?   setLoadingPreview: setLoading }
        setLanguage={setLanguage}
        setShowComponents={setShowComponents}
        isPreview={isPreview}
        SetMenuView = {SetMenuView}
        hideBrightsoftLoader={hideBrightsoftLoader}
        hideBackground= {hideBackground}
        setActiveView={setActiveView}
        index={index}
      />
      )
  }

  const renderRouteNotFound = () => {
    return <NotFound setLoading={isPreview ? setLoadingPreview : setLoading}/>;
  }

 
  const hasRoutes = routeListFlatten && routeListFlatten.length > 0;
  if (hasRoutes) {
    const results = routeListFlatten.map((item: LinkParam, index) => {
      
      return (
        <Route
          key={index + (language ? language : 'ES')}
          path={item.Link}
          element={renderPreviewRoute(item, index)}
        />
      );
    });
    results.push(<Route path='*' element={renderRouteNotFound()} key={'route-not-found-'+isPreview+ (language ? language : 'ES')}/>)
    return results;
  } else {
    setLoading(true);
    // console.log('Only Route is not found.');
    // return <NotFound source="PageLayout unique" />;
    return <Route path='*' element = {<FullScreenLoading state={true} mainActivityIndicator={mainActivityIndicator} hideBrightsoftLoader={hideBrightsoftLoader}/>} />;
  }
};
/* eslint-enable */