import React, { useEffect, useState } from 'react';
import { LinkParam } from '../../interfaces/interfaces';
import '../../styles/sass/sidenav/_sidenavlist.scss';
import SideNavItem from './SideNavItem';

interface SideNavListProps {
  setIsOpen: (isOpen: boolean) => void;
  itemList: LinkParam[];
  setIndexTitle: (index: number) => void;
  parentIndex?: number | null;
  mainText?: string | null;
}

const SideNavList: React.FC<SideNavListProps> = ({ itemList, setIsOpen, setIndexTitle, parentIndex, mainText }: SideNavListProps) => {
  const [lastLinkHoverLocal, setLastLinkHoverLocal] = useState('');

  return (
    <div className="side-nav-list" onMouseLeave={() => setLastLinkHoverLocal('')} title={JSON.stringify(itemList)}>
      {itemList.map((item: LinkParam, index: number) => {
        if (item.HideTitle) return null;
        return (
          <SideNavItem
            key={index}
            parentIndex={parentIndex !== null && parentIndex !== undefined  ? parentIndex : index}
            order={item.Order}
            item={item}
            setIsOpen={(value: boolean) => setIsOpen(value)}
            lastLinkHover={lastLinkHoverLocal}
            setLastLinkHover={(e) => setLastLinkHoverLocal(e)}
            setIndexTitle={setIndexTitle}
            mainText={mainText ? mainText : item.Text}
          />
        );
      })}
    </div>
  );
};

export default SideNavList;
